import { Routes } from '@angular/router'
import { NoticeComponent } from '../pages/notice/notice.component'
import { RedirectNoticesService } from '../services/redirect.notices.service'

const NoticeRoutes: Routes = [
  {
    path: 'noticia/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'auditorio/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/auditorio/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'variedades/call-of-cast/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'variedades/pod-pai-pod-filho/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'variedades/sbt-games/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'variedades/pe-na-estrada/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'variedades/sports-sunset/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'variedades/sbt-na-web/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'variedades/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/variedades/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'institucional/sbt-do-bem/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'institucional/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/institucional/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'realities/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/realities/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'talkshow/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/talkshow/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'novelas/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/novelas/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'infantil/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/infantil/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'filmes/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/filmes/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'series/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/series/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'jornalismo/sbt-sports/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'jornalismo/sbt-noticias/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'jornalismo/fofocalizando/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'jornalismo/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'afiliada/:station/jornalismo/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'especiais/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/especiais/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: 'colunas/:slugProgram/:type/:slug',
    canActivate: [RedirectNoticesService],
    component: RedirectNoticesService
  },
  {
    path: 'afiliada/:station/colunas/:slugProgram/:type/:slug',
    component: NoticeComponent
  }
]

export { NoticeRoutes }
