import { Location } from '@angular/common'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription, combineLatest, of} from 'rxjs'
import { UserService } from 'src/app/services/user.service'
import { home } from '../../app.metatags'
import Home from '../../models/home.class'
import { GeolocationService } from '../../services/geolocation.service'
import { MetatagsService } from '../../services/metatags.service'
import { StationService } from '../../services/station.service'
import { HomeService } from './home.service'
import { delay, switchMap } from 'rxjs/operators'
import { TitleService } from 'src/app/services/title.service'
import { CanonicalService } from 'src/app/services/canonical.service'
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeService, GeolocationService, StationService, CanonicalService]
})
export class HomeComponent implements OnInit, OnDestroy {
  public daysGrid: any[]
  public home: Home = new Home(null)
  public isAffiliate: boolean = false
  public subscriptionUserLogged: Subscription
  public quickAccessMenuComponents: any[] = []

  public hasProgramFav: boolean = false
  public hasStation: boolean = false

  public renderOrder: string[] = []
  public stationAdUnit: string = ''
  public stationName: string = ''

  public principalSubcription

  private titleDefault: string = 'Sistema Brasileiro de Televisão'

  constructor(
    private _service: HomeService,
    private _title: TitleService,
    private activatedRoute: ActivatedRoute,
    private stationService: StationService,
    private meta: Meta,
    private router: Router,
    private metasService: MetatagsService,
    private location: Location,
    private userService: UserService,
    private canonicalService: CanonicalService,
    private brandingService: BrandingService,
  ) {
    this.metasService.updateTags(home, this.meta)
  }

  ngOnDestroy() {
    this.principalSubcription.unsubscribe()
    this.canonicalService.removeLink()
    this.brandingService.setBranding(null)
  }

  ngDoCheck(): void {
    this.reRenderOrder()
    this.canonicalService.appendLink()

    if (this.home && this.home.quickAccessMenu) {
      if (this.quickAccessMenuComponents.length !== this.home.quickAccessMenu.length) {
        this.buildQuickAccessMenu()
        this.setQuickAccessMenu()
      }
    }
  }

  ngOnInit(): void {
    if (this.router.url === '/') {
      setTimeout(() => {
        this.reloadComponent()
      }, 240.0*1000)
    }

    let station: any = this.stationService.getStation(
      this.stationService.getStationCookie()
    )

    const combinedObservables = combineLatest(
      this.userService.getUserLogin(),
      this.activatedRoute.url
    )

    this.principalSubcription = of(null)
      .pipe(
        delay(500),
        switchMap(() => combinedObservables)
      )
      .subscribe(([{ userIsLogged, programFav }, urlSegment]) => {
        station = this.stationService.getStation(
          this.stationService.getStationCookie()
        )

        if (this.stationService.checkStationCookie()) {
          const stationIdentifier = this.stationService.getStationCookie()
          this.stationName = `_${stationIdentifier}`

          if (stationIdentifier) {
            const station = this.stationService.getStation(stationIdentifier)

            if (station && station[0]) {
              this.stationAdUnit = `_${station[0].adUnitName}`

              this.brandingService.setBranding(station[0].comscore.home)
            }
          }
        }

        if (
          localStorage.getItem('programFav') &&
          localStorage.getItem('user') &&
          userIsLogged
        ) {
          programFav = programFav || localStorage.getItem('programFav')
        }

        this.hasProgramFav = userIsLogged && programFav && programFav !== 'null'
        this.hasStation = !!station && !!station[0]

        this.setQuickAccessMenu()

        if (urlSegment.length === 0 && station.length > 0) {
          this.isAffiliate = true

          const path = station[0].affiliate
            ? `/afiliada/${station[0].identifier}`
            : `/${station[0].identifier}/home`

          const route = this.router.serializeUrl(
            this.router.createUrlTree([path])
          )

          if (this.router.url.indexOf(route) === -1) {
            this.location.replaceState(route)
          }

          this.buildPage(station[0], programFav)
        }

        if (urlSegment.length > 0 && station.length > 0) {
          this.isAffiliate = true
          this.buildPage(station[0], programFav)
        }

        if (!this.isAffiliate) {
          this._title.setTitle(`${this.titleDefault} - SBT`)

          this._service
            .getData(null, programFav)
            .then(response => this.home = response)
        }
      })
  }

  onUpdateUrlState(title) {
    if (this.stationService.checkStationCookie()) {
      const stationIdentifier = this.stationService.getStationCookie()

      if (stationIdentifier) {
        const station = this.stationService.getStation(stationIdentifier)

        if (station && station[0]) {
          //this._title.setTitle(`${station[0].title} - ${title} - SBT`)
          this._title.setTitle(`${this.titleDefault} - ${title} - SBT`)

          return
        }
      }
    }

    this._title.setTitle(`${this.titleDefault} - ${title} - SBT`)
  }

  setQuickAccessMenu(): any {
    return this.quickAccessMenuComponents.map(menu => {
      if (menu.id === 'station') {
        menu.showElement = this.hasStation
      }

      if (menu.id === 'programFav') {
        menu.showElement = this.hasProgramFav
      }

      return menu
    })
  }

  reRenderOrder() {
    const items = this.quickAccessMenuComponents
      .filter(component => component.showElement || component.title === 'Playlists')
      .map(component => component.id)

    this.renderOrder = [...items]
  }

  buildQuickAccessMenu() {
    if (this.home && this.home.quickAccessMenu) {
      this.quickAccessMenuComponents = this.home.quickAccessMenu
    }
  }

  buildPage(station: any, programFav: any): void {
    this.home = null

    if (station) {
      this.metasService.updateTags(
        [{ name: 'og:title', content: `SBT - ${station.title}` }],
        this.meta
      )

      this._service.getData(station, programFav)
        .then(response => {
          this.home = response
          //this._title.setTitle(`${station.title} - SBT`)
          this._title.setTitle(`${this.titleDefault} - SBT`)
          return this.home
        })
    } else {
      this._title.setTitle(`${this.titleDefault} - SBT`)
      this._service
        .getData(null, programFav)
        .then(response => this.home = response)
    }
  }

  //recarrega a rota, deixando de reutilizar a rota atual e dando um reload na mesma.
  reloadComponent() {
    let currentUrl = this.router.url
    if(currentUrl === '/'){
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload'
      this.router.navigate([currentUrl]);
    }
  }
}