const ALEXA_ID = '0biRI59l3UnkfcsqD8GH6QVXizM'
const REVISIT_TIME = '1 hour'
const GOOGLE_BOT = 'index, follow'
const ROBOTS = 'index, follow, max-image-preview:large'
const URL = window.location.href
const TYPE = 'website'
const LOCALE = 'pt_BR'
const SITE_NAME = 'SBT - Sistema Brasileiro de Televis&atilde;o'
const FB_APP_ID = '670550876335779'
const IMAGE = 'https://static.sbt.com.br/programas/logos/126.jpg'
const FB_PAGES =
  '132520803451684,191532334740778,307244686446293,436773339693697,716187165148569,1503209456609543,431876860204099,816993204984682,153220972096473,253475401439329,1721955458055496,341051066084935,664980390344560,1680728365547615,1624695494408795,925114444186559,1541478616126291,597110170360055,337908086289306,549386531791797,438295526238729,581880901873002,148257335319200,758323467596480,1886617128292036,1529230694003298,149370795104330,651362135005453,301921333503951,294741340553600,239001756281046,206989996006421,642975562421914,230539930447541,308253445928059,323458497763139,334098983369392,421951694545477,461914607172192,285082644915677,130240880430837,214507015246587'
const DESCRIPTION =
  'No site oficial do SBT você encontra tudo sobre a programação da TV. Artistas, celebridades,jornalismo, diversão, humor, shows, emoção, família e muito entretenimento.'
const KEYWORDS =
  'SBT, novelas, carrossel, chiquititas, chaves, Silvio Santos, eliana, celso portiolli, ratinho, Programa Silvio Santos, esquadrão da moda, câmeras escondidas, a praça é nossa, a praca e nossa, raul gil, programa raul gil, filmes, programa do ratinho, novelas mexicanas, bastidores da tv, programação SBT, casos de família, programa eliana, The Noite, Eliana, programa da maisa, silvio santos, inscricao, inscrição, INSCRIÇÃO, inscrições, sbt, as aventuras de poliana,poliana'

export const silviosantos = [
  { name: 'keywords', content: 'Silvio Santos' },
  { name: 'news_keywords', content: 'Silvio Santos' },
  {
    name: 'description',
    content:
      'Site do apresentador Silvio Santos, com biografia, curiosidades, vídeos e muito mais'
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: 'Silvio Santos' },
  {
    property: 'og:image',
    content: 'http://www.sbt.com.br/images/logos_wide/178_480x360.jpg'
  },
  {
    property: 'og:description',
    content:
      'Site do apresentador Silvio Santos, com biografia, curiosidades, vídeos e muito mais'
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  { property: 'fb:pages', content: FB_PAGES },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const downloadApp = [
  {
    name: 'keywords',
    content: KEYWORDS
  },
  {
    name: 'news_keywords',
    content: KEYWORDS
  },
  {
    name: 'description',
    content: DESCRIPTION
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: SITE_NAME },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: DESCRIPTION
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const terms = [
  {
    name: 'keywords',
    content: KEYWORDS
  },
  {
    name: 'news_keywords',
    content: KEYWORDS
  },
  {
    name: 'description',
    content: DESCRIPTION
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: 'Silvio Santos' },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: DESCRIPTION
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const siteMap = [
  {
    name: 'keywords',
    content: 'índice, silvio, sbt, canal 4'
  },
  {
    name: 'news_keywords',
    content: 'índice, silvio, sbt, canal 4'
  },
  {
    name: 'description',
    content: DESCRIPTION
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: SITE_NAME },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: DESCRIPTION
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const institutional = [
  {
    name: 'keywords',
    content:
      'Vídeos Institucionais, institucional, sbt, numeros sbt, sbt do bem,'
  },
  {
    name: 'news_keywords',
    content:
      'Vídeos Institucionais, institucional, sbt, numeros sbt, sbt do bem,'
  },
  {
    name: 'description',
    content: 'Vídeos Institucionais'
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: 'SBT - Institucional' },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: 'Vídeos Institucionais'
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const servicesAndPromotions = [
  {
    name: 'keywords',
    content: 'promoção, sbt, serviços'
  },
  {
    name: 'news_keywords',
    content: 'promoção, sbt, serviços'
  },
  {
    name: 'description',
    content: 'Promoções e Serviços'
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: 'SBT - Promoções e Serviços' },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: 'Promções e Serviços'
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const live = [
  {
    name: 'sbt:now',
    content: new Date()
  },
  {
    name: 'keywords',
    content:
      'SBT, novelas, carrossel, chiquititas, chaves, Silvio Santos, eliana, celso portiolli, ratinho, Programa Silvio Santos, esquadrão da moda, câmeras escondidas, a praça é nossa, a praca e nossa, raul gil, programa raul gil, filmes, programa do ratinho, novelas mexicanas, bastidores da tv, programação SBT, casos de família, programa eliana, amigos da onça'
  },
  {
    name: 'news_keywords',
    content:
      'SBT, novelas, carrossel, chiquititas, chaves, Silvio Santos, eliana, celso portiolli, ratinho, Programa Silvio Santos, esquadrão da moda, câmeras escondidas, a praça é nossa, a praca e nossa, raul gil, programa raul gil, filmes, programa do ratinho, novelas mexicanas, bastidores da tv, programação SBT, casos de família, programa eliana, amigos da onça'
  },
  {
    name: 'description',
    content: DESCRIPTION
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: SITE_NAME },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: DESCRIPTION
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const results = [
  {
    name: 'keywords',
    content: KEYWORDS
  },
  {
    name: 'news_keywords',
    content: KEYWORDS
  },
  {
    name: 'description',
    content: DESCRIPTION
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: SITE_NAME },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: DESCRIPTION
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const schedules = [
  {
    name: 'keywords',
    content: KEYWORDS
  },
  {
    name: 'news_keywords',
    content: KEYWORDS
  },
  {
    name: 'description',
    content: DESCRIPTION
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: SITE_NAME },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: DESCRIPTION
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const journalism = [
  {
    name: 'keywords',
    content: 'Jornalismo, SBT, SBT Brasil, Carlos Nascimento, Rachel Sheherazade, informação, notícia, Marcão do Povo, Dudu Camargo'
  },
  {
    name: 'news_keywords',
    content: 'Jornalismo, SBT, SBT Brasil, Carlos Nascimento, Rachel Sheherazade, informação, notícia, Marcão do Povo, Dudu Camargo'
  },
  {
    name: 'description',
    content: 'Liberdade editorial e credibilidade, fatores para o exercício de um jornalismo claro e transparente, são os alicerces que formam o compromisso do SBT com a notícia e a análise profunda dos fatos.'
  },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: 'Jornalismo SBT' },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: 'Liberdade editorial e credibilidade, fatores para o exercício de um jornalismo claro e transparente, são os alicerces que formam o compromisso do SBT com a notícia e a análise profunda dos fatos.'
  },
  {
    property: 'og:site_name',
    content: 'Jornalismo SBT'
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const program = [
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:site_name', content: SITE_NAME },
  { property: 'og:image', content: IMAGE },
  { property: 'fb:app_id', content: FB_APP_ID },
  { property: 'fb:pages', content: FB_PAGES },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const notice = [
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: 'article' },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:site_name', content: SITE_NAME },
  { property: 'fb:app_id', content: FB_APP_ID },
  { property: 'fb:pages', content: FB_PAGES },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]
//{ property: 'og:locale', content: LOCALE },
export const sbtVideos = [
  { name: 'keywords', content: 'SBT Vídeos, SBT, Vídeos, notícias' },
  { name: 'new_keywords', content: 'SBT Vídeos, SBT, Vídeos, notícias' },
  { name: 'description', content: DESCRIPTION },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: 'SBT Vídeos' },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: 'SBT Vídeos'
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]

export const home = [
  { name: 'keywords', content: KEYWORDS },
  { name: 'new_keywords', content: KEYWORDS },
  { name: 'description', content: DESCRIPTION },
  { name: 'revisit-after', content: REVISIT_TIME },
  { name: 'googlebot', content: GOOGLE_BOT },
  { name: 'robots', content: ROBOTS },
  { property: 'og:url', content: URL },
  { property: 'og:type', content: TYPE },
  { property: 'og:locale', content: LOCALE },
  { property: 'og:title', content: SITE_NAME },
  {
    property: 'og:image',
    content: IMAGE
  },
  {
    property: 'og:description',
    content: DESCRIPTION
  },
  {
    property: 'og:site_name',
    content: SITE_NAME
  },
  { property: 'fb:app_id', content: FB_APP_ID },
  {
    property: 'fb:pages',
    content: FB_PAGES
  },
  { name: 'alexaVerifyID', content: ALEXA_ID }
]
