import { Component, OnInit, Renderer2, Inject, Input, AfterViewInit, ViewChild, ElementRef  } from '@angular/core';
import { DOCUMENT} from '@angular/common';


@Component({
  selector: 'app-audima-banner',
  templateUrl: './audima-banner.component.html',
  styleUrls: ['./audima-banner.component.scss']

})
export class AudimaBannerComponent implements OnInit, AfterViewInit {

  
  public verify: any
  
  @Input('idnotice')
  public idnotice: number
  
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
    
    ) { }
    
    @ViewChild('audimaDiv', {static: true}) audimaDiv: ElementRef;
    ngAfterViewInit() {
      this.audimaDiv.nativeElement.id = `audimaBanner-${this.idnotice}`;

      this.verify = this.audimaDiv.nativeElement.id
      
      const audimaBanner = this.renderer.createElement('script')
      audimaBanner.text = `http = function() { try {return new XMLHttpRequest();} catch (error) {console.log(error)} try {return new ActiveXObject("Msxml2.XMLHTTP");} catch (error) {console.log(error)} try {return new ActiveXObject("Microsoft.XMLHTTP");} catch (error) {console.log(error)} throw new Error("Could not create HTTP request object."); }; placement = document.getElementById('${this.verify}'); console.log(placement); placement.innerHTML = ''; frame = document.createElement('iframe'); frame.border=0; frame.frameBorder=0; frame.width = 300; frame.height = 250; frame.title='Iframe Audima'; placement.appendChild(frame); request = http(); request.open("GET", "https://audima.co/dfp_frame_new_300x250.txt", true); request.send(null); request.onreadystatechange = function() { if (request.readyState == 4) { frame.contentWindow.document.open("text/html"); frame.contentWindow.document.write(request.responseText); frame.contentWindow.document.close(); } };`
      this.renderer.appendChild(this._document.head, audimaBanner)
  
         
    }
    
    ngOnInit() {

      
    }
}