import { Injectable } from '@angular/core'
import { URL_GEOLOCATION } from '../app.settings';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { StationService } from './station.service'

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  private httpClientWithoutInterceptor: HttpClient

  constructor (private _http: HttpClient, private handler: HttpBackend, private router: Router, private stationService: StationService) {
    this.httpClientWithoutInterceptor = new HttpClient(handler)
  }

  localizationInitial(): any {
    this.getPosition(
      position => {
        this.chooseStation(position.coords).then(response => {
          if (response.regional) {
            const identifier = this.stationService.formatStationIdentifier(
              response.regional
            )

            const currentStation = this.stationService.getStation(identifier)[0]

            if (
              !this.stationService.checkStationCookie() &&
              identifier !== 'nacional'
            ) {
              if (currentStation && currentStation.affiliate) {
                this.router.navigate([`/afiliada/${identifier}`])

                this.stationService.setStationCookie(identifier)

                return
              }

              this.router.navigate([`/${identifier}/home`])

              this.stationService.setStationCookie(identifier)
            }
          }
        })
      },
      error => {
        const errorDescription = this.catchError(error)
      }
    )
  }

  catchError (error): string {
    let descriptionError = 'Ops, '

    switch (error.code) {
      case error.PERMISSION_DENIED:
        descriptionError += 'usuário não autorizou a Geolocation.'
        break

      case error.POSITION_UNAVAILABLE:
        descriptionError += 'localização indisponível.'
        break

      case error.TIMEOUT:
        descriptionError += 'tempo expirado.'
        break

      case error.UNKNOWN_ERROR:
        descriptionError += 'não sei o que foi, mas deu erro!'
        break
    }

    return descriptionError
  }

  getPosition (successCallback: any, errorCallback: any): any {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback
      )
    }

    return null
  }
  
  chooseStation (coords: any) {
    return this.httpClientWithoutInterceptor
      .get(
        `${URL_GEOLOCATION}?lat=${coords.latitude}&lng=${coords.longitude}`
      )
      .toPromise()
      .then((response: any) => {
        response.regional = (response.regional && response.regional == 'SBT SC') ? 'Nacional' : response.regional
        if(localStorage){
          localStorage.setItem('emissora', response.regional);
        }        
        return response
      })
      .catch(error => {
        console.log(new Error(error))
      })
  }
}
