export class Thanks {
  public thumbnail: string
  public title: string
  public author: string

  constructor (object) {
    this.thumbnail = object.thumbnail
    this.title = object.title
    this.author = object.author
  }
}
