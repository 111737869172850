import Pagination from './pagination.class'

export class StayInsideNotice {
  public author: string
  public altimage: string
  public thumbnail: string
  public title: string
  public publishdate: string
  public publishdatestring: string
  public slug: string
  public program: string
  public pagination: Pagination
  public gender: string
  public idprogram: number
  public taghighlight: string

  constructor (object, pagination) {
    this.author = object.author
    this.altimage = object.altimage
    this.thumbnail = object.thumbnail
    this.title = object.title
    this.publishdate = object.publishdate
    this.publishdatestring = object.publishdatestring
    this.slug = object.slug
    this.program = object.program
    this.gender = object.gender
    this.idprogram = object.idprogram
    this.taghighlight = object.taghighlight

    if (pagination) {
      this.pagination = new Pagination(pagination)
    }
  }
}
