import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { isMobile } from '../../utils/util.browser'
import Podcast from '../../models/podcast.class'
import { Router } from '@angular/router'
import { PodcastsService } from './podcasts.service'
import { Meta } from '@angular/platform-browser'
import { MetatagsService } from '../../services/metatags.service'
import { siteMap } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { CanonicalService } from 'src/app/services/canonical.service'

@Component({
  selector: 'app-podcasts',
  templateUrl: './podcasts.component.html',
  styleUrls: ['./podcasts.component.scss'],
  providers: [PodcastsService, CanonicalService]
})
export class PodcastsComponent implements OnInit {
  @ViewChild('adsElement', {static: true})
  public adsElement: ElementRef

  public podcasts: Podcast[]
  public gendersList: string[]
  public itemsPerRow: string
  public hasAds: boolean
  public isMobile: boolean = false

  constructor (
    private route: Router,
    private podcastsService: PodcastsService,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private canonicalService: CanonicalService
  ) {
    this.metasService.updateTags(siteMap, this.meta)
  }

  ngOnDestroy (): void {
    this.canonicalService.removeLink()
  }

  ngOnInit () {
    this.canonicalService.appendLink()
    this.title.setTitle('Podcasts - SBT')
    this.podcastsService.getMedias().then(medias => {
      return (this.podcasts = medias)
    })
    this.isMobile = isMobile()

    this.itemsPerRow = '3'
  }
}
