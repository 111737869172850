import { Component, Input, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  @Input('nav-content')
  public navContent: any[]

  @Input('current-index')
  public currentItemIndex: number

  @Input('route')
  public route: string

  @Output('change-selected-value')
  public changeSelectedValue: EventEmitter<any> = new EventEmitter<any>()

  constructor (private router: Router) {
  }

  changeCurrentIndex (index: number, sectionURL: string): void {
    if(!sectionURL.includes('menu-de-conta')) this.currentItemIndex = index

    if (sectionURL.indexOf('http') === 0) {
      if (sectionURL.includes("menu-de-conta"))
        window.open(sectionURL, "_blank");
      else location.href = sectionURL;
    } else if (this.route) {
      this.router.navigate([`${this.route}/${sectionURL}`])
    }
    
    this.changeSelectedValue.emit({
      index,
      sectionURL
    })
  }
}
