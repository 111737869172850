import { Routes } from '@angular/router'
import { SchedulesComponent } from '../pages/schedules/schedules.component'
import { RedirectSchedulesService } from '../services/redirect.schedules.service'

const SchedulesRoutes: Routes = [
  {
    path: 'programacao',
    canActivate: [RedirectSchedulesService],
    component: RedirectSchedulesService
  },
  {
    path: 'afiliada/:station/programacao',
    component: SchedulesComponent
  }
]

export { SchedulesRoutes }
