import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import Program from '../../models/program.class'
import { isMobile } from '../../utils/util.browser'
import { Router } from '@angular/router'
import { ProgramsService } from './programs.service'
import { Meta } from '@angular/platform-browser'
import { MetatagsService } from '../../services/metatags.service'
import { siteMap } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { CanonicalService } from 'src/app/services/canonical.service'

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  providers: [ProgramsService, CanonicalService]
})
export class ProgramsComponent implements OnInit {
  @ViewChild('adsElement', {static: true})
  public adsElement: ElementRef

  public programs: Program[]
  public gendersList: string[]
  public itemsPerRow: string
  public hasAds: boolean
  public isMobile: boolean = false

  constructor (
    private route: Router,
    private _service: ProgramsService,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private canonicalService: CanonicalService
  ) {
    this.metasService.updateTags(siteMap, this.meta)
  }

  ngOnDestroy (): void {
    this.canonicalService.removeLink()
  }

  ngOnInit () {
    this.canonicalService.appendLink()
    this.title.setTitle('Programas - SBT')
    this._service.getPrograms().then(programs => (this.programs = programs))
    this.isMobile = isMobile()

    this.itemsPerRow = '3'
  }
}
