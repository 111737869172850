import { Injectable } from '@angular/core'

import { CookieService } from './cookie.service'
import { ANALYTICS_ID_ALLAMANDA, ANALYTICS_ID_VTV } from '../app.settings'

@Injectable()
export class StationService {
  private stations: any[] = [
    {
      id: 1,
      idsite: 216,
      idsitearea: 2397,
      idsiteareamediabox: 2088,
      identifier: 'riograndedosul',
      title: 'Rio Grande do Sul',
      logo: '../../../assets/images/stations/logo-rs.png',
      phone: '(51) 3230-5200',
      socialNetworks: {
        facebook: 'https://www.facebook.com/sbtrsoficial',
        twitter: 'https://twitter.com/sbtrs',
        youtube: 'https://www.youtube.com/user/TVSBTRS',
        instagram: 'https://www.instagram.com/sbtonline'
      },
      live: {
        idsite: 216,
        idsitearea: 2498,
        idplaylist: 10766
      },
      affiliate: false,
      adUnitName: 'Porto_Alegre',
      comscore: {
        live: 'RS',
        home: 'RS'
      }
    },
    {
      id: 2,
      idsite: 351,
      idsitearea: 2398,
      idsiteareamediabox: 2087,
      identifier: 'riodejaneiro',
      title: 'Rio de Janeiro',
      logo: '../../../assets/images/stations/logo-rio.png',
      phone: '(21) 3295-8300',
      socialNetworks: {
        facebook: 'https://www.facebook.com/sbtriooficial',
        twitter: 'https://twitter.com/sbtrio',
        youtube: 'https://www.youtube.com/user/SBTRJ',
        instagram: 'http://instagram.com/sbtriooficial'
      },
      live: {
        idsite: 351,
        idsitearea: 2395,
        idplaylist: 10596
      },
      affiliate: false,
      adUnitName: 'Rio_de_Janeiro',
      comscore: {
        live: 'Rio',
        home: 'Rio'
      }
    },
    {
      id: 3,
      idsite: 158,
      idsitearea: 2399,
      idsiteareamediabox: 2085,
      identifier: 'brasilia',
      title: 'Brasília',
      logo: '../../../assets/images/stations/logo-brasilia.png',
      phone: '(61) 3048-1500',
      socialNetworks: {
        facebook: 'https://www.facebook.com/sbt.brasilia',
        twitter: 'https://twitter.com/sbtbrasilia',
        youtube: 'https://www.youtube.com/user/TVSBTBrasilia',
        instagram: 'https://www.instagram.com/sbtonline'
      },
      live: {
        idsite: 158,
        idsitearea: 2497,
        idplaylist: 10764
      },
      affiliate: false,
      adUnitName: 'Brasilia',
      comscore: {
        live: 'Brasília',
        home: 'Brasília'
      }
    },
    {
      id: 5,
      idsite: 352,
      idsitearea: 2401,
      idsiteareamediabox: 2090,
      identifier: 'tvallamanda',
      title: 'TV Allamanda',
      logo: '../../../assets/images/stations/logo-afiliada-allamanda.png',
      phone: '(69) 3228-4000 / (69) 3228-5000',
      socialNetworks: {
        facebook: 'https://www.facebook.com/tvallamandasbt/',
        twitter: 'https://twitter.com/sbtonline',
        youtube: 'https://www.youtube.com/channel/UC1NRbdizkANwgElzo-YiVJw',
        instagram: 'https://www.instagram.com/sbtonline'
      },
      affiliate: true,
      live: {
        idsite: 352,
        idsitearea: 2313,
        idplaylist: 16119
      },
      analyticsId: ANALYTICS_ID_ALLAMANDA,
      adUnitName: 'TV_Allamanda',
      comscore: {
        live: 'TV Allamanda',
        home: 'SBT TV Allamanda'
      }
    },
    {
      id: 6,
      idsite: 358,
      idsitearea: 2400,
      idsiteareamediabox: 2121,
      identifier: 'vtv',
      title: 'VTV',
      logo: '../../../assets/images/stations/logo-afiliada-vtv.png',
      phone: '(19) 3795-3000 / (13) 3285-1818',
      socialNetworks: {
        facebook: 'https://www.facebook.com/redevtv/',
        twitter: 'https://twitter.com/redevtv',
        youtube: 'https://www.youtube.com/jornalismovtvsbt',
        instagram: 'https://www.instagram.com/redevtv/'
      },
      live: {
        idsite: 358,
        idsitearea: 2499,
        idplaylist: 10767
      },
      affiliate: true,
      analyticsId: ANALYTICS_ID_VTV,
      adUnitName: 'VTV',
      comscore: {
        live: 'VTV',
        home: 'SBT VTV'
      }
    },
    {
      id: 0,
      identifier: 'default',
      title: 'TV SBT SP (site nacional)',
      phone: '',
      live: {
        idsite: 207,
        idsitearea: 1562,
        idplaylist: 6307
      },
      affiliate: false
    }
  ]

  public cookieName: string = 'emissora'

  constructor (private cookieService: CookieService) {}

  getStation (identifier: string): any {
    return this.stations.filter(station =>
      station.identifier.includes(identifier)
    )
  }

  getStationById (id: number): any {
    return this.stations.filter(station =>
      station.id === id
    )
  }

  setStationCookie (identifier: string): any {
    this.cookieService.set(this.cookieName, identifier)
  }

  getStationCookie (): string | undefined {
    return this.cookieService.get(this.cookieName)
  }

  deleteStationCookie (): any {
    this.cookieService.delete(this.cookieName)
  }

  checkStationCookie (): boolean {
    return this.cookieService.check(this.cookieName)
  }

  getAllStations (): any {
    return this.stations
  }

  getAllStationsIdentifiers (): any {
    return this.stations.map(station => station.identifier)
  }

  getStationByUrl (): any {
    const url: string[] = window.location.href.split('/')

    let station: any

    this.getAllStationsIdentifiers().forEach(stationId => {
      if (url.includes('afiliada')) {
        station = this.getStation(url[4])
      }

      if (url[3] === stationId) {
        station = this.getStation(url[3])
      }

      return station
    })

    return station
  }

  getUrlStation (): any {
    const url: string[] = window.location.href.split('/')
    let station
    let fragment

    if (url.includes('afiliada')) {
      fragment = url[4]
      station = fragment.split('?')[0]
    }

    return station
  }

  formatStationIdentifier (station: string): string {
    station = station.toLowerCase()
    station = station.replace('sbt', '').trim()
    station = station.replace(/\s/g, '')
    station = station.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
    station = station.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
    station = station.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
    station = station.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
    station = station.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
    station = station.replace(new RegExp('[Ç]', 'gi'), 'c')

    return station
  }
}
