export default class Station {
  public title: string
  public notices: any[]

  constructor (object) {
    if (object) {
      this.title = object.title
      this.notices = object.notices
    }
  }
}
