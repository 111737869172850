import { Injectable } from '@angular/core'
import { COMSCORE_PUBLISHER_ID } from '../app.settings'
import { Log } from '../utils/util.console'
@Injectable({
  providedIn: 'root'
})
export class ComscoreService {
  private componentName: string = 'COMSCORE'
  private previousURL = ''

  init (url: string){
    if (!document.getElementById("comscore-sdk")){
      var s = document.createElement("script"),
        el = document.getElementsByTagName("script")[0];
      s.async = true;
      s.id = "comscore-sdk"

      s.src =
        (document.location.protocol == "https:" ? "https://sb" : "http://b") +
        ".scorecardresearch.com/beacon.js";

      el.parentNode.insertBefore(s, el);
      Log( this.componentName, `Setup`, true )
    } else {
      this.sendPageView(url)
    }
  }

  sendPageView (url: string) {
    if ( this.previousURL != url ){
      setTimeout(() => {
        const COMSCORE = (window as any).COMSCORE

        if (COMSCORE) {
          fetch(`/candidate.txt?${new Date().getTime()}`).then(() => {
            COMSCORE.beacon({
              c1: "2", c2: COMSCORE_PUBLISHER_ID, options: {
                enableFirstPartyCookie: true,
                bypassUserConsentRequirementFor1PCookie: true
              }
            });
              Log( this.componentName, `Send Pageview: ${url}`, true )

            })
        }
      }, 2000)
    }
    this.previousURL = url
  }
}
