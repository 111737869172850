import { Routes } from '@angular/router'
import { LiveComponent } from '../pages/live/live.component'

const LiveRoutes: Routes = [
  {
    path: 'ao-vivo',
    component: LiveComponent
  },
  {
    path: 'afiliada/:station/ao-vivo',
    component: LiveComponent
  }
]

export { LiveRoutes }
