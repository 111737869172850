import { Component, OnInit, HostBinding, Input } from '@angular/core'

import { SecretCodeService } from './secret-code.service'

@Component({
  selector: 'app-secret-code',
  templateUrl: './secret-code.component.html',
  styleUrls: ['./secret-code.component.scss'],
  providers: [SecretCodeService]
})
export class SecretCodeComponent {
  @Input('data')
  public requestData: any

  public code: string
  public animate: boolean = false
  public animateFeedback: boolean = false
  public hitTheCode: boolean = false
  public showResult: boolean = false
  public showVideo: boolean = false
  public addResult: boolean = false
  public removePanel: boolean = false
  public feedback: string = ''
  public secretUrl: string
  public videoUrl: string = ''
  public possibleValues: string[] = [
    'Tente novamente!',
    'Quase lá!',
    'Não foi dessa vez!'
  ]

  constructor (private _service: SecretCodeService) {}

  animateChest (): void {
    this.animate = !this.animate

    this.startFeedbackAnimation()

    this.feedback = 'Digite o código secreto!'
  }

  submitCode (): void {
    const randomNumber: number = Math.floor(Math.random() * 3)
    const code: string = this.code.toLowerCase()

    if (!code || code === '') {
      this.feedback = this.possibleValues[randomNumber]

      this.startFeedbackAnimation()

      return
    }

    if (code !== '') {
      this._service
        .sendSecretCode(code, this.requestData)
        .then(response => {
          if (response && response[0] && response[0].author === code) {
            this.videoUrl = response[0].description

            this.createAnimations()
          } else {
            this.feedback = this.possibleValues[randomNumber]
            this.animateFeedback = !this.animateFeedback
          }
        })
        .catch(err => console.log(err))
    }
  }

  createAnimations (): void {
    this.hitTheCode = true

    setTimeout(() => {
      this.removePanel = true

      setTimeout(() => {
        this.addResult = true

        setTimeout(() => {
          this.showResult = true
        }, 450)
      }, 450)
    }, 450)
  }

  startFeedbackAnimation (): void {
    this.animateFeedback = false
    setTimeout(() => (this.animateFeedback = true), 300)
  }

  showSecret (): void {
    this.secretUrl = this.videoUrl
    this.showVideo = !this.showVideo
  }
}
