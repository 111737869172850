import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core'
import { FormControl } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit, OnDestroy {
  public term: string = ''
  public subscriptions = []

  @Output('search')
  public emitSearch: EventEmitter<any> = new EventEmitter()

  @Output('hide-search')
  public emitHideSearch: EventEmitter<any> = new EventEmitter()

  public searchField: FormControl = new FormControl('')

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit (): void {
    const searcFieldSusbcription = this.searchField.valueChanges.pipe(debounceTime(1000)).subscribe(term => {
      this.emitSearch.emit(term)
    })

    this.subscriptions.push(searcFieldSusbcription)
  }

  search (): void {
    this.emitSearch.emit(this.searchField.value)
  }

  hideSearchBox (): void {
    this.emitHideSearch.emit(true)
  }
}
