import { Injectable } from '@angular/core'

import { URL } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  constructor(private _http: HttpClient) {}

  getAboutPromotions(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsite=302&idsitearea=2412&idplaylist=10678&limit=1`)
      .toPromise()
      .then((response: any) => {
        return response.results
      })
  }

  getResults(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsitearea=1694`)
      .toPromise()
      .then((response: any) => {
        const promotions = response

        promotions.results = promotions.results.filter(
          promotion => promotion.opcional2 === 'P'
        )

        promotions.results.forEach(promotion => {
          promotion.title = promotion.title
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
        })

        return promotions.results
      })
  }
}
