import { Routes } from '@angular/router'
import { ProgramComponent } from '../pages/program/program.component'
import { NoticeComponent } from '../pages/notice/notice.component'
import { JournalismComponent } from '../pages/journalism/journalism.component'
import { LiveComponent } from '../pages/live/live.component'
import { InstitutionalComponent } from '../pages/institutional/institutional.component'
import { SchedulesComponent } from '../pages/schedules/schedules.component'
import { InscriptionsComponent } from '../pages/inscriptions/inscriptions.component'
import { ProgramsComponent } from '../pages/programs/programs.component'

const StationRoutes: Routes = [
  {
    path: ':station/jornalismo',
    component: JournalismComponent
  },
  {
    path: ':station/ao-vivo',
    component: LiveComponent
  },
  {
    path: ':station/inscricoes',
    component: InscriptionsComponent
  },
  {
    path: ':station/institucional',
    component: InstitutionalComponent
  },
  {
    path: ':station/programacao',
    component: SchedulesComponent
  },
  {
    path: ':station/programas',
    component: ProgramsComponent
  },
  {
    path: ':station/variedades/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/variedades/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/institucional/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/institucional/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/filmes/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/filmes/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/series/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/series/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/auditorio/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/auditorio/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/jornalismo/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/jornalismo/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/infantil/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/infantil/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/realities/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/realities/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/talkshow/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/talkshow/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/novelas/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/novelas/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/especiais/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/especiais/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/auditorio/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/variedades/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/institucional/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/realities/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/talkshow/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/novelas/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/infantil/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/filmes/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/series/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/jornalismo/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/especiais/:slugProgram/:type/:slug',
    component: NoticeComponent
  },
  {
    path: ':station/colunas/:slug',
    component: ProgramComponent
  },
  {
    path: ':station/colunas/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: ':station/colunas/:slugProgram/:type/:slug',
    component: NoticeComponent
  }
]

export { StationRoutes }
