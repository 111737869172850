import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {
  @Input('title')
  public title: string

  @Input('content')
  public content: string

  public showSpiner: boolean = true
  
  constructor() { }

  ngOnInit() {

    setTimeout(() => {
      this.showSpiner = false
    }, 1500);
  }

}
