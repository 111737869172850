import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core'

import Program from '../../models/program.class'

@Component({
  selector: 'app-menu-editions',
  templateUrl: './menu-editions.component.html',
  styleUrls: ['./menu-editions.component.scss']
})
export class MenuEditionsComponent implements OnInit {
  @Input('editions')
  editions: Program

  @Input('type')
  type: any[]

  @Input('extra-classes')
  extraClasses: string = ''

  @ViewChild('tabItens', {static: true})
  public tabItens: ElementRef

  public editionIndex = 0

  constructor () {}

  ngOnInit () {}

  setEdition (event: Event, index: number): void {
    if (this.editionIndex !== index) {
      this.editionIndex = index

      // this.setCurrentContent(content.content)
    }
  }

  scroll (left: number): void {
    this.tabItens.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  prev (): void {
    this.scroll(-100)
  }

  next (): void {
    this.scroll(100)
  }
}
