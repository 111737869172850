export class About {
  public title: string
  public description: string
  public thumbnail: string

  constructor (object) {
    this.title = object.name
    this.description = object.description
    this.thumbnail = object.thumbnail
  }
}
