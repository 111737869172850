import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { terms } from 'src/app/app.metatags';
import { CanonicalService } from 'src/app/services/canonical.service'
import { MetatagsService } from 'src/app/services/metatags.service';
import { TitleService } from 'src/app/services/title.service';
import { EcadService } from './ecad.service';

@Component({
  selector: 'app-ecad',
  templateUrl: './ecad.component.html',
  styleUrls: ['./ecad.component.scss'],
  providers: [CanonicalService]
})
export class EcadComponent implements OnInit {
  @Input('update-title')
  public updateTitle: boolean = true

  @Input() opened = true;
  public ecadResults: any[] = []
  public currentContent: number
  public active:boolean = false

  constructor (
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private ecadService: EcadService,
    private canonicalService: CanonicalService
  ) {
    this.metasService.updateTags(terms, this.meta)
  }

  ngOnDestroy(): void {
    this.canonicalService.removeLink()
  }

  ngOnInit () {
    this.canonicalService.appendLink()
    if (this.updateTitle) {
      this.title.setTitle('Ecad - SBT')
    }

    this.ecadService.get().subscribe((records: any) => {
      if (records.results) {
        records.results.forEach(res => {
          const arrYear = this.ecadResults.find(item => item['year'] === res.titleplaylist)
          if ( arrYear ) {
            arrYear['id'] = res.idplaylist
            arrYear['data'].push({
              month: res.title,
              file: res.image
            })
          } else {
            this.ecadResults.push({
              year: res.titleplaylist,
              id: res.idplaylist,
              data: [{
                month: res.title,
                file: res.image
              }]
            })       
          }
        })

        this.ecadResults.sort((a, b) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))
      }
    })
  }

  setCurrentContent(index: number): void {
    if (this.currentContent === index) {
      this.currentContent = -1

      return
    }

    this.currentContent = index

  }
} 
