export class Timeline {
  public title: string
  public thumbnail: string
  public description: string
  public opcional1: string
  public textVideo: string
  public iframeVideo: string

  constructor (object) {
    this.title = object.opcional
    this.thumbnail = object.thumbnail
    this.opcional1 = object.opcional1

    this.textVideo = `<h3>${object.title}</h3> ${object.author}`
    this.iframeVideo = object.description

    this.description = `<h3>${object.title}</h3> ${object.author} ${
      object.description
    }`
  }
}
