import { Injectable } from '@angular/core'
import { URL } from '../../app.settings'
import { StationService } from '../../services/station.service'
import Program from '../../models/program.class'
import Pagination from 'src/app/models/pagination.class'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class SbtVideosService {

  constructor (private _http: HttpClient, private _stationService: StationService) {}

  getSbtVideosData (pagination: Pagination): Promise<any> {
    return new Promise(async resolve => {
      let mediabox
      let videos
      let programs
      let highlightPrograms = []

      await this.getMediabox().then(
        mediaboxResponse => (mediabox = mediaboxResponse)
      )
      await this.getPlaylists().then(videosResponse => (videos = videosResponse))
      await this.getPrograms(pagination).then(programsResponse => {
        programs = programsResponse
      })

      videos.forEach(video => {
        this.getProgramById(video[0][0].idprogram).then(program => highlightPrograms.push(program))
      })

      /* await programs.programs.forEach(program => {
        this.getPlaylistsTotal(program.id).then(
          response => (program.playlists = response)
        )
      }) */

      return resolve({
        mediabox,
        videos,
        programs,
        highlightPrograms
      })
    })
  }

  getProgramById (id: string): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/programs?id=${id}`)
      .toPromise()
      .then((response: any) => response.results[0])
  }

  getPlaylists (): Promise<any> {
    return this._http
      .get<any>(
      `${URL}/api/playlists?idsite=211&idsitearea=1065&limit=100&orderby=startdate&sort=desc`)
      .toPromise()
      .then((response: any) => {
        return Promise.all(
          response.results.map(
            result => this.getMediaProgramsVideos(result.id)
          )
        )
      })
  }

  getMediaProgramsVideos (playlist: string): Promise<any> {
    return this._http
      .get<any>(
      `${URL}/api/medias?idplaylist=${playlist}`)
      .toPromise()
      .then(async (response: any) => {
        return Promise.all(
          response.results.map(
            result => this.getVideosByProgram(result.opcional3)
          )
        )
      })
  }

  getMediabox (): Promise<any> {
    const hasStationCookie: boolean = this._stationService.checkStationCookie()

    if (hasStationCookie) {
      const stationIdentifier: string = this._stationService.getStationCookie()

      const station: any = this._stationService.getStation(stationIdentifier)

      if (station && station[0]) {
        return this._http
          .get<any>(
            `${URL}/api/medias?idsite=211&idsitearea=${
              station[0].idsiteareamediabox
            }&limit=4`
          )
          .toPromise()
          .then((response: any) => {
            if (response) {
              return response.results
            }

            return undefined
          })
      }
    }

    return this._http
      .get<any>(
        `${URL}/api/medias?idsite=211&idsitearea=1066&limit=10`
      )
      .toPromise()
      .then((response: any) => {

        return response.results
      })
  }

  getPrograms (pagination: Pagination, titleSearch?: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/programs?idregional=0&noar=S&showvideos=S${
          pagination && pagination.hasMore ? `&next=${pagination.key}` : ``
        }${
          titleSearch ? `&titlesearch=${titleSearch}` : '&limit=5'
        }`
      )
      .toPromise()
      .then((response: any) => {
        const programs: any = response

        const hasStationCookie: boolean = this._stationService.checkStationCookie()

        if (hasStationCookie) {
          const stationIdentifier: string = this._stationService.getStationCookie()

          const station: any = this._stationService.getStation(
            stationIdentifier
          )

          if (station && station[0] && !pagination) {
            return this._http
              .get<any>(
                `${URL}/api/programs?idregional=${
                  station[0].id
                }${
                  titleSearch ? `&titlesearch=${titleSearch}` : ''
                }&limit=20&noar=S&showvideos=S`
              )
              .toPromise()
              .then((regionalResponse: any) => {
                const regionalPrograms: any = regionalResponse.results.map(program => new Program(program))
                const nationalPrograms: any = programs.results.map(
                  program => new Program(program)
                )

                return {
                  programs: [...regionalPrograms, ...nationalPrograms],
                  pagination: programs.next
                }
              })
          }
        }

        return {
          programs: programs.results.map(program => new Program(program)),
          pagination: programs.next
        }
      })
      .catch(e => console.log(e))
  }

  getVideosByProgram (idprogram: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/videosyoutube?program=${idprogram}&limit=20`
      )
      .toPromise()
      .then((response: any) => {
        const videos = response.results

        videos.forEach(video => {
          video.link = video.id
        })

        return videos
      })
  }

  getPlaylistsTotal (idprogram: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/medias?idsite=211&idsitearea=1068&descriptionplaylist=${idprogram}&orderby=ordem&sort=asc`
      )
      .toPromise()
      .then((response: any) => response.results.length)
  }
}
