import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'

import { JournalismService } from './journalism.service'
import { Meta } from '@angular/platform-browser'
import { Location } from '@angular/common'

import Pagination from '../../models/pagination.class'
import { Journalism } from '../../models/journalism.class'
import { ScrollService } from '../../services/scroll.service'
import { Router, ActivatedRoute } from '@angular/router'

import { getTime, getDate } from '../../utils/util.dates'
import { MetatagsService } from '../../services/metatags.service'
import { journalism } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { CanonicalService } from 'src/app/services/canonical.service'
import { isMobile } from '../../utils/util.browser'


@Component({
  selector: 'app-journalism',
  templateUrl: './journalism.component.html',
  styleUrls: ['./journalism.component.scss'],
  providers: [JournalismService, ScrollService, CanonicalService]
})
export class JournalismComponent implements OnInit {
  @ViewChild('categoriesList', {static: true})
  public categoriesList: ElementRef

  public journalism: Journalism
  public videos: any[] = []
  public isCorrespondente: boolean = false
  public loadingContent = false
  public renderOrder: string[] = ['videos', 'lastNotices']
  public isMobile: boolean = false

  constructor (
    private _service: JournalismService,
    private title: TitleService,
    private _scroll: ScrollService,
    private location: Location,
    private router: Router,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private metasService: MetatagsService
  ) {
    this.metasService.updateTags(journalism, this.meta)

    this.journalism = new Journalism(null, null)
  }
  
  ngOnDestroy (): void {
    this.canonicalService.removeLink()
  }

  ngOnInit () {
    this.canonicalService.appendLink()
    this.isMobile = isMobile()
    this.title.setTitle(`Jornalismo - SBT`)

    this._service.getVideos().then(videos => {
      this.journalism.videos = videos
      this.videos.push(videos)
    })

    this._service
      .getMediaBox()
      .then(response => (this.journalism.mediabox = response))

    this.getNoticesByIdGender('15')

    const columnists = this._service.getColumnistList()
    this.journalism.columnists.push(...columnists)

    this._service
      .getColumnistAbout()
      .then(columnists => {
        this.journalism.columnistsAbout = columnists
      })
      .then(response => this.loadColumnistIfHaveHash())

    this._service.getHighlightedList().then(inHigh => {
      this.journalism.high = inHigh
    })
  }

  loadColumnistIfHaveHash (): void {
    if (this.checkIfHaveColumnist()) {
      if (this.journalism.columnists) {
        let columnistName: string = this.getColumnistHashName()

        columnistName = columnistName.split('-').join(' ')
        columnistName = columnistName.replace('#', '')

        const columnist = this.journalism.columnists.filter(columnist => {
          if (columnist.title.toLowerCase() === columnistName) {
            return columnist
          }
        })

        if (columnist) {
          let index = this.journalism.columnists.findIndex(
            columnist => columnist.title.toLowerCase() === columnistName
          )

          this.changeColumnist(columnist[0], index)
        }
      }
    }
  }

  checkIfHaveColumnist (): boolean {
    return window.location.hash !== ''
  }

  getColumnistHashName (): string {
    return window.location.hash
  }

  changeUrlState (content: any, isEditor: boolean): void {
    if (isEditor) {
      const route = this.router
        .createUrlTree(
          [`/jornalismo`] // Get uri
        )
        .toString()

      this.router.navigate(['/jornalismo'], { replaceUrl: true })
        .then(() => {
          this.title.setTitle(`Jornalismo - SBT`)
        })
      
      return
    }

    const fragment = `${content.title
      .toLowerCase()
      .split(' ')
      .join('-')}`

    this.router.navigate(['/jornalismo'], { replaceUrl: true, fragment })
      .then(() => {
        this.title.setTitle(`${content.title.toString()} - Jornalismo - SBT`)
      })
  }

  getNotices (term: string): void {
    const pagination = this.journalism.pagination
      ? this.journalism.pagination
      : null

    this._service.getColumnistLastNotices(term, pagination).then(notices => {
      if (pagination === null) {
        this.journalism.notices[0].medias = []
      }

      this.journalism.notices[0].medias.push(...notices.notices)

      this.journalism.notices[0].medias.forEach(media => {
        media.createdAt = `${getDate(media.publishdatestring)} às ${getTime(
          media.publishdatestring
        )}`
      })
      this.journalism.pagination = new Pagination(notices.pagination)
    })
  }

  scrollNotices(cb: Function) {
    const list = document
      .querySelector('.journalism-notices')
      .getElementsByTagName('app-lego-single-item')
    
    const total = list.length
    const ultimo = list[total - 1] as HTMLElement
       
    cb()
    setTimeout(() => {
      window.scrollTo(0, ultimo.offsetTop + 525)
    }, 50)
  }

  pushNotices (notices, op = false) {
    if(op) {          
      this.scrollNotices(() => { 
        this.journalism.notices[0].medias.push(...notices.notices)
        this.journalism.notices[0].medias.forEach(media => {
          media.createdAt = `${getDate(media.publishdatestring)} às ${getTime(
            media.publishdatestring
          )}`
        })
        this.journalism.pagination = new Pagination(notices.pagination)
      })
    } else { 
      this.journalism.notices[0].medias.push(...notices.notices)

      this.journalism.notices[0].medias.forEach(media => {
        media.createdAt = `${getDate(media.publishdatestring)} às ${getTime(
          media.publishdatestring
        )}`
      })
      this.journalism.pagination = new Pagination(notices.pagination)
    }
  }

  getNoticesByIdGender (gender: string, op:boolean = false): void {
    const pagination = this.journalism.pagination
      ? this.journalism.pagination
      : null
    this.loadingContent = true

    this._service
      .getColumnistLastNoticesByGender(gender, pagination)
      .then(notices => {
        if (pagination === null) {
          this.journalism.notices[0].medias = []
        }
        this.loadingContent = false
        
        this.pushNotices(notices, op)
      })
      .catch(() => (this.loadingContent = false))
  }

  getNoticesByProgram (program: string, op = false): void {
    const pagination = this.journalism.pagination
      ? this.journalism.pagination
      : null
    this.loadingContent = true

    this._service
      .getNoticesByProgram(program, pagination)
      .then(notices => {
        if (pagination === null) {
          this.journalism.notices[0].medias = []
        }
        this.loadingContent = false

        this.pushNotices(notices, op)
      })
      .catch(() => (this.loadingContent = false))
  }

  getNoticesByCategory (category, op = false) {
    const pagination = this.journalism.pagination
      ? this.journalism.pagination
      : null

    this._service.getNoticesByTag(category, pagination).then(notices => {
      if (pagination === null) {
        this.journalism.notices[0].medias = []
      }

      this.pushNotices(notices, op)
    })
  }

  loadMore (): void {    
    if (this.journalism.columnists[this.journalism.currentColumnistIndex].author === 'vlogs/isabelebenito') {
      this.getNoticesByProgram('440' , true)
      return
    }

    if (this.journalism.isColumnist) {
      const columnistName = this.journalism.columnists[
        this.journalism.currentColumnistIndex
      ].title.toLowerCase()

      this.getNotices(columnistName)

      return
    }
    if (this.journalism.currentCategory.toLowerCase() === 'todas') {
      this.getNoticesByIdGender('15', true)
      return
    }

    this.getNoticesByCategory(this.journalism.currentCategory, true)
  }

  setCategory (event: Event, index: number): void {
    if (this.journalism.currentCategoryIndex === index) {
      return
    }

    this.journalism.currentCategoryIndex = index
  }

  setColumnist (name: string): void {
    const columnists = this.journalism.columnistsAbout.filter(columnist =>
      columnist.title.toLowerCase().includes(name)
    )

    if (columnists && columnists.length > 0) {
      this.journalism.currentColumnist = columnists[0]

      setTimeout(() => this._scroll.scrollToId('journalism-area'), 500)

      return
    }

    this.journalism.currentColumnist = ''
  }

  hasNoticesMedias () {
    return (
      this.journalism &&
      this.journalism.notices &&
      this.journalism.notices[0] &&
      this.journalism.notices[0].medias &&
      this.journalism.notices[0].medias.length > 0
    )
  }

  changeCategory (category: string, index): void {
    if (index === this.journalism.currentCategoryIndex) {
      return
    }

    this.journalism.currentCategoryIndex = index
    this.journalism.currentCategory = category

    this.journalism.pagination = null
    if (category.toLowerCase() === 'todas') {
      this.getNoticesByIdGender('15')

      return
    }
    this.getNoticesByCategory(category)
  }

  changeColumnist (columnist: any, index: number): void {
    if (index === this.journalism.currentColumnistIndex) {
      return
    }

    const isEditor: boolean = columnist.title
      .toLowerCase()
      .includes('editoria')

    this.isCorrespondente = columnist.title
      .toLowerCase()
      .includes('correspondentes')

    this.journalism.notices[0].medias = []
    this.journalism.pagination = null

    this.journalism.currentColumnistIndex = index

    if (isEditor) {
      this.journalism.isColumnist = false

      this.getNoticesByIdGender('15')

      this.changeUrlState({}, true)

      return
    }

    this.changeUrlState(columnist, false)

    this.journalism.isColumnist = true

    this.setColumnist(columnist.title.toLowerCase())

    if (columnist.author === 'vlogs/isabelebenito') {
      this.getNoticesByProgram('440')
      return
    }
    
    this.getNotices(columnist.title.toLowerCase())
  }

  scroll (left: number): void {
    this.categoriesList.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  prev (): void {
    this.scroll(-200)
  }

  next (): void {
    this.scroll(200)
  }

  receiveData (content: any): void {
    this.changeCategory(content.title, content.index)
  }
}
