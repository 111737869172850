import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { terms } from 'src/app/app.metatags';
import { CanonicalService } from 'src/app/services/canonical.service'
import { MetatagsService } from 'src/app/services/metatags.service';
import { TitleService } from 'src/app/services/title.service';
import { PortalPolicyService } from './portal-privacy.service';

@Component({
  selector: 'app-portal-policy',
  templateUrl: './portal-privacy.component.html',
  styleUrls: ['./portal-privacy.component.scss'],
  providers: [CanonicalService]
})
export class PortalPrivacyComponent implements OnInit {
  @Input('update-title')
  public updateTitle: boolean = true
  public textPrivacy: Observable<string>
  
  constructor (
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private portalPolicy: PortalPolicyService,
    private canonicalService: CanonicalService
  ) {
    this.metasService.updateTags(terms, this.meta)
  }

  ngOnDestroy(): void {
    this.canonicalService.removeLink()
  }

  ngOnInit () {
    this.canonicalService.appendLink()
    if (this.updateTitle) {
      this.title.setTitle('Portal de Privacidade - SBT')
      this.textPrivacy = this.portalPolicy.getPortalPrivacy()
    }else{
      this.textPrivacy  = this.portalPolicy.getPortalPrivacy()
    }
  }
}
