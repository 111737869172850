import { Component, OnInit, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  @Input('videos')
  public videos: any[]

  @Input('programs')
  public programs: any[]

  @Input('slug')
  public slug: string = ''

  @Input('redirect-to-sbt-videos')
  public redirectToSbtVideos: boolean = false

  @Input('has-title')
  public hasTitle: boolean = true

  @Input('is-white')
  public isWhite: boolean = false

  @Input('title')
  public title: string = 'Vídeos'

  public sbtVideosPage: boolean = false

  constructor (private activatedRoute: ActivatedRoute) {}

  ngOnInit () {
    this.activatedRoute.snapshot.url[0].path.includes('sbt-videos') ? this.sbtVideosPage = true : this.sbtVideosPage = false
  }

  getProgram (videoSection: any[]): any {
    let idprogram
    if (videoSection[0][0])
      idprogram = videoSection[0][0].idprogram
    else
      if(videoSection[0])
        idprogram = videoSection[0].idprogram
      else
        idprogram = 0

    //const idprogram = videoSection.length > 1 ? ((videoSection[0].idprogram)?videoSection[0].idprogram:videoSection[0][0].idprogram) : videoSection[0][0].idprogram

    return this.programs ? this.programs.find(program => +program.id === +idprogram) : null
  }
}
