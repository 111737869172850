import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT} from '@angular/common';
@Component({
  selector: 'app-button-youtube',
  templateUrl: './button-youtube.component.html',
  styleUrls: ['./button-youtube.component.scss']
})

export class ButtonYoutubeComponent implements OnInit {
  
  @Input('youtube-buttons')
  public youtubeButtons: any[]
    
  constructor( 
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {}    

  ngOnInit() { 
    const buttonYoutube= this.renderer.createElement('script')
    buttonYoutube.src = 'https://apis.google.com/js/platform.js'
    this.renderer.appendChild(this._document.head, buttonYoutube)  
   }
}
