import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
  @Input('thanks')
  public thanks: any

  public thanksList: any[]

  ngOnInit (): void {
    if (!this.thanks.description) {
      this.thanksList = this.thanks
    } else {
      this.thanksList = this.thanks.thanks
    }
  }
}
