import { Injectable } from '@angular/core'
import { URL } from '../../app.settings'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class MarketOptinService {

  constructor(private _http: HttpClient) {}

  sendInfo(body: any): Promise<any> {
    return this._http
      .post<any>(
        `${URL}/api/market-optin`,
        body
      )
      .toPromise()
      .then(response => {
        console.log(response)
        return response
      })
      .catch(error => {
        console.log(error)
        return error
      })
  }

}
