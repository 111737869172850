import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { Inscription } from 'src/app/models/inscription.class'
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-card-inscriptions',
  templateUrl: './card-inscriptions.component.html',
  styleUrls: ['./card-inscriptions.component.scss']
})
export class CardInscriptionsComponent implements OnInit, OnChanges {
  @Input('content')
  public contents: Inscription[]

  @Input('route')
  public route: string

  @Output('cancel-inscription')
  public cancelInscription: EventEmitter<any> = new EventEmitter()

  public contentList: Inscription[]
  public inscription: Inscription

  constructor (private ngxSmartModalService: NgxSmartModalService, private title: TitleService) {}

  ngOnInit () {
    this.contentList = this.contents
  }

  ngOnChanges () {
    this.contentList = this.contents
  }

  removeFromTitle (title: string) {
    const currentTitle = this.title.getTitle().replace(`${title} - `, '')

    this.title.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.title.getTitle()

    this.title.setTitle(`${title} - ${currentTitle}`)
  }

  cancelInscriptionModal (inscription: Inscription): void {
    this.ngxSmartModalService.getModal('modalCancel').open()

    this.inscription = inscription
  }

  confirmDeletion (inscription: Inscription): void {
    this.ngxSmartModalService.getModal('modalCancel').close()
    this.ngxSmartModalService.getModal('modalFinish').open()

    this.cancelInscription.emit(inscription)
  }
}
