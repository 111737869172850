import Pagination from './pagination.class'

export class Inscription {
  public id: number
  public program: string
  public title: string
  public description: string
  public opcional1: string
  public opcional4: string
  public thumbnail: string
  public gender: string
  public routerLink: string
  public pagination: Pagination

  constructor (object, pagination) {
    this.id = object.id
    this.program = object.opcional
    this.title = object.title
    this.description = object.description
    this.thumbnail = object.thumbnail
    this.opcional1 = object.opcional1
    this.opcional4 = object.opcional4
    this.gender = object.gendersite
    this.routerLink = object.routerLink

    if (pagination) {
      this.pagination = new Pagination(pagination)
    }
  }
}
