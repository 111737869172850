import { Injectable } from '@angular/core'

import { URL } from '../../app.settings'
import Notice from '../../models/notice.class'
import ImageModel from '../../models/image.class'
import Pagination from '../../models/pagination.class'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class NoticeService {
  constructor (private _http: HttpClient) {}

  getNoticesByProgram (notice: Notice, pagination?: Pagination): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?program=${
          notice.idprogram
        }&limit=12&orderby=publishdate&sort=desc${
          pagination && pagination.hasMore
            ? `&next=${pagination.key}`
            : ''
        }`
      )
      .toPromise()
      .then((response: any) => {
        const notices: any = response

        return notices.results
          .filter((response: Notice) => response.id !== notice.id)
          .map(
            (notice: Notice) => new Notice(notice, notices.next)
          )
      })
      .catch(e => console.log(e))
  }

  getNotices (tags: string[]): Promise<Notice[]> {
    const index = Math.floor(Math.random() * tags.length)

    let tag: string = tags.length > 1 ? tags[index] : tags[0]

    return this._http
      .get<any>(
        `${URL}/api/notices?searchkeyword=${tag.toLowerCase()}&limit=5&orderby=publishdate&sort=desc`
      )
      .toPromise()
      .then((response: any) => {
        const notices: any = response

        return notices.results.map((notice: Notice) => new Notice(notice))
      })
      .catch(e => console.log(e))
  }

  getNotice (slug: string): Promise<any> {
    const id = parseInt(slug, 10)
    return this._http
      .get<any>(`${URL}/api/notices?id=${id}&limit=1`)
      .toPromise()
      .then((response: any) => {
        const notice: any = response

        if (notice && notice.results && notice.results.length === 0) {
          return {
            loaded: false
          }
        }

        return new Notice(notice.results[0], notice.next)
      })
      .catch(e => console.log(e))
  }

  getNextNotice (notice: Notice): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?limit=1&orderby=publishdate&sort=desc&program=${
          notice.idprogram
        }${
          notice.pagination && notice.pagination.hasMore
            ? `&next=${notice.pagination.key}`
            : ''
        }`
      )
      .toPromise()
      .then((response: any) => {
        const notice: any = response

        return new Notice(notice.results[0], notice.next)
      })
      .catch(e => console.log(e))
  }

  getGalleryImages (idPlaylist: number): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idplaylist=${idPlaylist}&limit=40`)
      .toPromise()
      .then((response: any) => response.results)
  }
}
