export class Tip {
  public title: string
  public thumbnail: string
  public publishedAt: string
  public slug: string

  constructor (object) {
    this.title = object.title
    this.thumbnail = object.thumbnail
    this.publishedAt = object.publishdatestring
    this.slug = object.slug
  }
}
