import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core'

@Component({
  selector: 'app-vertical-scroll-content',
  templateUrl: './vertical-scroll-content.component.html',
  styleUrls: ['./vertical-scroll-content.component.scss']
})
export class VerticalScrollContentComponent implements OnInit {
  @Input('content')
  public contents: any[]

  @Input('title')
  public title: string

  @ViewChild('tabItens', {static: false})
  public tabItens: ElementRef

  public contentIndex: number = 0
  public currentContent: any

  ngOnInit () {
    this.currentContent = this.contents[0]
  }

  setCurrentContent (content: any): void {
    this.currentContent = content
  }

  setContent (event: Event, index: number, content: any): void {
    if (this.contentIndex !== index) {
      this.contentIndex = index

      this.setCurrentContent(content)
    }
  }

  scroll (left: number): void {
    this.tabItens.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  prev (): void {
    this.scroll(-100)
  }

  next (): void {
    this.scroll(100)
  }
}
