import { Injectable } from '@angular/core'

import { URL } from '../../app.settings'
import Program from '../../models/program.class'
import { StationService } from '../../services/station.service'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

  constructor (private _http: HttpClient, private _stationService: StationService) {}


  getPrograms (): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/programs?idregional=0&noar=S`)
      .toPromise()
      .then((response: any) => {
        const programs: any = response

        const hasStationCookie: boolean = this._stationService.checkStationCookie()

        if (hasStationCookie) {
          const stationIdentifier: string = this._stationService.getStationCookie()

          const station: any = this._stationService.getStation(
            stationIdentifier
          )

          if (station && station[0]) {
            return this._http
              .get<any>(
                `${URL}/api/programs?noar=S&idregional=${station[0].id}`
              )
              .toPromise()
              .then((regionalResponse: any) => {
                const regionalPrograms: any = regionalResponse.results.map(program => new Program(program))
                const nationalPrograms: any = programs.results.map(
                  program => new Program(program)
                )

                return [...regionalPrograms, ...nationalPrograms]
              })
          }
        }

        return programs.results.map(program => new Program(program))
      })
      .catch(e => console.log(e))
  }
}
