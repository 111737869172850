import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core'
import { ProgrammingGridService } from './programming-grid.service'

import { getTime, generateDaysGrid } from '../../utils/util.dates'
import { ScrollService } from 'src/app/services/scroll.service';
import { isMobile } from 'src/app/utils/util.browser';

@Component({
  selector: 'app-programming-grid',
  templateUrl: './programming-grid.component.html',
  styleUrls: ['./programming-grid.component.scss'],
  providers: [ScrollService]
})
export class ProgrammingGridComponent implements OnInit {
  @Input('title')
  public title: string

  @Input('station-name')
  public stationName: string = ''

  @ViewChild('daysList', {static: true})
  public daysList: ElementRef

  @ViewChild('scheduleList', {static: false})
  public scheduleList: ElementRef

  @Input('is-home')
  public isHome: boolean = false

  public currentDayIndex: number = 3
  public currentProgramAbout: number = -1
  public daysGrid: any[] = []
  public programmingGrid: any = {
    programs: []
  }
  public loadingContent = false
  constructor (
    private _service: ProgrammingGridService,
    private scrollService: ScrollService,
  ) {}

  ngOnInit (): void {
    this.daysGrid = generateDaysGrid(10)

    const currentDate = this.daysGrid[3].formattedDate

    this.getProgramsSchedule(currentDate)
  }

  scroll (
    offset: number,
    element: ElementRef,
    direction: string = 'left'
  ): void {
    if (direction === 'up') {
      window.scrollBy({
        top: offset,
        behavior: 'smooth'
      })
    }

    if (direction === 'left') {
      element.nativeElement.scrollBy({
        left: offset,
        behavior: 'smooth'
      })
    }
  }

  showOpsMessage () {
    return (
      this.programmingGrid.programs &&
      this.programmingGrid.programs.length === 0 &&
      !this.loadingContent
    )
  }

  prev (element: string): void {
    if (element === 'days') {
      return this.scroll(-200, this.daysList)
    }

    if (element === 'schedules') {
      return this.scroll(-200, this.scheduleList)
    }
  }

  next (element: string): void {
    if (element === 'days') {
      return this.scroll(200, this.daysList)
    }

    if (element === 'schedules') {
      return this.scroll(200, this.scheduleList)
    }
  }

  setDay (index: number): void {
    if (this.currentDayIndex === index) {
      return
    }

    this.currentDayIndex = index

    const date = this.daysGrid[index].formattedDate

    this.getProgramsSchedule(date)
  }

  getProgramsSchedule (date: string): void {
    this.loadingContent = true
    this._service
      .getSchedule(date)
      .then(schedules => {
        this.programmingGrid.programs = schedules

        const currentDate = new Date()
        this.programmingGrid.programs.map((program, index) => {
          const currentProgram: any = program
          const nextProgram: any =
            index === this.programmingGrid.programs.length - 1
              ? this.programmingGrid.programs[index]
              : this.programmingGrid.programs[index + 1]

          const currentProgramTime: Date = new Date(
            currentProgram.searchstartdate
          )

          const nextProgramTime: Date = new Date(
            nextProgram.searchstartdate
          )

          const isLive: boolean =
            currentProgramTime < currentDate &&
            currentDate < nextProgramTime

          if (isLive) {
            this.programmingGrid.programs[index].live = 'S'
            this.programmingGrid.programs[index + 1].next = 'S'
          }

          if (this.programmingGrid.programs[index].live === 'S') {
            this.scrollToLive(index)
          }
        })

        this.loadingContent = false
      })
      .catch(() => {
        this.loadingContent = false
      })
  }

  scrollToLive (index: number): void {
    let cardSize: number

    isMobile() ? cardSize = 168 : cardSize = 216

    const offsetToScrollX: number = index * cardSize

    setTimeout(() => {
      const element = document.getElementById(index.toString())
      let offsetToScrollY

      if (element) {
        offsetToScrollY = element.getBoundingClientRect().top - 64
      }

      this.isHome
        ? this.scroll(offsetToScrollX, this.scheduleList, 'left')
        : this.scrollService.scrollToLive(offsetToScrollY)
    }, 300)
  }

  openInfo (event: Event, index: number): void {
    if (this.currentProgramAbout === index) {
      this.currentProgramAbout = -1

      return
    }

    this.currentProgramAbout = index
  }

  getTime (date: string): string {
    return getTime(date)
  }
}
