import { Injectable } from '@angular/core'
import Pagination from '../../models/pagination.class'
import { URL, API_GATEWAY_TOKEN } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LegoListService {

  constructor(private _http: HttpClient) {}

  async getMoreResults(endpoint: string): Promise<any> {
    return this._http
      .get<any>(endpoint)
      .toPromise()
      .then(async (response: any) => {
        const result: any = response
        const playlists: any[] = result.results
        const pagination: Pagination = new Pagination(result.next)
        playlists.forEach(async (playlist) => {
          if (pagination.hasMore) {
            playlist.pagination = pagination
            playlist.nextQuery = `${URL}/api/playlists?idsitearea=2376&playlisttitle=${playlist.title}&limit=1&orderby=startdate`
          }
          await this.getCustomMedia(`idplaylist=${playlist.id}`).then(
            medias => (playlist.medias = medias)
          )
        })

        return playlists
      })
  }

  getCustomMedia (queryString: string): any {
    return this._http
      .get<any>(`${URL}/api/medias?${queryString}&orderby=ordem&sort=desc`)
      .toPromise()
      .then((response: any) => {
        const medias: any = response.results

        return medias
      })
  }
}
