import { Injectable } from '@angular/core'

import { URL, URL_INSCRIPTIONS } from '../../app.settings'
import { Inscription } from '../../models/inscription.class'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InscriptionsService {
  constructor (private _http: HttpClient) {}

  createUserInscription (data: any): Promise<any> {
    return this._http
      .post<any>(`${URL_INSCRIPTIONS}/api/inscriptions`, data)
      .toPromise()
      .then((response: any) => {
        const createdResponse = response

        if (!createdResponse.created) {
          throw new Error(createdResponse.error)
        }

        return response
      })
      .catch(error => {
        throw new Error(error)
      })
  }

  getInscriptions (): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idplaylist=6170`)
      .toPromise()
      .then((response: any) => {
        const inscriptions = response

        return inscriptions.results.map(
          inscription => new Inscription(inscription, null)
        )
      })
      .catch(e => console.log(e))
  }

  getHighlightedInscriptions (): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/medias?idplaylist=6170&limit=12&searchopcional3=S&orderby=ordem&sort=desc`
      )
      .toPromise()
      .then((response: any) => {
        const inscriptions = response

        return inscriptions.results.map(
          inscription => new Inscription(inscription, inscriptions.next)
        )
      })
  }

  getInscriptionsById (inscriptionId: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/medias?idplaylist=6170&id=${inscriptionId}`
      )
      .toPromise()
      .then((response: any) => {
        const inscriptions: any = response

        return inscriptions.results.map(
          inscription => new Inscription(inscription, null)
        )
      })
      .catch(e => console.log(e))
  }

  cancelInscription (inscriptionId: string, userEmail: string): Promise<any> {
    return this._http
      .delete(
        `${URL_INSCRIPTIONS}/api/inscriptions/${parseInt(
          inscriptionId,
          10
        )}/${userEmail}`
      )
      .toPromise()
      .then((response: any) => response)
      .catch(err => console.log(err))
  }
}
