import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http'
import { API_GATEWAY_TOKEN } from '../app.settings'
import { Observable } from 'rxjs'

/** Pass untouched request through to the next request handler. */

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  intercept (
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${API_GATEWAY_TOKEN}`,
        'Access-Control-Allow-Origin': '*'
      }
    })
    return next.handle(authReq)
  }
}
