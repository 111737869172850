import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core'
import { Router } from '@angular/router'
import { StationService } from '../../services/station.service'

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit, AfterViewInit {
  @Input('path')
  public path: string

  @Input('sizes')
  public sizes: string

  @Input('sizesDesktop')
  public sizesDesktop: string

  @Input('sizesMobile')
  public sizesMobile: string

  @Input('wallpaper')
  public wallpaper: boolean = false

  @Input('metadata')
  public metadata: object

  @Input('renderAd')
  public renderAd: boolean = true

  @Input('margin')
  public margin: string

  @Input('padding')
  public padding: string


  public id: string

  public hideAd: boolean = false
  public adContent: any

  closeAd () {
    this.hideAd = true
  }

  constructor (
    private router: Router,
    private stationService: StationService,
    private renderer: Renderer2
  ) {}

  ngOnInit () {
    const random = (Math.random() * 1000).toFixed()
    const currentTime = new Date().getTime()
    this.id = `div-gpt-ad-1551894864222-0-${this.path}-${random}-${currentTime}`
  }

  ngAfterViewInit() {
    if (this.wallpaper && document.querySelector('.tagmanager-ad')) {
      this.adContent = document.querySelector('.tagmanager-ad');
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if (this.wallpaper && this.adContent) {
      const sticky = this.adContent.parentElement.offsetTop - 28;
      if (window.scrollY >= sticky) {
        this.adContent.classList.add('sticky')
      } else {
        this.adContent.classList.remove('sticky')
      }
    }
  }

  render () {
    if (this.wallpaper) {
      return `
        <div class="tagmanager-wrapper">
          <div id="${this.id}" (scroll)="onScroll($event)" class="tagmanager-ad wallpaper-ad" width: 100%; text-align:center;">
            <script type="text/javascript">
              googletag.cmd.push(function() {
                var slot = googletag.defineOutOfPageSlot("/1011235/${this.path}", "${this.id}");

                if(slot) {
                  slot.addService(googletag.pubads());
                  googletag.pubads().enableSingleRequest();
                  googletag.pubads().collapseEmptyDivs(true);

                  if (window.googletag && googletag.apiReady) {
                    googletag.pubads().enableLazyLoad({
                      fetchMarginPercent: 500,
                      renderMarginPercent: 200,
                      mobileScaling: 2
                    })


                    /* ---START NAVEGG--- */
                    try {
                      var name, col, persona = JSON.parse(window.localStorage.getItem("nvgpersona72620"));
                      for (col in persona) {
                          name = "nvg_" + col;
                          name = name.substring(0, 10);
                          if (typeof(googletag) == "object")
                              googletag.pubads().setTargeting(name, persona[col]);
                          if (typeof(GA_googleAddAttr) == "function")
                              GA_googleAddAttr(name, persona[col]);
                      }
                    } catch (e) {}
                    /* ---END NAVEGG--- */
                  }

                  var metadata = ${JSON.stringify(this.metadata)};
                  if (metadata) {
                    Object.keys(metadata).forEach(key => {
                      googletag.pubads().setTargeting(key, metadata[key]);
                    })
                  }
                  
                  googletag.enableServices();
                  googletag.display("${this.id}");
                }
              });
            </script>
        </div>`
    } else {
      const isTop = this.path.includes('/top');
      return `
      <div id="${this.id}" style="width: 100%; text-align:center; padding: ${(this.padding) ? this.padding : '16px 0'}; ${!isTop ? `margin: ${(this.margin) ? this.margin : '16px 0'};` : ''}">
        <script type="text/javascript">
          googletag.cmd.push(function() {
            var slot = googletag.defineSlot("/1011235/${this.path}", [${this.sizesDesktop}, ${this.sizesMobile}], "${this.id}");
            
            if(slot) {
              slot.addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.pubads().collapseEmptyDivs(true);
              
              if (window.googletag && googletag.apiReady) {
                googletag.pubads().enableLazyLoad({
                  fetchMarginPercent: 500,
                  renderMarginPercent: 200,
                  mobileScaling: 2
                })

                /* ---START NAVEGG--- */
                try {
                  var name, col, persona = JSON.parse(window.localStorage.getItem("nvgpersona72620"));
                  for (col in persona) {
                      name = "nvg_" + col;
                      name = name.substring(0, 10);
                      if (typeof(googletag) == "object")
                          googletag.pubads().setTargeting(name, persona[col]);
                      if (typeof(GA_googleAddAttr) == "function")
                          GA_googleAddAttr(name, persona[col]);
                  }
                } catch (e) {}
                /* ---END NAVEGG--- */
              }

              var metadata = ${JSON.stringify(this.metadata)};
              if (metadata) {
                Object.keys(metadata).forEach(key => {
                  googletag.pubads().setTargeting(key, metadata[key]);
                })
              }

              googletag.enableServices();

              var mapping = googletag.sizeMapping().
                addSize([900, 200], [${this.sizesDesktop}]).
                addSize([0, 0], [${this.sizesMobile}]).
                build();
              slot.defineSizeMapping(mapping);

              googletag.display("${this.id}");
            }
          });
        </script>
        </div>`
      }
  }
}
