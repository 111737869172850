import { Injectable } from '@angular/core'

import { URL } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private _http: HttpClient) {}

  getAboutServices(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsite=302&idsitearea=2412&idplaylist=10679&limit=1`)
      .toPromise()
      .then((response: any) => {
        return response.results
      })
  }

  getResults(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsitearea=1694`)
      .toPromise()
      .then((response: any) => {
        const services = response

        services.results = services.results.filter(
          promotion => promotion.opcional2 === 'S'
        )

        services.results.forEach(service => {
          service.title = service.title
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
        })

        return services.results
      })
  }
}
