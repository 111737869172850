import { regexSrc } from './util.patterns'

export const getUrlIframe = (iframe: string = '') => {
  const regex = new RegExp(regexSrc, 'g')
  const iframeSrc = Array.isArray(iframe.match(regex)) ? iframe.match(regex)[0] : null

  return iframeSrc.split('"')[1] || null
}

export const regexIndexOf = (text, regex, startpos) => {
  const indexOf = text.substring(startpos || 0).search(regex)
  return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf
}
