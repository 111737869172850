import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core'
import { log } from 'util'

@Component({
  selector: 'app-tab-cards',
  templateUrl: './tab-cards.component.html',
  styleUrls: ['./tab-cards.component.scss']
})
export class TabCardsComponent implements OnInit {
  @Input('title')
  public title: string

  @ViewChild('tabItens', {static: true})
  public tabItens: ElementRef

  public contentIndex: number = 0
  public currentContent: any

  public contents: any[] = [
    {
      title: 'Ligação',
      cards: [
        {
          cardTitle: 'Telefone pós pago (fixo ou celular)',
          observation:
            'R$ 0,39 por minuto, chamadas terminais fixos + impostos.\n\nR$ 0,71 por minuto, chamadas terminais móveis + impostos',
          cardDescription:
            'Para doar, basta fazer uma ligação para 0500 e ouvir a mensagem da Poliana até o final, e pronto! A doação é debitada diretamente da fatura do telefone.',
          values: [
            {
              valueTitle: 'Para doar 5 reais',
              valueDescription: '0500 12345 05'
            },
            {
              valueTitle: 'Para doar 20 reais',
              valueDescription: '0500 12345 20'
            },
            {
              valueTitle: 'Para doar 40 reais',
              valueDescription: '0500 12345 40'
            }
          ]
        },
        {
          cardTitle: '0800 770 1231',
          cardDescription: `O atendimento pelos voluntários através do 0800 estará disponível apenas no sábado Teleton, 10 de novembro.\n\nSerá possível fazer as doações via Boleto Bancário e Cartão de Crédito.\n\nPelo 0800 também será possível solicitar os bonecos Tonzinho e Nina.`
        }
      ]
    },
    {
      title: 'Opções Bancárias',
      cards: [
        {
          cardTitle: 'Boleto bancário ou cartão de crédito',
          cardDescription:
            'É possível realizar a doação através do Site,  SMS ou WhatsApp.',
          values: [
            {
              valueTitle: 'Para doar via Site',
              valueDescription:
                'É só acessar teleton.org.br de um computador, smartphone ou tablet.'
            },
            {
              valueTitle: 'Para doar via SMS',
              valueDescription:
                'Basta enviar uma mensagem de texto com a letra "T” para o número 28127 e seguir as instruções'
            },
            {
              valueTitle: 'Para doar via WhatsApp',
              valueDescription:
                'É só salvar o número (11) 99539 8118 nos contatos do seu telefone e depois enviar uma mensagem com a letra ”T" e seguir as instruções'
            }
          ]
        },
        {
          cardTitle: 'Débito em conta',
          cardDescription:
            'É possível realizar a doação neste modelo através do Site.',
          values: [
            {
              valueTitle: 'Para doar via Site',
              valueDescription:
                'É só acessar teleton.org.br de um computador, smartphone ou tablet.'
            }
          ]
        },
        {
          cardTitle: 'Depósito ou transferência bancária',
          cardDescription:
            'AACD Associação de Assistência a Criança Deficiente.\nCNPJ: 60.979.457/0001-11',
          banks: [
            {
              title: 'Banco Bradesco',
              description: 'Agência: 3381-2\nCC: 11150 - 3'
            },
            {
              title: 'Banco Itaú',
              description: 'Agência: 0183\nCC: 00077-7'
            }
          ]
        }
      ]
    },
    {
      title: 'Messenger',
      cards: [
        {
          cardTitle: 'Facebook Messenger',
          cardDescription:
            'No Facebook é possível doar qualquer valor via cartão de crédito pelo MESSENGER, com atendimento do nosso robozinho. Basta enviar uma mensagem para facebook/TeletonOficial'
        }
      ]
    },
    {
      title: 'Outros',
      cards: [
        {
          cardTitle: 'Bonecos Tonzinho e Nina',
          cardDescription: `Para ganhar os mascotes do Teleton, que estão com look novo, as doações podem ser feitas pelo Site, SMS ou WhatsApp.\nPara ajudar a AACD e ganhar um dos mascotes, basta doar R$100,00 e para ganhar os dois juntos, ó só doar R$ 170,00`,
          values: [
            {
              valueTitle: 'Para doar via Site',
              valueDescription:
                'É só acessar teleton.org.br de um computador, smartphone ou tablet.'
            },
            {
              valueTitle: 'Para doar via SMS',
              valueDescription:
                'Basta enviar uma mensagem de texto com a letra "T” para o número 28127 e seguir as instruções'
            },
            {
              valueTitle: 'Para doar via WhatsApp',
              valueDescription:
                'É só salvar o número (11) 99539 8118 nos contatos do seu telefone e depois enviar uma mensagem com a letra ”T" e seguir as instruções'
            }
          ]
        },
        {
          cardTitle: 'Livro Personalizado',
          cardDescription: `Para ajudar a AACD e ganhar o livro personalizado, basta doar R$60, 00 exclusivamente através do site, usando a opção "Livro”.\n\nO livro personalizado estará disponível nas doações feitas à partir do dia 24/10.`,
          values: [
            {
              valueTitle: 'Para doar via Site',
              valueDescription:
                'É só acessar teleton.org.br de um computador, smartphone ou tablet.'
            }
          ]
        },
        {
          cardTitle: 'PicPay',
          cardDescription: `Agora é possível fazer doações pelo aplicativo de pagamentos PICPAY. Para quem já tem conta no aplicativo é só selecionar o Teleton, escolher o valor e confirmar a doação.\n\nPara novos usuários, basta baixar o app no smatphone, riar uma conta e pronto, já pode fazer sua doação!`
        },
        {
          cardTitle: 'NET',
          cardDescription: `Para os assinantes da NET, é possível doar pelo canal 254, escolhendo R$ 10,00, R$ 20,00 ou R$ 40,00 direto com o controle remoto.\n\nA doação é debitada diretamente na fatura da NET.`
        }
      ]
    }
  ]

  ngOnInit () {
    this.currentContent = this.contents[0]
  }

  setContent (event: Event, index: number, content: any): void {
    if (this.contentIndex !== index) {
      this.contentIndex = index

      this.currentContent = this.contents[this.contentIndex]
    }
  }

  scroll (left: number): void {
    this.tabItens.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  prev (): void {
    this.scroll(-100)
  }

  next (): void {
    this.scroll(100)
  }
}
