import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import Notice from 'src/app/models/notice.class'
import { ProgramService } from 'src/app/pages/program/program.service'
import { generateSlug } from 'src/app/utils/utils.generators'

@Component({
  selector: 'app-highlighted-list',
  templateUrl: './highlighted-list.component.html',
  styleUrls: ['./highlighted-list.component.scss']
})
export class HighlightedListComponent implements OnInit {
  @Input('title')
  public title: string

  @Input('content')
  public content: any[]

  public noticesContent: Notice[]

  public firstContent: any
  public topContent: any[]
  public bottomContent: any[]

  public defaultImage: string = '../../../assets/images/default-image.png'

  constructor (private router: Router, private programService: ProgramService) {}

  ngOnInit () {
    this.noticesContent = []

    this.content.forEach((content: any) => {
      content = new Notice(content)

      this.programService.getProgramById(content.idprogram).then(program => {
        if (program) {
          const gender = generateSlug(program.gender)

          content.programRoute = `/${gender}/${
            program.slug
          }`

          gender === 'jornalismo'
            ? (content.type = 'noticia')
            : (content.type = 'fiquepordentro')
        }
      })
      this.noticesContent.push(content)
    })

    this.firstContent = this.noticesContent.slice(0, 1)[0]
    this.topContent = this.noticesContent.slice(1, 3)
    this.bottomContent = this.noticesContent.slice(3)
  }

  buildLink (programRoute: string, type: string, noticeSlug: string): string {
    return `${programRoute}/${type}/${noticeSlug}`
  }
}
