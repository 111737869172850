import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { Meta } from '@angular/platform-browser'

import Program from '../../models/program.class'
import { ScrollService } from '../../services/scroll.service'
import { ActivatedRoute, Router, Params } from '@angular/router'
import { MetatagsService } from '../../services/metatags.service'
import { program } from '../../app.metatags'
import { TrophyPressService } from './trophy-press.service'
import { TitleService } from 'src/app/services/title.service'
import { isMobile } from '../../utils/util.browser'

@Component({
  selector: 'app-trophy-press',
  templateUrl: './trophy-press.component.html',
  styleUrls: ['./trophy-press.component.scss'],
  providers: [TrophyPressService, ScrollService]
})
export class TrophyPressComponent implements OnInit {
  public program: any
  public loaded: boolean = false
  public renderOrder: string[]
  public hasContent: boolean
  public isMobile: boolean = false
  public quickAccessMenuComponents: any[] = [
    { id: 'about', title: 'Sobre', showElement: true },
    { id: 'notices', title: 'Fique por Dentro', showElement: true },
    { id: 'judges', title: 'Jurados', showElement: true },
    { id: 'photos', title: 'Fotos', showElement: true },
    { id: 'videos', title: 'Vídeos', showElement: true },
    { id: 'winners', title: 'Ganhadores', showElement: true }
  ]

  constructor (
    private _service: TrophyPressService,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService
  ) {}

  ngOnInit (): void {
    this.title.setTitle('Troféu Imprensa - SBT')

    this.getProgram('trofuinternet')
  }

  getProgram (slug: string) {
    this.isMobile = isMobile();
    this._service.getProgram(slug).then(response => {
      this.program = response

      const lastPhotosEdition = this.program.photosEditionsAndPlaylists[0]

      this.changePhotosEdition({ title: lastPhotosEdition.year })
    })
  }

  async changePhotosEdition ({ title }) {
    const idPlaylist = await this.program.photosEditionsAndPlaylists.filter(
      edition => edition.year === title
    )

    if (idPlaylist) {
      this._service
        .getProgramPhotos(
          this.program.idsite,
          this.program.photosSiteArea,
          idPlaylist[0].idplaylist
        )
        .then((response: any) => {
          this.program.photos = response
        })
    }
  }

  async changeJudgeYear ({ title }) {
    const idPlaylist = await this.program.judgesEditionYearsAndPlaylists.filter(
      edition => edition.year === title
    )

    if (idPlaylist) {
      this._service
        .getProgramJudges(
          this.program.idsite,
          this.program.judgesSiteArea,
          idPlaylist[0].idplaylist
        )
        .then((response: any) => {
          this.program.judges = response
        })
    }
  }

  async changeWinnersYear ({ title }) {
    const idPlaylist = await this.program.winnersEditionYearsAndPlaylists.filter(
      edition => edition.year === title
    )

    if (idPlaylist) {
      this._service
        .getProgramWinners(
          this.program.idsite,
          this.program.winnersSiteArea,
          idPlaylist[0].idplaylist
        )
        .then((response: any) => {
          const winners = response

          winners.forEach(winner => {
            let html: string

            if (winner.author && winner.author.includes('[*]')) {
              html = `
                <h2>${winner.author.replace('[*]', '')} </h2>
                <br>
                <strong>Outros participantes: </strong>
                <br>
                <span>${winner.description}</span>
                <br>
                <span>${winner.opcional}</span>`
            }

            if (winner.description && winner.description.includes('[*]')) {
              html = `
                <h2>${winner.description.replace('[*]', '')} </h2>
                <br>
                <strong>Outros participantes: </strong>
                <br>
                <span>${winner.author}</span>
                <br>
                <span>${winner.opcional}</span>`
            }

            if (winner.opcional && winner.opcional.includes('[*]')) {
              html = `
                <h2>${winner.opcional.replace('[*]', '')} </h2>
                <br>
                <strong>Outros participantes: </strong>
                <br>
                <span>${winner.author}</span>
                <br>
                <span>${winner.description}</span>`
            }

            winner.description = html
          })

          this.program.winners = winners
        })
    }
  }
}
