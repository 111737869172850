import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ProgramService } from 'src/app/pages/program/program.service';
import { generateTitleSlug } from 'src/app/utils/utils.generators';
import { isMobile } from 'src/app/utils/util.browser';

@Component({
  selector: 'app-video-section',
  templateUrl: './video-section.component.html',
  styleUrls: ['./video-section.component.scss']
})
export class VideoSectionComponent implements OnInit {
  @Input('section')
  public section: any

  @Input('program')
  public program: any

  @ViewChild('videos', {static: false})
  public videos: ElementRef

  @Input('has-title')
  public hasTitle: boolean = true

  @Input('slug')
  public slug: string

  @Input('is-white')
  public isWhite: boolean = false

  @Input('redirect-to-sbt-videos')
  public redirectToSbtVideos: boolean = false

  public currentVideo: string = ''
  public programAdUnit: string = ''
  public currentActiveVideo: number

  constructor (private router: Router, private programService: ProgramService) {}

  ngOnInit () {
    if (this.section.length === 1) {
      if (this.section[0].length > 1) {
        this.section = this.section[0]
      }
    }
  }

  scrollX (left: number): void {
    this.videos.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  scrollY (top: number): void {
    window.scrollBy({
      top,
      behavior: 'smooth'
    })
  }

  previousVideos (): void {
    this.scrollX(-860)
  }

  nextVideos (): void {
    this.scrollX(860)
  }

  getRouterLink (video: any) {
    const title = generateTitleSlug(video.title)

    if (this.slug) {
      return `/sbt-videos/${this.slug}/${video.idcategory}/${video.id}/${title}`
    }

    return `/sbt-videos/${this.program.slug}/${video.idcategory}/${video.id}/${title}`
  }

  setCurrentVideo (video: any, index: number): any {
    if (isMobile()) {
      this.scrollY(250)
    }

    if (this.currentActiveVideo === index) {
      this.changeCurrentVideo(video)

      return
    }

    this.currentActiveVideo = index

    this.changeCurrentVideo(video)
  }

  closeVideo (): void {
    this.currentVideo = ''
    this.programAdUnit = ''
  }

  changeCurrentVideo (video: any) {
    this.programService.getProgramById(video.idprogram)
      .then(program => {
          this.programAdUnit = program.adunitname ||  `${video.gender}/${video.program.replace(/ /g, "_")}`
          const url: string = `https://www.youtube.com/watch?v=${video.id}`
          
          this.currentVideo = url
      })
  }
}
