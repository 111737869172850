import { Injectable } from '@angular/core'
import { URL,IDSITE_TERMS_POLICY, IDSITEAREA_TERMS_POLICY_B2B, PLAYLIST_ID_POLICY_B2B } from '../../app.settings'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  constructor (private _http: HttpClient) {}

  getPolicy(){
    let queryString : string = `idsite=${IDSITE_TERMS_POLICY}&idsitearea=${IDSITEAREA_TERMS_POLICY_B2B}&idplaylist=${PLAYLIST_ID_POLICY_B2B}`
    return this._http
      .get<string>(`${URL}/api/medias?limit=1&${queryString}`)
  }
}
