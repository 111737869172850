import { Routes } from '@angular/router'
import { MySbtComponent } from '../pages/my-sbt/my-sbt.component'
import { AuthGuard } from '../guards/auth.guard'

const MySbtRoutes: Routes = [
  {
    path: 'meu-sbt',
    component: MySbtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'meu-sbt/:section',
    component: MySbtComponent,
    canActivate: [AuthGuard]
  }
]

export { MySbtRoutes }
