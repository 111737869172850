import { Component, OnInit } from '@angular/core'
import { Validators, FormBuilder } from '@angular/forms'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { Meta } from '@angular/platform-browser'
import { terms } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'

import { RECAPTCHA } from 'src/app/app.settings'
import { checkCpf } from 'src/app/validators/cpf.validator';
import { checkOfAge } from 'src/app/validators/age-of.validator';
import { checkInvalidDate } from 'src/app/validators/invalid-date.validator';
import { SignalSearchService } from './signal-search.service'
import { MySbtService } from '../../pages/my-sbt/my-sbt.service'
import { debounceTime } from 'rxjs/operators'
import { StatesService } from 'src/app/services/states.service';
import { TitleService } from 'src/app/services/title.service';
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-signal-search',
  templateUrl: './signal-search.component.html',
  styleUrls: ['./signal-search.component.scss'],
  providers: [SignalSearchService]
})
export class SignalSearchComponent implements OnInit {
  public siteKey: string = RECAPTCHA
  public subscriptions = []
  public registerError: string
  public confirmEmailError: string

  public booleanOptions: any = [
    {
      value: 'Sim',
      text: 'Sim'
    },
    {
      value: 'Nao',
      text: 'Não'
    }
  ]

  public sexOptions: any = [
    {
      value: 'Masculino',
      text: 'Masculino'
    },
    {
      value: 'Feminino',
      text: 'Feminino'
    }
  ]

  public maritalOptions: any = [
    {
      value: 'Solteiro',
      text: 'Solteiro'
    },
    {
      value: 'Casado',
      text: 'Casado'
    },
    {
      value: 'Divorciado',
      text: 'Divorciado'
    },
    {
      value: 'Viuvo',
      text: 'Viúvo'
    }
  ]

  public placeOptions: any = [
    {
      value: 'Apartamento',
      text: 'Apartamento'
    },
    {
      value: 'Casa',
      text: 'Casa'
    }    
  ]

  public sintonizeChanel: any = [
    {
      value: 'Antena Externa',
      text: 'Antena Externa'
    },
    {
      value: 'Antena Interna',
      text: 'Antena Interna'
    },
    
    {
      value: 'Parabolica',
      text: 'Parabólica'
    },   

    {
      value: 'TV por Assinatura',
      text: 'TV por Assinatura'
    }
  ]

  public problemSignal: any = [
    {
      value: 'Sem Sinal',
      text: 'Sem Sinal'
    },
    {
      value: 'Sinal Fraco',
      text: 'Sinal Fraco'
    }    
  ]

  public problemTime: any = [
    {
      value: 'Menos de 1 semana',
      text: 'Menos de 1 semana'
    },
    {
      value: 'Mais de 1 semana',
      text: 'Mais de 1 semana'
    },
    {
      value: 'Mais de 1 mes',
      text: 'Mais de 1 mês'
    }
  ]  

  public statesOptions: any = this.statesService.getStates()

  public pureTextPattern: string = '^[A-Za-zÀ-ú ]+$'
  public emailPattern: string = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.]{1}[a-z]{2,4}$'
  public cpfValidator: string = '^[0-9]{11}$'
  public birthValidator: string = '^[0-9]{8}$'

  public personalData = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(this.pureTextPattern)]],
    sex: [null, [Validators.required]],
    birthday: [
      '',
      [
        Validators.required,
        Validators.pattern(this.birthValidator),
        checkOfAge(18),
        checkInvalidDate()
      ]
    ],  
    cpf: ['', [Validators.required, Validators.pattern(this.cpfValidator), checkCpf()]]
  })

  public contactData = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    confirmEmail: ['', [Validators.required]],
    cep: ['', [Validators.required]],
    state: [null, Validators.required],
    city: ['', Validators.required],
    neighborhood: ['', Validators.required],
    address: ['', Validators.required],
    number: ['', Validators.required],
    additional: [''],
    place: [null, Validators.required],
    cellphone: [''],
    phone: ['']
  })

  public signalData = this.fb.group({
    haveSignal: [null, Validators.required],//obrigatorio
    tunesIn: [''],
    haveProblem: [null, Validators.required],//obrigatorio
    problem: [''],
    problemTime: [''],
    problemDescription: [''],
    acceptTerms: [false, Validators.requiredTrue],
    optin: [''],
    recaptcha: [null, Validators.required]
  }) 

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private signalSearchService: SignalSearchService,
    private mySbtService: MySbtService,
    private statesService: StatesService,
    private brandingService: BrandingService
  ) {
    this.metasService.updateTags(terms, this.meta)
  }

  ngOnDestroy() {
    this.brandingService.setBranding(null)

    this.subscriptions
      .forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit () {
    this.title.setTitle('Pesquisa de Sinal - SBT')

    this.brandingService.setBranding('Institucional')

    const cepFieldSubscription = this.contactData
      .get('cep')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(cep => {
        if (cep.length === 8 && cep != null) {
          this.mySbtService
            .getAdressByCEP(cep)
            .then(response => {
              const { uf, city, district, street } = response

              if (uf) {
                this.contactData.get('state').setValue(uf)
              }

              if (city) {
                this.contactData.get('city').setValue(city)
              }

              if (district) {
                this.contactData.get('neighborhood').setValue(district)
              }

              if (street) {
                this.contactData.get('address').setValue(street)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
        
      })
      
      //cepFieldSubscription.unsubscribe()
      const confirmEmailFieldSubscription = this.contactData
      .get('confirmEmail')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(confirmationEmail => {
        if (confirmationEmail !== this.contactData.get('email').value) {
          this.confirmEmailError = 'Esses emails não coincidem'

          return
        }

        this.confirmEmailError = undefined
      })      
      this.subscriptions.push(cepFieldSubscription, confirmEmailFieldSubscription)
  }

  removeFromTitle (title: string) {
    const currentTitle = this.title.getTitle().replace(`${title} - `, '')

    this.title.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.title.getTitle()

    this.title.setTitle(`${title} - ${currentTitle}`)
  }


  // birthday.slice(0,2) + '-' + birthday.slice(2,4) + '-' + birthday.slice(4)
  
  openFinishdata (): void {
    
    const birthdayFull:string = this.personalData.get('birthday').value
    
    const data = {
      name: this.personalData.get('name').value,
      sex: this.personalData.get('sex').value,
      cpf: this.personalData.get('cpf').value,
      birthday: birthdayFull.slice(0,2) + '-' + birthdayFull.slice(2,4) + '-' + birthdayFull.slice(4),
      email: this.contactData.get('email').value.toLowerCase(),
      cep: this.contactData.get('cep').value,
      state: this.contactData.get('state').value,
      city: this.contactData.get('city').value,
      neighborhood: this.contactData.get('neighborhood').value,
      address: this.contactData.get('address').value,
      number: this.contactData.get('number').value,
      additional: this.contactData.get('additional').value,
      place: this.contactData.get('place').value,
      cellphone: this.contactData.get('cellphone').value,
      phone: this.contactData.get('phone').value,
      haveSignal: this.signalData.get('haveSignal').value,
      tunesIn: this.signalData.get('tunesIn').value,
      haveProblem: this.signalData.get('haveProblem').value,
      problem: this.signalData.get('problem').value,
      problemTime: this.signalData.get('problemTime').value,
      problemDescription: this.signalData.get('problemDescription').value,
      optin: this.signalData.get('optin').value,
      acceptTerms: this.signalData.get('acceptTerms').value
    }

    this.signalSearchService
      .sendInfo(data)
      .then(() => {
        this.ngxSmartModalService.getModal('modalSignalSearch').open()
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
        this.resetForms()
      })
      .catch(error => {
        console.log(error)
      })
  }

  resetForms (): void {
    this.personalData.reset()
    this.contactData.reset()
    this.signalData.reset()
  }

  openTermsModal () {
    this.ngxSmartModalService
      .getModal('modalTerms')
      .addCustomClass('high-index')
    this.ngxSmartModalService.getModal('modalTerms').open()
  }

  openPolicyModal () {
    this.ngxSmartModalService
      .getModal('modalPolicy')
      .addCustomClass('high-index')
    this.ngxSmartModalService.getModal('modalPolicy').open()
  }

  isBirthDayValid () {
    return (
      this.personalData.get('birthday').errors &&
      this.personalData.get('birthday').errors.underAge &&
      (this.personalData.get('birthday').touched ||
        this.personalData.get('birthday').dirty)
    )
  }

  isDateValid () {
    return (
      this.personalData.get('birthday').errors &&
      this.personalData.get('birthday').errors.invalidDate &&
      (this.personalData.get('birthday').touched ||
        this.personalData.get('birthday').dirty)
    )
  }

  isCpfValid () {
    if(this.personalData.get('cpf').value === null) {
      return false
    }
    return (
      this.personalData.get('cpf').value.length === 11 &&
      this.personalData.get('cpf').errors &&
      this.personalData.get('cpf').errors.invalidCpf &&
      (this.personalData.get('cpf').touched ||
        this.personalData.get('cpf').dirty)
    )
  }  

}
