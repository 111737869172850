import { Injectable } from '@angular/core'

import { URL, BUCKET } from '../../app.settings'
import { Video } from '../../models/video.class'
import Pagination from '../../models/pagination.class'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class JournalismService {
  constructor (private _http: HttpClient) {}

  getColumnistList (): any {
    return [
      {
        description: '',
        author: 'correspondentes/',
        descriptionplaylist: 'Chamada Lateral Vlogs',
        opcional: '',
        image: `${BUCKET}/media/playlist/20100804112619/20150707102211/20150918163539.jpg`,
        descriptionsite: '/jornalismo/',
        id: 98924,
        title: 'Correspondentes',
        idsitearea: 1441,
        idgendersite: 15,
        idsite: 43
      }
    ]
  }

  getVideos (): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/videosyoutube?limit=20&genderid=15&regional=0`)
      .toPromise()
      .then((response: any) => {
        const videos = response

        return videos.results.filter(video => video.idprogram !== 529 && video.idprogram !== 675)
      })
  }

  getHighlightedList (): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/highlighted?type=jornalismo&limit=7`)
      .toPromise()
      .then((response: any) => {

        let medias = response.results

        return [
          {
            medias
          }
        ]
      })
  }

  getMediaBox (): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/medias?idsite=43&idsitearea=1441&idplaylist=10752&limit=10&orderby=ordem&sort=asc`
      )
      .toPromise()
      .then((response: any) => response.results)
  }

  getColumnistAbout (): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/medias?idgendersite=15&gendersite=Jornalismo&titlesitearea=Sobre&limit=10`
      )
      .toPromise()
      .then((response: any) => {
        const columnists = response

        return columnists.results
      })
  }

  getColumnistLastNotices (
    term: string,
    pagination: Pagination = null
  ): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?limit=5&idregional=0&idgender=15&searchkeyword=${term}${
          pagination && pagination.hasMore ? `&next=${pagination.key}` : ''
        }&orderby=publishdate&sort=desc`
      )
      .toPromise()
      .then((response: any) => {
        const notices = response

        return {
          notices: notices.results,
          pagination: notices.next
        }
      })
  }

  getColumnistLastNoticesByGender (
    gender: string,
    pagination: Pagination = null
  ): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?limit=10&idregional=0&idgender=${gender}${pagination && pagination.hasMore ? `&next=${pagination.key}` : ''
        }&orderby=publishdate&sort=desc`
      )
      .toPromise()
      .then((response: any) => {
        const notices = response
        
        return {
          notices: notices.results.filter(notice => notice.idprogram !== 529 && notice.idprogram !== 675 && notice.idprogram !== 682),
          pagination: notices.next
        }
      })
  }

  getNoticesByProgram (
    program: string,
    pagination: Pagination = null
  ): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?limit=5&idregional=0&program=${program}${
          pagination && pagination.hasMore ? `&next=${pagination.key}` : ''
        }&orderby=publishdate&sort=desc`
      )
      .toPromise()
      .then((response: any) => {
        const notices = response
        return {
          notices: notices.results,
          pagination: notices.next
        }
      })
  }

  getNoticesByTag (tag = '', pagination: Pagination = null): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?limit=5&idregional=0&idgender=15&searchkeyword=${tag.toLocaleLowerCase()}${
          pagination && pagination.hasMore ? `&next=${pagination.key}` : ''
        }&orderby=publishdate&sort=desc`
      )
      .toPromise()
      .then((response: any) => {
        const notices = response

        return {
          notices: notices.results,
          pagination: notices.next
        }
      })
  }
}
