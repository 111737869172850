import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input('url')
  public url: string = ''

  @Input('type')
  public type: string = ''

  @Input('route')
  public route: string

  @Input('extra-classes')
  public extraClasses: string = ''

  @Input('has-blurred-frame')
  public hasBlurredFrame: boolean = true

  @Input('has-dark-overlay')
  public hasDarkOverlay: boolean = false

  @Input('has-blue-overlay')
  public hasBlueOverlay: boolean = false

  @Input('is-only-image')
  public isOnlyImage: boolean = false

  @Input('title')
  public title: string

  @Input('alt')
  public alt: string

  @Input('logo')
  public logo: string

  @Input('reload-to-same-page')
  public reloadToSamePage: boolean = false

  constructor (private router: Router) {}

  ngOnInit () {
    if (this.reloadToSamePage) {
      this.route = this.router.url
    }
  }
}
