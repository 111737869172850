import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { ScrollService } from '../../services/scroll.service'
import { StationService } from '../../services/station.service'
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [ScrollService]
})
export class FooterComponent implements OnInit, OnDestroy {
  public phone: string = '(11) 3236 0111'
  public year: string
  public branding: string
  public subscriptions = []

  constructor (
    private router: Router,
    private scroll: ScrollService,
    private stationService: StationService,
    private brandingService: BrandingService
  ) {}

  ngOnDestroy () {
    this.brandingService.resetBranding();

    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit () {
    this.year = new Date().getFullYear().toString()

    const routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const hasStation = this.stationService.checkStationCookie()

        if (hasStation) {
          const stationCookie = this.stationService.getStationCookie()

          const station = this.stationService.getStation(stationCookie)

          if (station[0]) {
            this.phone = station[0].phone
          }
        }

        if (!hasStation) {
          const station = this.stationService.getStation('default')

          if (station[0]) {
            this.phone = station[0].phone
          }
        }

        this.brandingService.getBranding().subscribe(branding => branding ? this.branding = branding : this.branding = null)
      }
    })

    this.subscriptions.push(routeSubscription)
  }

  backToTop (): void {
    if (window.pageYOffset !== 0) {
      this.scroll.scrollToOffset(0)
    }
  }
}
