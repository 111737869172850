import { Component, Input, OnDestroy } from '@angular/core'
import { StationService } from 'src/app/services/station.service'
import { Router, NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss']
})
export class MenuFooterComponent implements OnDestroy {
  @Input('footers')
  public footers: any
  @Input('socialNetworks')
  public socialNetworks: any
  @Input('apps')
  public apps: any

  public station: string

  public subscriptions = []

  constructor(private router: Router) {
    const routeSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.includes('/afiliada')) {
          let arrUrl = event.urlAfterRedirects.split('/')

          this.station = arrUrl.splice(1, 2).join('/')

          return
        }

        this.station = ''
      }

      console.log('[MENU-FOOTER]', this.socialNetworks, this.apps, this.footers)
    })

    this.subscriptions.push(routeSubscription)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  sendToContent(link: string) {
    window.open(link, '_blank')
  }

  isInternalLink(url: string): boolean {
    const domain = (new URL(url))
    if (url.indexOf(domain.host) > -1) {
      return true
    } else {
      return false
    }
  }

  getInternalUrl(url: string): string {
    const domain = (new URL(url))
    return domain.pathname
  }
}
