import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { generateSlug, generateTitleSlug } from 'src/app/utils/utils.generators'

@Component({
  selector: 'app-lego-hibrido',
  templateUrl: './lego-hibrido.component.html',
  styleUrls: ['./lego-hibrido.component.scss']
})
export class LegoHibridoComponent implements OnInit {

  @Input('content')
  public content: any

  @Input('size')
  public size: string = 'normal'

  @Input('color')
  public color: string

  @Input('show-badge')
  public showBadge: boolean = false

  constructor (private router: Router, private cd: ChangeDetectorRef) { }

  public queryParam: Array<any> = new Array()
  public parameters: Array<any> = new Array()
  public fragment: Array<any> = new Array()  


  ngOnInit () {
    this.cd.detectChanges()
  }

  handleExternalLink(event: Event) {
    const element = event.target as HTMLElement
    const a = element.closest('a')
    const attHref = a.getAttribute('href')
    if(attHref.indexOf('/') === 0) {
      a.setAttribute('href',attHref.slice(1))
    }
  }

  goToLink(event: Event, content: any, link: string, parameters: {video: string} | null = null, fragment: string = '') {
    let newLink
    if(parameters !== null) {
      newLink = link + '?' + 'video=' + parameters.video + '#' + fragment
    } else {
      newLink = link  
    }
    if(content === 'B') {
      event.stopPropagation()
      event.preventDefault()
      const parsedLink =  newLink.indexOf('/') == 0 ? newLink.substring(1) : newLink
      window.open(parsedLink, '_blank')
    } else {
      this.router.navigateByUrl(newLink)
    }
    return false
  }


  getRouterLink (item: any, i: number): string {    
    if(item.author && item.author.includes('/')){
      if(item.author.includes('#videos')) {
        
        const newLinks = item.author.split('?')
        this.queryParam[i] = newLinks[1].slice(6,-7)
        this.parameters[i] = { video: this.queryParam[i] }
        this.fragment[i] = 'videos'
        
        return `/${newLinks[0]}`
      }
      
      return `/${item.author}`  
    }

    if (item.idprogram) {
      const gender = generateSlug(item.gender)
      const programSlug = generateTitleSlug(item.program)

      let type

      gender === 'jornalismo'
        ? (type = 'noticia')
        : (type = 'fiquepordentro')

      return `${gender}/${programSlug}/${type}/${item.slug}`
    }

    return `/noticia/${item.author}`
  }
  
  hasTag (element: any): boolean {
    if (element.taghighlight) {
      return true
    }

    if (element.program) {
      return true
    }

    if (element.opcional) {
      return true
    }

    return false
  }


}
