import { Component, OnDestroy, HostListener } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { SearchResultsService } from './search-results.service'

import { Meta } from '@angular/platform-browser'
import { MetatagsService } from '../../services/metatags.service'
import { results } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { ProgramService } from '../program/program.service'
import { isMobile } from '../../utils/util.browser'

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  providers: [SearchResultsService]
})
export class SearchResultsComponent implements OnDestroy {
  public queryString: string
  public searching: boolean = false
  public pages: any = {
    noticesPage: 0,
    videosPage: 0
  }

  public results: any = {
    notices: [],
    videos: [],
    programs: [],
    tags: [],
    highlightList: [],
    allPrograms: [],
    hasMoreNotices: false,
    hasMoreVideos: false
  }

  public subscriptions = []

  public isMobile: boolean = false

  public bannerfixed:boolean = false;

  @HostListener('window:scroll',['$event']) onscroll(){
    if(window.scrollY > 1995) {
      this.bannerfixed = true
    } else {
      this.bannerfixed = false
    }
  }

  constructor (
    private service: SearchResultsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private programService: ProgramService
  ) {
    this.metasService.updateTags(results, this.meta)

    if (!this.activatedRoute.snapshot.queryParamMap.has('busca')) {
      this.router.navigate([`/`])
    }

    const querySubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let query: string =
          this.activatedRoute.snapshot.queryParamMap.get('busca') || ''

        if (query === '') {
          return
        }

        this.queryString = query.split('-').join(' ') || ''
        this.search()
      }
    })

    this.subscriptions.push(querySubscription)

    this.title.setTitle('Resultados da Busca - SBT')
  }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit() {
    this.isMobile = isMobile()
  }

  addTags (tags: string): void {
    tags.split(',').forEach(tag => {
      let finalTag: string = tag.toLowerCase().trim()

      if (
        !this.results.tags.includes(finalTag) &&
        this.results.tags.length < 20 &&
        finalTag !== ''
      ) {
        finalTag = finalTag.split(',').join('')
        this.results.tags.push(finalTag)
      }
    })
  }

  clean (): void {
    this.results = {
      notices: [],
      videos: [],
      programs: [],
      tags: [],
      highlightList: [],
      hasMoreNotices: false,
      hasMoreVideos: false
    }
  }

  getResults (): void {
    if (this.queryString) {
      this.service.getResults(this.queryString).then((response: any) => {
        if (response) {
          this.searching = !this.searching

          if (response.notices) {
            this.results.notices.push(...response.notices)

            response.notices.forEach(notice => {
              if (notice.keywords) {
                this.addTags(notice.keywords)
              }
            })
          }

          if (response.videos) {
            this.results.videos.push(...response.videos)

            response.videos.forEach(videos => {
              if (videos.tags) {
                this.addTags(videos.tags)
              }
            })
          }

          this.programService.getPrograms().then(programs => {
            this.results.allPrograms = programs
          })

          if (response.hasMoreNotices) {
            this.results.hasMoreNotices = response.hasMoreNotices
          }

          if (response.hasMoreVideos) {
            this.results.hasMoreVideos = response.hasMoreVideos
          }

          if (response.programs) {
            this.results.programs = response.programs
          }

          this.pages.noticesPage = 0
          this.pages.videosPage = 0

          this.service.getHighlightedList().then(highlight => {
            this.results.highlightList = highlight
          })
        }
      })
    }
  }

  loadMoreNotices (): void {
    if (this.queryString) {
      this.pages.noticesPage += 1

      this.service
        .getNotices(this.queryString, this.pages.noticesPage)
        .then((response: any) => {
          if (response) {
            this.searching = !this.searching

            if (response.notices) {
              this.results.notices.push(...response.notices)

              response.notices.forEach(notice => {
                if (notice.keywords) {
                  this.addTags(notice.keywords)
                }
              })
            }

            if (response.hasMoreNotices !== undefined) {
              this.results.hasMoreNotices = response.hasMoreNotices
            }
          }
        })
    }
  }

  loadMoreVideos (): void {
    if (this.queryString) {
      this.pages.videosPage += 1

      this.service
        .getVideos(this.queryString, this.pages.videosPage)
        .then((response: any) => {
          if (response) {
            this.searching = !this.searching

            if (response.videos) {
              this.results.videos.push(...response.videos)

              response.videos.forEach(video => {
                if (video.tags) {
                  this.addTags(video.tags)
                }
              })
            }

            if (response.hasMoreVideos !== undefined) {
              this.results.hasMoreVideos = response.hasMoreVideos
            }
          }
        })
    }
  }

  search (): void {
    this.clean()
    this.getResults()
    this.searching = !this.searching
  }

  loadMore (): void {
    this.getResults()
  }
}
