import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DOMAIN_SBT_TV } from '../app.settings'

@Injectable({
    providedIn: 'root'
})
export class RedirectSchedulesService implements CanActivate {
    constructor(private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        window.location.href = `${DOMAIN_SBT_TV}/programacao`
        return true;
    }
}
