import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { SchedulesService } from './schedules.service'
import { Meta } from '@angular/platform-browser'
import { MetatagsService } from '../../services/metatags.service'
import { CanonicalService } from 'src/app/services/canonical.service'
import { schedules } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { isMobile } from '../../utils/util.browser'

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
  providers:[CanonicalService]
})
export class SchedulesComponent implements OnInit {
  public highlightList: any[]
  public isMobile: boolean = false

  constructor (
    private _service: SchedulesService,
    private title: TitleService,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private metasService: MetatagsService
  ) {}

  ngOnDestroy (): void {
    this.canonicalService.removeLink()
  }

  ngOnInit (): void {
    this.metasService.updateTags(schedules, this.meta)
    this.isMobile = isMobile()
    this.canonicalService.appendLink()
    this.title.setTitle(`Programação - SBT`)

    this._service.getHighlightedList().then(highlight => {
      this.highlightList = highlight
    })
  }
}
