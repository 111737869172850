import { Injectable } from '@angular/core';
import { encrypt, decrypt, encryptFull } from '../utils/util.encrypt'
import { Log } from '../utils/util.console'

declare let dataLayer: any

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  constructor () {}
  setAnalyticsID ( GAConfig: any ) {}
  sendPageView ( page: string, title: string, tracker: string ) {
    const userLogged = decrypt(localStorage.getItem('user') || '')
    let userEncryptedToGA = ''
    if (userLogged) userEncryptedToGA = encryptFull(userLogged['email'], '541278abcd4564654abdAc', '23123908abcde67532acc6')

    this.sendDataLayerEvent({
      event: "pageview-sbt",
      user_id: userEncryptedToGA
    })
  }
  sendEvent (action:string, category:string, label:string = '', value:number = 1) {}

  sendDataLayerEvent (obj: any): void {
    Log('GA', obj, true)
    dataLayer.push(obj)
  }
}
