import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import Notice from '../../models/notice.class'

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnChanges {
  @Input('galleryImages')
  public galleryImages: any

  @Input('type')
  public type: any[]

  @Input('reduced')
  public reduced: boolean = false

  public images: any[]

  public currentImage: any

  ngOnChanges(changes: SimpleChanges) {
    if (changes.galleryImages) {
      if (changes.galleryImages.currentValue) {
        this.images = this.chunks(changes.galleryImages.currentValue, 15)

        this.prepareGallery()
      }
    }
  }

  prepareGallery() {
    if (this.images.length > 0) {
      this.currentImage = this.images[0][0].images[0]

      this.images[0].isActive = true
    }
  }

  changeCurrentImage(element: any, image: any): void {
    this.removeActiveClasses(element)
    element.target.parentNode.classList.add('active')
    this.currentImage = image
  }

  removeActiveClasses(element: any): void {
    const { children } = element.target.parentNode.parentNode

    for (let i = 0; i < children.length; i++) {
      const galleryItem = children[i]
      galleryItem.classList.remove('active')
    }
  }

  chunks(array: any[], size: number): any[] {
    let results = []

    while (array && array.length) {
      results.push([{ images: array.splice(0, size) }])
    }

    return results
  }

  nextGridSlider(): any {
    const index = this.images.findIndex(list => {
      return list.isActive
    })

    const nextIndex = index + 1

    if (nextIndex < this.images.length) {
      this.images.map((list, index) => {
        list.isActive = index === nextIndex
      })

      this.currentImage = this.images[nextIndex][0].images[0]
    }
  }

  prevGridSlider(): any {
    const index = this.images.findIndex(list => {
      return list.isActive
    })

    const prevIndex = index - 1

    if (prevIndex >= 0) {
      this.images.map((list, index) => {
        list.isActive = index === prevIndex
      })

      this.currentImage = this.images[prevIndex][0].images[0]
    }
  }

  callGridSlider(nextIndex: number): void {
    this.images.map((list, index) => {
      list.isActive = index === nextIndex
    })
  }
}
