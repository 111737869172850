import { Injectable } from '@angular/core'

import { URL, URL_SEARCH_API } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private _http: HttpClient) {}

  search(term: string): Promise<any> {
    const notices = this._http
      .get<any>(`${URL_SEARCH_API}/api/notices?term=${term}&page=0`)
      .toPromise()

    const programs = this._http
      .get<any>(`${URL}/api/programs?titlesearch=${term}&limit=5`)
      .toPromise()

    return Promise.all([notices, programs]).then((response: any) => {
      const notices = response[0].results
      const programs = response[1].results

      return {
        notices,
        programs
      }
    })
  }
}
