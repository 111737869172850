import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent {
  @Input('title')
  public title: string

  @Input('has-search')
  public hasSearch: boolean = false

  @Output('toggle-search')
  public emitToggleSearch: EventEmitter<any> = new EventEmitter()

  toggleSearch(): void {
    this.emitToggleSearch.emit()
  }
}
