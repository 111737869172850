import { Video } from './video.class'
import { Inscription } from './inscription.class'
import Program from './program.class'
import Station from './station.class'
import Notice from './notice.class'

export default class Home {
  public quickAccessMenu: any[]
  public mediabox: any
  public programmingGrid: any
  public station: Station
  public entertainment: any
  public novels: any
  public notices: any
  public famous: any
  public videos: Video[]
  public inscriptions: Inscription[]
  public programs: Program[]
  public inHigh: any[]
  public programFavNotices: any
  public programFav: any
  public programFavTitle: string
  public programFavRoute: string
  public playlists: any[]

  constructor (object: any) {
    if (object) {
      this.quickAccessMenu = object.quickAccessMenu
      this.mediabox = object.mediabox
      this.programmingGrid = object.programmingGrid
      this.station = object.station
      this.entertainment = object.entertainment
      this.novels = object.novels
      this.notices = object.notices
      this.famous = object.famous
      this.videos = object.videos
      this.inscriptions = object.inscriptions
      this.programs = object.programs
      this.inHigh = object.inHigh
      this.programFav = object.programFav
      this.programFavTitle = object.programFavTitle
      this.programFavRoute = object.programFavRoute
      this.playlists = object.playlists
    }
  }
}
