import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { ProgramService } from 'src/app/pages/program/program.service'
import { generateSlug } from 'src/app/utils/utils.generators';

@Component({
  selector: 'app-see-more',
  templateUrl: './see-more.component.html',
  styleUrls: ['./see-more.component.scss']
})
export class SeeMoreComponent {
  @Input('notices')
  notices: any[] = []

  constructor (private router: Router, private programService: ProgramService) {}

  sendToPage(item: any): any {
    if (item.author && item.author.includes('/')) {
      return this.router.navigate([item.author])
    }

    if (item.idprogram) {
      this.programService.getProgramById(item.idprogram).then(response => {
        const program: any = response

        const gender = generateSlug(program.gender)

        let type

        gender === 'jornalismo'
          ? (type = 'noticia')
          : (type = 'fiquepordentro')

        return this.router.navigate([
          `${gender}/${program.slug}/${type}/${item.slug}`
        ])
      })
    }

    return this.router.navigate([`/noticia/${item.author}`])
  }
}
