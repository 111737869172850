import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input('items')
  public items: any
  @Input("program")
  public program: any = {}

  ngOnInit(): void {}
}