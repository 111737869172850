import { Injectable } from '@angular/core'

import { URL, URL_SEARCH_API, ID_SITE_SBTNEWS, ID_SITE_SBT_BRASIL, ID_SITE_PRIMEIRO_IMPACTO} from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchResultsService {

  constructor(private _http: HttpClient) {}

  getNotices(query: string, page: number = 0): Promise<any> {
    const notices = this._http
      .get<any>(
        `${URL_SEARCH_API}/api/notices?term=${query}&page=${page}`
      )
      .toPromise()

    return Promise.all([notices]).then((response: any) => {
      const notices = response[0]

      return {
        notices: notices.results,
        hasMoreNotices: notices.hasMore
      }
    })
  }

  getVideos(query: string, page: number = 0): Promise<any> {
    const videos = this._http
      .get<any>(
        `${URL_SEARCH_API}/api/videos?term=${query}&page=${page}`
      )
      .toPromise()

    return Promise.all([videos]).then((response: any) => {
      const videos = response[0]

      return {
        videos: videos.results,
        hasMoreVideos: videos.hasMore
      }
    })
  }

  getResults(query: string, page: number = 0): Promise<any> {
    const notices = this._http
      .get<any>(
        `${URL_SEARCH_API}/api/notices?term=${query}&page=${page}`
      )
      .toPromise()

    const videos = this._http
      .get<any>(
        `${URL_SEARCH_API}/api/videos?term=${query}&page=${page}`
      )
      .toPromise()

    const programs = this._http
      .get<any>(`${URL}/api/programs?titlesearch=${query}&limit=5`)
      .toPromise()

    return Promise.all([notices, programs, videos]).then((response: any) => {
      const notices = response[0]
      const programs = response[1].results
      const videos = response[2]


      return {
        notices: notices.results.filter(notice => notice.idProgram != ID_SITE_SBTNEWS || ID_SITE_SBT_BRASIL || ID_SITE_PRIMEIRO_IMPACTO),
        videos: videos.results,
        programs,
        hasMoreNotices: notices.hasMore,
        hasMoreVideos: videos.hasMore
      }
    })
  }

  getHighlightedList(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/highlighted?type=entretenimento&limit=5`)
      .toPromise()
      .then((response: any) => {
        const notices = response.results
        return [
          {
            medias: notices
          }
        ]
      })
  }
}
