import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import Pagination from 'src/app/models/pagination.class'
import { generateTitleSlug } from 'src/app/utils/utils.generators'
import { generateSlug } from '../../utils/utils.generators'
import {Location} from '@angular/common'
import {ProgramService} from 'src/app/pages/program/program.service'
import { AnalyticsService } from './../../services/analytics.service'
import { StationService } from './../../services/station.service'
import { ComscoreService } from './../../services/comscore.service'


@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit {

  @Input('videos')
  public videos: any

  @Input('pagination')
  public pagination: Pagination

  @Input('current-video')
  public currentVideo: string

  @Input('route')
  public route: string

  @Input('program-title')
  public programTitle: string = ''

  @Input('program-id')
  public programId: number

  @Input('program-unit')
  public adunit: string = ''

  public urlAnalytics: string = ''

  @Output('load-more')
  public loadMore: EventEmitter<any> = new EventEmitter()

  public videoUrl: string
  public _gender: any
  public _slug: any

  constructor (private router: Router, private location: Location, private programService: ProgramService, private analyticsService : AnalyticsService, private stationService: StationService, private comscoreService: ComscoreService) {}

  ngOnInit () {
    this.urlAnalytics = `${this.removeFragment(this.clearUrl(this.router.routerState.snapshot.url))}/videos/`

    this.videoUrl = `https://www.youtube.com/watch?v=${this.currentVideo}`
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const videoId = this.router.routerState.snapshot.root.children[0].params.video
        this.videoUrl = `https://www.youtube.com/watch?v=${videoId}`
        this.currentVideo = videoId
      }
    })


    this.programService.getProgramById(this.programId.toString()).then(response => {
      const program: any = response
      this._gender = generateSlug(program.gender)
      this._slug = generateSlug(program.slug)
    })
  }

  replaceState(_gender, _slug ): void{
    this.location.replaceState('/' +  _gender+ '/' +  _slug + '?video=' +  this.currentVideo +'#videos');

  }

  changeVideo(video): void {
    this.programTitle = video['program']
    this.programId = video['idprogram']
    this.videoUrl = `https://www.youtube.com/watch?v=${video['id']}`
    this.currentVideo = video['id']
    this.replaceState(this._gender, this._slug)

    const currentUrl = window.location
    const urlToGA = `${currentUrl.pathname}${currentUrl.search}${currentUrl.hash}`

    const hasStation = !!this.stationService.checkStationCookie()
    const stationIdentifier = this.stationService.getStationCookie()
    const station = this.stationService.getStation(stationIdentifier)[0] || ''

    this.analyticsService.sendPageView(urlToGA,  `${video['title']}`, (hasStation) ? station.identifier : 'nacional')
    this.comscoreService.sendPageView(urlToGA)
  }

  removeFragment(url: string) {
    if(url.indexOf('#') < 0){
      return url
    } else {
      return (url.slice(0, url.indexOf('#'))).trim()
    }
  }

  clearUrl(url: string) {
    if(url.indexOf('?video=') < 0){
      return url
    } else {
      return (url.slice(0, url.indexOf('?video='))).trim()
    }
  }

  getRouterLink (video: any): string {
    const title = generateTitleSlug(video.title)

    return `/${this.route}/${video.link}/${title}`
  }

  sendInfo (): void {
    this.loadMore.emit(this.pagination)
  }
}
