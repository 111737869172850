import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private title: BehaviorSubject<string> = new BehaviorSubject(null)

  private titleChange$: Observable<string> = this.title.asObservable()

  constructor (private titleService: Title) {
    // this.title.next(this.getTitle())
  }

  getTitleChangeObservable () {
    return this.titleChange$
  }

  getTitle (): string {
    return this.titleService.getTitle()
  }
  /**
   * Set the title of the current HTML document.
   * @param newTitle
   */
  setTitle (newTitle: string): void {
    this.titleService.setTitle(newTitle)
    this.title.next(newTitle)
  }
}
