export class Video {
  public id: string
  public title: string
  public description: string
  public thumbnail: string
  public duration: number
  public program: string
  public idprogram: number
  public gender: string
  public playlists: string
  public highlighted: boolean
  public idgender: number
  public programlink: string
  public routerLink: string
  public publishdate: string
  public publishdatestring: string
  public regional: string
  public tags: string
  public idcategory: string
  public keywordsearch: string[]
  public titlesearch: string[]
  public originalid: string

  constructor (object) {
    this.id = object.id
    this.title = object.title
    this.description = object.description
    this.thumbnail = object.thumbnail
    this.duration = object.duration
    this.program = object.program
    this.idprogram = object.idprogram
    this.gender = object.gender
    this.playlists = object.playlists
    this.highlighted = object.highlighted
    this.idgender = object.idgender
    this.programlink = object.programlink
    this.routerLink = object.routerLink
    this.publishdate = object.publishdate
    this.publishdatestring = object.publishdatestring
    this.regional = object.regional
    this.tags = object.tags
    this.idcategory = object.idcategory
    this.keywordsearch = object.keywordsearch
    this.titlesearch = object.titlesearch
    this.originalid = object.originalid
  }
}
