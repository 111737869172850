const getTime = (date: string): string => {
  const tmpDate = new Date(date)

  let resultHour =
    tmpDate.getHours() < 10 ? `0${tmpDate.getHours()}` : tmpDate.getHours()
  let resultMinute =
    tmpDate.getMinutes() < 10
      ? `0${tmpDate.getMinutes()}`
      : tmpDate.getMinutes()

  return `${resultHour}:${resultMinute}`
}

const getDate = (date: string): string => {
  const tmpDate = new Date(date)

  const day =
    tmpDate.getDate() < 10 ? `0${tmpDate.getDate()}` : tmpDate.getDate()
  const month =
    tmpDate.getMonth() + 1 < 10
      ? `0${tmpDate.getMonth() + 1}`
      : tmpDate.getMonth() + 1

  const year = tmpDate.getFullYear()

  return `${day}/${month}/${year}`
}

const generateDaysGrid = (
  daysAmount: number,
  daysBefore: number = 3
): any[] => {
  const dates: any[] = []

  for (let i = 0; i < daysAmount; i++) {
    let date = new Date()

    date.setDate(date.getDate() - daysBefore + i)

    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1

    const year = date.getFullYear()

    const dateString = `${day}/${month}`

    const dayString = getStringDay(date.getDay())

    const formattedDate = `${year}-${month}-${day}`

    dates.push({
      title: dayString,
      date: dateString,
      formattedDate
    })
  }

  return dates
}

const formatDateToDefaultFormat = (date: any) => {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month = (date.getMonth()+1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const year = date.getFullYear()
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`
}

const getStringDay = (dayIndex: number): string => {
  switch (dayIndex) {
    case 0:
      return `DOM`

    case 1:
      return `SEG`

    case 2:
      return `TER`

    case 3:
      return `QUA`

    case 4:
      return `QUI`

    case 5:
      return `SEX`

    case 6:
      return `SÁB`

    default:
      return ``
  }
}

export { getTime, getDate, getStringDay, generateDaysGrid, formatDateToDefaultFormat }
