import { Component, OnInit, Input} from '@angular/core';
import { generateSlug, generateTitleSlug } from 'src/app/utils/utils.generators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-lego-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})

export class ItemComponent implements OnInit {
  @Input('content')
  public content: any
  @Input('imageBig')
  public imageBig:any

  constructor (private router: Router) { }

  public queryParam: Array<any> = new Array()
  public parameters: Array<any> = new Array()
  public fragment: Array<any> = new Array()

  ngOnInit () {
  }

  goToLink(link: string, parameters: {video: string} | null = null, fragment: string = '') {
    let newLink
    if(parameters !== null) {
      newLink = link + '?' + 'video=' + parameters.video + '#' + fragment
    } else {
      newLink = link  
    }
    this.router.navigateByUrl(newLink)
    return false
  }

  getRouterLink (item: any = '', i:number = 0): string {
    if(item.author && item.author.includes('/')){
      if(item.author.includes('#videos')) {
        const newLinks = item.author.split('?')
        this.queryParam[0] = newLinks[1].slice(6,-7)
        this.parameters[0] = { video: this.queryParam[0] }
        this.fragment[0] = 'videos'
        return `/${newLinks[0]}`
      }
      return `/${item.author}`  
    }

    if (item.idprogram) {
      const gender = generateSlug(item.gender)
      const programSlug = generateTitleSlug(item.program)

      let type

      gender === 'jornalismo'
        ? (type = 'noticia')
        : (type = 'fiquepordentro')

      return `${gender}/${programSlug}/${type}/${item.slug}`
    }

    return `/noticia/${item.author}`
  }
}
