import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import Home from '../../models/home.class'
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-home-wrapper',
  templateUrl: './home-wrapper.component.html',
  styleUrls: ['./home-wrapper.component.scss']
})
export class HomeWrapperComponent implements OnDestroy {
  @Input('type')
  public type: string

  @Input('home')
  public home: Home

  @Input('station-ad-unit')
  public stationAdUnit: string = ''

  @Input('station-name')
  public stationName: string = ''

  @Output()
  public updateUrlState = new EventEmitter()

  public subscription: Subscription
  public debouncer: Subject<any> = new Subject<any>()

  constructor() {
    this.subscription = this.debouncer
      .pipe(debounceTime(500))
      .subscribe(title => this.updateUrlState.emit(title))
  }

  ngOnDestroy() {
    this.debouncer.unsubscribe()
  }
}
