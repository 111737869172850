import { Injectable } from '@angular/core'
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'

import { ID_SITE_SBTNOTICIAS, ID_SITE_SBTNEWS, URL } from '../../app.settings'
import Program from '../../models/program.class'
import { Edition } from '../../models/edition.class'
import { About } from '../../models/about.class'
import { Character } from '../../models/character.class'
import { Video } from '../../models/video.class'
import { Thanks } from '../../models/thanks.class'
import { Presenter } from '../../models/presenter.class'
import { Frame } from '../../models/frame.class'
import { Timeline } from '../../models/timeline.class'
import { Recipie } from '../../models/recipie.class'
import { Tip } from '../../models/tip.class'
import { Winner } from '../../models/winner.class'
import { Inscription } from '../../models/inscription.class'
import Pagination from '../../models/pagination.class'
import Notice from 'src/app/models/notice.class'
import { HttpClient } from '@angular/common/http'
import { Iframe } from 'src/app/models/iframe.class'

@Injectable({
  providedIn: 'root'
})

export class ProgramService {
    
  public urlTabelas: SafeResourceUrl

  constructor (private _http: HttpClient, public sanitizer: DomSanitizer) {}

  getPrograms (): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/programs?noar=s`)
      .toPromise()
      .then((response: any) => {
        const programs: any = response

        return programs.results.map(program => new Program(program))
      })
      .catch(e => console.log(e))
  }

  getProgramById (id: string): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/programs?id=${id}`)
      .toPromise()
      .then((response: any) => response.results[0])
  }

  getProgramBySlug (slug: string): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/programs?slug=${slug}`)
      .toPromise()
      .then((response: any) => {
        const responseObject: any = response.results[0]

        if (!responseObject) {
          return {
            program: undefined,
            loaded: false
          }
        }

        const { components, header } = responseObject

        if (components) {
          delete responseObject.header

          const program: Program = new Program(responseObject)

          const renderOrder: any[] = []
          const quickAccessMenu: any[] = []
          const excludeComponentsFromMenu: string[] = [
            'header',
            'mediabox',
            'editions'
          ]

          components.forEach(component => {
            const {
              idSiteArea,
              order,
              type,
              keyword,
              name,
              idPlaylist,
              sort,
              orderBy,
              searchOpcional1,
              limit,
              hasMoreThanOnePlaylist
            } = component

            const queryParams = {
              idSite: program.idSite,
              idSiteArea,
              idPlaylist,
              orderBy,
              sort,
              searchOpcional1,
              limit
            }

            if (!excludeComponentsFromMenu.includes(type)) {
              quickAccessMenu.push({
                title: name,
                id: type
              })
            }

            renderOrder.push({
              order,
              type,
              title: component.name
            })

            switch (component.type) {
              case 'header':
                if (queryParams.idSiteArea) {
                  this.getMedias(queryParams).then(
                    programs =>
                      (program.header =
                        programs.results[0].thumbnail || program.header)
                  )
                }
                break

              case 'about':
                if (!queryParams.idSiteArea) {
                  let description

                  description = program.description

                  if (program.showTimes) {
                    description = `<strong><i>HORÁRIO DE EXIBIÇÃO:</strong> ${program.showTimes.toUpperCase()}</i><br/><br/>${
                      program.description
                    }`
                  }

                  program.about = new About({
                    title: program.title,
                    description,
                    thumbnail: program.header
                  })
                } else {
                  this.getMedias(queryParams).then(about => {
                    if (about.results && about.results[0]) {
                      if (program.showTimes) {
                        about.results[0].description = `<strong><i>HORÁRIO DE EXIBIÇÃO:</strong> ${program.showTimes.toUpperCase()}</i><br/><br/>${
                          about.results[0].description
                        }`
                      }

                      program.about = new About(about.results[0])
                    } else {
                      let description

                      description = program.description

                      if (program.showTimes) {
                        description = `<strong><i>HORÁRIO DE EXIBIÇÃO:</strong> ${program.showTimes.toUpperCase()}</i><br/><br/>${
                          program.description
                        }`
                      }

                      program.about = new About({
                        title: program.title,
                        description,
                        thumbnail: program.header
                      })
                    }
                  })
                }
                break

              case 'mediabox':
                const mediabox = []

                this.getMedias({
                  idSite: program.idSite,
                  idSiteArea,
                  searchOpcional1
                }).then(homeMediabox => mediabox.push(homeMediabox.results))

                this.getMedias(queryParams).then(
                  mediabox => (program.mediabox = mediabox.results)
                )
                break
              
              case 'iframe':
                this.getMedias(queryParams).then( iframe => {
                  let url
                  let title           

                  if (iframe.results && iframe.results[0]) {
                    this.urlTabelas = this.sanitizer.bypassSecurityTrustResourceUrl( iframe.results[0].description )                    
                    program.iframe = new Iframe({
                      description: this.urlTabelas
                    })
                  }              
                 }
                  
                )
                break

              case 'programs':
                this.getMedias(queryParams).then(
                  programs => (program.programs = programs.results)
                )
                break

              case 'singleNotices':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.notices = notices.results
                })
                break

              case 'campaigns':
                this.getProgramCustomNotices(program, keyword).then(
                  notices => (program.campaigns = notices.results)
                )
                break

              case 'tbt':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.tbt = notices.results
                })
                break

              case 'whereTheyAre':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.whereTheyAre = notices.results
                })
                break

              case 'quiz':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.quiz = notices.results
                })
                break

              case 'looks':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.looks = notices.results
                })
                break

              case 'music':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.music = notices.results
                })
                break

              case 'sbtistas':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.sbtistas = notices.results
                })
                break

              case 'support':
                this.getProgramCustomNotices(program, keyword).then(notices => {
                  notices.results.forEach(noticeElement => {
                    noticeElement.pagination = new Pagination(notices.next)
                    noticeElement.keywordSection = keyword
                  })

                  program.support = notices.results
                })
                break

              case 'characters':
                if (hasMoreThanOnePlaylist) {
                  this.getPlaylists({ idSiteArea, limit: limit || 50, orderBy: 'startdate', sort: 'desc' })
                    .then(editions => {
                      program.charactersEditions = editions.results

                      const { idsitearea, id } = program.charactersEditions[0]

                      const queryParams = {
                        idsitearea,
                        idPlaylist: id,
                        limit: 30
                      }

                      this.getMedias(queryParams).then(
                        characters =>
                          (program.characters = characters.results.map(
                            character => new Character(character)
                          ))
                      )
                    })
                  break
                }

                this.getMedias(queryParams).then(
                  characters =>
                    (program.characters = characters.results.map(
                      character => new Character(character)
                    ))
                )
                break

              case 'thanks':
                this.getMedias(queryParams).then(
                  thanks =>
                    (program.thanks = thanks.results.map(
                      thank => new Thanks(thank)
                    ))
                )
                break

              case 'chapters':
                if (hasMoreThanOnePlaylist) {
                  this.getPlaylists({ idSiteArea, limit: limit || 50, orderBy: 'startdate', sort: 'desc' })
                    .then(editions => {
                      program.chaptersEditions = editions.results

                      const { idsitearea, id } = program.chaptersEditions[0]

                      const queryParams = {
                        idsitearea,
                        idPlaylist: id,
                        limit: 200
                      }

                      this.getMedias({ ...queryParams }).then(
                        chapters =>
                          (program.chapters = chapters.results.map(chapter => {
                            return {
                              chapter,
                              pagination: new Pagination(chapters.next)
                            }
                          }))
                      )
                    })
                  break
                }

                this.getMedias({ ...queryParams, limit: 200 }).then(
                  chapters =>
                    (program.chapters = chapters.results.map(chapter => {
                      return {
                        chapter,
                        pagination: new Pagination(chapters.next)
                      }
                    }))
                )
                break

              case 'presenter':
                this.getMedias(queryParams).then(
                  presenters =>
                    (program.presenter = presenters.results[0]
                      ? new Presenter(presenters.results[0])
                      : undefined)
                )
                break

              case 'presenters':
                this.getMedias({ ...queryParams, limit: 100 }).then(
                  presenters =>
                    (program.presenters = presenters.results.map(
                      presenter => new Presenter(presenter)
                    ))
                )
                break

              case 'frames':
                this.getMedias(queryParams).then(frames => {
                  program.frames = frames.results.map(
                    frame => new Frame(frame, frames.next)
                  )
                })
                break

              case 'timeline':
                this.getMedias(queryParams).then(
                  timeline =>
                    (program.timeline = timeline.results.map(
                      year => new Timeline(year)
                    ))
                )
                break

              case 'editions':
                this.getMedias(queryParams).then(
                  editions =>
                    (program.editions = editions.results.map(
                      edition => new Edition(edition)
                    ))
                )
                break

              case 'winners':
                if (hasMoreThanOnePlaylist) {
                  this.getPlaylists({ idSiteArea, limit: limit || 50, orderBy: 'startdate', sort: 'desc' })
                    .then(editions => {
                      program.winnersEditions = editions.results

                      const { idsitearea, id } = program.winnersEditions[0]

                      const queryParams = {
                        idsitearea,
                        idPlaylist: id,
                        limit: 30
                      }

                      this.getMedias(queryParams).then(winners => {
                        program.winners = winners.results.map(
                          winner => new Winner(winner, winners.next)
                        )
                      })
                    })
                  break
                }

                this.getMedias(queryParams).then(winners => {
                  program.winners = winners.results.map(
                    winner => new Winner(winner, winners.next)
                  )
                })
                break

              case 'videos':
                this.getProgramVideos(program).then(
                  videos =>
                    (program.videos = [
                      videos.results.map(video => new Video(video))
                    ])
                )
                break

                case 'videosprograma':
                  this.getProgramVideos(program).then(
                    videosprograma =>
                      (program.videosprograma = [
                        videosprograma.results.map(video => new Video(video))
                      ])
                  )
                  break

              case 'recipies':
                this.getProgramCustomNotices(program, keyword, null).then(
                  recipies =>
                    (program.recipies = recipies.results.map(
                      recipie => new Recipie(recipie)
                    ))
                )
                break

              case 'tips':
                this.getProgramCustomNotices(program, keyword, null).then(
                  tips => (program.tips = tips.results.map(tip => new Tip(tip)))
                )
                break

              case 'notices':
                this.getProgramCustomNotices(
                  program,
                  keyword,
                  null,
                  queryParams.limit
                ).then(
                  notices =>
                    (program.stayInside = notices.results.map(
                      notice => new Notice(notice, notices.next)
                    ))
                )
                break

              case 'inscriptions':
                this.getProgramInscriptions(program).then(
                  inscriptions =>
                    (program.inscriptions = inscriptions.results.map(
                      inscription =>
                        new Inscription(inscription, inscriptions.next)
                    ))
                )
                break

              case 'secretCode':
                program.secretCodeData = {
                  idSite: program.idSite,
                  idSiteArea,
                  idPlaylist
                }
                break

              case 'gallery':
                if (hasMoreThanOnePlaylist) {
                  this.getPlaylists({ idSiteArea, limit: limit || 50, orderBy: 'startdate', sort: 'desc' })
                    .then(editions => {
                      program.galleryEditions = editions.results

                      const { idsitearea, id } = program.galleryEditions[0]

                      const queryParams = {
                        idsitearea,
                        idPlaylist: id,
                        limit: 30
                      }

                      this.getMedias(queryParams).then(
                        photos => (program.gallery = photos.results)
                      )
                    })
                  break
                }

                this.getMedias(queryParams).then(
                  photos => (program.gallery = photos.results)
                )
                break
            }
          })

          return { program, renderOrder, quickAccessMenu, header, loaded: true }
        }

        return {
          program: undefined,
          loaded: false,
          header
        }
      })
  }

  getMedias (params: any): Promise<any> {
    const query: string = this.getQueryRequest(params, 'medias')

    return this._http
      .get<any>(query)
      .toPromise()
      .then((response: any) => response)
      .catch(err => {
        throw new Error(err)
      })
  }

  getPlaylists (params: any): Promise<any> {
    const query: string = this.getQueryRequest(params, 'playlists')

    return this._http
      .get<any>(query)
      .toPromise()
      .then((response: any) => response)
      .catch(err => {
        throw new Error(err)
      })
  }

  getProgramCustomNotices (
    program: Program,
    keyword: string = '',
    pagination: Pagination = null,
    limit: number = 4
  ): Promise<any> {
    let query: string = `${URL}/api/notices?program=${program.id === ID_SITE_SBTNOTICIAS ? program.id = ID_SITE_SBTNEWS : program.id}`

    if (limit) {
      query += `&limit=${limit}`
    } else {
      query += `&limit=4`
    }

    if (keyword) {
      query += `&searchkeyword=${keyword}`
    }
    
    if (pagination) {
      query += `&next=${pagination.key}`
    }
    
    query += `&orderby=publishdate&sort=desc`

    return this._http
      .get<any>(query)
      .toPromise()
      .then((response: any) => response)
  }

  getProgramCustomNoticesById (
    id: number,
    keyword: string = '',
    pagination: Pagination = null
  ): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?program=${id}&limit=4${
          keyword !== '' ? `&searchkeyword=${keyword}` : ''
        }${
          pagination ? `&next=${pagination.key}` : ``
        }&orderby=publishdate&sort=desc`
      )
      .toPromise()
      .then((response: any) => response)
  }

  getProgramNotices (
    program: Program,
    pagination: Pagination = null,
    keyword: string = ''
  ): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/notices?program=${program.id}&limit=4${
          pagination ? `&next=${pagination.key}` : ``
        }${
          keyword !== '' ? `&searchkeyword=${keyword}` : ''
        }&orderby=publishdate&sort=desc`
      )
      .toPromise()
      .then((response: any) => response)
  }

  getProgramVideos (program: Program): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/videosyoutube?program=${program.id}&limit=20`)
      .toPromise()
      .then((response: any) => response)
  }

  getProgramInscriptions (program: Program): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsitearea=1500&idplaylist=6170&opcional=${program.id}&orderby=ordem&sort=desc`)
      .toPromise()
      .then((response: any) => response) 
  }

  getProgramRecipies (program: Program): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/notices?program=${program.id}`)
      .toPromise()
      .then((response: any) => response)
  }

  getProgramTips (program: Program): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/notices?program=${program.id}`)
      .toPromise()
      .then((response: any) => response)
  }

  getProgramCustomContent (url: string): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/${url}`)
      .toPromise()
      .then((response: any) => response)
  }

  getQueryRequest (params, endpoint): string {
    let query: string

    switch (endpoint) {
      case 'medias':
        query = `${URL}/api/medias`
        break

      case 'playlists':
        query = `${URL}/api/playlists`
        break
    }

    if (params.limit) {
      query += `?limit=${params.limit}`
    } else {
      query += `?limit=10`
    }

    if (params.idSite) {
      query += `&idsite=${params.idSite}`
    }

    if (params.idSiteArea) {
      query += `&idsitearea=${params.idSiteArea}`
    }

    if (params.idPlaylist) {
      query += `&idplaylist=${params.idPlaylist}`
    }

    if (params.orderBy) {
      query += `&orderby=${params.orderBy}`

      if (params.sort) {
        query += `&sort=${params.sort}`
      } else {
        query += `&sort=desc`
      }
    } else {
      query += `&orderby=ordem`

      if (params.sort) {
        query += `&sort=${params.sort}`
      } else {
        query += `&sort=asc`
      }
    }

    return query
  }
}
