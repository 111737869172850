import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common'

import { TYPE_ENVIRONMENT_CHAT_BOT } from 'src/app/app.settings';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {

  private objConfig:object
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) public _document: Document
  ) { }
  
  ngOnDestroy () {
    this.renderer.destroy()
  }
  
  ngOnInit() {
    this.objConfig = {
      bot_id : '449',
      bot_token : 'efdgdfg4456fjj',
      bot_server_type : `${TYPE_ENVIRONMENT_CHAT_BOT}`
    }
    const scriptEnvironment = this.renderer.createElement('script');
    scriptEnvironment.type = 'text/javascript';
    scriptEnvironment.async = 'async'

    scriptEnvironment.text = `${this.renderContent()}`
    this.renderer.appendChild(this._document.head, scriptEnvironment)
  
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = 'async'
    script.id = 'chatscript'
    script.src = 'https://in.bot/api/inbot.js';
    this.renderer.appendChild(this._document.head, script)
  }

  renderContent(){
    let content = ''
    for (const key in this.objConfig) {
      content += `${key} = '${this.objConfig[key]}'; `
    }
    return content
  }

}
