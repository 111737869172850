import { Injectable } from '@angular/core'

import { URL, MENU_IDS } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MenuService {

  constructor(private _http: HttpClient) { }

  modelHeader(item): any {
    return {
      title: item["title"],
      link: item["opcional"],
      externalLink: item["opcional1"]
    }
  }

  modelEmissoras(item): any {
    return {
      title: item["title"],
      link: item["opcional"],
      externalLink: item["opcional1"],
      socialsNetworks: item["opcional2"],
    }
  }

  modelFooter(item): any {
    return {
      title: item["title"],
      link: item["opcional"],
      externalLink: item["opcional1"],
      titleSection: item["titleplaylist"]
    }
  }

  getMenu(): Promise<any> {
    const requestHeader = this._http
      .get<any>(`${URL}/api/medias?idsite=${MENU_IDS["site"]["id"]}&idsitearea=${MENU_IDS["areas"]["header"]["id"]}&orderby=ordem&sort=asc&limit=100`)
      .toPromise()

    const requestEmissoras = this._http
      .get<any>(`${URL}/api/medias?idsite=${MENU_IDS["site"]["id"]}&idsitearea=${MENU_IDS["areas"]["emissoras"]["id"]}&orderby=ordem&sort=asc&limit=100`)
      .toPromise()

    const requestFooter = this._http
      .get<any>(`${URL}/api/medias?idsite=${MENU_IDS["site"]["id"]}&idsitearea=${MENU_IDS["areas"]["footer"]["id"]}&orderby=ordem&sort=asc&limit=100`)
      .toPromise()

    return Promise.all([
      requestHeader,
      requestEmissoras,
      requestFooter
    ]).then((response: any) => {
      const menuHeaders = response[0].results
      const menuEmissoras = response[1].results
      const menuFooters = response[2].results

      const resHeaders = menuHeaders.map(item => this.modelHeader(item))

      const resEmissoras = {
        'emissorasSudeste': menuEmissoras.filter(item => item.idplaylist === MENU_IDS["areas"]["emissoras"]["playlists"]["sudeste"]["id"]).map(item => this.modelEmissoras(item)),
        'emissorasSul': menuEmissoras.filter(item => item.idplaylist === MENU_IDS["areas"]["emissoras"]["playlists"]["sul"]["id"]).map(item => this.modelEmissoras(item)),
        'emissorasNorte': menuEmissoras.filter(item => item.idplaylist === MENU_IDS["areas"]["emissoras"]["playlists"]["norte"]["id"]).map(item => this.modelEmissoras(item)),
        'emissorasNordeste': menuEmissoras.filter(item => item.idplaylist === MENU_IDS["areas"]["emissoras"]["playlists"]["nordeste"]["id"]).map(item => this.modelEmissoras(item)),
        'emissorasCentroOeste': menuEmissoras.filter(item => item.idplaylist === MENU_IDS["areas"]["emissoras"]["playlists"]["centroOeste"]["id"]).map(item => this.modelEmissoras(item))
      }

      const resFooters = {
        'about': menuFooters.filter(item => item.idplaylist === MENU_IDS["areas"]["footer"]["playlists"]["about"]["id"]).map(item => this.modelFooter(item)),
        'gss': menuFooters.filter(item => item.idplaylist === MENU_IDS["areas"]["footer"]["playlists"]["gss"]["id"]).map(item => this.modelFooter(item))
      }

      return {
        headers: resHeaders,
        emissoras: resEmissoras,
        footers: resFooters
      }
    }).catch(e => {
      return {
        headers: [],
        emissoras: [],
        footers: []
      }
    })
  }
}
