import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core'
import { ProgramService } from 'src/app/pages/program/program.service'
import { generateSlug } from '../../utils/utils.generators'
import { from } from 'rxjs'

@Component({
  selector: 'app-list-article',
  templateUrl: './list-article.component.html',
  styleUrls: ['./list-article.component.scss']
})
export class ListArticleComponent implements OnInit {
  @Input('title')
  public title: string

  @Input('has-categories')
  public hasCategories: boolean = false

  @Input('content')
  public contents: any[]

  @Input('url')
  public url: string

  @Input('has-link')
  public hasLink: boolean = false

  @Input('link')
  public link: string = ''

  @Input('link-title')
  public linkTitle: string = ''

  @ViewChild('contentSlider', {static: true})
  public contentSliderList: ElementRef
  
  constructor (private programService: ProgramService) {}

  ngOnInit () {
    let program: any = ''
    program = this.programService.getPrograms()
    from(program).subscribe((program:any) => {
      let _program: any
      this.contents.forEach(content => {
        _program = program.find(program => +content.idprogram === program.id)
        if(_program && _program.gender){
          this.getRouterLink(content, _program)
        }
      })
    })
  }

  getRouterLink (content: any, program: any): void {
    
    // content.routerLink = `${this.url}/${program.slug}/${content.idcategory}/${content.id}/${title}`
    //console.log(content.routerLink)
    content.routerLink = `/${generateSlug(program.gender)}/${program.slug}`
  }

  scroll (left: number): void {
    this.contentSliderList.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  prev (): void {
    this.scroll(-200)
  }

  next (): void {
    this.scroll(200)
  }
}
