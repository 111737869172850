import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input('services')
  public services: any[]

  @Output('open-modal')
  public eventEmit: EventEmitter<any> = new EventEmitter()

  @Output('send-to-page')
  public sendEmit: EventEmitter<any> = new EventEmitter()

  sendInfo ({ title, opcional, opcional1 }, modal: string): void {
    this.eventEmit.emit({
      type: 'openModal',
      title,
      regulation: opcional,
      winners: opcional1,
      modal
    })
  }
}
