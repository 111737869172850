import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MediastreamService } from './mediastream.service';

@Component({
    selector: 'app-live-mediastream',
    templateUrl: './mediastream.component.html',
    styleUrls: ['./mediastream.component.scss'],
    providers: []
})
export class MediastreamComponent implements OnInit {
    @Input('url')
    public url: string
    public safeSrc: SafeResourceUrl;

    constructor (private sanitizer: DomSanitizer, private mediastreamService: MediastreamService) {}

    ngOnInit () {
        const match_IDMedia = this.url.match(/.*\/live-stream\/([a-zA-Z0-9]{0,30}).*/)
        const match_isRequiredAccessToken = this.url.match(/.*(\?access_token).*/)
        const idMedia = match_IDMedia && match_IDMedia[1] ? match_IDMedia[1] : null
        const isRequiredAccessToken = match_isRequiredAccessToken && match_isRequiredAccessToken[1] ? match_isRequiredAccessToken[1] : null
        if (idMedia) {
            if (isRequiredAccessToken) {
                this.mediastreamService.get(idMedia).then((res: any) => {
                    if (res && res['data'] && res['data'].access_token && !res.error){
                        this.url = this.url.replace('?access_token=', '')
                        this.url = this.url.replace('?access_token', '')
                        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.url}?access_token=${res.data.access_token}`)
                    } else {
                        console.log('[MEDIASTREAM]: Erro ao requisitar o Access Token')
                    }
                }).catch((e) => {
                    console.log('[MEDIASTREAM]: Erro ao requisitar serviço que recebe o Access Token')
                })
            } else {
                this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
            }
        } else {
            console.log("[MEDIASTREAM]: ID da media não informado")
        }
    }
} 
