import { Component, OnInit, Input, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @Input('title')
  public title: string

  @Input('video')
  public video: string

  @Input('content')
  public content: string

  @Input('header')
  public header: string

  @Input('has-donation')
  public hasDonation: boolean = false

  @Input('full-width')
  public fullWidth: boolean = false

  @Input('has-share')
  public hasShare: boolean = false

  @Input('social-networks')
  public socialNetworks: any[]

  @Input('program-title')
  public programTitle: string

  public about: string
  public open: boolean = false

  ngOnInit (): void {
    this.cutContentString()
  }

  ngOnChanges (changes: SimpleChanges): void {
    this.cutContentString()
  }

  cutContentString (): void {
    this.open = false

    if (this.content.length > 255) {
      this.about = `${this.content.substr(0, 255)}...`
    } else {
      this.about = this.content
      this.open = !this.open
    }
  }

  readMore (): void {
    this.about = this.content
    this.open = !this.open
  }

  donate (): void {
    alert('Doar!')
  }
}
