import { Component, OnInit } from '@angular/core'
import {
  SafeResourceUrl,
  DomSanitizer,
  Meta
} from '@angular/platform-browser'
import { silviosantos } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { TitleService } from 'src/app/services/title.service';
import { HOTSITE_SILVIOSANTOS } from 'src/app/app.settings';

@Component({
  selector: 'app-silviosantos',
  templateUrl: './silviosantos.component.html',
  styleUrls: ['./silviosantos.component.scss']
})
export class SilviosantosComponent {
  public hotSiteUrl: string = `${HOTSITE_SILVIOSANTOS}`

  public url: SafeResourceUrl

  constructor (
    public sanitizer: DomSanitizer,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService
  ) {
    this.metasService.updateTags(silviosantos, this.meta)
  }

  ngOnInit () {
    this.title.setTitle('Sílvio Santos - SBT')

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.hotSiteUrl)
  }
}
