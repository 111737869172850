import { Injectable } from '@angular/core'
import { URL } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotFoundService {

  constructor(
    private _http: HttpClient
    ) {}


  getHighlightedList(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/highlighted?type=entretenimento&limit=4`)
      .toPromise()
      .then((response: any) => {
        const notices = response.results
        return [
          {
            medias: notices
          }
        ]
      })
  }
}
