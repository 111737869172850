import { Component } from '@angular/core'
import { Validators, FormBuilder } from '@angular/forms'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { EmailService } from 'src/app/services/email.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [EmailService]
})
export class ContactFormComponent {
  public pureTextPattern: string = '^[A-Za-zÀ-ú ]+$'
  public emailPattern: string = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.]{1}[a-z]{2,4}$'

  public personalData = this.fb.group({
    nome: ['', [Validators.required, Validators.pattern(this.pureTextPattern)]]
  })

  public contactData = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    telefone: ['']
  })

  public writeMessage = this.fb.group({
    mensagem: ['', Validators.required]
  })

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private emailService: EmailService,
    private titleService: TitleService
  ) {}

  openFinishdata (): void {
    const data = {
      ...this.personalData.value,
      ...this.contactData.value,
      ...this.writeMessage.value
    }

    this.emailService.sendEmail(data).then(response => {
      this.ngxSmartModalService.getModal('modalFinish').open()
      this.resetForms()
    })

  }

  resetForms (): void {
    this.personalData.reset()
    this.contactData.reset()
    this.writeMessage.reset()
  }

  removeFromTitle(title: string) {
    const currentTitle = this.titleService.getTitle().replace(`${title} - `, '')

    this.titleService.setTitle(`${currentTitle}`)
  }

  changeTitle(title: string) {
    const currentTitle = this.titleService.getTitle()

    this.titleService.setTitle(`${title} - ${currentTitle}`)
  }
}
