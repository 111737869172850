export function normalizeURL (val) {
  return val
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[^a-zA-Z ]/gi, '')
    .replace(/[^\w\s]/gi, '')
    .replace(/ +(?= )/g, '')
    .replace(/[\u0300-\u036f]/gim, '')
    .trim()
    .replace(/\s/gim, '-')
}
