import { AbstractControl, ValidatorFn } from '@angular/forms';

const daysInMonth = (month, year) => {
  switch (month) {
      case 1 :
          return (year % 4 == 0 && year % 100) || year % 400 == 0 ? 29 : 28;
      case 8 : case 3 : case 5 : case 10 :
          return 30;
      default :
          return 31
  }
};

const isValidDate = (day, month, year) => {
  month = parseInt(month, 10) - 1;
  return month >= 0 && month < 12 && day > 0 && day <= daysInMonth(month, year) && year > 1900;
};

export const checkInvalidDate = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const date: string = control.value
    
    if(date === null) return { invalidDate: true }
    const dateToValidate =
      date.includes('/')
      ? {
        day: Number(date.substring(0, 2)),
        month: Number(date.substring(3, 5)),
        year: Number(date.substring(6, 10))
      }
      : {
        day: Number(date.substring(0, 2)),
        month: Number(date.substring(2, 4)),
        year: Number(date.substring(4, 8))
      }
    const { year, month, day } = dateToValidate
    const validDate: boolean = isValidDate(day, month, year)

    return validDate ? null : { invalidDate: true }
  }
}
