import { Component, ViewChild, ElementRef, Input, OnInit} from '@angular/core'
import { Router } from '@angular/router'
import { generateSlug } from '../../utils/utils.generators'
import { LegoListService } from './lego-list.service'
import Pagination from 'src/app/models/pagination.class'
import { ProgramService } from 'src/app/pages/program/program.service'
import { isMobile } from '../../utils/util.browser'

@Component({
  selector: 'app-lego-list',
  templateUrl: './lego-list.component.html',
  styleUrls: ['./lego-list.component.scss'],
  providers: [LegoListService]
})
export class LegoListComponent implements OnInit {
  @Input('list')
  public list: any[]

  @Input('title')
  public title: string

  @Input('color')
  public color: string

  @Input('hexa-color')
  public hexaColor: string

  @Input('is-horizontal-list')
  public isHorizontalList: boolean = false

  @Input('show-more')
  public showMore: boolean

  @Input('show-badge')
  public showBadge: boolean = true

  @Input('full-items')
  public fullItems: boolean = false

  @Input('without-playlists')
  public withoutPlaylists: boolean = false

  @Input('has-title')
  public hasTitle: boolean = true

  @Input('size')
  public size: string = 'normal'

  @Input('is-home')
  public isHome: boolean = false

  @Input('pagination')
  public pagination: Pagination

  @Input('route')
  public route: string

  @Input('has-link')
  public hasLink: boolean = false

  @Input('link')
  public link: string = ''

  @Input('link-title')
  public linkTitle: string = ''

  @ViewChild('articles', {static: true})
  public articles: ElementRef

  @Input('station-name')
  public stationName: string = ''

  @Input('scrollNotice')
  public scrollNotice: string

  @ViewChild("limiteScroll") 
  public limiteScroll: ElementRef;

  public programFavNotices: any[]
  public componentVariation: string = 'card-mobile'
  public canPaginate: boolean = false
  public isMobile: boolean = false
  public dialogElement: any
  public valueTotal: any
  constructor (
    private router: Router,
    private legolistService: LegoListService,
    private programService: ProgramService
  ) {}

  ngOnInit () {
    this.isMobile = isMobile()
    const lastListItem = this.list.length - 1
    const lastItem: any = this.list[lastListItem]

    if (lastItem && lastItem.pagination && lastItem.pagination.hasMore) {
      this.canPaginate = true
    }

    this.programFavNotices = []

    if (this.pagination) {
      this.programFavNotices.push(this.list)
    }
  }

  getRouterLink (content: any): string {
    if (content.slug) {
      return `${this.route}/${content.slug}`
    }

    const slug: string = generateSlug(content.title)

    return `${this.route}/${slug}`
  }

  scroll (left: number): void {
    this.articles.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  scrollTop (top: number): void {
     this.dialogElement = this.limiteScroll.nativeElement as HTMLElement;
    this.dialogElement.scrollBy({
      top,
      behavior: 'smooth'
    })

    let value = 100 * this.dialogElement.scrollTop / (this.dialogElement.scrollHeight - this.dialogElement.clientHeight)
    this.valueTotal = value

    if( this.valueTotal >= 99 ){
      this.dialogElement.scrollTop = 0
    }

  }

  previousArticlesTop (): void {
    this.scrollTop(-300)
  }

  nextArticlesTop (): void {
    this.scrollTop(300)
  }

  previousArticles (): void {
    this.scroll(-865)
  }

  nextArticles (): void {
    this.scroll(865)
  }

  loadMore (): void {
    const lastListItem = this.list.length - 1
    const lastItem: any = this.list[lastListItem]

    const nextQuery: string = `${lastItem.nextQuery}&next=${encodeURIComponent(lastItem.pagination.key)}`

    this.legolistService.getMoreResults(nextQuery).then((results: any) => {
      if (results) {
        this.list.push(...results)

        const lastListItem = this.list.length - 1
        const lastItem: any = this.list[lastListItem]

        if (lastItem.pagination && lastItem.pagination.hasMore) {
          this.canPaginate = true
        } else {
          this.canPaginate = false
        }
      }
    })
  }

  loadMoreNotices (): void {
    const lastListItem = this.list.length - 1
    const lastItem: any = this.list[lastListItem]

    this.programService.getProgramCustomNoticesById(lastItem.idprogram, lastItem.keywordSection, lastItem.pagination).then((response: any) => {
      if (response) {
        response.results.forEach(notice => {
          notice.pagination = new Pagination(response.next)
          notice.keywordSection = lastItem.keywordSection
        })

        this.list.push(...response.results)

        const newLastListItem = this.list.length - 1
        const newLastItem: any = this.list[newLastListItem]

        if (newLastItem.pagination && newLastItem.pagination.hasMore) {
          this.canPaginate = true
        } else {
          this.canPaginate = false
        }
      }
    })
  }
}
