import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  private branding: BehaviorSubject<any> = new BehaviorSubject(null)
  private branding$: Observable<any> = this.branding.asObservable()

  getBranding () {
    return this.branding$
  }

  setBranding (brand: any) {
    this.branding.next(brand)
  }

  resetBranding () {
    this.branding.next(null)
  }
}
