import { Component, OnInit } from '@angular/core'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { FormBuilder, Validators } from '@angular/forms'
import { PromotionsService } from './promotions.service'

// import { pureTextPattern, emailPattern } from '../../utils/util.patterns'

import { RECAPTCHA } from 'src/app/app.settings'
import { Meta } from '@angular/platform-browser'
import { servicesAndPromotions } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { EmailService } from 'src/app/services/email.service';
import { TitleService } from 'src/app/services/title.service';
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
  providers: [EmailService, MetatagsService]
})
export class PromotionsComponent implements OnInit {
  public siteKey: string = `${RECAPTCHA}`

  public renderCaptcha: boolean = false

  public pureTextPattern: string = '^[A-Za-zÀ-ú ]+$'
  public emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'

  public loadingContent = false
  public promotions: any[]
  public programToQuestion: string
  public programRegulation: string
  public programWinners: string

  public aboutContent: string

  public operatorsList: any = [
    {
      value: 'Vivo',
      text: 'Vivo'
    },
    {
      value: 'Claro',
      text: 'Claro'
    },
    {
      value: 'Tim',
      text: 'Tim'
    },
    {
      value: 'Oi',
      text: 'Oi'
    }
  ]

  public reasonsList: any = [
    {
      value: 'Dúvidas',
      text: 'Dúvidas'
    },
    {
      value: 'Sugestões',
      text: 'Sugestões'
    },
    {
      value: 'Reclamações',
      text: 'Reclamações'
    }
  ]

  public personalData = this.fb.group({
    nome: [
      '',
      [Validators.required, Validators.pattern(this.pureTextPattern)]
    ],
    sobrenome: [
      '',
      [Validators.required, Validators.pattern(this.pureTextPattern)]
    ]
  })

  public contactData = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    telefone: ['', Validators.required],
    operadora: [null, Validators.required]
  })

  public writeMessage = this.fb.group({
    assunto: [null, [Validators.required]],
    mensagem: ['', Validators.required],
    recaptcha: [null, Validators.required]
  })

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private service: PromotionsService,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private emailService: EmailService,
    private brandingService: BrandingService
  ) {}

  ngOnDestroy() {
    this.brandingService.setBranding(null)
  }

  ngOnInit () {
    this.brandingService.setBranding('Interatividade')

    this.metasService.updateTags(servicesAndPromotions, this.meta)

    this.title.setTitle('Promoções - SBT')

    this.service.getAboutPromotions().then(about => {
      if (about && about[0]) {
        this.aboutContent = about[0].description
      }
    })

    this.getPromotions()
  }

  removeFromTitle (title: string) {
    const currentTitle = this.title.getTitle().replace(`${title} - `, '')

    this.title.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.title.getTitle()

    this.title.setTitle(`${title} - ${currentTitle}`)
  }

  showOpsMessage () {
    return (
      this.promotions && this.promotions.length === 0 && !this.loadingContent
    )
  }

  receiveData ({ type, title, modal, regulation, winners }): void {
    switch (type) {
      case 'openModal':
        this.programToQuestion = title
        this.programRegulation = regulation
        this.programWinners = winners
        this.sendInfo(modal)
    }
  }

  sendInfo (modal: string): void {
    this.ngxSmartModalService.getModal(modal).open()
  }

  sendFinish (): void {
    const data = {
      ...this.personalData.value,
      ...this.contactData.value,
      ...this.writeMessage.value
    }

    this.emailService.sendEmail(data).then(response => {
      this.ngxSmartModalService.getModal('modalService').close()
      this.ngxSmartModalService.getModal('modalFinish').open()
      this.resetForms()
    })
  }

  getPromotions (): void {
    this.loadingContent = true
    this.service
      .getResults()
      .then((promotions: any) => {
        if (promotions) {
          this.promotions = promotions
        }

        this.loadingContent = false
      })
      .catch(() => {
        this.loadingContent = false
      })
  }

  resetForms (): void {
    this.personalData.reset()
    this.contactData.reset()
    this.writeMessage.reset()
  }

  renderCaptchaStep (): void {
    this.renderCaptcha = true
  }
}
