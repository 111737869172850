import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'

@Injectable()
export class CanonicalService {

  constructor (@Inject(DOCUMENT) private doc) { }

  appendLink () {
    const tag = this.doc.querySelector('[rel="canonical"]')
    
    if (!tag) {
      const link: HTMLLinkElement = this.doc.createElement('link')
      link.setAttribute('rel', 'canonical')
      link.setAttribute('href', window.location.href)
      this.doc.head.appendChild(link)

      return
    }

    tag.setAttribute('href', window.location.href)
  }

  removeLink () {
    let link = this.doc.querySelector('[rel="canonical"]')
    if(link) this.doc.head.removeChild(link)
  }
}
