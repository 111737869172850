import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-list-content',
  templateUrl: './list-content.component.html',
  styleUrls: ['./list-content.component.scss']
})
export class ListContentComponent implements OnInit {
  @Input('title')
  public title: string

  @Input('color')
  public color: string

  constructor () {}

  ngOnInit () {}
}
