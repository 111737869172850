export default class Pagination {
  public key: string
  public hasMore: boolean

  constructor (object) {
    this.key = object.endCursor
    this.hasMore =
      object.moreResults === 'MORE_RESULTS_AFTER_LIMIT' ? true : false
  }
}
