import { Component, OnInit, OnDestroy } from '@angular/core'
import { InscriptionsService } from './inscriptions.service'
import { Inscription } from 'src/app/models/inscription.class'
import Program from 'src/app/models/program.class'
import { ProgramService } from '../program/program.service'
import { StayInsideNotice } from 'src/app/models/stayInsideNotice.class'
import { ActivatedRoute } from '@angular/router'
import { StationService } from 'src/app/services/station.service'
import { CookieService } from 'src/app/services/cookie.service'
import { TitleService } from 'src/app/services/title.service';
import { isMobile } from '../../utils/util.browser'
import { HostListener } from '@angular/core'

@Component({
  selector: 'app-inscriptions',
  templateUrl: './inscriptions.component.html',
  styleUrls: ['./inscriptions.component.scss'],
  providers: [InscriptionsService]
})
export class InscriptionsComponent implements OnInit, OnDestroy {
  public inscriptions: Inscription[]
  public programs: Program[]
  public highlightInscriptions: Inscription[] = []
  public stayInside: any[]

  public isAffiliate: boolean = false
  public station: any
  public isMobile: boolean = false

  public hideSearch: boolean = true
  public filterTerm: string = ''
  public fragment: string = ''

  public subscriptions = []

  public bannerfixed:boolean = false;

  @HostListener('window:scroll',['$event']) onscroll(){
    if(window.scrollY > 1950) {
      this.bannerfixed = true
    } else {
      this.bannerfixed = false
    }
  }

  constructor (
    private service: InscriptionsService,
    private programService: ProgramService,
    private activatedRoute: ActivatedRoute,
    private stationService: StationService,
    private cookieService: CookieService,
    private title: TitleService,
  ) {}

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe()
      }
    })
  }

  ngOnInit () {
    this.title.setTitle('Inscrições - SBT')
    this.isMobile = isMobile()

    const fragmentRoute = this.activatedRoute.fragment
      .subscribe(fragment => {
        this.fragment = fragment
    })

    this.subscriptions.push(fragmentRoute)

    const activeRouteSubscriber = this.activatedRoute.url.subscribe(urlSegment => {
      if (urlSegment[0] && urlSegment[0].path.includes('afiliada')) {
        this.isAffiliate = true
        const stationIdentifier: string = urlSegment[1].path

        this.station = this.stationService.getStation(stationIdentifier)[0]
        this.stationService.setStationCookie(this.station.identifier)
      }
    })

    this.subscriptions.push(activeRouteSubscriber)

    this.service.getInscriptions().then(async inscriptions => {
      let programs = await inscriptions.map(inscription => inscription.program)
      programs = Array.from(new Set(programs))

      let result = []
      let stayInsideNotices = []

      await programs.forEach(async program => {
        const programsInscriptions = await inscriptions.filter(
          inscription => inscription.program === program
        )

        result.push({
          idprogram: program,
          inscriptions: programsInscriptions
        })
      })

      this.inscriptions = result

      this.programService.getPrograms().then(async programs => {
        let programsFiltered = programs.filter(program =>
          this.inscriptions.some(
            (inscription: any) =>
              inscription.idprogram === program.id.toString()
          )
        )

        programsFiltered.forEach(program => {
          this.programService.getProgramNotices(program).then(notices => {
            stayInsideNotices.push(...notices.results)
          })

          this.inscriptions.forEach((programInscriptions: any) => {
            if (programInscriptions.idprogram === program.id.toString()) {
              program.inscriptions = programInscriptions.inscriptions
            }
          })
        })

        this.stayInside = await stayInsideNotices

        if (this.station) {
          let indexes = []

          programsFiltered.forEach((program, index) => {
            if (program.idregional === this.station.id) {
              indexes.push(index)
            }
          })

          indexes.forEach(index => {
            programsFiltered = this.moveArrayPosition(
              programsFiltered,
              index,
              0
            )
          })
        }

        this.programs = programsFiltered
      })
    })

    this.service
      .getHighlightedInscriptions()
      .then(response => (this.highlightInscriptions = response))
  }

  toggleSearch (): void {
    this.hideSearch = !this.hideSearch

    if (this.hideSearch) {
      this.filterTerm = ''
    }
  }

  search (term: string): void {
    this.filterTerm = term
  }

  moveArrayPosition (arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length

      while (k-- + 1) {
        arr.push(undefined)
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    return arr
  }
}
