import { Component, Input, OnInit } from '@angular/core'
import { generateSlug, generateTitleSlug } from 'src/app/utils/utils.generators'
import { URL_SITE_SBTNEWS, ID_SITE_SBTNEWS, ID_SITE_SBT_BRASIL, ID_SITE_PRIMEIRO_IMPACTO } from 'src/app/app.settings';

@Component({
  selector: 'app-lego-single-item',
  templateUrl: './lego-single-item.component.html',
  styleUrls: ['./lego-single-item.component.scss']
})
export class LegoSingleItemComponent implements OnInit {
  @Input('content')
  public content: any

  @Input('color')
  public color: string

  @Input('size')
  public size: string = 'normal'

  @Input('show-badge')
  public showBadge: boolean = false

  public link: string
  public defaultImage: string = '../../../assets/images/default-image.png'
  public isNoticeNews: boolean = false

  constructor() {}

  ngOnInit () {  
    this.link = `${this.getRouterLink(this.content)}`
  }

  getRouterLink (item: any): string {
    if (item.author && item.author.includes('/')) {
      return `${item.author}`
    }

    if (item.idprogram) {
      const gender = generateSlug(item.gender)
      const programSlug = generateTitleSlug(item.program)
      let type
      
      if (item.idprogram === ID_SITE_SBTNEWS) {
        this.isNoticeNews = true
        return `${URL_SITE_SBTNEWS}/noticia/${generateSlug(item.taghighlight)}/${item.slug}`
      }else {
        if (item.idprogram === ID_SITE_SBT_BRASIL || item.idprogram === ID_SITE_PRIMEIRO_IMPACTO) {
          this.isNoticeNews = true
          return `${URL_SITE_SBTNEWS}/noticia/${programSlug}/${item.slug}`
        } else {
          gender === 'jornalismo'
          ? (type = 'noticia')
          : (type = 'fiquepordentro')
        }
  
        return `${gender}/${programSlug}/${type}/${item.slug}`        
      }  
    }

    return `/noticia/${item.author}`
  }

  hasTag (element: any): boolean {
    if (element.taghighlight) {
      return true
    }

    if (element.program) {
      return true
    }

    if (element.opcional) {
      return true
    }

    return false
  }
}