import { Injectable } from '@angular/core'
import { URL, ID_PODCASTS, IDSITEAREA_PODCASTS } from '../../app.settings'
import Podcast from '../../models/podcast.class'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PodcastsService {

  constructor (private _http: HttpClient) {}

  getMedias():Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsite=${ID_PODCASTS}&idsitearea=${IDSITEAREA_PODCASTS}&limit=100&orderby=ordem&sort=asc`)
      .toPromise()
      .then((response: any) => {
        const podcasts: any = response

        return podcasts.results.map(podcast => new Podcast(podcast))
      })
      .catch(e => console.log(e))
  }
}

