import { Component, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { isMobile } from '../../utils/util.browser'
import { SbtVideosService } from './sbt-videos.service'
import Pagination from 'src/app/models/pagination.class'
import { MetatagsService } from '../../services/metatags.service'
import { sbtVideos } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-sbt-videos',
  templateUrl: './sbt-videos.component.html',
  styleUrls: ['./sbt-videos.component.scss'],
  providers: [SbtVideosService]
})
export class SbtVideosComponent implements OnInit {
  public mediabox: any[] = []
  public programs: any[] = []
  public highlightPrograms: any[] = []
  public currentPrograms: any[] = []
  public videos: any[] = []
  public hideSearch: boolean = true
  public filterTerm: string = ''
  public isMobile: boolean = false

  public pagination: Pagination
  public currentPagination: Pagination

  constructor (
    private title: TitleService,
    private service: SbtVideosService,
    private meta: Meta,
    private metasService: MetatagsService,
    private brandingService: BrandingService
  ) {
    this.metasService.updateTags(sbtVideos, this.meta)
  }

  ngOnDestroy(): void {
    this.brandingService.setBranding(null)
  }

  ngOnInit () {    
    window.location.href = `https://www.sbtvideos.com.br`
    this.title.setTitle(`Vídeos - SBT`)

    this.brandingService.setBranding('Vídeos')

    this.isMobile = isMobile()
    const pagination = this.pagination ? this.pagination : null

    this.service
      .getSbtVideosData(pagination)
      .then(({ mediabox, videos, programs, highlightPrograms }) => {
        this.mediabox = mediabox
        this.videos.push(...videos)
        this.programs = programs.programs
        this.highlightPrograms = highlightPrograms

        if (programs.pagination) {
          this.pagination = new Pagination(programs.pagination)
        }
      })
  }

  toggleSearch (): void {
    this.hideSearch = !this.hideSearch

    if (this.hideSearch && this.currentPrograms && this.currentPagination) {
      this.programs = this.currentPrograms
      this.pagination = this.currentPagination
      this.filterTerm = ''
    }
  }

  search (term: string): void {
    if (!this.filterTerm) {
      this.currentPrograms = this.programs
      this.currentPagination = this.pagination
    }

    const termLowerCase = term.toLowerCase()
    this.service.getPrograms(null, termLowerCase).then(programsResponse => {
      this.programs = programsResponse.programs
      this.pagination = new Pagination(programsResponse.pagination)
      this.filterTerm = termLowerCase
    })
  }

  loadMore (pagination: Pagination): void {
    if (pagination.hasMore) {
      this.service.getPrograms(pagination).then(programsResponse => {
        this.programs = [...this.programs, ...programsResponse.programs]
        this.pagination = new Pagination(programsResponse.pagination)
      })
    }
  }
}
