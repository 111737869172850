import Pagination from './pagination.class'

export class Frame {
  public title: string
  public description: string
  public thumbnail: string
  public pagination: Pagination
  public idSite: number
  public idSiteArea: number

  constructor (object, pagination) {
    this.title = object.title
    this.description = object.description
    this.thumbnail = object.thumbnail
    this.pagination = new Pagination(pagination)
    this.idSite = object.idsite
    this.idSiteArea = object.idsitearea
  }
}
