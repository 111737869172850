import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { utils } from 'protractor'
import { StationService } from '../../../services/station.service'

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent implements OnInit, OnDestroy {
  public homeLink: string = '/'
  public liveLink: string = '/ao-vivo'
  public subscriptions = []

  public showH1Header: boolean

  constructor (private router: Router, private stationService: StationService) {}

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit () {  
    
    const routeSubscription = this.router.events.subscribe(event => {
      
      if (event instanceof NavigationEnd) {
        const hasStation: boolean = this.stationService.checkStationCookie()

        if (hasStation) {
          const stationIdentifier = this.stationService.getStationCookie()

          if (stationIdentifier) {
            const station = this.stationService.getStation(stationIdentifier)

            if (station[0]) {
              if (station[0].affiliate) {
                this.homeLink = `/afiliada/${station[0].identifier}`
                this.liveLink = `/afiliada/${station[0].identifier}/ao-vivo`

                return
              }
              this.homeLink = `/${station[0].identifier}/home`
              this.liveLink = `/${station[0].identifier}/ao-vivo`
            }
          }

          return
        }

        this.homeLink = ``
        this.liveLink = `/ao-vivo`

        let url = window.location.href;
        if(url.includes('noticia') || url.includes('fiquepordentro')) {
          this.showH1Header = false
        }else {
          this.showH1Header = true
        }
      }
    })

    this.subscriptions.push(routeSubscription)
  }
}
