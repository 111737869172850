import { Component, OnInit, Input, Pipe, PipeTransform } from '@angular/core';
import { Meta, DomSanitizer } from '@angular/platform-browser';
import { MetatagsService } from 'src/app/services/metatags.service';
import { terms } from 'src/app/app.metatags';
import { TitleService } from 'src/app/services/title.service';
import { PolicyService } from './policy.service'
import { Observable } from 'rxjs'
import { CanonicalService } from 'src/app/services/canonical.service'

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  providers: [CanonicalService]
})
export class PrivacyPolicyComponent implements OnInit {
  @Input('update-title')
  public updateTitile: boolean = true
  public textPolicy$: Observable<string>
  
  constructor (
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private policy: PolicyService,
    private canonicalService: CanonicalService
  ) {
    this.metasService.updateTags(terms, this.meta)
  }

  ngOnDestroy (): void {
 
    this.canonicalService.removeLink()

  }

  ngOnInit () {
    this.canonicalService.appendLink()
    if (this.updateTitile) {
      this.title.setTitle('Política de Privacidade - SBT')
      this.textPolicy$ = this.policy.getPolicy()
    }else{
      this.textPolicy$  = this.policy.getPolicy()
    }
  }
}
