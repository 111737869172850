import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { passwordConfirmation } from 'src/app/validators/password.validator';
import { TitleService } from 'src/app/services/title.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  public token: string
  public error: string
  public accountData : any  
  public confirmPasswordError: string
  public subscriptions = []
  public emailTravado: string

  constructor(
    private activatedRoute: ActivatedRoute, 
    private fb: FormBuilder, 
    private userService: UserService, 
    private ngxModalService: NgxSmartModalService,
    private titleService: TitleService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.token = params.token
      this.emailTravado = params.email
    })

    this.accountData = this.fb.group(
      {
        email: [{value:this.emailTravado, disabled: true}, [Validators.required, Validators.email]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: [passwordConfirmation]
      }
    )


    const passwordFieldSubscription = this.accountData
    .get('password')
    .valueChanges.pipe()
    .subscribe(password => {
      if (password.length < 6) {
        this.confirmPasswordError = 'SENHA PRECISA TER NO MÍNIMO 6 E NO MÁXIMO 20 CARACTERES'

        return
      }

      this.confirmPasswordError = undefined
    })

    const confirmPasswordFieldSubscription = this.accountData
      .get('confirmPassword')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(newPassword => {
        if (newPassword !== this.accountData.get('password').value) {
          this.confirmPasswordError = 'Essas senhas não coincidem'

          return
        }

        this.confirmPasswordError = undefined
      })

      this.subscriptions = [confirmPasswordFieldSubscription, passwordFieldSubscription]

  }

  resetPassword() {
    const user = {
      email: this.accountData.get('email').value.toLowerCase(),
      newPassword: this.accountData.get('password').value
    }

    this.userService.resetPassword(this.token, user)
      .then(response => {
        this.ngxModalService.getModal('modalResetPassword').open()
      })
      .catch(error => this.error = error.message)
  }

  openLoginModal() {
    if (this.ngxModalService.getModal('modalResetPassword').isVisible()) {
      this.ngxModalService.getModal('modalResetPassword').close()
    }

    this.ngxModalService.getModal('modalLogin').open()
  }

  removeFromTitle(title: string) {
    const currentTitle = this.titleService.getTitle().replace(`${title} - `, '')

    this.titleService.setTitle(`${currentTitle}`)
  }

  changeTitle(title: string) {
    const currentTitle = this.titleService.getTitle()

    this.titleService.setTitle(`${title} - ${currentTitle}`)
  }
}
