import { Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'
import { Meta } from '@angular/platform-browser'
import { SbtVideosProgramService } from './sbt-videos-program.service'
import { TitleService } from 'src/app/services/title.service'
import Pagination from 'src/app/models/pagination.class'
import Program from 'src/app/models/program.class'
import { isMobile } from 'src/app/utils/util.browser'
import { StationService } from '../../services/station.service'
import { generateTitleSlug } from 'src/app/utils/utils.generators'
// import { MetatagsService } from 'src/app/services/metatags.service'

@Component({
  selector: 'app-sbt-videos-program',
  templateUrl: './sbt-videos-program.component.html',
  styleUrls: ['./sbt-videos-program.component.scss'],
  providers: [SbtVideosProgramService]
})
export class SbtVideosProgramComponent implements OnInit {

  public videos: any[]
  public playlists: any[]
  public program: Program
  public slugProgram: string
  public isMobile: boolean = false
  public renderAd: boolean = true
  public urlVideo: string = ''
  public subscriptions = []
  public currentPlaylist: string
  public currentVideo: string = ''
  public pagination: Pagination
  public stationAdUnit: string = ''
  
  
  @ViewChild('sectionAssistindo', {static: true}) sectionAssistindo:ElementRef<HTMLElement>

  constructor (
    private service: SbtVideosProgramService,
    private route: ActivatedRoute,
    private title: TitleService,
    private stationService: StationService,
    private router: Router,
    // private metaService: MetatagsService,
    private meta: Meta
  ) {}

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  async ngOnInit () {  
    const urlParams = new URLSearchParams(window.location.search);
    
    const myParam = urlParams.get('video');
    if(myParam != null){      
      this.currentVideo = myParam
    }else{        
        this.route.fragment.subscribe(fragment => {
        if(!!fragment && fragment.includes('videos')){
          this.currentVideo = fragment.replace('videos','')          
        }
      })    
    }

    this.urlVideo = this.router.url
    const pagination = this.pagination ? this.pagination : null
    this.isMobile = isMobile()

    // const slugProgramSubscription = await this.route.params.subscribe(
    //   params => (this.slugProgram = params['slug'])
    // )

    const slugProgramSubscription = await this.route.url.subscribe(
      params => (this.slugProgram = params[1].path)
    )

    await this.service.getProgramBySlug(this.slugProgram).then(program => {

      console.log(`[PROGRAM_SLUG]:`, this.slugProgram)
      console.log(`[PROGRAM_DATA]: `, program)

      this.program = program
      this.setStationAdUnit()
    })

    const videosByProgramSubscription = await this.route.params.subscribe(
      params => {
        this.currentPlaylist = params['playlist']
          ? params['playlist']
          : 'todos'

        this.service
          .getVideosByProgram(
            this.program.id,
            this.currentPlaylist !== 'todos' ? this.currentPlaylist : null,
            pagination
          )
          .then(({ results, next }) => {
            this.videos = results
            const currentVideoObject = this.videos[0] ? this.videos[0] : null
            // this.updateMetaTags(currentVideoObject)
            if (params['video'] && this.currentVideo === '') {
              this.currentVideo = params['video']
            } else if (this.videos.length > 0  && this.currentVideo === '') {
              this.currentVideo = this.videos[0].id
              this.urlVideo = this.router.serializeUrl(
                this.router.createUrlTree([
                  `sbt-videos/${params['slug']}/${this.currentPlaylist}/${
                    this.currentVideo
                  }/${generateTitleSlug(this.videos[0].title)}`
                ])
              )
            }
            // this.setTitleByVideo(this.currentVideo)
            if (next) {
              this.pagination = new Pagination(next)
            }
          })
      }
    )

    // await this.service
    //   .getPlaylists(this.program.id)
    //   .then(playlistResponse => (this.playlists = playlistResponse))
    // const renderAdSubscription = this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     this.renderAd = false
    //     setTimeout(() => {
    //       this.renderAd = true
    //     }, 100)
    //   }
    // })

    this.subscriptions = [
      slugProgramSubscription,
      videosByProgramSubscription
      
    ]
  }

  // updateMetaTags (currentVideoObject: any): void {
  //   // this.metaService.removeTags(this.meta)
  //   const metatags = [
  //     { name: 'description', content: currentVideoObject.title },
  //     { name: 'keywords', content: currentVideoObject.keywordsearch.join(',') },
  //     { property: 'og:type', content: 'website' },
  //     { property: 'og:locale', content: 'pt_BR' },
  //     { property: 'og:site_name', content: 'SBT - Sistema Brasileiro de Televis&atilde;o' },
  //     { property: 'og:title', content: currentVideoObject.title },
  //     { property: 'og:description', content: currentVideoObject.title },
  //     { property: 'og:image', content: `https://i.ytimg.com/vi/${currentVideoObject.id}/maxresdefault.jpg` },
  //     { property: 'og:url', content: window.location.href },
  //     { name: 'twitter:card', content: 'summary' }
  //   ]
  //   // this.metaService.updateTags(metatags, this.meta)
  // }

  setStationAdUnit () {
    if (this.stationService.checkStationCookie()) {
      const stationIdentifier = this.stationService.getStationCookie()
      if (stationIdentifier) {
        const station = this.stationService.getStation(stationIdentifier) 
        if (station && station[0] && this.program.regional !== 0) {
          this.stationAdUnit = `_${station[0].adUnitName}`
        }
      }
    }
  }

  setTitleByVideo (currentVideo: string): any {
    const video = this.videos.find(video => video.id === currentVideo)
    this.title.setTitle(`${video.title} - SBT`)
  }

  alphabeticSort (first: any, second: any) {
    if (first && first.title && second && second.title) {
      if (first.title.toLowerCase() < second.title.toLowerCase()) {
        return -1
      }
      if (first.title.toLowerCase() > second.title.toLowerCase()) {
        return 1
      }
      return 0
    }
  }

  buildRoute (): string {
    if (this.currentPlaylist) {
      return `sbt-videos/${this.slugProgram}/${this.currentPlaylist}`
    }
    return `sbt-videos/${this.slugProgram}`
  }

  loadMore (pagination: Pagination): void {
    if (pagination.hasMore) {
      this.service
        .getVideosByProgram(
          this.program.id,
          this.currentPlaylist !== 'todos' ? this.currentPlaylist : null,
          pagination
        )
        .then(({ results, next }) => {
          this.videos.push(...results)
          this.currentVideo = this.videos[0].id
          if (next) {
            this.pagination = new Pagination(next)
          }
        })
    }
  }

  getPlaylist(event: any){
    this.currentPlaylist = event['link']
    const pagination = this.pagination ? this.pagination : null
    this.service
      .getVideosByProgram(
        this.program.id,
        this.currentPlaylist !== 'todos' ? this.currentPlaylist : null,
        pagination
      )
      .then(({ results, next }) => {
        this.videos = results
        // console.log(results)
        const currentVideoObject = this.videos[0] ? this.videos[0] : null
        // this.updateMetaTags(currentVideoObject)
        if (next) {
          this.pagination = new Pagination(next)
        }
      })
  }
}
