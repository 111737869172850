import { Component } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { downloadApp } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { CanonicalService } from 'src/app/services/canonical.service'
import { TitleService } from 'src/app/services/title.service'


@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
  providers: [CanonicalService]

})
export class DownloadAppComponent {
  public randomImage: string
  public images: string[] = [
    '../../../assets/images/download-app/baixe-o-app-danilo.png',
    '../../../assets/images/download-app/baixe-o-app-eliana.png',
    '../../../assets/images/download-app/baixe-o-app-poliana.png'
  ]

  constructor (
    private meta: Meta,
    private title: TitleService,
    private metasService: MetatagsService,
    private canonicalService: CanonicalService,
  ) {
    this.metasService.updateTags(downloadApp, this.meta)
    this.title.setTitle(`Baixe o App - SBT`)

    this.randomImage = this.images[
      Math.floor(Math.random() * this.images.length)
    ]
  }

  ngOnDestroy (): void {
    this.canonicalService.removeLink()
  }
  ngOnInit(): void {
    this.canonicalService.appendLink()
  }

  
}
