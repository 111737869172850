import { Injectable } from '@angular/core'
import { URL } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  constructor(private _http: HttpClient) {}

  getSites(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsitearea=1111&idsite=207`)
      .toPromise()
      .then((response: any) => {
        return {
          sites: response.results
        }
      })
  }

  getOtherLinks(): Promise<any> {
    const menuHeader = this._http
    .get(`${URL}/api/menu?idsite=123&orderby=ordem&sort=asc&limit=15`)
    .toPromise()

    const menuFooter = this._http
      .get(`${URL}/api/menu?idsite=123456&orderby=ordem&sort=asc&limit=15`)
      .toPromise()

    const menuFooterItems = this._http
      .get(
        `${URL}/api/menu?idsite=1234567&orderby=ordem&sort=asc&limit=21`
      )
      .toPromise()

    return Promise.all([
      menuHeader,
      menuFooter,
      menuFooterItems
    ]).then((response: any) => {
      const headers = response[0].results
      const areas = response[1].results
      const footerItems = response[2].results

      areas.forEach(footer => {
        footer.subItems = footerItems.filter(
          footerItem =>
          footerItem.descriptionsite === `/${footer.title.toLowerCase()}/`
          )
      })

      areas.unshift({ title: 'Páginas', subItems: headers })

      return areas
    })
  }
}
