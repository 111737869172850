import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { InstitutionalService } from './institutional.service'
import { Meta, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'
import { institutional } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { StationService } from 'src/app/services/station.service'
import { TitleService } from 'src/app/services/title.service'
import { BUCKET, INSTITUTIONAL_MAP } from 'src/app/app.settings'
import { BrandingService } from 'src/app/services/branding.service'
import { CanonicalService } from 'src/app/services/canonical.service'
import { generateTitleSlug } from 'src/app/utils/utils.generators'

@Component({
  selector: 'app-institutional',
  templateUrl: './institutional.component.html',
  styleUrls: ['./institutional.component.scss'],
  providers: [InstitutionalService, CanonicalService]
})
export class InstitutionalComponent implements OnInit {
  @ViewChild('categoriesList', {static: true})
  public categoriesList: ElementRef

  public mapUrl = `${INSTITUTIONAL_MAP}`
  public mapUrlSanitized: SafeResourceUrl

  public logoEditions: any[] = [
    {
      title: '1981',
      image: '../../../assets/images/institucional/logos/sbt_1981.png'
    },
    {
      title: '1985',
      image: '../../../assets/images/institucional/logos/sbt_1985.png'
    },
    {
      title: '1988',
      image: '../../../assets/images/institucional/logos/sbt_1988.png'
    },
    {
      title: '1989',
      image: '../../../assets/images/institucional/logos/sbt_1989.png'
    },
    {
      title: '1999',
      image: '../../../assets/images/institucional/logos/sbt_1999.png'
    },
    {
      title: '2002',
      image: '../../../assets/images/institucional/logos/sbt_2002.png'
    },
    {
      title: '2004',
      image: '../../../assets/images/institucional/logos/sbt_2004.png'
    },
    {
      title: '2014',
      image: '../../../assets/images/institucional/logos/sbt_2014.png'
    }
  ]

  public isAffiliate: boolean = false
  public station: any
  public quickAccessMenuComponents: any[] = [
    { id: 'information', title: 'Quem Somos', showElement: true },
    { id: 'location', title: 'Onde Estamos', showElement: true },
    {
      id: 'social-responsability',
      title: 'Responsabilidade Social',
      showElement: true
    },
    { id: 'work-with-us', title: 'Trabalhe Conosco', showElement: true }
  ]

  public categories: string[] = [
    'Missão e Valores',
    'Nossos Pilares',
    'SBT em Números',
    'Relatório igualdade salarial'
  ]

  public scCategories: string[] = ['História', 'Missão e Visão']
  public locations: any

  public currentHeader: number
  public currentItem: any
  public currentCategory: number = 0

  public socialResponsabilityText: string = `
    Todos os anos nossa plataforma de boas práticas, a SBT do BEM, encampa uma campanha dedicada à Doação de Sangue. Em 2017, a campanha apropria-se de um dos episódios do Chaves, transformando-o em filme publicitário para sensibilizar e lembrar nossos telespectadores que doar sangue é um ato generoso e também muito simples. <br><br>
    Divulgando a hashtag #DoaçãoDoNhonho, a campanha procura engajar, mas também divertir e estimular o telespectador no ato de doar.<br><br>
    “Aproveitamos a força de uma das séries de TV mais amadas pelos brasileiros, que é o Chaves, para lembrar aquela promessa que todo mundo faz, mas nem sempre se concretiza: a de doar sangue. Assim, retomamos uma fala do Nhonho que aconteceu há 40 anos, e que ainda é atual”, destaca Priscila Stoliar, gerente de Marketing do SBT. <br><br>
    Criada pela agência Publicis Brasil, a campanha incentiva o uso da hashtag #DoaçãoDoNhonho, para lembrar aquelas pessoas que também prometeram doar sangue e nunca cumpriram o combinado.  <br><br>
    A doação de sangue é uma das causas mais presentes no calendário de ações da plataforma SBT do Bem. São campanhas de expressiva repercussão e resultados, como a Doe pela Priscila, que ganhou um Leão no Festival de Cannes,  ou Pare e Doe, em parceria com o App Waze, que venceu o Wave Festival e Colunistas.<br><br>
    O filme está sendo veiculado na programação normal do canal, no Youtube e em todas as redes sociais da emissora. Permanece no ar até 30 de novembro.<br><br>
  `
  public socialResponsabilityVideo: string =
    'https://www.youtube.com/embed/aBb17wsIzcE?modestbranding=1&amp;showinfo=0&amp;rel=0'

  public teletonImage: string = `${BUCKET}/programas/logos/79.jpg`
  public sbtDoBemImage: string = `${BUCKET}/programas/logos/435.jpg`
  public trabalheConoscoImage: string = `../../../assets/images/trabalhe-conosco.png`

  public subscriptions = []

  constructor (
    public sanitizer: DomSanitizer,
    private _service: InstitutionalService,
    private title: TitleService,
    private meta: Meta,
    private metaService: MetatagsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stationService: StationService,
    private metasService: MetatagsService,
    private canonicalService: CanonicalService,
    private brandingService: BrandingService
  ) {
    this.metaService.updateTags(institutional, this.meta)
  }

  ngOnDestroy () {
    this.brandingService.setBranding(null)
    this.canonicalService.removeLink()

    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit () {
    this.title.setTitle('Institucional - SBT')
    this.canonicalService.appendLink()

    this.brandingService.setBranding('Institucional')

    this.locations = this._service
      .getLocations()
      .locations.sort(this.alphabeticSort)

    const activeRouteSubscription = this.activatedRoute.url.subscribe(
      urlSegment => {
        if (urlSegment[0] && urlSegment[0].path.includes('afiliada')) {
          this.isAffiliate = true
          const stationIdentifier: string = urlSegment[1].path

          this.station = this.stationService.getStation(stationIdentifier)[0]
        }
      }
    )

    this.subscriptions.push(activeRouteSubscription)

    this.mapUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.mapUrl
    )
  }

  changeCategory (item: any, index: number): void {
    if (this.currentCategory === index) {
      return
    }

    this.currentCategory = index
  }

  openSubMenu (element: any, index: number): void {
    if (this.currentHeader === index) {
      this.currentHeader = -1

      return
    }

    this.currentHeader = index
  }

  scroll (left: number): void {
    this.categoriesList.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  prev (): void {
    this.scroll(-200)
  }

  next (): void {
    this.scroll(200)
  }

  alphabeticSort (first: any, second: any) {
    if (first.estado.toLowerCase() < second.estado.toLowerCase()) {
      return -1
    }

    if (first.estado.toLowerCase() > second.estado.toLowerCase()) {
      return 1
    }

    return 0
  }

  receiveData (content: any): void {
    this.currentCategory = content.index
  }

  onUpdateUrlState (title) {
    const sectionTitle = generateTitleSlug(title)

    const newtitle = `Institucional - ${title} - SBT`

    const currentUrl: string = window.location.pathname

    const newUrl = `${currentUrl}#${sectionTitle}`

    window.history.pushState({}, '', `${newUrl}`)

    this.title.setTitle(newtitle)
  }
}
