import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core'
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  FormGroup
} from '@angular/forms'
import { UploadService } from 'src/app/services/upload.service'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    }
  ]
})
export class FormInputComponent implements ControlValueAccessor, OnInit {
  @Input('parent-form')
  public parentForm: FormGroup

  @Input('form')
  public form: any

  @Input('model')
  public model

  @Input('name')
  public name: string

  @Input('id')
  public id: number

  @Input('limit-caracteres')
  public limitcaracteres: string
  //limit-caracteres

  @Input('label')
  public label: string

  @Input('placeholder')
  public placeholder: string

  @Input('inputMask')
  public inputMask: string

  @Input('inputPattern')
  public inputPattern: string

  @Input('required')
  public required: boolean = false

  @Input('type')
  public type: string

  @Input('multiple')
  public multiple: boolean = false

  @Input('options')
  public options: any[]

  @Input('maxLength')
  public maxLength: string

  @Input('minLength')
  public minLength: string

  @Input('hint')
  public hint: boolean = false

  @Output('upload-field')
  public uploadField: EventEmitter<any> = new EventEmitter()

  @ViewChild('select', {static: true})
  public selectField: ElementRef

  public fileName: string
  public textAreaMaxLength: number = 1250
  public textInputMaxLength: number = 500

  public active: boolean = false
  public show: boolean = false
  public uploadError: string

  public imgTypes: string[] = ['jpg', 'jpeg', 'png']
  public videosTypes: string[] = ['mp4', 'quicktime', 'avi', 'wmv']

  constructor (private uploadService: UploadService) {}

  ngOnInit () {
    if (this.required) {
      this.label = ` ${this.label} <span class="required">*</span>`
    }
  }

  checkForErrors () {
    // console.log(this.parentForm.get(this.name).valid)
    // console.log(this.parentForm.get(this.name).value)
  }

  onChange: (_: any) => void = (_: any) => {}

  onTouched: () => void = () => {}

  updateChanges () {
    this.onChange(this.model)
  }

  ngOnChanges (changes) {}

  writeValue (value: string): void {
    this.updateChanges()
  }

  registerOnChange (fn: any): void {
    this.onChange = fn
  }

  registerOnTouched (fn: any): void {
    this.onTouched = fn
  }

  setActive (): void {
    this.active = !this.active
    this.selectField.nativeElement.focus()
  }

  toggleVisible (): void {
    this.show = !this.show
  }

  uploadVideo (fileInput: any): void {
    const fieldName = fileInput.target.attributes['name'].value

    const file: File = fileInput.target.files[0]

    const maxSize: number = 150 * 1024 * 1024

    const { name } = file
    this.fileName = name

    if (!this.checkFileType(this.videosTypes, file)) {
      return this.uploadField.emit({
        uploadError: 'É aceito somente vídeos nos formatos: mp4, mov, avi, wmv'
      })
    }

    if (file.size > maxSize) {
      return this.uploadField.emit({
        uploadError: 'O tamanho máximo do video é 150Mb'
      })
    }

    this.uploadFile(file, fieldName)
  }

  uploadImage (fileInput: any): void {
    const fieldName = fileInput.target.attributes['name'].value

    const file: File = fileInput.target.files[0]

    const maxSize: number = 2 * 1024 * 1024

    const { name } = file
    this.fileName = name

    if (!this.checkFileType(this.imgTypes, file)) {
      return this.uploadField.emit({
        uploadError: 'É aceito somente imagens nos formatos: jpg, jpeg, png'
      })
    }

    if (file.size > maxSize) {
      return this.uploadField.emit({
        uploadError: 'O tamanho máximo da imagem é 2Mb'
      })
    }

    this.uploadFile(file, fieldName)
  }

  uploadFile (file, fieldName) {
    this.fileName = 'Carregando...'
    this.uploadField.emit({
      uploadError: null
    })

    this.uploadService
      .uploadFile(file)
      .then(response => {
        const fieldValue = response.file

        this.uploadField.emit({
          fieldName,
          fieldValue
        })
        const { name } = file
        this.fileName = name
      })
      .catch(err => {
        this.uploadError = err

        this.uploadField.emit({
          uploadError: this.uploadError
        })
      })
  }

  checkFileType (typesToCheck: string[], file: File): boolean {
    return typesToCheck.some(type => file.type && file.type.includes(type))
  }
}
