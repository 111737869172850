import { Routes } from '@angular/router'
import { InstitutionalComponent } from '../pages/institutional/institutional.component'

const InstitutionalRoutes: Routes = [
  {
    path: 'institucional',
    component: InstitutionalComponent
  }
]

export { InstitutionalRoutes }
