import { Routes } from '@angular/router'
import { InscriptionFormComponent } from '../pages/inscription-form/inscription-form.component'
import { RedirectInscriptionsService } from '../services/redirect.inscriptions.service'
 
const InscriptionsRoutes: Routes = [
  // {
  //   path: 'inscricoes',
  //   canActivate: [RedirectInscriptionsService],
  //   component: RedirectInscriptionsService
  // },
  {
    path: 'inscricoes/:slug/:inscription',
    canActivate: [RedirectInscriptionsService],
    component: RedirectInscriptionsService
  },
  {
    path: 'inscricoes/call-of-cast/:inscription/:inscriptionTitle',
    component: InscriptionFormComponent
  },
  {
    path: 'inscricoes/pod-pai-pod-filho/:inscription/:inscriptionTitle',
    component: InscriptionFormComponent
  },
  {
    path: 'inscricoes/sbt-noticias/:inscription/:inscriptionTitle',
    component: InscriptionFormComponent
  },
  {
    path: 'inscricoes/sbt-sports/:inscription/:inscriptionTitle',
    component: InscriptionFormComponent
  },
  {
    path: 'inscricoes/sbt-esporte-rio/:inscription/:inscriptionTitle',
    component: InscriptionFormComponent
  },
  {
    path: 'inscricoes/sbt-games/:inscription/:inscriptionTitle',
    component: InscriptionFormComponent
  },  
  {
    path: 'inscricoes/sbt-na-web/:inscription/:inscriptionTitle',
    component: InscriptionFormComponent
  },  
  {
    path: 'inscricoes/:slug/:inscription/:inscriptionTitle',
    canActivate: [RedirectInscriptionsService],
    component: RedirectInscriptionsService
  },
  {
    path: 'afiliada/:station/inscricoes',
    component: InscriptionFormComponent
  }
]
 
export { InscriptionsRoutes }