import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-related',
  templateUrl: './related.component.html',
  styleUrls: ['./related.component.scss']
})
export class RelatedComponent implements OnInit {
  @Input('contents')
  contents: any[] = []

  constructor () {}

  ngOnInit () {}
}
