import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import Program from '../../models/program.class'
import { ProgramService } from '../../pages/program/program.service'
import { StayInsideNotice } from '../../models/stayInsideNotice.class'
import { generateSlug, generateTitleSlug} from 'src/app/utils/utils.generators'
import { URL_SITE_SBTNEWS, ID_SITE_SBTNEWS, ID_SITE_PRIMEIRO_IMPACTO, ID_SITE_SBT_BRASIL, ID_SBTNOTICIAS } from 'src/app/app.settings';
import Notice from '../../models/notice.class';
import { StationService } from 'src/app/services/station.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stay-inside',
  templateUrl: './stay-inside.component.html',
  styleUrls: ['./stay-inside.component.scss'],
  providers: [ProgramService]
})
export class StayInsideComponent implements OnInit {
  @Input('stay-inside')
  public stayInside: Notice[]

  @Input('program')
  public program: Program

  @ViewChild('articles', {static: true})
  public articles: ElementRef<HTMLDivElement>

  @Input('title')
  public title: string = 'Fique Por Dentro'

  public lastIndex: number = 0
  public isNoticeNews: boolean = false
  public link: string
  public count = 0
  public keywordSBTNoticias: any
  public siglaAfiliate: any

  constructor (
    private _service: ProgramService,
    private cd: ChangeDetectorRef,
    private stationService: StationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.cd.detectChanges();
    this.siglaAfiliate = this.route.snapshot.paramMap.get("station");
  }

  scroll (top: number): void {
    this.articles.nativeElement.scrollBy({
      top,
      behavior: 'smooth'
    })
  }

  getRouterLink (item: Notice): string {
    if (item.author && item.author.includes('/')) {
      return item.author
    }

    if (item.idprogram === ID_SITE_SBTNEWS) {
      this.isNoticeNews = true

      if(this.count <= 10) {
        this.count++
      }

      const taghighlight = `${generateSlug(item.taghighlight)}`
      return `${URL_SITE_SBTNEWS}/noticia/${taghighlight}/${item.slug}`

    }

    if (item.idprogram === ID_SITE_SBT_BRASIL || item.idprogram === ID_SITE_PRIMEIRO_IMPACTO) {
      this.isNoticeNews = true
      return `${URL_SITE_SBTNEWS}/noticia/${generateTitleSlug(item.program)}/${item.slug}`
    }

    if (item.gender && item.program) {
      const gender = generateSlug(item.gender).replace(/-/g, '')
      const programSlug = generateTitleSlug(item.program)
      let type
      let newUrl

      gender === 'jornalismo' ? (type = 'noticia') : (type = 'fiquepordentro')


      if ( this.siglaAfiliate != undefined) {
        const cookie = this.stationService.getStationCookie()
        const cookieStation = this.stationService.getStation(cookie)[0]
        const station = this.stationService.getStation(this.siglaAfiliate) [0] || cookieStation

        if( station.affiliate ) {
          newUrl = `/afiliada/${station.identifier}/${gender}/${programSlug}/${type}/${item.slug}`
        } else if( !station.affiliate && station.identifier != 'default' ) {
          newUrl = `/${station.identifier}/${gender}/${programSlug}/${type}/${item.slug}`
        } else {
          newUrl = `/${gender}/${programSlug}/${type}/${item.slug}`
        }
      } else {
        newUrl = `/${gender}/${programSlug}/${type}/${item.slug}`
      }

      return newUrl
    }
  }

  lengthIsMaxSplit(item: any) : any {
    const title = item.trim()
    if(title.length > 59) {
      return `${title.slice(0, 59)}...`
    } else {
      return title
    }
  }

  previousArticles (): void {
    this.scroll(-300)
  }

  nextArticles (): void {
    this.scroll(300)
  }

  checkIfCanLoad (event: any) {
    if (event.visible) {
      this.loadMore()
    }
  }

  loadMore (): void {
    this.keywordSBTNoticias = this.program.id === ID_SITE_SBTNEWS && this.program.idSite === ID_SBTNOTICIAS ? 'portalnews,sbt-noticias': ''
    this._service
    .getProgramNotices(
      this.program,
      this.stayInside[this.lastIndex].pagination,
      this.keywordSBTNoticias
      )
      .then(response => {
        const notices = response.results.map(
          result => new StayInsideNotice(result, response.next)
        )

        this.stayInside.push(...notices)

        this.lastIndex = this.stayInside.length - 1
      })
  }
}
