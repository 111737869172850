import { Injectable } from '@angular/core'

import { URL } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InscriptionFormService {

  constructor (private _http: HttpClient) {}

  getFields (idInscripion: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/playlistsrelated?idmediarelated=${idInscripion}`
      )
      .toPromise()
      .then((response: any) => {
        const fields = response.results

        fields.map(field => {
          if (
            field.author.toLowerCase() === 'text' ||
            field.author.toLowerCase() === 'youtubeurl'
          ) {
            field.author = 'input'
          }
        })

        return fields
      })
      .catch(e => console.log(e))
  }
}
