import * as CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../app.settings'
import { Log } from './util.console';

export const encrypt = (str: string) => {
    try{
        const encryptedText = CryptoJS.AES.encrypt(str, SECRET_KEY)
        return encryptedText.toString()
    } catch(e) {
        Log('ENCRYPT', e.message)
        return ''
    }
}

export const decrypt = (str: string) => {
    try{
        let bytes  = CryptoJS.AES.decrypt(str, SECRET_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch(e) {
        Log('DECRYPT', e.message)
        return ''
    }
} 

export const encryptFull = (str: string, key: string, iv: string) => {
    try{
        return CryptoJS.AES.encrypt(str, CryptoJS.enc.Base64.parse(key), { iv: CryptoJS.enc.Base64.parse(iv) }).toString()
    } catch(e) {
        Log('ENCRYPT_FULL', e.message)
        return ''
    }
}

export const decryptFull = (str: string, key: string, iv: string) => {
    try{
        return CryptoJS.AES.decrypt(str, CryptoJS.enc.Base64.parse(key), { iv: CryptoJS.enc.Base64.parse(iv) }).toString(CryptoJS.enc.Utf8)
    } catch(e) {
        Log('DECRYPT_FULL', e.message)
        return ''
    }
} 