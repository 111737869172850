import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TitleService } from 'src/app/services/title.service'
import { NotFoundService } from './not-found.service'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit{
  public notFoundHigh: any[]

  constructor (
    private _service: NotFoundService,
    private title: TitleService,
    private router: Router
    ) {this.title.setTitle('Conteúdo não encontrado - SBT')}

    ngOnInit (): void {
    
      this._service.getHighlightedList().then(highlight => {
        this.notFoundHigh = highlight
      })
    }
}
