import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { URL, URL_SEARCH_API, ID_SITE, IDSITEAREA_FAQ, ID_FAQ } from '../../app.settings'

@Injectable()
export class Medias {
  constructor (private _http: HttpClient) {}

  get (idplaylist: number, next: string): Promise<object> {
    let params = `idplaylist=${idplaylist}&idsite=${ID_FAQ}&idsitearea=${IDSITEAREA_FAQ}&limit=5&orderby=ordem&sort=desc`
    if (next) params += `&next=${next}`

    return this._http.get(`${URL}/api/medias?${params}`).toPromise()
  }

  search (keyword: string, next: string): Promise<object> {
    let params = `idsite=${ID_SITE}&idsitearea=${IDSITEAREA_FAQ}&limit=99&term=${keyword}`
    if (next) params += `&next=${next}`
    return this._http.get(`${URL_SEARCH_API}/api/medias?${params}`).toPromise()
  }
}

@Injectable()
export class Playlists {
  constructor (private _http: HttpClient) {}

  get (next: string): Promise<object> {
    let params = `idsite=${ID_FAQ}&idsitearea=${IDSITEAREA_FAQ}&limit=200&orderby=ordem&sort=desc`
    if (next) params += `&next=${next}`

    return this._http.get(`${URL}/api/playlists?${params}`).toPromise()
  }
}
