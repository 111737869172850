import Pagination from './pagination.class'

export class Winner {
  public title: string
  public thumbnail: string
  public description: string
  public pagination: Pagination
  public idSite: number
  public idSiteArea: number

  constructor (object, pagination) {
    this.title = object.title
    this.thumbnail = object.thumbnail
    this.pagination = new Pagination(pagination)
    this.idSite = object.idsite
    this.idSiteArea = object.idsitearea

    this.buildWinnersHtml(object)
  }

  buildWinnersHtml (object) {
    let winners: string[] = []
    let finalists: string[] = []
    let html: string = ''

    if (object.description.includes('[*]')) {
      object.description = object.description.substr(0, object.description.indexOf('['))

      winners.push(object.description)
    }

    if (object.opcional.includes('[*]')) {
      object.opcional = object.opcional.substr(0, object.opcional.indexOf('['))

      winners.push(object.opcional)
    }

    if (object.author.includes('[*]')) {
      object.author = object.author.substr(0, object.author.indexOf('['))

      winners.push(object.author)
    }

    if (!winners.includes(object.description)) {
      finalists.push(object.description)
    }

    if (!winners.includes(object.opcional)) {
      finalists.push(object.opcional)
    }

    if (!winners.includes(object.author)) {
      finalists.push(object.author)
    }

    winners.forEach(winner => {
      html += `<h3>${winner}</h3>`
    })

    if (finalists.length > 0) {
      finalists.length > 1
      ? html += `<strong>Finalistas</strong><p>`
      : html += `<strong>Finalista</strong><p>`

      finalists.forEach(finalist => {
        html += `${finalist}<br>`
      })

      html += `</p>`
    }

    this.description = html
  }
}
