import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { WEBPUSH } from '../app.settings';
import { AnalyticsService } from './analytics.service';

@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    tokenLS = (window.localStorage) ? window.localStorage.getItem('sbtpntoken') : null

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private analyticsService: AnalyticsService
    ) {
        this.angularFireMessaging.messages.subscribe(
            (_messaging: AngularFireMessaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            })
    }

    requestPermission() {
        // user permission
        //uses a VAPIDKEY, unique server identifier to subscribe the user to notifications by that server
        this.angularFireMessaging.usePublicVapidKey(WEBPUSH.VAPIKEY)
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                
                //envia o token pro server e salva o token
                if (token) {
                    if (this.tokenLS !== token) {
                        this.sendTokenToServer('subscribe', token).then((res) => {
                            this.saveToken(token)
                        }).catch(e => {
                            this.saveToken(null)
                        })
                    }

                } else {
                    //Show permission request UI
                    this.saveToken(null)
                }
            },
            (err) => {
                this.saveToken(null)
            }
        );
    }


    receiveMessage() {
        //sending the push message
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                this.showNotification(payload)
                this.currentMessage.next(payload);
            })
    }

    showNotification(payload) {

        const notificationTitle = payload.data.title;
        const notificationOptions = {
            body: payload.data.body,
            icon: payload.data.icon,
            image: payload.data.image,
            requireInteraction: payload.data.requireInteraction,
            tag: payload.data.tag
        };
        
        if (!("Notification" in window)) {
            console.log(`This browser does not support system notifications`)
        } else {
            var notification = new Notification(notificationTitle, notificationOptions);
            if (Notification.permission === "granted") {
                try {
                    notification.addEventListener('click', (e) => {
                        e.preventDefault(); //prevent the browser from focusing the Notification's tab
                        window.open(payload.data.tag, '_blank')
                        notification.close()
                    })
                } catch (err) {
                    try { //Need this part as on Android we can only display notifications thru the serviceworker
                        navigator.serviceWorker.ready.then(function (reg) {
                            reg.showNotification(notificationTitle, notificationOptions)
                        });
                    } catch (err1) {
                        console.log(err1.message)
                    }
                }
            }
        }
    }

    saveToken(token) {
        if (window.localStorage) window.localStorage.setItem('sbtpntoken', token)
        this.tokenLS = token
    }

    sendTokenToServer(type = 'subscribe', token) {
        let urlServer = ''
        switch (type) {
            case 'subscribe':
                urlServer = `${WEBPUSH.SERVICE}/${WEBPUSH.METHODS.SUBSCRIBE}/${WEBPUSH.TOPIC_NAME}`
                break;
            case 'unsubscribe':
                urlServer = `${WEBPUSH.SERVICE}/${WEBPUSH.METHODS.UNSUBSCRIBE}/${WEBPUSH.TOPIC_NAME}`
                break;
            default:
                urlServer = `${WEBPUSH.SERVICE}/${WEBPUSH.METHODS.SUBSCRIBE}/${WEBPUSH.TOPIC_NAME}`
                break;
        }

        return new Promise((resolve, reject) => {
            const headers = new Headers()
            headers.set('Content-Type', 'application/json')
            headers.set('Authorization', `Bearer ${WEBPUSH.SERVICE_ACCESS_TOKEN}`)

            fetch(urlServer, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ token }),
                mode: 'cors',
                cache: 'no-cache'
            }).then(res => res.json()).then((res) => {
                resolve(res)
            }).catch(e => {
                reject(e)
            })
        })
    }
}