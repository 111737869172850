import { OnInit, Component } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  templateUrl: './manager-preferences-cookie.component.html',
  styleUrls: ['./manager-preferences-cookie.component.scss'],
  selector: 'app-manager-preferences-cookie'
})
export class ManagerPreferencesCookieComponent implements OnInit {
  constructor() { }

  ngOnInit() {

  }

}