import { Location } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { ActivatedRoute, Params } from '@angular/router'
import { $ } from 'protractor'

import { Router } from '@angular/router'
import { AnalyticsService } from 'src/app/services/analytics.service'
import { ComscoreService } from 'src/app/services/comscore.service'
import { TitleService } from 'src/app/services/title.service'
import { getURL } from 'src/app/utils/util.getUrl'

import { MediaInterface } from '../../interfaces/index.interface'
import { normalizeURL } from '../../utils/util.normalize'

import { CategoryInterface } from './faq.interfaces'
import { Medias, Playlists } from './faq.service'

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [Medias, Playlists]
})

export class FAQ implements OnInit {
  constructor(
    private analytics: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private comscore: ComscoreService,
    private location: Location,
    private medias: Medias,
    private playlists: Playlists,
    private title: TitleService,
    private router: Router
  ) { }

  @ViewChild('menuCategories') public menuCategories: ElementRef

  private activeDoubt: number = -1
  private activeCategory: object
  private categories: Array<object>
  private data: Array<object>
  private paging: boolean = false
  private urlCategory: number = 0
  private urlDoubt: string = ''
  private urlSearch: string = ''

  public doubt: MediaInterface
  public doubts: Array<object>
  public itemsBreadcrumb: Array<object> = []
  public loader: boolean = true
  public searchTerm: string = ''
  public showActiveDoubt: boolean = false
  public showCategories: boolean = false
  public showDoubts: boolean = false
  public showErrorMessage: boolean = false
  public showMenu: boolean = false
  public showSeeMore: boolean = false
  public showSearch: boolean = false
  public state: string
  public breadcrumbPieceRoute: string = ''

  handleActiveAccordionDoubt(item: number): void {
    if (item === this.activeDoubt) return this.setActiveDoubt(-1)
    this.analytics.sendEvent('Open Accordion', 'click', this.activeCategory['title'])
    this.setActiveDoubt(item)
  }

  handleActiveDoubt(doubt: MediaInterface, termSearched: string) {
    let category: object = {}

    this.data.map(el => el['doubts'].map((x: MediaInterface) => {
      if (doubt !== x) return false
      category = el
    }))

    !doubt.keywords ? doubt.keywords = Array.from(doubt.opcional.split(',')) : doubt.keywords

    const urlSearch = location.href.split('=')[1]

    if (termSearched) {
      this.breadcrumbPieceRoute = `/faq/search?q=${termSearched}`
      category['title'] = termSearched
    } else if (urlSearch) {
      category['title'] = urlSearch
      this.breadcrumbPieceRoute = `/faq/search?q=${urlSearch}`
    } else {
      this.breadcrumbPieceRoute = `/faq/${category['id']}`
    }

    const breadcrumbTitle = doubt.title.length > 50 ? doubt.title.substring(0, 50).concat('...') : doubt.title
    this.itemsBreadcrumb = [{ route: '/faq', title: 'Faq' }, { route: this.breadcrumbPieceRoute, title: category['title'] }, { route: '', title: breadcrumbTitle }]

    this.location.replaceState(`/faq/duvida/${doubt.id}-${normalizeURL(doubt.title)}`)
    this.analytics.sendPageView(getURL(), normalizeURL(doubt.title), '')
    this.comscore.sendPageView(getURL())
    this.analytics.sendEvent('Open doubt', 'click', `${doubt.id}-${normalizeURL(doubt.title)}`)
    this.setDoubt(doubt)
    this.setShowActiveDoubt(true)
    this.setShowDoubts(false)
    this.setShowMenu(true)
    this.setShowSeeMore(false)
    this.setLoader(false)

    this.resetForm()
  }

  handleActiveCategory(category: number): void {
    this.resetForm()
    this.state = 'category'
    const [selectedCategory] = this.data.filter(({ id }: CategoryInterface) => id === category)
    this.itemsBreadcrumb = [{ route: '/faq', title: 'Faq' }, { route: '', title: selectedCategory['title'] }]

    this.location.replaceState(`/faq/${selectedCategory['id']}`)
    this.analytics.sendPageView(getURL(), normalizeURL(selectedCategory['title']), '')
    this.comscore.sendPageView(getURL())
    this.setActiveDoubt(-1)
    this.setLoader(true)
    this.setShowActiveDoubt(false)
    this.setShowDoubts(false)
    this.setShowCategories(false)

    this.setShowSearch(false)
    this.setShowSeeMore(false)
    this.categories = [selectedCategory, ...this.data.filter(({ id }: CategoryInterface) => id !== selectedCategory['id'])]

    this.data
      .filter(({ id }: CategoryInterface) => id === category)
      .map((el: CategoryInterface) => {
        el.doubts = el.doubts.map((doubt: MediaInterface) => {
          doubt.keywords = doubt.opcional.split(',').map(el => el.trim())
          return doubt
        })

        if (el.next.moreResults === 'MORE_RESULTS_AFTER_LIMIT') this.setShowSeeMore(true)

        this.setActiveCategory(el)
        this.setDoubts(el.doubts)
        this.setLoader(false)

        this.setShowDoubts(true)
        this.setShowMenu(true)
        this.scrollMenuCategories(-999)
      })
  }

  handleKeyword(keyword: string): void {
    this.analytics.sendEvent('Keyword clicked', 'click', keyword)
    this.setActiveDoubt(-1)
    this.setLoader(true)
    this.setShowActiveDoubt(false)
    this.handleSearch(keyword)
    this.setSearchTerm(keyword)
  }

  handlePagination(): void {
    if (this.paging) return

    this.paging = true

    this.categories
      .filter((el: { id: number }) => el.id === this.activeCategory['id'])
      .map((el: { doubts: Array<object>, next: { endCursor: string, moreResults: string } }) => {

        this.medias
          .get(this.activeCategory['id'], el.next.endCursor)
          .then(({ next, results }: { next: { endCursor: string, moreResults: string }, results: Array<object> }) => {
            el.next = next
            el.doubts = [...el.doubts, ...results]

            if (next.moreResults !== 'MORE_RESULTS_AFTER_LIMIT') this.setShowSeeMore(false)
            this.setDoubts(el.doubts)

            this.paging = false
          })
          .catch(err => {
            throw err
          })
      })
  }

  handleFormSearch(form: NgForm): void {
    if (!form.controls.search.value) {
      this.router.navigate(['/faq']);
      this.setShowCategories(true)
      this.setShowActiveDoubt(false)
      this.setShowDoubts(false)
      this.setShowMenu(false)
      this.itemsBreadcrumb = []
    } else {
      this.state = 'search'
      this.setShowCategories(false)
      this.setShowActiveDoubt(false)
      this.setShowMenu(false)
      this.setShowDoubts(false)
      this.setLoader(true)
      this.handleSearch(form.value.search)
      this.setSearchTerm(form.value.search)
      this.itemsBreadcrumb = [{ route: '', title: 'Faq' }]
    }
  }

  handleSearch(term: string): void {
    this.itemsBreadcrumb = [{ route: '', title: 'faq' }]

    this.location.replaceState(`/faq/search?q=${normalizeURL(term)}`)
    this.setShowErrorMessage(false)
    this.medias.search(normalizeURL(term), '').then(({ results }: { results: Array<MediaInterface> }) => {
      this.doubts = results.map(el => {
        el.id = el['tableid']
        return el
      })

      const [activeCategory] = this.data

      this.setActiveCategory(activeCategory)
      this.setShowMenu(true)
      this.setLoader(false)
      this.setShowDoubts(true)
      this.setShowSearch(true)
    })
  }
  ngOnInit(): void {
    this.title.setTitle('FAQ - SBT')
    this.activatedRoute.params.subscribe((params: Params) => {
      this.urlCategory = Number(params.urlCategory)
      this.urlDoubt = params.urlDoubt
      this.urlSearch = params.urlSearch
    })
    this.playlists.get('')
      .then(async ({ results }: { results: Array<object> }) => {
        if (!results) {
          this.setLoader(false)
          this.setShowErrorMessage(true)
          return false
        }

        const playlists = results
          .map(({ id, opcionalplaylist3: image, title }: { id: number, opcionalplaylist3: string, title: string }) => ({
            id,
            image: image.replace(/\/tn\//, '/') || '/assets/images/sbt-icon-image-broken-fallback.png',
            title
          }))
        const categories = await Promise.all(
          playlists.map(({ id, image, title }: { id: number, image: string, title: string }) =>
            this.medias.get(id, '').then(({ next, results: doubts }: { next: string, results: Array<object> }) => {

              doubts = doubts.map((el: MediaInterface): MediaInterface => {
                el.description = el.description.replace(/<p>/gim, '<p style="margin: 0 0 8px; padding: 0;">')
                el.description = el.description.replace(/<img/gim, '<img style="width: 100%; height: auto;"')
                el.description = el.description.replace(/<figure/gim, '<figure style="margin: 12px auto;"')
                el.description = el.description.replace(/<figcaption/gim, '<figcaption style="font-size: 14px; margin: 4px 0 0; text-align: center;"')
                return el
              })

              return {
                doubts,
                id,
                image,
                next,
                title
              }
            })
          )
        )

        this.categories = categories
        this.data = categories

        if (!this.urlCategory && !this.urlDoubt && !this.urlSearch.length) {
          this.setLoader(false)
          this.setShowCategories(true)
        }
        if (this.urlCategory && !this.urlDoubt && !this.urlSearch.length) {
          return this.handleActiveCategory(this.urlCategory)
        }
        if (!this.urlCategory && this.urlDoubt && !this.urlSearch.length) {
          const id = Number(this.urlDoubt.substring(0, this.urlDoubt.indexOf('-')))

          this.data.map((category: CategoryInterface) => {
            const filtered = category.doubts.filter((doubt: MediaInterface) => {
              if (doubt.id !== id) return false
              doubt.keywords = doubt.opcional.split(',').map(el => el.trim())
              return doubt
            })
            if (!filtered.length) {
              this.setLoader(false)
              this.setShowErrorMessage(true)
              return
            }
            this.setActiveCategory(category)
            return filtered.map((el: MediaInterface) => {
              this.handleActiveDoubt(el, ' ')
            })
          })
        }
        if (!this.urlCategory && !this.urlDoubt && this.urlSearch !== '') {
          this.handleSearch(new URLSearchParams(new URL(window.location.href).search).get('q'))
          this.setSearchTerm(location.href.split('=')[1])
        }
      })
      .catch(err => {
        throw err
      })
  }

  scrollMenuCategories(left: number): void {
    if (!this.menuCategories) return

    this.menuCategories.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  setActiveDoubt(item: number): void {
    this.activeDoubt = item
  }

  setActiveCategory(category: object): void {
    this.activeCategory = category
  }

  setDoubt(doubt: MediaInterface): void {
    this.doubt = doubt
  }

  setDoubts(doubts: Array<object>): void {
    this.doubts = doubts
  }

  setLoader(bool: boolean): void {
    this.loader = bool
  }

  setSearchTerm(term: string): void {
    this.searchTerm = term
  }

  setShowCategories(bool: boolean): void {
    this.showCategories = bool
  }

  setShowActiveDoubt(bool: boolean): void {
    this.showActiveDoubt = bool
  }

  setShowDoubts(bool: boolean): void {
    this.showDoubts = bool
  }

  setShowErrorMessage(bool: boolean): void {
    this.showErrorMessage = bool
  }

  setShowMenu(bool: boolean): void {
    this.showMenu = bool
  }

  setShowSearch(bool: boolean): void {
    this.showSearch = bool
  }

  setShowSeeMore(bool: boolean): void {
    this.showSeeMore = bool
  }

  resetForm(): void {
    (document.querySelector('form') as HTMLFormElement).reset();
  }
}