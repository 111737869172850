import { Location } from '@angular/common'
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ANALYTICS_ID, WEBPUSH } from './app.settings'
import { IUserObservable } from './models/user.class'
import { AnalyticsService } from './services/analytics.service'
import { ComscoreService } from './services/comscore.service'
import { NaveggClass } from './thirdparty/navegg.service'
import { CookieService } from './services/cookie.service'
import { GeolocationService } from './services/geolocation.service'
import { StationService } from './services/station.service'
import { TitleService } from './services/title.service'
import { UserService } from './services/user.service'
import { UpdateBotService } from '../app/thirdparty/inbot.service'
import { decrypt } from './utils/util.encrypt'
import { MessagingService } from './services/messaging.service'
import { win } from 'ngx-youtube-player/src/services/youtube-player.service'
import { encryptFull } from './utils/util.encrypt'
import { Log } from './utils/util.console'
import { take } from 'rxjs/operators'

declare const googletag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('siteContent', { static: true })
  public siteContent: ElementRef
  public lastTitleAnalytics: string
  public lastUrl: string
  public userCookie: string
  public showStationTab: boolean = false
  public subscriptions = []
  public message: any
  public push: boolean = false
  public epochTime: any
  public expires: any
  public cache: any
  public pushName: any
  public cacheSession: any
  public pushStatusNotAccepted: any
  public localizationFixed: boolean = false
  public localizationName: any
  public localization: boolean = false
  public localizationStatusNotAccepted: any

  constructor(
    private renderer: Renderer2,
    private cookieService: CookieService,
    private geoService: GeolocationService,
    private titleService: TitleService,
    private stationService: StationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private comScoreService: ComscoreService,
    private naveggService: NaveggClass,
    private messagingService: MessagingService
  ) {
    const time = Math.floor(Date.now() / 1000)
    this.epochTime = time
    this.expires = parseInt(this.epochTime + 2592000, 10)
    this.pushName = "pushNotificationSbt"
    this.pushStatusNotAccepted = 'not accepted'
    this.localizationName = "geolocalizationSbt"
    this.localizationStatusNotAccepted = "not accepted"
  }

  setInitialVars() {
    interface UserProps {
      firstName: string;
      lastName: string;
      socialName: string;
      birthDate: string;
      email: string;
      id: string;
      emailValidated: boolean;
    }

    interface UserData {
      sub: string;
      id: string;
      user: UserProps;
      iat: number;
      exp: number;
  }

  // if (localStorage.getItem('user')) {
  //   let decryptedData = decrypt(localStorage.getItem('user') || '')

    if (localStorage.getItem('SBT_access_token')) {
      const token = localStorage.getItem('SBT_access_token');
      let toJSON = token.split('.')[1]
      const base64 = toJSON && toJSON.replace(/-/g, '+').replace(/_/g, '/')

      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
  
      const userData: UserData = JSON.parse(jsonPayload);
      const user: UserProps = userData.user;
      
      console.log(token)
      console.log(toJSON)
      console.log(userData)
      console.log(user)



      UpdateBotService({ name: `${user.firstName} ${user.lastName}` || '', email: user.email || '' }, 3000)
      // const user = decryptedData
      // UpdateBotService({ name: user.name || '', email: user.email || '' }, 3000)

    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngAfterViewInit() {
    let userAgent = navigator.userAgent || navigator.vendor

    if (!userAgent.match(/iPad/i) && !userAgent.match(/iPhone/i) && !userAgent.match(/iPod/i) && !userAgent.match(/Macintosh/i) && !userAgent.includes("Mac")) {

      if (WEBPUSH.ENABLED === true) {
        this.cache = this.getCacheLocal(this.pushName)

        let cacheSession = window.sessionStorage.getItem('closePushNotificationSbt')

        if (!this.cache.expires) {
          this.setCacheLocal(this.pushName, "no reply", this.expires)
        }

        if (Notification.permission === 'granted') {
          this.setCacheLocal(this.pushName, 'accepted', -1)
        }

        if (Notification.permission === 'denied') {
          this.setCacheLocal(this.pushName, this.pushStatusNotAccepted, -1)
        }

        if (Notification.permission === 'default' && !cacheSession && this.cache.status !== this.pushStatusNotAccepted) {
          setTimeout(() => {
            this.push = true
          }, 3000);
        }

        if (Notification.permission === 'default' && this.cache.expires < this.epochTime) {
          setTimeout(() => {
            this.push = true
          }, 3000);
        }
      }

      if (navigator.geolocation) {
        this.cache = this.getCacheLocal(this.localizationName)

        navigator.permissions.query({ name: "geolocation" }).then(resp => {

          if (!this.cache.expires) {
            this.setCacheLocal(this.localizationName, "no reply", -1)
          }

          if (resp.state === 'denied') {
            this.setCacheLocal(this.localizationName, this.localizationStatusNotAccepted, -1)
          }

          if (resp.state === 'granted') {
            this.setCacheLocal(this.localizationName, "accepted", -1)
          }

          if (resp.state === 'prompt' && this.cache.status !== this.localizationStatusNotAccepted) {
            this.localization = true
          }


          if (resp.state === 'prompt' && this.cache.expires < this.epochTime) {
            this.localization = true
          }
        })
      }
    } else {
      this.geoService.getPosition(
        position => {
          this.geoService.chooseStation(position.coords).then(response => {
            if (response.regional) {
              const identifier = this.stationService.formatStationIdentifier(
                response.regional
              )

              const currentStation = this.stationService.getStation(identifier)[0]

              if (
                !this.stationService.checkStationCookie() &&
                identifier !== 'nacional'
              ) {
                if (currentStation && currentStation.affiliate) {
                  this.router.navigate([`/afiliada/${identifier}`])

                  this.stationService.setStationCookie(identifier)

                  return
                }

                this.router.navigate([`/${identifier}/home`])

                this.stationService.setStationCookie(identifier)
              }
            }
          })
        },
        error => {
          const errorDescription = this.geoService.catchError(error)
        }
      )
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(({token, jwt}) => {
      if (token || jwt) {
        this.userService.loginSSO(decodeURIComponent(token || jwt));
      }
    })

    this.setInitialVars()

    let GA_CONFIG: any = [{ id: ANALYTICS_ID, station: 'nacional' }]

    let stationIdentifier = this.stationService.getUrlStation()
    const cookie = this.stationService.getStationCookie()
    const cookieStation = this.stationService.getStation(cookie)[0]
    const station = this.stationService.getStation(stationIdentifier)[0] || cookieStation
    if (station && station.analyticsId) {
      GA_CONFIG.push({ id: station.analyticsId, station: station.identifier })
    }

    this.analyticsService.setAnalyticsID(GA_CONFIG)
    this.comScoreService.init(this.getUrl())


    const userLoggedSubscription = this.userService
      .getUserLogin()
      .subscribe((userObservable: IUserObservable) => {
        if (userObservable && userObservable.userIsLogged) {
          this.userCookie = userObservable.user.email

          return
        }

        this.userCookie = undefined
      })

      const urlParams = new URLSearchParams(window.location.search);
      
      if(!localStorage.getItem('SBT_access_token') && urlParams.get('jwt')){
        const jwt = urlParams.get('jwt');
        localStorage.setItem('SBT_access_token', jwt);
      }

    const titleChangeSubscription = this.titleService.getTitleChangeObservable().subscribe(title => {
      const urlToSave = this.getUrl()

      if (this.lastTitleAnalytics === title || title === null || title === undefined) return

      this.lastUrl = urlToSave
      this.lastTitleAnalytics = title

      this.analyticsService.sendPageView(urlToSave, title, (station && station.analyticsId) ? station.identifier : 'nacional')

      this.comScoreService.sendPageView(urlToSave)

      if (this.userService.isLoggedIn()) {
        try {
          const user = decrypt(localStorage.getItem('user'))
          const nameHash = (this.userService.isLoggedIn()) ? encryptFull(user.name, '541278abcd4564654abdAc', '23123908abcde67532acc6') : ''
          const emailHash = (this.userService.isLoggedIn()) ? encryptFull(user.email, '541278abcd4564654abdAc', '23123908abcde67532acc6') : ''
          this.naveggService.sendAnalyticsData({ name: nameHash, email: emailHash, path: urlToSave }, ['USER_NAVIGATION'], 0)
        } catch (e) {
          Log('NAVEGG', e, false, 'error')
        }
      }

    })

    const stationSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let stationIdentifier = this.getStationByRoute()
        const cookie = this.stationService.getStationCookie()
        const cookieStation = this.stationService.getStation(cookie)[0]
        const station =
          this.stationService.getStation(stationIdentifier)[0] || cookieStation
        if (stationIdentifier || cookieStation) {
          this.stationService.setStationCookie(
            stationIdentifier || cookieStation.identifier
          )
        }

        this.showStationTab = !!station && !!station.affiliate

        if (!this.showStationTab) {
          this.renderer.addClass(
            this.siteContent.nativeElement,
            'national-station'
          )
        } else {
          this.renderer.removeClass(
            this.siteContent.nativeElement,
            'national-station'
          )
        }

        if (googletag && googletag.destroySlots && this.lastUrl !== event.url) {
          googletag.destroySlots()
        }
      }
    })

    this.subscriptions = [userLoggedSubscription, titleChangeSubscription, stationSubscription]

    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(registerFirebase => {
        for (let i = 0; i < registerFirebase.length; i++) {
          if (registerFirebase[i].scope.match(/firebase/gi)) registerFirebase[i].update()
        }
      })
    }
  }

  localizationAccepted() {
    this.localization = false
    this.setCacheLocal(this.localizationName, 'accepted', -1)
    this.geoService.localizationInitial()
  }

  localizationNotAccepted() {
    this.localization = false

    this.setCacheLocal(this.localizationName, this.localizationStatusNotAccepted, this.expires)
  }

  webPushAccepted() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    this.push = false
    this.setCacheLocal(this.pushName, 'accepted', -1)
  }

  webPushNotAccepted() {
    this.setCacheLocal(this.pushName, this.pushStatusNotAccepted, this.expires)
    this.push = false
  }

  webPushClose() {
    window.sessionStorage.setItem('closePushNotificationSbt', this.expires)
    this.setCacheLocal(this.pushName, "closePushNotificationSbt", this.expires)
    this.push = false
  }

  setGaNotificationBox(permissionPush) {
    this.analyticsService.sendEvent(`${permissionPush}`, 'notification')
  }

  setCacheLocal(key, status, expires) {
    localStorage.setItem(key, JSON.stringify({ status, expires }))
  }

  getCacheLocal(key) {
    const LC = localStorage.getItem(key)
    if (LC) {
      try {
        return JSON.parse(LC)
      } catch (e) {
        return {}
      }
    } else {
      return {}
    }
  }

  visiblePopup(display: string) {
    document.getElementById("accept-cookie").style.display = display
  }

  getUrl(): any {
    return window.location.pathname + window.location.search + window.location.hash
  }

  getStationByRoute(): any {
    const accept_cookies = this.cookieService.get('accept_cookies')
    const url = window.location.href

    if (url.includes("politica-de-privacidade")) {
      this.visiblePopup('none')
    } else {
      
      accept_cookies ? '' : this.visiblePopup('block')
    }

    if (
      this.router.routerState.snapshot &&
      this.router.routerState.snapshot.root.children &&
      this.router.routerState.snapshot.root.children[0] &&
      this.router.routerState.snapshot.root.children[0].params
    ) {
      return this.router.routerState.snapshot.root.children[0].params.station
    }

    return null
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      this.renderer.addClass(this.siteContent.nativeElement, 'sticky')
    } else {
      this.renderer.removeClass(this.siteContent.nativeElement, 'sticky')
    }
  }

  @HostListener('window:scroll', ['$event']) onscroll() {
    if (window.scrollY > 100) {
      this.localizationFixed = true
    } else {
      this.localizationFixed = false
    }
  }
}
