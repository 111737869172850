import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss']
})
export class TwitterComponent implements OnInit {

  constructor () {}

  ngOnInit () {}

  render () {
    return `<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
  }
}
