import { Iframe } from './iframe.class';
import Pagination from './pagination.class'
import { About } from './about.class'
import { Character } from './character.class'
import { Thanks } from './thanks.class'
import { Tip } from './tip.class'
import { Video } from './video.class'
import { Timeline } from './timeline.class'
import { Presenter } from './presenter.class'
import { Winner } from './winner.class'
import { Edition } from './edition.class'

export default class Program {
  public id: number
  public idSite: number
  public regional: number
  public title: string
  public gender: string
  public slug: string
  public about: About
  public description: string
  public chapters: any[]
  public chaptersEditions: any
  public characters: Character[]
  public charactersEditions: any
  public thanks: Thanks[]
  public stayInside: any[]
  public tips: Tip[]
  public recipies: string[]
  public mediabox: string[]
  public header: string
  public frames: any[]
  public videos: Video[]
  public videosprograma: Video[]
  public timeline: Timeline[]
  public presenters: Presenter[]
  public presenter: Presenter
  public inscriptions: any[]
  public winners: Winner[]
  public winnersEditions: any
  public editions: Edition[]
  public notices: any[]
  public programs: any[]
  public tbt: any[]
  public whereTheyAre: any[]
  public quiz: any[]
  public looks: any[]
  public music: any[]
  public sbtistas: any[]
  public support: any[]
  public campaigns: any[]
  public showTimes: string
  public showVideos: string
  public gallery: any
  public galleryEditions: any
  public adunitname: string
  public tagComscore: string
  public secretCodeData: any
  public showComments: boolean
  public showAudima: boolean
  public socialNetworks: any[]
  public youtubeSubscribeButton: any[]  
  public _showVideosOnSbtVideos: boolean
  public iframe: Iframe

  constructor (object) {
    if (object) {
      this.id = object.id
      this.idSite = object.idSite || object.idsite
      this.regional = object.regional
      this.title = object.title
      this.gender = object.gender
      this.slug = object.slug
      this.chapters = object.chapters
      this.chaptersEditions = object.chaptersEditions
      this.characters = object.characters
      this.charactersEditions = object.charactersEditions
      this.thanks = object.thanks
      this.stayInside = object.stayInside
      this.tips = object.tips
      this.recipies = object.recipies
      this.mediabox = object.mediabox
      this.header = object.header
      this.description = object.description
      this.frames = object.frames
      this.videos = object.videos
      this.videosprograma = object.videos
      this.timeline = object.timeline
      this.presenters = object.presenters
      this.presenter = object.presenter
      this.inscriptions = object.inscriptions
      this.winners = object.winners
      this.winnersEditions = object.winnersEditions
      this.editions = object.editions
      this.notices = object.notices
      this.programs = object.programs
      this.tbt = object.tbt
      this.whereTheyAre = object.whereTheyAre
      this.quiz = object.whereTheyAre
      this.looks = object.looks
      this.music = object.music
      this.sbtistas = object.sbtistas
      this.support = object.support
      this.campaigns = object.campaigns
      this.showTimes = object.showtimes
      this.showVideos = object.showvideos
      this.gallery = object.gallery
      this.galleryEditions = object.galleryEditions
      this.adunitname = object.adunitname
      this.socialNetworks = object.socialNetworks
      this.youtubeSubscribeButton = object.youtubeSubscribeButton      
      this.tagComscore = object.tagComscore
      this.showComments = object.showComments
      this.showAudima = object.showAudima
    }
  }

  get showVideosOnSbtVideos (): boolean {
    if (this.gender.toLowerCase() === 'jornalismo') {
      const programsToShowOnSbtVideos: string[] = [
        'poder em foco',
        'conexão repórter'
      ]

      return programsToShowOnSbtVideos.indexOf(this.title.toLowerCase()) !== -1
    }

    return true
  }
}
