import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core'
import { Router } from '@angular/router'
import { isIE } from '../../../utils/util.browser'


@Component({
  selector: 'app-lego-trio-inverted',
  templateUrl: './lego-trio-inverted.component.html',
  styleUrls: ['./lego-trio-inverted.component.scss']
})
export class LegoTrioInvertedComponent {
  @ViewChild('cardsContainer', {static: true})
  public cardsContainer: ElementRef

  @Input('content')
  public content: any

  @Input('color')
  public color: string

  @Input('show-badge')
  public showBadge: boolean = false

  constructor (private renderer: Renderer2, private router: Router, private cd: ChangeDetectorRef) { }

  ngOnInit () {
    this.cd.detectChanges();
    const elRenderer = this.renderer
    const ElCards = this.cardsContainer.nativeElement
    
    if (ElCards.clientWidth > 950 || isIE()) {
      elRenderer.addClass(ElCards, 'full-width')
    }
  }
}
