import { Component, OnInit } from '@angular/core'
import { SitemapService } from './sitemap.service'
import { Meta } from '@angular/platform-browser'
import { siteMap } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { TitleService } from 'src/app/services/title.service'
import { generateTitleSlug } from 'src/app/utils/utils.generators'

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
  providers: [SitemapService]
})
export class SitemapComponent implements OnInit {
  public sites: Map<any, any>
  public alphabetLetters: any[]
  public categories: string[] = ['Programas', 'Outros Links']
  public currentCategoryIndex: number = 0
  public otherLinks: any = {
    links: [],
    content: []
  }

  constructor (
    private _service: SitemapService,
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService
  ) {
    this.metasService.updateTags(siteMap, this.meta)
  }

  ngOnInit () {
    this.title.setTitle('Mapa do Site - SBT')
    this._service.getSites().then(({ sites }) => {
      sites = sites.sort(this.alphabeticSort)

      this.sites = this.splitData(sites)

      this.alphabetLetters = [
        {
          value: 'a',
          available: true
        },
        {
          value: 'b',
          available: true
        },
        {
          value: 'c',
          available: true
        },
        {
          value: 'd',
          available: true
        },
        {
          value: 'e',
          available: true
        },
        {
          value: 'f',
          available: true
        },
        {
          value: 'g',
          available: true
        },
        {
          value: 'h',
          available: true
        },
        {
          value: 'i',
          available: true
        },
        {
          value: 'j',
          available: true
        },
        {
          value: 'k',
          available: true
        },
        {
          value: 'l',
          available: true
        },
        {
          value: 'm',
          available: true
        },
        {
          value: 'n',
          available: true
        },
        {
          value: 'o',
          available: true
        },
        {
          value: 'p',
          available: true
        },
        {
          value: 'q',
          available: true
        },
        {
          value: 'r',
          available: true
        },
        {
          value: 's',
          available: true
        },
        {
          value: 't',
          available: true
        },
        {
          value: 'u',
          available: true
        },
        {
          value: 'v',
          available: true
        },
        {
          value: 'w',
          available: true
        },
        {
          value: 'x',
          available: true
        },
        {
          value: 'y',
          available: true
        },
        {
          value: 'z',
          available: true
        }
      ]
    })

    this._service.getOtherLinks().then(areas => {
      this.otherLinks.links = areas
    })
  }

  splitData (sites: any) {
    let map = ((m, a) => (
      a.forEach(s => {
        let a = m.get(s.title[0]) || []
        m.set(s.title[0], (a.push(s), a))
      }),
      m
    ))(new Map(), sites)

    return map
  }

  changeCategory (category: string, index): void {
    if (index === this.currentCategoryIndex) {
      return
    }

    this.currentCategoryIndex = index
  }

  getSitesOf (letter: string): any[] {
    const sites = this.sites.get(letter.toUpperCase())

    if (sites === undefined) {
      const index = this.alphabetLetters
        .map(letter => {
          return letter.value
        })
        .indexOf(letter)

      this.alphabetLetters[index].available = false

      return [
        {
          title: 'Nenhum link disponível',
          description: ''
        }
      ]
    }

    return sites
  }

  getLinksOf (link: string): any[] {
    const links = this.otherLinks.content.filter(
      currentLink =>
        currentLink.descriptionsitearea === `/${link.toLowerCase()}/`
    )

    if (links.length === 0) {
      return [
        {
          title: 'Nenhum link disponível',
          description: ''
        }
      ]
    }

    return links
  }

  buildLink (link: string): string {
    link = link.replace('http://www.sbt.com.br/', '')

    return `/${link}`
  }

  alphabeticSort (first: any, second: any) {
    if (first.title.toLowerCase() < second.title.toLowerCase()) {
      return -1
    }

    if (first.title.toLowerCase() > second.title.toLowerCase()) {
      return 1
    }

    return 0
  }
}
