import { About } from './about.class'
import Pagination from './pagination.class'

export class Journalism {
  public categories: string[]
  public currentColumnistIndex: number
  public currentCategoryIndex: number
  public currentColumnist: any
  public currentCategory: any
  public columnists: any[]
  public columnistsAbout: About[]
  public high: any[]
  public isColumnist: boolean
  public notices: any[]
  public videos: any[]
  public writers: any[]
  public pagination: Pagination
  public mediabox: any

  constructor(object: any, pagination: Pagination) {
    this.currentColumnistIndex = 0
    this.currentCategoryIndex = 0
    this.isColumnist = false
    this.notices = [{ medias: [] }]
    this.categories = [
      'Todas',
      'Brasil',
      'Comportamento',
      'Coronavírus',
      'Crime',
      'Economia',
      'Esportes',
      'Justiça',
      'Mundo',
      'Política',
      'Saúde'
    ]

    this.currentCategory = this.categories[0]

    this.columnists = [
      {
        author: '/vlogs/editoria/',
        descriptionsite: '/jornalismo/',
        id: 92025,
        idgendersite: 15,
        idsite: 43,
        idsitearea: 1441,
        opcional: '',
        title: 'Por Editoria'
      }
    ]


    if (object) {
      this.mediabox = object.mediabox
      this.currentColumnist = object.currentColumnist
      this.columnistsAbout = object.columnistsAbout
      this.high = object.high
      this.notices = object.notices
      this.videos = object.videos
      this.writers = object.writers

      if (pagination) {
        this.pagination = new Pagination(pagination)
      }
    }
  }
}
