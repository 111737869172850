import { Injectable } from '@angular/core'
import { URL, URL_TOKEN_MEDIASTREAM  } from '../../app.settings'
import { Video } from '../../models/video.class'
import { StationService } from '../../services/station.service'
import { HttpClient } from '@angular/common/http'
import { formatDateToDefaultFormat } from '../../utils/util.dates'

@Injectable({
  providedIn: 'root'
})
export class LiveService {

  constructor (private _http: HttpClient, private stationService: StationService) {}

  getLiveVideoUrl () {
    const stationIdentifier: string =
      this.stationService.getStationCookie() || 'default'

    const station = this.stationService.getStation(stationIdentifier)

    if (station && station[0]) {
      const { live } = station[0]

      let queryString: string

      if (live) {
        queryString = `idsite=${live.idsite}&idsitearea=${live.idsitearea}${
          live.idplaylist ? `&idplaylist=${live.idplaylist}` : ``
        }`
      } else {
        const station = this.stationService.getStation('default')

        const { live } = station[0]

        queryString = `idsite=${live.idsite}&idsitearea=${live.idsitearea}${
          live.idplaylist ? `&idplaylist=${live.idplaylist}` : ``
        }`
      }

      return this._http
        .get<any>(`${URL}/api/medias?limit=1&${queryString}`)
        .toPromise()
        .then((response: any) => {
          const medias: any = response.results

          return medias
        })
    }
  }

  getVideos (program: any): Promise<any> {
    if (!program.exibe || program.exibe !== 'N') {
      return this._http
        .get<any>(
          `${URL}/api/videosyoutube?program=${program.id}&limit=12`
        )
        .toPromise()
        .then((response: any) => {
          const videos = response
          if (videos.results.length === 0) {
            return this.getVideosIfHaveNotProgram()
          }

          return {
            videos: videos.results.map(video => new Video(video)),
            title: `Últimos Vídeos de: ${program.title}`
          }
        })
    } else {
        return this.getVideosIfHaveNotProgram()
    }
  }

  getProgrammingGrade (): Promise<any> {
    const date = new Date()

    const currentDate = formatDateToDefaultFormat(new Date())

    let currentProgram
    let nextProgram

    if (this.stationService.checkStationCookie()) {
      const identifier = this.stationService.getStationCookie()

      const station = this.stationService.getStation(identifier)

      if (station && station[0]) {
        currentProgram = this._http
          .get(`${URL}/api/programscheduleregionais?livegrade=${currentDate}&idregional=${station[0].id}&operation=le&orderby=startdate&limit=1`)
          .toPromise()

        nextProgram = this._http
          .get(`${URL}/api/programscheduleregionais?livegrade=${currentDate}&idregional=${station[0].id}&operation=ge&limit=1`)
          .toPromise()
      }
    } else {
      currentProgram = this._http
        .get(`${URL}/api/programschedule?limit=1&livegrade=${currentDate}&operation=le&orderby=startdate&limit=1`)
        .toPromise()

      nextProgram = this._http
        .get(`${URL}/api/programschedule?livegrade=${currentDate}&operation=ge&limit=1`)
        .toPromise()
    }

    return Promise.all([currentProgram, nextProgram]).then((response: any) => {
      const currentProgram = response[0].results
      const nextProgram = response[1].results

      let result: any[] = []

      if (currentProgram[0]) {
        result[0] = currentProgram[0]
      }

      if (nextProgram[0]) {
        result[1] = nextProgram[0]
      }

      return result
    })
  }

  getVideosIfHaveNotProgram (): Promise<any> {
    if (this.stationService.checkStationCookie()) {
      const identifier : string = this.stationService.getStationCookie()
      const station :any = this.stationService.getStation(identifier)
      if (station && station[0]) {
        return this._http
          .get<any>(`${URL}/api/videosyoutube?limit=3&highlighted=s&regional=${station[0].id}`)
          .toPromise()
          .then((response: any) => {
            const videosRegionais = response && response.results ? response.results.map(video => new Video(video)) : []
           
            return this._http
              .get<any>(`${URL}/api/videosyoutube?limit=8&highlighted=s&videoshomenacional=true`)
              .toPromise()
              .then((response: any) => {
                const videosNacionais = response && response.results ? response.results.map(video => new Video(video)) : []
               
                return {
                  videos: [...videosRegionais, ...videosNacionais],
                  title: 'Últimos Vídeos'
                }
              })
          })
      } else {
        return this._http
          .get<any>(`${URL}/api/videosyoutube?limit=12&highlighted=s&videoshomenacional=true`)
          .toPromise()
          .then((response: any) => {
            const videos = response
            if (videos.results.length === 0) return this

            return {
              videos: videos.results.map(video => new Video(video)),
              title: 'Últimos Vídeos'
            }
          })
      }
    } else {
      return this._http
        .get<any>(`${URL}/api/videosyoutube?limit=12&highlighted=s&videoshomenacional=true`)
        .toPromise()
        .then((response: any) => {
          const videos = response
          if (videos.results.length === 0) return this
          return {
            videos: videos.results.map(video => new Video(video)),
            title: 'Últimos Vídeos'
          }
        })
    }
  }

  getMediaAoVivo (idMedia: string): Promise<any> {
    return this._http
    .get<any>(`${URL_TOKEN_MEDIASTREAM}${idMedia}`)
    .toPromise()
    .then((response: any) => {
        return response
    })
}
}
