import { Routes } from '@angular/router'

import { HomeComponent } from './pages/home/home.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { SearchResultsComponent } from './pages/search-results/search-results.component'
import { ServicesComponent } from './pages/services/services.component'
import { PromotionsComponent } from './pages/promotions/promotions.component'
import { SitemapComponent } from './pages/sitemap/sitemap.component'
import { TermsComponent } from './pages/terms/terms.component'
import { TermsPartnerComponent } from './pages/terms-partner/terms-partner.component'
import { DownloadAppComponent } from './pages/download-app/download-app.component'
import { SignalSearchComponent } from './pages/signal-search/signal-search.component'
import { MarketOptinComponent } from './pages/market-optin/market-optin.component'
import { SilviosantosComponent } from './pages/silviosantos/silviosantos.component'
import { ProgramRoutes } from './routes/program.route'
import { NoticeRoutes } from './routes/notice.route'
import { JournalismRoutes } from './routes/journalism.route'
import { LiveRoutes } from './routes/live.route'
import { InstitutionalRoutes } from './routes/institutional.route'
import { SchedulesRoutes } from './routes/schedules.route'
import { MySbtRoutes } from './routes/my-sbt.route'
import { StationRoutes } from './routes/station.route'
import { InscriptionsRoutes } from './routes/inscriptions.route'
import { FAQ } from './routes/faq.routes'
import { PodcastsRoutes } from './routes/podcasts.routes'
import { TrophyPressComponent } from './pages/trophy-press/trophy-press.component'
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component'
import { ValidatedComponent } from './pages/validate-email/validate.component'
import { RedirectRoutes } from './routes/redirects.route'
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component'
import { PrivacyB2BComponent } from './pages/privacy-b2b/privacy-b2b.component'
import {SbtVideosComponent} from './pages/sbt-videos/sbt-videos.component'
import { PortalPrivacyComponent } from './pages/portal-privacy/portal-privacy.component'
import { EcadComponent } from './pages/ecad/ecad.component'
import { PwaIntegraComponent } from './pages/pwa-integra/pwa-integra.component'

const AppRoutes: Routes = [
  ...FAQ,
  ...PodcastsRoutes,
  ...RedirectRoutes,
  ...ProgramRoutes,
  ...InstitutionalRoutes,
  ...NoticeRoutes,
  ...JournalismRoutes,
  ...InscriptionsRoutes,
  ...LiveRoutes,
  ...SchedulesRoutes,
  ...MySbtRoutes,
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'recuperar-senha/:token/:email',
    component: ResetPasswordComponent
  },
  {
    path: 'afiliada/:station',
    component: HomeComponent
  },
  {
    path: 'silviosantos',
    component: SilviosantosComponent
  },
  {
    path: 'sbtvideos',
    component: SbtVideosComponent
  },
  {
    path: 'sbt-videos',
    component: SbtVideosComponent
  },

/*   {
    path: 'programa/trofeu-imprensa',
    component: TrophyPressComponent
  },
  {
    path: 'afiliada/:station/programa/trofeu-imprensa',
    component: TrophyPressComponent
  }, */
  {
    path: 'resultados',
    component: SearchResultsComponent
  },
  {
    path: 'servicos',
    component: ServicesComponent
  },
  {
    path: 'promocoes',
    component: PromotionsComponent
  },
  {
    path: 'mapa-do-site',
    component: SitemapComponent
  },
  {
    path: 'termos-de-uso',
    component: TermsComponent
  },
  {
    path: 'termos-de-privacidade-uso-parceiros',
    component: TermsPartnerComponent
  },
  {
    path: 'politica-de-privacidade',
    component: PrivacyPolicyComponent
  },
  {
    path: 'b2b',
    component: PrivacyB2BComponent
  },
  {
    path: 'portal-de-privacidade',
    component: PortalPrivacyComponent
  },
  {
    path: 'ecad',
    component: EcadComponent
  },
  {
    path: 'instale-nosso-app',
    component: PwaIntegraComponent
  },
  {
    path: 'baixe-o-app',
    component: DownloadAppComponent
  },
  {
    path: 'pesquisa-de-sinal',
    component: SignalSearchComponent
  },
  {
    path: 'optin',
    component: MarketOptinComponent
  },
  {
    path: 'validar-usuario',
    component: ValidatedComponent
  },
  {
    path: ':station/programa/trofeu-imprensa',
    component: TrophyPressComponent
  },
  {
    path: ':station/home',
    component: HomeComponent
  },
  ...StationRoutes,
  {
    path: '**',
    component: NotFoundComponent
  }
]

export { AppRoutes }
