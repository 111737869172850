import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http';
import { URL_INSCRIPTIONS, URL_USERS_API } from '../app.settings'
import { UserService } from './user.service'
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor (private _http: HttpClient, private userService: UserService, private router: Router) {}

  errorHandler ({ error }) {
    const errorText: string = error.error

    throw new Error(errorText)
  }

  uploadFile (file: File): Promise<any> {

    let formData = new FormData()
    formData.append('file', file, file.name)

    let url: string

    if (this.userService.isLoggedIn()) {
      const user = this.userService.getUser()

      if (this.router.url.includes('/meu-sbt/meus-dados')) {
        url = `${URL_USERS_API}/api/upload/${user.email}`
      }

      if (this.router.url.includes('inscricoes')) {
        const inscriptionId: number = this.router.routerState.snapshot.root.children[0].params.inscription
        url = `${URL_INSCRIPTIONS}/api/upload/${inscriptionId}/${user.email}`
      }
    } else {
      url = `${URL_INSCRIPTIONS}/api/upload`
    }

    return this._http
      .post<any>(url, formData)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(this.errorHandler)
  }
}
