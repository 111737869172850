import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  public shareObj = {
    href: `/${this.router.url}`
  }

  public showMore: boolean = false

  constructor(private router: Router) { }

  ngOnInit() {
  }

  share(): void { }

  showButtons(): void {
    this.showMore = !this.showMore
  }

  hideButtons(): void {
    this.showMore = !this.showMore
  }
}
