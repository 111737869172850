import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-dynamic-card-list',
  templateUrl: './dynamic-card-list.component.html',
  styleUrls: ['./dynamic-card-list.component.scss']
})
export class DynamicCardListComponent implements OnInit {
  @Input('title')
  public title: string

  public cardView: boolean = true

  constructor () {}

  ngOnInit () {}

  changeView (change: boolean): void {
    this.cardView = change
  }
}
