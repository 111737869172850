export class Presenter {
  public title: string
  public name: string
  public description: string
  public thumbnail: string
  public twitter: string
  public facebook: string

  constructor (object) {
    this.title = object.title
    this.name = object.name
    this.description = object.description
    this.thumbnail = object.thumbnail
    this.twitter = object.opcional
    this.facebook = object.opcional1
  }
}
