import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  AfterViewChecked,
  ChangeDetectorRef
} from '@angular/core'

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, AfterViewChecked {
  @Output('receive-data')
  public eventEmit: EventEmitter<any> = new EventEmitter()

  @Input('content-list')
  public contentList: any[]

  @Input('extra-classes')
  public extraClasses: any[]

  @ViewChild('tabContent', {static: true})
  public tabContent: ElementRef

  @Input('current-index')
  public currentIndex: number

  @Input('sort')
  public sort: boolean = true

  public isOverflown: boolean

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.sort) {
      this.contentList.sort(this.alphabeticStringSort)
    }

    if (this.contentList) {
      this.contentList = this.contentList.filter(
        (content, position) => this.contentList.indexOf(content) === position
      )

      if (this.contentList.includes('Todos')) {
        this.contentList = this.contentList.filter(value => value !== 'Todos')
        this.contentList.unshift('Todos')
        this.currentIndex = this.contentList.indexOf('Todos')
      }

      if (this.contentList.includes('Todas')) {
        this.contentList = this.contentList.filter(value => value !== 'Todas')
        this.contentList.unshift('Todas')
        this.currentIndex = this.contentList.indexOf('Todas')
      }
    }

    setTimeout(() => {
      this.scroll(this.currentIndex * 250, this.tabContent)
    }, 250)
  }

  ngAfterViewChecked () {
    this.isOverflown =
      this.tabContent.nativeElement.scrollWidth >
      this.tabContent.nativeElement.clientWidth

    this.cdRef.detectChanges()
  }

  alphabeticStringSort(first: string, second: string): number {
    if (first.toLowerCase() < second.toLowerCase()) {
      return 1
    }

    if (first.toLowerCase() > second.toLowerCase()) {
      return 1
    }

    return 0
  }

  scroll(offset: number, element: ElementRef): void {
    element.nativeElement.scrollBy({
      left: offset,
      behavior: 'smooth'
    })
  }

  prev(): void {
    return this.scroll(-200, this.tabContent)
  }

  next(): void {
    return this.scroll(200, this.tabContent)
  }

  changeIndex(index: number, title: string): void {
    if (this.currentIndex !== index) {
      this.currentIndex = index
    }

    this.eventEmit.emit({ title, index })
  }
}
