import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges
} from '@angular/core'
import { DomService } from '../../services/dom.service'
import { ScrollService } from '../../services/scroll.service'
import { StationService } from '../../services/station.service'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { isMobile } from 'src/app/utils/util.browser'

@Component({
  selector: 'app-quick-access-menu',
  templateUrl: './quick-access-menu.component.html',
  styleUrls: ['./quick-access-menu.component.scss'],
  providers: [DomService, ScrollService]
})
export class QuickAccessMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Input('components')
  public components: any[]

  @Input('fragment')
  public fragment: any

  @Input('change-url-state')
  public changeUrlState: boolean = false

  @Input('order')
  public order: boolean = false

  @Input('offset-scroll')
  public offsetScroll: number = -100

  @Input('is-at-home')
  public isAtHome: boolean = false

  @Output()
  public updateUrlState = new EventEmitter()

  @ViewChild('menu', {static: true})
  public menu: ElementRef

  public open: boolean
  public visible: boolean = false
  public elements: any[]
  public activeSection: string
  public hasStation: boolean = false
  public stationIdentifier: string
  public subscription: Subscription
  public debouncer: Subject<any> = new Subject<any>()
  public isMobile: boolean = false

  constructor (
    private _scrollService: ScrollService,
    private renderer: Renderer2,
    private stationService: StationService
  ) {
    this.subscription = this.debouncer
      .pipe(debounceTime(500))
      .subscribe(title => this.updateUrlState.emit(title))
  }

  ngOnDestroy () {
    this.subscription.unsubscribe()
  }

  ngOnChanges (changes) {
    if (changes && changes.components && changes.components.currentValue) {
      this.components = changes.components.currentValue

      this.mapElements()

      if (this.order) {
        this.orderItems()
      }
    }
  }

  ngOnInit (): void {
    this.isMobile = isMobile()

    if (this.isMobile) {
      this.offsetScroll = this.offsetScroll / 2
    }

    this.renderer.addClass(this.menu.nativeElement, 'hidden')

    if (this.stationService.checkStationCookie()) {
      this.hasStation = true
      this.stationIdentifier = this.stationService.getStationCookie()
    }

    setTimeout(() => {
      this.mapElements()

      if (this.order) {
        this.orderItems()
      }

      if (this.fragment) {
        this.scrollTo(this.fragment)
      }
    }, 3000)
  }

  mapElements () {
    this.elements = this.components.map(element => ({
      ...element,
      domElement: element.showElement ? document.getElementById(element.id) : null
    }))
  }

  openQuickAccessMenu (): void {
    this.open = !this.open
  }

  orderItems() {
    this.elements.sort((firstItem, nextItem) => {
      if (firstItem.order < nextItem.order) return -1
      if (firstItem.order > nextItem.order) return 1

      return 0
    })
  }

  scrollTo (element: any): void {
    this._scrollService.scrollToId(element.id, this.offsetScroll)
      .then(() => {
        this.activeSection = element.id
      })

    if (this.isMobile) {
      this.open = false
    }
  }

  formatId (id: string): string {
    return id
      .split('-')
      .join(' ')
      .toUpperCase()
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    const pageOffsetTop: number = window.pageYOffset

    pageOffsetTop < 50
      ? this.renderer.addClass(this.menu.nativeElement, 'hidden')
      : this.renderer.removeClass(this.menu.nativeElement, 'hidden')

    if (this.elements && this.elements.length) {
      let fromTop = window.scrollY

      this.elements.forEach(element => {
        if (element.showElement) {
          let section = document.getElementById(element.id)

          if (section) {
            const initialTopStart = section.offsetTop - (Math.abs(this.offsetScroll) + 460)
            const initialTopEnd = section.offsetTop + section.offsetHeight

            const isActive: boolean =
              fromTop >= initialTopStart && fromTop <= initialTopEnd

            if (isActive) {
              this.activeSection = element.id

              if (this.changeUrlState || this.isAtHome) {
                this.debouncer.next(element.title)
              }
            }
          }
        }
      })
    }
  }
}
