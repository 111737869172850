export const setWinVar = (varName: string, varValue: string, callback: any = null): void => {
  if (window && varName && varValue) {
    window[varName] = varValue
  }
  if (callback && typeof callback === 'function') {
    callback()
  }
}

export const getWinVar = (varName: string): any[] => {
  if (window && window[varName]) {
    return window[varName]
  }
  return null
}

export const callWinFN = (fn: string, param: any = null, callback: any = null): void => {
  if (window && window[fn]) {
    try {
      window[fn](param)
      if (callback && typeof callback === 'function'){
        callback()
      }
    } catch (e) {
      console.log(`Error on call callWinFN: ${fn}`, e)
    }
  }
}
