import { Injectable } from '@angular/core'

import { URL } from '../../app.settings'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class SecretCodeService {
  constructor (private _http: HttpClient) {}

  sendSecretCode (code: string, params: any): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/medias?idsite=${params.idSite}&idsitearea=${
          params.idSiteArea
        }&idplaylist=${params.idPlaylist}&author=${code.toLowerCase()}`
      )
      .toPromise()
      .then((response: any) => response.results)
      .catch(err => console.log(err))
  }
}
