import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {

  constructor () {}

  ngOnInit () {}

  render () {
    return `<script async src="https://platform.instagram.com/en_US/embeds.js" charset="utf-8"></script>`
  }
}
