import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {
  @Input('values')
  public pills: string[] = []

  public values: string[] = []

  constructor (private router: Router) {}

  ngOnInit () {
    this.values = this.pills.filter(pill => pill !== '' && pill !== ' ' && pill !== '  ' && pill !== 'portalnews' && pill !== 'sbt teste')
  }

  getQueryParams (value: string): any {
    let formatUrl = value.toLowerCase().replace(/\s/g, '-')

    return { busca: formatUrl }
  }
}
