import { Routes, UrlSegment } from '@angular/router'
import { FAQ as component } from '../pages/faq/faq.component'

export function matcher (consumed: UrlSegment[]) {
  if (!consumed.length) return null
  if (!consumed[0].path.match(/faq/)) return null

  const posParams = {
    urlCategory: new UrlSegment('', {}),
    urlDoubt: new UrlSegment('', {}),
    urlSearch: new UrlSegment('', {})
  }

  if (consumed[1] && !consumed[2]) posParams.urlCategory = new UrlSegment(consumed[1].path, {})
  if (consumed[1] && consumed[1].path.match(/duvida/gim) && consumed[2]) posParams.urlDoubt = new UrlSegment(consumed[2].path, {})
  if (consumed[1] && consumed[1].path.match(/search/gim) && !consumed[2]) posParams.urlSearch = new UrlSegment(consumed[1].path, {})

  return {
    consumed,
    posParams
  }
}

export const FAQ: Routes = [{
  matcher,
  component
}]
