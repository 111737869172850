import { Injectable } from '@angular/core'

import { URL } from '../../app.settings'
import Pagination from 'src/app/models/pagination.class'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SbtVideosProgramService {

  constructor(private _http: HttpClient) {}

  getProgramBySlug (slug: string): any {
    return this._http
      .get<any>(`${URL}/api/programs?slug=${slug}`)
      .toPromise()
      .then((response: any) => response.results[0])
  }

  getVideosByProgram (
    idprogram: number = null,
    playlist: string = null,
    pagination: Pagination
  ): Promise<any> {
    if (playlist) {
      return this._http
        .get<any>(
          `${URL}/api/videosyoutube?program=${idprogram}&category=${playlist}&limit=20&${
          pagination && pagination.hasMore ? `&next=${pagination.key}` : ``
          }`
        )
        .toPromise()
        .then((response: any) => {
          const videos = response

          const { results, next } = videos

          results.forEach(video => {
            video.link = video.id
          })

          return {
            results: [...results],
            next
          }
        })
    } else {
      return this._http
        .get<any>(
          `${URL}/api/videosyoutube?program=${idprogram}&limit=20&${
          pagination && pagination.hasMore ? `&next=${pagination.key}` : ``
          }`
        )
        .toPromise()
        .then((response: any) => {
          const videos = response

          const { results, next } = videos

          results.forEach(video => {
            video.link = video.id
          })

          return {
            results: [...results],
            next
          }
        })
    }
  }

  getPlaylists (idprogram: number): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/medias?idsite=211&idsitearea=1068&descriptionplaylist=${idprogram}&orderby=ordem&sort=asc`)
      .toPromise()
      .then((response: any) => {
        let playlists = response.results

        playlists.forEach(playlist => {
          playlist.link = playlist.opcional1 === '0' ? '' : playlist.opcional1
        })

        return playlists
      })
  }
}
