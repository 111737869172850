import { Injectable } from '@angular/core'
import { URL } from '../../app.settings'
import { StationService } from '../../services/station.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProgrammingGridService {

  constructor (private _http: HttpClient, private _stationService: StationService) {}


  async getSchedule (date: string): Promise<any> {
    const hasStationCookie: boolean = this._stationService.checkStationCookie()

    let station: any

    if (hasStationCookie) {
      const stationIdentifier: string = await this._stationService.getStationCookie()

      station = await this._stationService.getStation(stationIdentifier)
    }

    if (station && station[0]) {
      return this._http
        .get<any>(
          `${URL}/api/programscheduleregionais?datagrade=${date}${
            station && station[0] ? `&idregional=${station[0].id}` : ''
          }&limit=49`
        )
        .toPromise()
        .then((response: any) => {
          const schedules = response.results

          return schedules
        })
    }

    return this._http
      .get<any>(
        `${URL}/api/programgrade?datagrade=${date}&limit=49`,
      )
      .toPromise()
      .then((response: any) => {
        const schedules = response.results

        return schedules
      })
  }
}
