import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ElementRef,
  ViewChild,
  OnDestroy
} from '@angular/core'

import Program from '../../models/program.class'
import { ProgramService } from '../../pages/program/program.service'
import Pagination from '../../models/pagination.class'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss'],
  providers: [ProgramService]
})
export class ChaptersComponent implements OnInit, OnDestroy {
  @Input('program')
  public program: Program

  @Input('title')
  public title: string

  @ViewChild('episodesSlider', {static: true})
  public episodesSlider: ElementRef

  public chapters: any[]
  public itemsPerPage: number = 5
  public episodes: any[]
  public episodesToPaginate: any[]
  public currentVideo: string = ''
  public currentChapter: number = 0
  public lastChapter: number = 0
  public watchRouteComponents: string[] = [
    'capitulo',
    'capitulos'
  ]

  public subscriptions = []

  constructor (
    private render: Renderer2,
    private route: ActivatedRoute,
    private programService: ProgramService
  ) { }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit (): void {
    if (this.program.chapters) {
      this.buildEpisodes(this.program.chapters)
    }

    const routeSubscription = this.route.params.subscribe(params => {
      const { component, value } = params

      if (component) {
        if (this.watchRouteComponents.includes(component)) {
          const index = this.episodesToPaginate.findIndex(
            item => item.chapter.title.toLowerCase().includes(value.toLowerCase())
          )

          if (index === -1) {
            this.currentChapter = 0
          }

          if (index < this.itemsPerPage) {
            this.currentChapter = index
          }

          if (index > this.itemsPerPage) {
            this.moveArrayPosition(index, 0)
            this.currentChapter = 0
          }
        }
      }
    })

    this.subscriptions.push(routeSubscription)
  }

  moveArrayPosition (from: number, to: number): void {
    this.episodesToPaginate.splice(to, 0, this.episodesToPaginate.splice(from, 1)[0])
  }

  scroll (left: number): void {
    this.episodesSlider.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  previousEpisodes (): void {
    this.scroll(-861)
  }

  nextEpisodes (): void {
    this.scroll(861)
  }

  openContent (index: number): void {
    if (this.currentChapter === index) {
      this.currentChapter = -1

      return
    }

    this.currentChapter = index
  }

  closeVideo (): void {
    this.currentVideo = ''
  }

  changeCurrentVideo (episode: any): void {
    this.currentVideo = ''
    this.currentVideo = episode.src
  }

  readResume (event: Event): void {
    const button: EventTarget = event.target
    const parent: HTMLElement = this.render.parentNode(button)

    const resume: Element = parent.children[1]

    this.render.setStyle(button, 'display', 'none')
    this.render.setStyle(resume, 'display', 'block')
  }

  loadOldChapters (): void {
    if (this.episodesToPaginate.length > this.itemsPerPage) {
      this.episodes.push(...this.episodesToPaginate.splice(0, this.itemsPerPage))

      return
    }

    this.episodes.push(...this.episodesToPaginate)

    this.episodesToPaginate = []

    return
  }

  changeChapters ({ title }) {
    const edition = this.program.chaptersEditions.filter(
      edition => edition.title === title.title
    )

    if (edition) {
      const { idsitearea, id } = edition[0]

      const queryParams = {
        idsitearea,
        idPlaylist: id,
        limit: 200
      }

      this.programService.getMedias({ ...queryParams }).then(
        chapters => {
          this.program.chapters = chapters.results.map(chapter => {
            return {
              chapter,
              pagination: new Pagination(chapters.next)
            }
          })

          this.buildEpisodes(this.program.chapters)
        }
      )
    }
  }

  buildEpisodes (chapters: any[]): void {
    this.episodesToPaginate = chapters

    this.episodes = this.episodesToPaginate.splice(0, this.itemsPerPage)
  }
}
