import { Routes } from '@angular/router'
import { ProgramComponent } from '../pages/program/program.component'
import { ProgramsComponent } from '../pages/programs/programs.component'
import { RedirectProgramsService } from '../services/redirect.programs.service'

const ProgramRoutes: Routes = [
  // {
  //   path: 'programas',
  //   canActivate: [RedirectProgramsService],
  //   component: RedirectProgramsService
  // },
  {
    path: 'afiliada/:station/programas',
    component: ProgramsComponent
  },
  {
    path: 'variedades/call-of-cast',
    component: ProgramComponent
  },
  {
    path: 'variedades/pod-pai-pod-filho',
    component: ProgramComponent
  },
  {
    path: 'variedades/sbt-games',
    component: ProgramComponent
  },
  {
    path: 'variedades/sbt-na-web',
    component: ProgramComponent
  },
  {
    path: 'variedades/pe-na-estrada',
    component: ProgramComponent
  },
  {
    path: 'variedades/sports-sunset',
    component: ProgramComponent
  },
  {
    path: 'variedades/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/variedades/:slug',
    component: ProgramComponent
  },
  {
    path: 'variedades/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/variedades/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'institucional/sbt-do-bem',
    component: ProgramComponent
  },
  {
    path: 'institucional/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/institucional/:slug',
    component: ProgramComponent
  },
  {
    path: 'institucional/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/institucional/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'filmes/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/filmes/:slug',
    component: ProgramComponent
  },
  {
    path: 'filmes/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/filmes/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'series/barbie-voce-pode-ser-tudo-o-que-quiser',
    component: ProgramComponent
  },
  {
    path: 'series/sbt-series',
    component: ProgramComponent
  },
  {
    path: 'series/sabado-serie',
    component: ProgramComponent
  },
  {
    path: 'series/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/series/:slug',
    component: ProgramComponent
  },
  {
    path: 'series/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/series/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'auditorio/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/auditorio/:slug',
    component: ProgramComponent
  },
  {
    path: 'auditorio/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/auditorio/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'jornalismo/sbt-sports',
    component: ProgramComponent
  },
  {
    path: 'jornalismo/sbt-noticias',
    component: ProgramComponent
  },
  {
    path: 'jornalismo/supercopa-da-uefa',
    component: ProgramComponent
  },
  {
    path: 'jornalismo/sbt-agro',
    component: ProgramComponent
  },
  {
    path: 'jornalismo/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/jornalismo/:slug',
    component: ProgramComponent
  },
  {
    path: 'jornalismo/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/jornalismo/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'infantil/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/infantil/:slug',
    component: ProgramComponent
  },
  {
    path: 'infantil/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'afiliada/:station/infantil/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'realities/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/realities/:slug',
    component: ProgramComponent
  },
  {
    path: 'realities/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/realities/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'talkshow/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/talkshow/:slug',
    component: ProgramComponent
  },
  {
    path: 'talkshow/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/talkshow/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'novelas/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/novelas/:slug',
    component: ProgramComponent
  },
  {
    path: 'novelas/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/novelas/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'especiais/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/especiais/:slug',
    component: ProgramComponent
  },
  {
    path: 'especiais/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/especiais/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'colunas/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/colunas/:slug',
    component: ProgramComponent
  },
  {
    path: 'colunas/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/colunas/:slug/secao/:component/:value',
    component: ProgramComponent
  },
  {
    path: 'programa/sbt-na-web',
    component: ProgramComponent
  },
  {
    path: 'programa/:slug',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/programa/:slug',
    component: ProgramComponent
  },
  {
    path: 'programa/:slug/secao/:component/:value',
    canActivate: [RedirectProgramsService],
    component: RedirectProgramsService
  },
  {
    path: 'afiliada/:station/programa/:slug/secao/:component/:value',
    component: ProgramComponent
  }
]

export { ProgramRoutes }
