import { Injectable } from '@angular/core'
import { URL_TOKEN_MEDIASTREAM } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MediastreamService {
    constructor (private _http: HttpClient) {}

    get (idMedia: string): Promise<any> {
        return this._http
        .get<any>(`${URL_TOKEN_MEDIASTREAM}${idMedia}`)
        .toPromise()
        .then((response: any) => {
            return response
        })
    }
}