import { AbstractControl, ValidatorFn } from '@angular/forms';

export const checkOfAge = (age: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const birthday: string = control.value
    
    if(birthday === null) return { underAge: true }

    const dateToValidate =
      birthday.includes('/')
      ? {
        day: Number(birthday.substring(0, 2)),
        month: Number(birthday.substring(3, 5)),
        year: Number(birthday.substring(6, 10))
      }
      : {
        day: Number(birthday.substring(0, 2)),
        month: Number(birthday.substring(2, 4)),
        year: Number(birthday.substring(4, 8))
      }

    const today = Number(
      new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '')
    )

    const birthDate =
      dateToValidate.year * 10000 +
      dateToValidate.month * 100 +
      dateToValidate.day * 1

    const isOfAge = today - birthDate >= age * 10000

    return isOfAge ? null : { underAge: true }
  }
}
