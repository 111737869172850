import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramService } from '../../program/program.service';
import { generateTitleSlug } from 'src/app/utils/utils.generators';

@Component({
  selector: 'app-playlist-videos',
  templateUrl: './playlist-videos.component.html',
  styleUrls: ['playlist-videos.component.scss']

})
export class PlaylistVideosComponent implements OnInit {
  @Input('title')
  public title: string

  @Input('route')
  public route: string

  @Input('has-categories')
  public hasCategories: boolean = false

  @Input('is-inscription')
  public isInscription: boolean = false

  @Input('content')
  public contents: any[]

  @Input('programs')
  public programs: any[]

  @Input('has-button-to-internal-page')
  public hasButtonToInternalPage: boolean = false

  @Input('show-more-link')
  public showMoreLink: string

  @Input('current-playlist')
  public currentPlaylist: string

  @Input('current-video')
  public currentVideo: string

  @Input('extra-classes')
  public extraClasses: string[]

  @ViewChild('contentSlider', {static: true})
  public contentSlider: ElementRef

  @ViewChild('tabContent', {static: true})
  public tabContent: ElementRef

  @Output() 
  public emitPlaylist: EventEmitter<any> = new EventEmitter<any>()

  public contentList: any[]
  public isOverflown: boolean
  public currentCategoryIndex: number = 0
  public categories: string[] = ['Todos']

  constructor (private router: Router, private programService: ProgramService) {}

  async ngOnInit () {
    if (this.hasCategories) {
      await this.contents.forEach(content => {
        if (content.gender) {
          this.categories.push(content.gender)
        }
      })
    }

    (await Array.from(new Set(this.categories)).length) > 2
      ? (this.hasCategories = true)
      : (this.hasCategories = false)

    this.contentList = this.contents

    if (this.router.routerState.snapshot.root.children.length > 0) {
      this.currentPlaylist = this.router.routerState.snapshot.root.children[0].params.playlist
    }

    setTimeout(() => {
      this.isOverflown = this.contents.length > 4

      if(this.programs) {
        this.contents.forEach(content => {
          const program = this.programs.find(program => +content.program === program.id)
          this.getRouterLink(content, program)
        })
      }
    }, 2000)
  }

  getRouterLink (content: any, program: any): void {
    if (this.isInscription) {
      if (program) {
        const title = generateTitleSlug(content.title);
        content.routerLink = `/${this.route}/${program.slug}/${content.id}/${title}`

        return
      }

      content.routerLink = '/404'

      return
    }

    if (content.link) {
      if (this.route) {
        content.routerLink = `/${this.route}/${content.link}`

        return
      }

      content.routerLink = content.link
    }

    if (this.route) {
      content.routerLink = `/${this.route}`
    }
  }

  scroll (offset: number): void {
    this.contentSlider.nativeElement.scrollBy({
      left: offset,
      behavior: 'smooth'
    })
  }

  prev (): void {
    return this.scroll(-200)
  }

  next (): void {
    return this.scroll(200)
  }

  setCategory (event: Event, index: number): void {
    if (this.currentCategoryIndex !== index) {
      this.currentCategoryIndex = index
    }
  }

  filter (gender: string): void {
    if (gender === 'Todos') {
      this.contentList = this.contents

      return
    }

    this.contentList = this.contents.filter(
      content => content.gender === gender
    )
  }

  receiveData (content: any): void {
    this.filter(content.gender)

    this.currentCategoryIndex = content.index
  }

  changePlaylist(content: any) {
    this.emitPlaylist.emit(content)
  }
}