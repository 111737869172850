import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterContentInit,
  EventEmitter,
  Output
} from '@angular/core'

import Pagination from 'src/app/models/pagination.class'
import { Router } from '@angular/router'
import { ProgramService } from 'src/app/pages/program/program.service'
import { generateSlug } from '../../utils/utils.generators'

@Component({
  selector: 'app-tab-search-results',
  templateUrl: './tab-search-results.component.html',
  styleUrls: ['./tab-search-results.component.scss']
})
export class TabSearchResultsComponent implements OnInit {
  @Input('results')
  public results: any

  @Input('searching')
  public searching: boolean

  @Input('queryString')
  public queryString: string

  @Input('pagination')
  public pagination: Pagination

  @Output('load-more-notices')
  public loadNoticesEmmiter: EventEmitter<any> = new EventEmitter<any>()

  @Output('load-more-videos')
  public loadVideosEmmiter: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('tabItens', {static: true})
  public tabItens: ElementRef


  public idVideo: string =''
  public contentIndex: number = 0
  public contentTitle: string = 'Notícias'
  public currentContent: any

  public defaultImage: string = '../../../assets/images/default-image.png'

  public contents: any[] = [{ title: 'Notícias' }, { title: 'Vídeos' }]

  constructor (private router: Router, private programService: ProgramService) {}

  ngOnInit () {
    this.currentContent = this.results
  }

  sendToContent (content: any, type: string) {
    if (type === 'notice') {
      if (this.currentContent.allPrograms) {
        const program = this.currentContent.allPrograms.find(program => program.id.toString() === content.idProgram)
        if (content.author && content.author.includes('/')) {
          return content.author
        }

        if (program) {
          let type

          content.gender.toLowerCase() === 'jornalismo'
            ? (type = 'noticia')
            : (type = 'fiquepordentro')

          const gender = generateSlug(program.gender)
          return `/${gender}/${program.slug}/${type}/${content.slug}`
        }

        return `/noticia/${content.slug}`
      }
    }

    if (type === 'video') {
      if (this.currentContent.allPrograms) {
        const program = this.currentContent.allPrograms.find(program => program.id.toString() === content.idprogram)
        if (program) {
          const gender = generateSlug(program.gender)
          // return `/sbt-videos/${program.slug}/${content.idcategory}/${content.idvideo}/${title}`
          return `/${gender}/${program.slug}`
        }
      }
    }
  }

  setContent (event: Event, index: number, content: any): void {
    if (this.contentIndex !== index) {
      this.contentIndex = index
    }
    this.contentTitle = content
  }

  scroll (left: number): void {
    this.tabItens.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  prev (): void {
    this.scroll(-100)
  }

  next (): void {
    this.scroll(100)
  }

  loadMoreNotices (): void {
    this.loadNoticesEmmiter.emit()
  }

  loadMoreVideos (): void {
    this.loadVideosEmmiter.emit()
  }
}
