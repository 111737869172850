import Pagination from './pagination.class'
import { regexIframe } from '../utils/util.patterns'
import { getUrlIframe } from '../utils/util.iframes'

const breakDescriptionsNoticies = (description): INoticeDescription[] => {
  const regex = new RegExp(regexIframe, 'g')
  const videoIframesArrays = description.match(regex) || []

  description = description.replace(/<img/g, '<img loading="lazy"');

  const textArray = description.split(regex) || []
  const array = textArray.reduce((acc, currValue, index) => {
    const isYoutubeIframe = !!videoIframesArrays[index] &&
        (videoIframesArrays[index].includes('youtube.com') ||
          videoIframesArrays[index].includes('www.sbt.com.br'))
      return [
        ...acc,
        { content: currValue, type: typeDescriptionEnum.TEXT },
        {
          content: isYoutubeIframe ? getUrlIframe(videoIframesArrays[index]) : videoIframesArrays[index],
          type: isYoutubeIframe ? typeDescriptionEnum.IFRAME : typeDescriptionEnum.TEXT,
          isFixable: false
        }
      ]
    }, []).filter(description => !!description.content)
  return array
}

export interface INoticeDescription {
  content: string
  type: typeDescriptionEnum
  isFixable: boolean
}

export enum typeDescriptionEnum {
  IFRAME = 'iframe',
  TEXT = 'text'
}

export default class Notice {
  public id: number
  public idprogram: number
  public slug: string
  public header: string
  public video: string
  public title: string
  public taghighlight: string
  public description: string
  public descriptions: INoticeDescription[]
  public author: string
  public category: string
  public gender: string
  public categoryId: number
  public createdAt: string
  public content: string
  public keywords: string[]
  public images: any[]
  public thumbnail: string
  public pagination: Pagination
  public programHeader: string
  public programRoute: string
  public type: string
  public link: string
  public altimage: string
  public program: string
  public publishdatestring: string
  public relatedNotices: any
  public pageLink: string
  public facebooknewstags: string
  public facebooknewsindexing : string

  constructor (object, pagination: any = null) {
    if (object) {
      this.id = object.id
      this.idprogram = object.idprogram
      this.slug = object.slug
      this.header = object.thumbnail
      this.video = object.video
      this.title = object.title
      this.description = object.description
      this.descriptions = breakDescriptionsNoticies(this.description)
      this.author = object.author
      this.category = object.gender
      this.gender = object.gender
      this.categoryId = object.idgender
      this.createdAt = object.publishdatestring
      this.content = object.shortdescription
      this.keywords = object.keywordsearch || []
      this.images = object.images
      this.thumbnail = object.thumbnail
      this.link = object.link
      this.pageLink = object.linkSite
      this.altimage = object.altimage
      this.program = object.program
      this.publishdatestring = object.publishdatestring
      this.relatedNotices = object.relatedNotices
      this.taghighlight = object.taghighlight
      this.facebooknewstags = object.facebooknewstags
      this.facebooknewsindexing = object.facebooknewsindexing
    }

    if (pagination) {
      this.pagination = new Pagination(pagination)
    }
  }
}
