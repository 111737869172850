import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core'
import { debounceTime } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
import { generateSlug, generateTitleSlug } from 'src/app/utils/utils.generators'
import { SearchService } from './search.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [SearchService]
})
export class SearchComponent implements OnInit, OnDestroy {
  @Output('hide-search')
  public hideSearch: EventEmitter<any> = new EventEmitter()

  @Input('placeholder')
  public placeholder: string

  public currentPath: string
  public searchField: FormControl = new FormControl('')
  public values: string[] = []
  public canShowAutoComplete: boolean = false

  public results: any = {
    notices: [],
    programs: []
  }

  public subscriptions = []

  constructor(
    private _service: SearchService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    if (this.hasUrlFragments()) {
      this.currentPath = this.activatedRoute.snapshot.url[0].path
    }

    if (this.hasSearchQueryParam()) {
      let searchValue = this.activatedRoute.snapshot.queryParamMap.get('busca')

      if (searchValue) {
        searchValue = searchValue.replace(/-/g, ' ')

        this.searchField.setValue(searchValue)
      }
    }

    const routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let query = this.activatedRoute.snapshot.queryParamMap.get('busca')

        if (query) {
          query = query.replace(/-/g, ' ')
        }

        if (this.searchField.value !== query) {
          this.searchField.setValue(query)
        }

        if (!this.isResultsPage()) {
          this.hide()
        }
      }
    })

    const searchFieldSubscription = this.searchField.valueChanges.pipe(debounceTime(1000)).subscribe(term => {
      if (this.isResultsPage()) {
        this.navigateToResults()

        return
      }

      if (this.searchField.value !== '') {
        this.search()
        return
      }

      this.results = {
        notices: [],
        programs: []
      }
    })

    this.subscriptions = [routeSubscription, searchFieldSubscription]
  }

  navigateToResults(): void {
    if (this.searchField.value !== '') {
      this.canShowAutoComplete = false

      this.router.navigate(['/resultados'], {
        queryParams: { busca: this.searchField.value }
      })
    }
  }

  ngOnChanges(): void {
    if (this.hasUrlFragments()) {
      this.currentPath = this.activatedRoute.snapshot.url[0].path
    }
  }

  hide(): void {
    this.hideSearch.emit({ hide: true })
  }

  getByTitle(content: any[], size: number = 4): any[] {
    return content
      .filter((item: any) => {
        return (
          item.title
            .toLowerCase()
            .indexOf(this.searchField.value.toLowerCase()) !== -1 &&
          this.searchField.value !== ''
        )
      })
      .slice(0, size)
  }

  search(): void {
    this._service.search(this.searchField.value).then((response: any) => {
      this.clearResults()

      if (response) {
        this.canShowAutoComplete = true

        if (response.notices) {
          this.results.notices = response.notices.slice(0, 4)
        }

        if (response.programs) {
          this.results.programs = response.programs.slice(0, 4)
        }
      }
    })
  }

  getRouterLink (item: any): string {
    const gender = generateSlug(item.gender)
    const programSlug = generateTitleSlug(item.program)

    let type

    gender === 'jornalismo'
      ? (type = 'noticia')
      : (type = 'fiquepordentro')

    return `${gender}/${programSlug}/${type}/${item.slug}` 

  }

  clearResults(): void {
    this.results = {
      notices: [],
      programs: []
    }
  }

  hasUrlFragments(): boolean {
    return (
      this.activatedRoute.snapshot.url &&
      this.activatedRoute.snapshot.url.length > 0
    )
  }

  hasSearchQueryParam(): boolean {
    return (
      this.currentPath &&
      this.activatedRoute.snapshot.queryParamMap.get('busca') !== ''
    )
  }

  isResultsPage(): boolean {
    return this.currentPath === 'resultados'
  }
}
