export default class Podcast {
  public title: string
  public url: string
  public thumbnail: string

  constructor (object) {
    this.title = object.title
    this.url = object.author
    this.thumbnail = object.thumbnail
  }
}
