import { OnInit, Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { CookieService } from '../../services/cookie.service'

@Component({
  templateUrl: './accept-cookie.component.html',
  styleUrls: ['./accept-cookie.component.scss'],
  selector: 'app-accept-cookie'
})
export class AcceptCookieComponent implements OnInit {
  public isShow: boolean
  private cookieName = 'accept_cookies'
  constructor(
    private userService: UserService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    const cookieValue = this.cookieService.get(this.cookieName)
    this.isShow = (cookieValue === 'true') ? false : true
  }

  handleClick() {
    this.cookieService.set(this.cookieName, 'true')
    this.isShow = false
  }
}