import { Component, OnInit, OnDestroy } from '@angular/core'

import { NgxSmartModalService } from 'ngx-smart-modal'
import { FormBuilder, Validators, FormControl } from '@angular/forms'
import Program from 'src/app/models/program.class'
import { Inscription } from 'src/app/models/inscription.class'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { InscriptionsService } from '../inscriptions/inscriptions.service'
import { ProgramService } from '../program/program.service'
import { RECAPTCHA, SBT_SITE_SIGNIN, SBT_SITE_SIGNUP } from 'src/app/app.settings'

import { InscriptionFormService } from './inscription-form.service'
import { UserService } from 'src/app/services/user.service'
import { Subscription, Subscriber } from 'rxjs'
import { IUserObservable } from 'src/app/models/user.class'
import { TitleService } from 'src/app/services/title.service'
import { MetatagsService } from 'src/app/services/metatags.service'
import { Meta } from '@angular/platform-browser'
import { isMobile } from '../../utils/util.browser'
import { HostListener } from '@angular/core'
import { AnalyticsService } from 'src/app/services/analytics.service'
import { Log } from 'src/app/utils/util.console'

@Component({
  selector: 'app-inscription-form',
  templateUrl: './inscription-form.component.html',
  styleUrls: ['./inscription-form.component.scss']
})
export class InscriptionFormComponent implements OnInit, OnDestroy {
  public siteKey: string = `${RECAPTCHA}`

  public subscriptionUserLogged: Subscription
  public userCookie: string

  public inscriptionCookie: string
  public inscriptionSuccess: string
  public inscriptionError: string
  public uploadError: string

  public currentInscription: string
  public currentProgramSlug: string
  public inscription: Inscription
  public program: Program
  public uploadPathObject: any = {}
  public subscribed: boolean

  public fields: any[]

  public subscriptions = []

  public isMobile: boolean = false
  public notContent: boolean = false

  public inscriptionData = this.fb.group({
    recaptcha: [null, Validators.required]
  })

  public bannerfixed:boolean = false;

  @HostListener('window:scroll',['$event']) onscroll(){
    if(window.scrollY > 550) {
      this.bannerfixed = true
    } else {
      this.bannerfixed = false
    }
  }

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private activatedRout: ActivatedRoute,
    private inscriptionsService: InscriptionsService,
    private service: InscriptionFormService,
    private programService: ProgramService,
    private userService: UserService,
    private router: Router,
    private title: TitleService,
    private metaService: MetatagsService,
    private meta: Meta,
    private analytics: AnalyticsService
  ) {}

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe()
      }
    })
  }

  async ngOnInit () {
    this.isMobile = isMobile()
    const activeRouteSubscriber = this.activatedRout.params.subscribe(
      params => {
        this.currentInscription = params['inscription']
        this.currentProgramSlug = params['slug']
      }
    )

    if (isNaN(parseInt(this.currentInscription, 10))) {
      this.router.navigate(['/inscricoes'])
    }

    this.subscriptionUserLogged = this.userService
      .getUserLogin()
      .subscribe(async (userObservable: IUserObservable) => {
        if (userObservable.userIsLogged) {
          await this.userService.getUserFromDatabase(userObservable.user.email)
          .then((response: any) => {
            if (response && response.email) {
              this.userCookie = userObservable.user.email
              this.userService
                .getInscriptionsByUser(this.userCookie)
                  .then(inscription => {
                    if (inscription && inscription.length > 0) {                
                      const hasInscription = inscription[0].inscriptions
                        .find(inscription => inscription.id.toString() === this.currentInscription)
      
                      hasInscription ? this.subscribed = true : this.subscribed = false
                    }else{                
                      this.title.setTitle(`Sistema Brasileiro de Televisão - SBT`)
                    }
                  })
            } else {
              this.userService.logout()
              this.router.navigate(['/'])
            }
          })
          .catch((error: Error) => {
              this.userService.logout()
              this.router.navigate(['/'])
          })          
        }
      })

    this.subscriptions.push(this.subscriptionUserLogged)
    this.subscriptions.push(activeRouteSubscriber)

    await this.inscriptionsService
      .getInscriptionsById(this.currentInscription)
      .then(inscription => {
        
        this.inscription = inscription[0]        
        
        if (inscription && inscription.length > 0){          
          this.title.setTitle(`${this.inscription.title} - SBT`)
          this.updateMetaTags()
        }else{
          this.notContent = true                    
        }        
      })

    await this.programService
      .getProgramBySlug(this.currentProgramSlug)
      .then(program => {
        this.program = program.program
      })

    this.service.getFields(this.currentInscription).then((response: any) => {
      this.fields = response

      this.fields.forEach((field: any) => {
        if (field.opcional1.length > 0) {
          field.opcional1 = this.buildSelectOptions(field.opcional1)
        }

        field.opcional === 'S'
          ? this.inscriptionData.addControl(
              field.description,
              new FormControl(null, Validators.required)
            )
          : this.inscriptionData.addControl(
              field.description,
              new FormControl(null)
            )

        if (field.author.includes('video')) {
          const videoFieldInscription = this.inscriptionData
            .get(field.description)
            .valueChanges.subscribe(value => {
              this.uploadPathObject[field.description] = value
            })

          this.subscriptions.push(videoFieldInscription)
        }

        if (field.author.includes('image')) {
          const imageFieldInscription = this.inscriptionData
            .get(field.description)
            .valueChanges.subscribe(value => {
              this.uploadPathObject[field.description] = value
            })

          this.subscriptions.push(imageFieldInscription)
        }
      })
    })
  }

  updateMetaTags (): void {
    this.metaService.removeTags(this.meta)

    const metatags = [
      { name: 'description', content: this.inscription.title },
      { name: 'keywords', content: `SBT, novelas, carrossel, chiquititas, chaves, Silvio Santos, eliana, celso portiolli, ratinho, Programa Silvio Santos, esquadrão da moda, câmeras escondidas, a praça é nossa, a praca e nossa, raul gil, programa raul gil, filmes, programa do ratinho, novelas mexicanas, bastidores da tv, programação SBT, casos de família, programa eliana, The Noite, Eliana, programa da maisa, silvio santos, inscricao, inscrição, INSCRIÇÃO, inscrições, sbt, as aventuras de poliana,poliana` },
      { property: 'og:type', content: 'website' },
      { property: 'og:locale', content: 'pt_BR' },
      { property: 'og:site_name', content: 'SBT - Sistema Brasileiro de Televis&atilde;o' },
      { property: 'og:title', content: this.inscription.title },
      { property: 'og:description', content: this.inscription.title },
      { property: 'og:image', content: this.inscription.thumbnail },
      { property: 'og:url', content: window.location.href },
      { name: 'twitter:card', content: 'summary' }
    ]

    this.metaService.updateTags(metatags, this.meta)
  }

  removeFromTitle (title: string) {
    const currentTitle = this.title.getTitle().replace(`${title} - `, '')

    this.title.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.title.getTitle()

    this.title.setTitle(`${title} - ${currentTitle}`)
  }

  buildSelectOptions (optionsString: string): any[] {
    let options = optionsString.split(';')

    let optionsObject = options.filter(option => option !== '').map(option => {
      if (option.length > 0) {
        const value = option.split('_')[0]
        const text = option.split('_')[1]

        return {
          value,
          text
        }
      }
    })

    return optionsObject
  }

  isInvalidUpload () {
    return (
      this.uploadError ||
      Object.keys(this.uploadPathObject).some(key => {
        return this.uploadPathObject[key].includes('fakepath')
      })
    )
  }

  openModal (): void {
    this.ngxSmartModalService.getModal('modalRegulamento').open()
  }

  openModalTC (): void {
    this.ngxSmartModalService.getModal('modalTermoDeConsentimento').open()
  }

  openFinishModal () {
    location.hash = "finalizouinscricao" 

    let data = {
      ...this.inscriptionData.value,
      enable: true
    }

    data = { ...data, ...this.uploadPathObject }

    const inscriptionValues = {
      inscription: this.currentInscription,
      user: this.userCookie,
      inscriptionName: this.inscription.title,
      inscriptionProgram: this.program.title,
      data
    }

    this.inscriptionsService
      .createUserInscription(inscriptionValues)
      .then(response => {
        this.ngxSmartModalService.getModal('inscriptionSuccess').open()

        Log('INSCRICAO', {idinscription: this.currentInscription, inscription: this.inscription, program: this.program})
        
        this.analytics.sendDataLayerEvent({
          event: "inscricao-programa",
          programa: this.program.title,
          quadro: this.inscription.title,
          status: 'sucesso'
        })

        setTimeout(() => this.router.navigate(['/inscricoes']), 4000)
      })
      .catch(error => {
        this.inscriptionError = error.message

        this.analytics.sendDataLayerEvent({
          event: "inscricao-programa",
          programa: this.program.title,
          quadro: this.inscription.title,
          status: 'falha'
        })
      })
  }

  openCreateAccountModal (): void {
    // if (this.ngxSmartModalService.getModal('modalLogin').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalLogin').close()
    // }

    // this.ngxSmartModalService.getModal('modalCreateAccount').open()
    const url = `${SBT_SITE_SIGNUP}/criar-conta?redirect=${location.href}`
    window.open(url, '_self');
  }

  openLoginModal (): void {
    // if (this.ngxSmartModalService.getModal('modalForms').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalForms').close()
    // }

    // if (this.ngxSmartModalService.getModal('modalFinish').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalFinish').close()
    // }

    // if (this.ngxSmartModalService.getModal('modalCreateAccount').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalCreateAccount').close()
    // }

    // this.ngxSmartModalService.getModal('modalLogin').open()

    const url = `${SBT_SITE_SIGNIN}?redirect=${location.href}`
    window.open(url, '_self');
  }

  receiveData (event: any): void {
    if (event.uploadError) {
      this.uploadError = event.uploadError

      return
    }

    this.uploadError = undefined
    if (event.fieldName) {
      this.uploadPathObject[event.fieldName] = event.fieldValue
    }
  }
}
