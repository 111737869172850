import { Component, OnInit } from '@angular/core'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { FormBuilder, Validators } from '@angular/forms'
import { ServicesService } from './services.service'

// import { pureTextPattern, emailPattern } from '../../utils/util.patterns'
import { RECAPTCHA } from 'src/app/app.settings'
import { Meta } from '@angular/platform-browser'
import { servicesAndPromotions } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { EmailService } from 'src/app/services/email.service';
import { TitleService } from 'src/app/services/title.service';
import { CanonicalService } from 'src/app/services/canonical.service'
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  providers: [EmailService, ServicesService, CanonicalService]
})
export class ServicesComponent implements OnInit {
  public siteKey: string = `${RECAPTCHA}`

  public renderCaptcha: boolean = false

  public pureTextPattern: string = '^[A-Za-zÀ-ú ]+$'
  public emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'

  public services: any[]
  public programToQuestion: string
  public programRegulation: string
  public programWinners: string

  public aboutContent: string
  public operatorsList: any = [
    {
      value: 'Vivo',
      text: 'Vivo'
    },
    {
      value: 'Claro',
      text: 'Claro'
    },
    {
      value: 'Tim',
      text: 'Tim'
    },
    {
      value: 'Oi',
      text: 'Oi'
    }
  ]

  public reasonsList: any = [
    {
      value: 'Dúvidas',
      text: 'Dúvidas'
    },
    {
      value: 'Sugestões',
      text: 'Sugestões'
    },
    {
      value: 'Reclamações',
      text: 'Reclamações'
    }
  ]

  public personalData = this.fb.group({
    nome: [
      '',
      [Validators.required, Validators.pattern(this.pureTextPattern)]
    ],
    sobrenome: [
      '',
      [Validators.required, Validators.pattern(this.pureTextPattern)]
    ]
  })

  public contactData = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    telefone: ['', Validators.required],
    operadora: [null, Validators.required]
  })
  public loadingContent = false

  public writeMessage = this.fb.group({
    assunto: [null, [Validators.required]],
    mensagem: ['', Validators.required],
    recaptcha: [null, Validators.required]
  })

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private service: ServicesService,
    private title: TitleService,
    private meta: Meta,
    private metaService: MetatagsService,
    private emailService: EmailService,
    private canonicalService: CanonicalService,
    private brandingService: BrandingService
  ) {}

  ngOnDestroy() {
    this.canonicalService.removeLink()
    this.brandingService.setBranding(null)
  }

  ngOnInit () {
    this.brandingService.setBranding('Interatividade')
    this.canonicalService.appendLink()

    this.metaService.updateTags(servicesAndPromotions, this.meta)

    this.title.setTitle('Serviços - SBT')

    this.service.getAboutServices().then(about => {
      if (about && about[0]) {
        this.aboutContent = about[0].description
      }
    })

    this.getServices()
  }

  removeFromTitle (title: string) {
    const currentTitle = this.title.getTitle().replace(`${title} - `, '')

    this.title.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.title.getTitle()

    this.title.setTitle(`${title} - ${currentTitle}`)
  }

  receiveData ({ type, title, modal, regulation, winners }): void {
    switch (type) {
      case 'openModal':
        this.programToQuestion = title
        this.programRegulation = regulation
        this.programWinners = winners
        this.sendInfo(modal)
    }
  }

  showOpsMessage () {
    return this.services && this.services.length === 0 && !this.loadingContent
  }

  sendInfo (modal: string): void {
    this.ngxSmartModalService.getModal(modal).open()
  }

  sendFinish (): void {
    const data = {
      ...this.personalData.value,
      ...this.contactData.value,
      ...this.writeMessage.value
    }

    this.emailService.sendEmail(data).then(response => {
      this.ngxSmartModalService.getModal('modalService').close()
      this.ngxSmartModalService.getModal('modalFinish').open()
      this.resetForms()
    })

  }

  getServices (): void {
    this.loadingContent = true
    this.service
      .getResults()
      .then((services: any) => {
        if (services) {
          this.services = services
        }

        this.loadingContent = false
      })
      .catch(() => {
        this.loadingContent = false
      })
  }

  resetForms (): void {
    this.personalData.reset()
    this.contactData.reset()
    this.writeMessage.reset()
  }

  renderCaptchaStep (): void {
    this.renderCaptcha = true
  }
}
