import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'


@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor (@Inject(DOCUMENT) private document: Document) {}

  check (cookie: string): boolean {
    const cookies: string = this.document.cookie

    return cookies.includes(`${cookie}=`)
  }

  get (cookie: string): string | undefined {
    const cookies: string = `; ${this.document.cookie}`

    const cookieParts: string[] | undefined = cookies.split(`; ${cookie}=`)

    if (cookieParts.length !== 2) {
      return undefined
    }

    return cookieParts
      .pop()
      .split(';')
      .shift()
  }

  set (cookie: string, value: string): string {
    this.document.cookie = `${cookie}=${value || ''}; path=/`

    return this.document.cookie
  }

  delete (cookie: string): any {
    this.document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
  }
}
