export class User {
  public name: string
  public password: string
  public email: string
  public cpf: string
  public rg: string
  public gender: string
  public birthdayDay: number
  public birthdayMonth: number
  public birthdayYear: number
  public postalcode: string
  public streetLabel: string
  public streetNumber: string
  public streetNumber2: string
  public district: string
  public city: string
  public state: string
  public homephone: string
  public cellphone: string
  public favoriteProgram: string
  public favoriteNewsProgram: string
  public acceptTerms: boolean
  public emailoptin: boolean
  public downloadtoken: boolean
  public source: string

  constructor (object) {
    if (object) {
      this.name = object.name
      this.password = object.password
      this.email = object.email
      this.cpf = object.cpf
      this.rg = object.rg
      this.gender = object.gender
      this.birthdayDay = object.birthdayDay
      this.birthdayMonth = object.birthdayMonth
      this.birthdayYear = object.birthdayYear
      this.postalcode = object.postalcode
      this.streetLabel = object.streetLabel
      this.streetNumber = object.streetNumber
      this.streetNumber2 = object.streetNumber2
      this.district = object.district
      this.city = object.city
      this.state = object.state
      this.homephone = object.homephone
      this.cellphone = object.cellphone
      this.favoriteProgram = object.favoriteProgram
      this.favoriteNewsProgram = object.favoriteNewsProgram
      this.acceptTerms = object.acceptTerms
      this.emailoptin = object.emailoptin
      this.downloadtoken = object.downloadtoken
      this.source = object.source
    }
  }
}

export interface IUserObservable {
  user: User
  userIsLogged: Boolean
  programFav: any
}
