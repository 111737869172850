import {
  Component,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  AfterViewInit
} from '@angular/core'
import { Location } from '@angular/common';
import Pagination from 'src/app/models/pagination.class'
import { NgxSmartModalService } from 'ngx-smart-modal'
import Program from 'src/app/models/program.class'
import { Inscription } from 'src/app/models/inscription.class'
import {
  generateSlug,
  removeAccents,
  generateTitleSlug
} from 'src/app/utils/utils.generators'
import { StationService } from 'src/app/services/station.service'
import { TitleService } from '../../services/title.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit, OnChanges, AfterViewInit{
  @Input('content')
  public contents: any[]

  @Input('title')
  public title: string

  @Input('route')
  public route: string

  @Input('show-genders')
  public showGenders: boolean = true

  @Input('return-genders')
  public returnGenders: boolean = false

  @Input('items-per-row')
  public itemsPerRow: number

  @Input('button-text')
  public buttonText: string = 'Veja o Site'

  @Input('term-to-filter')
  public termToFilter: string = ''

  @Input('desktop-view')
  public desktopView: string = 'column'

  @Input('pagination')
  public pagination: Pagination

  @Input('items-per-page')
  public itemsPerPage: number = 5

  @Input('sort')
  public sort: string

  @Input('searchManual')
  public searchManual: boolean = false

  @Input('is-inscriptions')
  public isInscriptions: boolean = false

  @Input('is-podcasts')
  public isPodcasts: boolean = false

  @Input('fragment')
  public fragment: string =''


  @Output('load-more')
  public loadMore: EventEmitter<any> = new EventEmitter()

  public genders: string[] = ['Todos']
  public contentList: any[]
  public currentIndex: number
  public currentProgram: any
  public currentInscriptionInfo: number = -1

  constructor (
    private ngxSmartModalService: NgxSmartModalService,
    private stationService: StationService,
    private titleService: TitleService,
    private location: Location,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    if(this.fragment != null && this.fragment) {
      this.contents.forEach(content => {
        if (content.slug === this.fragment) {
          this.openModal(content)
          return false
        }
      })
    }
  }

  ngOnInit () {

    this.contents.forEach(content => {
      if (content.gender) {
        this.genders.push(content.gender)
      }
    })

    this.contentList = this.contents

    if (this.sort === 'alphabetic') {
      this.contentList = this.contents.sort(this.alphabeticObjectSort)
    }
  }

  removeFromTitle (title: string) {
    const currentTitle = this.titleService.getTitle().replace(`${title} - `, '')

    this.titleService.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.titleService.getTitle()

    this.titleService.setTitle(`${title} - ${currentTitle}`)
  }

  ngOnChanges (changes: SimpleChanges): void {
    this.contentList = this.contents

    if (changes.termToFilter && !this.searchManual) {
      this.contentList = this.contents.filter(content =>
        content.title
          .toLowerCase()
          .includes(changes.termToFilter.currentValue.toLowerCase())
      )

      if (this.isInscriptions) {
        this.contentList = this.contents.filter(content =>
          content.inscriptions.some(inscription =>
            inscription.title
              .toLowerCase()
              .includes(changes.termToFilter.currentValue.toLowerCase())
          )
        )
      }
    }

    if(changes.contents) {
      this.contentList = this.contents = changes.contents.currentValue
    }

  }

  getProgramLink (): string {
    const gender = removeAccents(this.currentProgram.gender)

    const link = `${gender}/${this.currentProgram.slug}`

    return `${link}`
  }

  getRouterLink (content: any): string {
    if (!this.isInscriptions && !this.isPodcasts) {
      if (this.route) {
        return `/${this.route}/${content.slug}`
      }

      if (content.gender) {
        const gender = generateSlug(content.gender)

        if (content.regional) {
          const station = this.stationService.getStationById(content.regional)

          if (station.length > 0) {
            if (station[0].affiliate) {
              return `/afiliada/${station[0].identifier}/${gender}/${content.slug}`
            }

            return `/${station[0].identifier}/${gender}/${content.slug}`
          }
        }

        return `/${gender}/${content.slug}`
      }
    }

    if (this.isPodcasts && this.isPodcasts === true) {
      let prefixURL = ''
      if (content.url && content.url.substring(0, 1) != 'h' && content.url.substring(0, 1) != '/') prefixURL = '/'

      return `${prefixURL}${content.url}`
    }

    this.openModal(content)
  }

  navigateToForm (program: Program, inscription: Inscription): string {
    const title = generateTitleSlug(inscription.title)
    return `${this.route}/${program.slug}/${inscription.id}/${title}`
  }

  filterPrograms (gender: string): void {
    if (gender === 'Todos') {
      this.contentList = this.contents

      return
    }

    this.contentList = this.contents.filter(
      content => content.gender === gender
    )
  }

  loadMoreContent (): void {
    if (this.contents.length > this.itemsPerPage) {
      this.contentList.push(...this.contents.splice(0, this.itemsPerPage))

      return
    }

    this.contentList.push(...this.contents)

    this.contents = []

    return
  }

  alphabeticObjectSort (first: any, second: any): number {
    if (first.title.toLowerCase() < second.title.toLowerCase()) {
      return -1
    }

    if (first.title.toLowerCase() > second.title.toLowerCase()) {
      return 1
    }

    return 0
  }

  receiveData (content: any): void {
    this.filterPrograms(content.title)

    this.currentIndex = content.index
  }

  sendInfo (): void {
    this.loadMore.emit(this.pagination)
  }

  openModal (program: any): void {
    this.ngxSmartModalService.getModal('modalInscription').open()
    this.currentProgram = program
  }

  openInfo (event: Event, index: number): void {
    if (this.currentInscriptionInfo === index) {
      this.currentInscriptionInfo = -1

      return
    }

    this.currentInscriptionInfo = index
  }

  removeFragment (fragment?: string) {
    const pathWithHash = this.location.path(true)
    const pathWithoutHash = this.location.path(false)

    if (pathWithHash === `#${fragment}` || !fragment) {
      this.router.serializeUrl(
        this.router.createUrlTree([], { fragment: null })
      )

      this.location.replaceState(pathWithoutHash)
    }
  }

}
