import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'

@Injectable()
export class AmpService {

  constructor (@Inject(DOCUMENT) private doc) { }

  appendLink (linkRef: string) {
    const tag = this.doc.querySelector('[rel="amphtml"]')

    if (!tag) {
      const link: HTMLLinkElement = this.doc.createElement('link')
      link.setAttribute('rel', 'amphtml')
      link.setAttribute('href', linkRef)
      this.doc.head.appendChild(link)

      return
    }

    tag.setAttribute('href', linkRef)
  }

  removeLink () {
    let link = this.doc.querySelector('[rel="amphtml"]')
    if (link) this.doc.head.removeChild(link)
  }
}
