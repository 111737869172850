import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DomService {
  getIds (): any[] {
    const elements = Array.from(document.getElementsByTagName('*'))
    const allIds: any[] = elements
      .filter(element => {
        if (element.id) {
          return element
        }
      })
      .map((element: any) => {
        return {
          id: element.id,
          top: element.children[0].offsetTop,
          title: element.title
        }
      })

    return allIds
  }

  filterIds (ids: string[]): any[] {
    const elements = Array.from(document.getElementsByTagName('*'))

    const allIds: any[] = elements
      .filter(element => {
        if (element.id && element.tagName !== 'iframe') {
          return element
        }
      })
      .map((currentElement: any) => {
        const element = { id: '', title: '', top: '' }

        if (currentElement.id) {
          element.id = currentElement.id
        }

        if (currentElement.title) {
          element.title = currentElement.title
        }

        if (
          currentElement.title.toLowerCase().includes('disqus') ||
          currentElement.title.toLowerCase().includes('dsq')
        ) {
          return
        }

        if (
          currentElement.id.toLowerCase().includes('disqus') ||
          currentElement.id.toLowerCase().includes('dsq')
        ) {
          return
        }

        if (
          currentElement.children &&
          currentElement.children[0] &&
          currentElement.children[0].offsetTop
        ) {
          element.top = currentElement.children[0].offsetTop
        }

        return element
      })

    return allIds
  }
}
