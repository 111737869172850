import { getWinVar, callWinFN } from '../utils/util.window'
import { Injectable } from '@angular/core'
import { Log } from '../utils/util.console'

@Injectable({
  providedIn: 'root'
})
export class NaveggClass {
  setOnboard = (user: any, timeout: number = 0): void => {
    if (window) {
      try {
        setTimeout(() => {
          const NaveggInstalled = getWinVar('Navegg')
          if (NaveggInstalled) {
            callWinFN('nvg72620.setOnboard', {
              prtid: user.naveggAccount,
              prtusride: user.emailHash
            })
          }
        }, timeout)
      } catch (e) {
        Log('NAVEGG', `Error on call setOnboard: ${e.message}`, false)
      }
    }
  }

  sendAnalyticsData = (attrs: any, events: string[], timeout: number = 0): void => {
    if (window) {
      // console.log(`[NAVEGG]: name = ${user.nameHash}, email = ${user.emailHash}`)
      try {
        setTimeout(() => {
          const NaveggInstalled = getWinVar('Navegg')
          if (NaveggInstalled) {
            const nvgObj = window['nvg72620']
            if (nvgObj) {
              // console.log(`[NAVEGG]: Function sendAnalyticsData ${nvgObj.sendAnalyticsData ? 'EXISTS' : 'NOT EXISTS'} in nvg72620`)
              nvgObj.sendAnalyticsData({
                attrs,
                events
              })
              Log('NAVEGG', `Sending data to Navegg: ${JSON.stringify({attrs, events})}`, false)
            } else {
              Log('NAVEGG', 'nvg72620 not exists', false)
            }
          }
        }, timeout)
      } catch (e) {
        Log('NAVEGG', `Error on call sendAnalyticsData: ${e.message}`, false)
      }
    }
  }
}
