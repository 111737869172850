import { Injectable } from '@angular/core'

import { URL_USERS_API } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MySbtService {

  constructor(private _http: HttpClient) {}

  getAdressByCEP (cep: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL_USERS_API}/api/address/${cep}`
      )
      .toPromise()
      .then((response: any) => {
        return response
      })
  }

  getJsonUserData (token: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL_USERS_API}/api/user/${token}/download`
      )
      .toPromise()
      .then(() => {
        return `${URL_USERS_API}/api/user/${token}/download`
      })
  }
}
