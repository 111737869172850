import { Injectable } from '@angular/core'
import { URL, ID_ECAD, IDSITEAREA_ECAD } from '../../app.settings'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class EcadService {
  constructor (private _http: HttpClient) {}

  get(){
    let queryString: string = `idsite=${ID_ECAD}&idsitearea=${IDSITEAREA_ECAD}&orderby=ordem&sort=desc`
    return this._http.get(`${URL}/api/medias?limit=200&${queryString}`)
  }
}