import { Component, OnInit, Input } from '@angular/core'
import Program from 'src/app/models/program.class'
import { UserService } from '../../services/user.service'
import { generateSlug } from 'src/app/utils/utils.generators'
import { StationService } from 'src/app/services/station.service';
import { decrypt } from '../../utils/util.encrypt'
@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
  @Input('content')
  public contents: Program[]

  @Input('title')
  public title: string

  @Input('show-genders')
  public showGenders: boolean = true

  @Input('return-genders')
  public returnGenders: boolean = false

  public genders: string[] = ['Programas Gerais', 'Programas Jornalísticos']
  public currentIndex: number
  public contentList: Program[]

  public programFav: string

  constructor (
    private stationService: StationService,
    private userService: UserService
  ) {}

  async ngOnInit () {
    this.programFav = await localStorage.getItem('programFav')

    await this.contents.sort(program => {
      if (this.programFav === program.id.toString()) {
        return -1
      }

      if (this.programFav !== program.id.toString()) {
        return 1
      }

      return 0
    })

    this.contentList = this.contents.filter(
      content => content.gender !== 'Jornalismo'
    )

    this.currentIndex = this.genders.indexOf('Programas Gerais')
  }

  getRouterLink (content: any): string {
    if (content.gender) {
      const gender = generateSlug(content.gender)

      if (content.regional) {
        const station = this.stationService.getStationById(content.regional)

        if (station.length > 0) {
          if (station[0].affiliate) {
            return `afiliada/${station[0].identifier}/${gender}/${content.slug}`
          }

          return `${station[0].identifier}/${gender}/${content.slug}`
        }
      }

      return `${gender}/${content.slug}`
    }
  }

  filterPrograms (gender: string): void {
    if (gender === 'Programas Gerais') {
      this.contentList = this.contents.filter(
        content => content.gender !== 'Jornalismo'
      )

      return
    }

    this.contentList = this.contents.filter(
      content => content.gender === 'Jornalismo'
    )
  }

  changeIndex (index: number, gender: string): void {
    if (this.currentIndex !== index) {
      this.currentIndex = index

      this.filterPrograms(gender)
    }
  }

  bookmarkProgram (program: Program): void {
    this.programFav = program.id.toString()

    if (localStorage.getItem('user')) {
      const decryptedData = decrypt(localStorage.getItem('user') || '')
      const loogedUser: any = decryptedData

      if (loogedUser.favoriteProgram === this.programFav) {
        return
      }

      const user = {
        email: loogedUser.email,
        favoriteProgram: this.programFav
      }

      this.userService.update(user).then(response => {
        localStorage.setItem('programFav', program.id.toString())
      })
    }
  }

  removeBookmark(): void {
    this.programFav = null

    if (localStorage.getItem('user')) {
      const loogedUser = decrypt(localStorage.getItem('user') || '')
      const user = {
        email: loogedUser.email,
        favoriteProgram: "null"
      }

      this.userService.update(user).then(() => {
        localStorage.setItem('programFav', null)
      })
    }
  }

  isBookmarked (program: Program): boolean {
    return this.programFav === program.id.toString()
  }
}
