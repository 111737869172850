import {
  Component,
  OnInit,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core'
import { COMSCORE_PUBLISHER_ID } from '../../app.settings'
import { DomSanitizer } from '@angular/platform-browser'

declare const ns_

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnChanges {
  @Input('url')
  public url: string = ''

  @Input('adunit')
  public adunit: string = ''

  @Input('fixable')
  public fixable: boolean = false

  @Input('is-fixed')
  public isFixed: boolean = false

  @Input('is-full')
  public isFull: boolean = true

  @Input('pause-video')
  public pauseVideo: boolean = false

  @Input('full-width')
  public fullWidth: boolean = false

  @Input('program-title')
  public programTitle: string = ''

  @Input('program-id')
  public programId: number

  @Input('is-live')
  public isLive: boolean = false

  // @Input('iframe')
  // public iframeString: string

  @Output()
  public fixVideo = new EventEmitter()

  public player: any
  public videoId: string
  public iframe: any
  public videoData: any
  public params: object

  constructor (
    private hostElement: ElementRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit () {
    // if (!this.isYoutube) {
    //   this.iframe = this.getIframeLink()
    // }

    this.videoId = this.getId(this.url)
    this.params =  this.getPlayerVars(this.url)
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.pauseVideo) {
      if (changes.pauseVideo.currentValue) {
        this.player.pauseVideo()
      }
    }

    if (changes.url && changes.url.previousValue) {
      const { url } = changes

      if (url.previousValue) {
        this.videoId = this.getId(url.currentValue)

        this.player.loadVideoById(this.videoId)
        this.player.stopVideo()
      }
    }
  }

  onStateChange ($event) {
    if ($event.data === 0) {
      (window as any).streamingAnalytics.notifyEnd()
    }

    if ($event.data === 1) {
      (window as any).streamingAnalytics.notifyPlay()
    }

    if ($event.data === 2) {
      (window as any).streamingAnalytics.notifyPause()
    }

    if ($event.data === 3) {
      (window as any).streamingAnalytics.notifyBufferStart()
    }
  }

  savePlayer (player: any) {
    this.player = player
    this.videoData = this.player.getVideoData();

    (window as any).streamingAnalytics = new (ns_ as any).StreamingAnalytics({
      publisherId: COMSCORE_PUBLISHER_ID
    });
    (window as any).streamingAnalytics.createPlaybackSession();
    (window as any).streamingAnalytics
      .getPlaybackSession()
      .getAsset()
      .setLabels({
        ns_st_tp: 1,
        ns_st_stc: 11, // channel id do SBT
        ns_st_tpr: this.programId,
        ns_st_tdt: '2018-11-29',
        ns_st_tm: '21:00', // horário que foi ao ar
        ns_st_ep: this.videoData.title,
        ns_st_sn: 1,
        ns_st_en: 142,
        ns_st_cl: player.getDuration() * 1000,
        ns_st_ci: player.getVideoData().video_id,
        ns_st_pr: this.programTitle,
        ns_st_ct: this.isLive ? 'vc13' : 'vc12',
        c3: 'youtube',
        c4: 'SBT',
        c6: '*null',
        ns_st_st: 'SBT',
        ns_st_pu: 'SBT',
        ns_st_li: this.isLive ? 1 : '*null'
      })
  }

  get isYoutube () {
    return this.url.includes('youtube.com')
  }

  getId (currentUrl: string): any {
    let ID: string | string[] = ''
    let newUrl = []
    if (currentUrl.includes('youtube.com')) {
      newUrl = currentUrl
        .replace(/(>|<)/gi, '')
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)

        if (newUrl[2]) {
          ID = newUrl[2].split(/[^0-9a-z_\-]/i)
          ID = ID[0]
        } else {
          ID = newUrl
        }

    } else {
      ID = [currentUrl.match('videos-yt/(.*)/embed')[1]] || []
    }


    return ID
  }

  getPlayerVars (currentUrl: string): object {
    let newUrl = []
    let urlParams: any = {}
    if (currentUrl.includes('youtube.com') && !currentUrl.includes('watch?v=')) {
      newUrl = currentUrl.split('?')
        if (newUrl[1]) {
           urlParams = JSON.parse('{"' + decodeURI(newUrl[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
           if (urlParams.embed_config) { 
             urlParams.embed_config = JSON.parse(decodeURIComponent(urlParams.embed_config))
           }
        } 
    } 

    let playerVars: object =  {
      controls: urlParams.controls && urlParams.controls == 0  ? 0 : 1,
      enablejsapi: 1,
      rel: urlParams.rel && urlParams.rel == 1  ? 1 : 0,
      showinfo: urlParams.showinfo && urlParams.showinfo == 1  ? 1 : 0,
      autoplay: urlParams.autoplay && urlParams.autoplay == 1  ? 1 : 0,
      mute: urlParams.mute && urlParams.mute == 1  ? 1 : 0,
      modestbranding: urlParams.modestbranding && urlParams.modestbranding == 0  ? 0 : 1,
      embed_config: JSON.stringify(urlParams.embed_config ? urlParams.embed_config : {
        autonavRelatedVideos:  false,
        disableRelatedVideos:  true,
        hideInfoBar:  true,
        primaryThemeColor:  "",
        relatedChannels:[],
        relatedVideos:[],
        adsConfig:{
            adTagParameters: {
              iu: `/1011235/SBT_Videos/${this.adunit}/video`,
              cust_params: "",
              ppid: "",
              pp: ""
            },
            disableAds: false,
            nonPersonalizedAd: false
        }
      })
    }

    return playerVars
  }

  fixUrl (): void {
    this.url = this.url.replace('watch?v=', 'embed/')
  }

  onFixVideo (element: any): void {
    this.isFixed = !this.isFixed
    this.isFull = !this.isFull
    this.fixVideo.emit(this.url)
    let notice = element.target.parentNode.parentNode.parentNode.parentNode
    let divFixed = element.target.parentNode.parentNode

    
    notice.classList.contains('fixed-video')
      ? notice.classList.remove('fixed-video')
      : notice.classList.add('fixed-video')

    divFixed.classList.contains('video-full')
      ? divFixed.classList.remove('video-full')
      : divFixed.classList.add('video-full')
  
  }
}
