import { Routes } from '@angular/router'

const RedirectRoutes: Routes = [
  {
    path: 'sbtnaweb',
    redirectTo: 'programa/sbt-na-web'
  },
  {
    path: 'casosdefamilia',
    redirectTo: 'auditorio/casos-de-familia'
  },
  {
    path: 'programasilviosantos',
    redirectTo: 'auditorio/programa-silvio-santos'
  },
  {
    path: 'pss',
    redirectTo: 'auditorio/programa-silvio-santos'
  },
  {
    path: 'topaounaotopa',
    redirectTo: 'auditorio/topa-ou-nao-topa'
  },  
  {
    path: 'bomdiaecia',
    redirectTo: 'infantil/bom-dia'
  },
  {
    path: 'sbtnaweb',
    redirectTo: 'programa/sbt-na-web'
  },
  {
    path: 'chaves',
    redirectTo: 'series/chaves'
  },
  {
    path: 'sabadoanimado',
    redirectTo: 'infantil/sabado-animado'
  },
  {
    path: 'apracaenossa',
    redirectTo: 'variedades/a-praca-e-nossa'
  },
  {
    path: 'sbtbrasil',
    redirectTo: 'jornalismo/sbt-brasil'
  },
  {
    path: 'comprova',
    redirectTo : 'jornalismo/comprova'
  },  
  {
    path: 'copaamerica',
    redirectTo: 'jornalismo/copa-america'
  },
  {
    path: 'copa-america',
    redirectTo: 'jornalismo/copa-america'
  },
  {
    path: 'domingolegal',
    redirectTo: 'auditorio/domingo-legal'
  },
  {
    path: 'supernatural',
    redirectTo: 'series/supernatural'
  },
  {
    path: 'sobrenatural',
    redirectTo: 'series/supernatural'
  },
  {
    path: 'fff',
    redirectTo: 'realities/familias-frente-a-frente'
  },
  {
    path: 'sbtgames',
    redirectTo: 'variedades/sbt-games'
  },
  {
    path: 'games',
    redirectTo: 'variedades/sbt-games'
  },
  {
    path: 'umavidaumsonho',
    redirectTo: 'realities/uma-vida-um-sonho'
  },  
  {
    path: 'teladesucessos',
    redirectTo: 'filmes/tela-de-sucessos'
  },
  {
    path: 'rodaaroda',
    redirectTo: 'auditorio/roda-a-roda'
  },
  {
    path: 'cineespetacular',
    redirectTo: 'filmes/cine-espetacular'
  },
  {
    path: 'teleton',
    redirectTo: 'especiais/teleton'
  },
  {
    path: 'omcdb',
    redirectTo: 'especiais/omcdb'
  },
  {
    path: 'esquadraodamoda',
    redirectTo: 'realities/esquadrao-da-moda'
  },
  {
    path: 'trofeuimprensa',
    redirectTo: 'especiais/trofeu-imprensa'
  },
  {
    path: 'ratinho',
    redirectTo: 'auditorio/ratinho'
  },
  {
    path: 'eliana',
    redirectTo: 'auditorio/eliana'
  },
  {
    path: 'conexaoreporter',
    redirectTo: 'jornalismo/conexao-reporter'
  },
  {
    path: 'raulgil',
    redirectTo: 'auditorio/raul-gil'
  },
  {
    path: 'sbtfolia',
    redirectTo: 'especiais/sbt-folia'
  },
  {
    path: 'carrossel',
    redirectTo: 'novelas/carrossel'
  },
  {
    path: 'brasilcaminhoneiro',
    redirectTo: 'variedades/brasil-caminhoneiro'
  },
  {
    path: 'thenoite',
    redirectTo: 'talkshow/the-noite'
  },
  {
    path: 'sbtdobem',
    redirectTo: 'institucional/sbt-do-bem'
  },
  {
    path: 'bakeoffbrasil',
    redirectTo: 'realities/bake-off-brasil'
  },
  {
    path: 'acelerados',
    redirectTo: 'variedades/acelerados'
  },
  {
    path: 'cumplicesdeumresgate',
    redirectTo: 'novelas/cumplices-de-um-resgate'
  },
  {
    path: 'arqueiro',
    redirectTo: 'series/arqueiro'
  },
  {
    path: 'arrow',
    redirectTo: 'series/arqueiro'
  },
  {
    path: 'adona',
    redirectTo: 'novelas/a-dona'
  },
  {
    path: 'kenanekel',
    redirectTo: 'series/kenan-e-kel'
  },
  {
    path: 'teresa',
    redirectTo: 'novelas/teresa'
  },
  {
    path: 'bbq',
    redirectTo: 'realities/bbq'
  },
  {
    path: 'praganharesorodar',
    redirectTo: 'variedades/pra-ganhar-e-so-rodar'
  },
  {
    path: 'primeiroimpacto',
    redirectTo: 'jornalismo/primeiro-impacto'
  },
  {
    path: 'agarotadamoto',
    redirectTo: 'series/a-garota-da-moto'
  },
  {
    path: 'fabricadecasamentos',
    redirectTo: 'realities/fabrica-de-casamentos'
  },
  {
    path: 'sbtnoticias',
    redirectTo: 'jornalismo/sbt-noticias'
  },
  {
    path: 'fofocalizando',
    redirectTo: 'jornalismo/fofocalizando'
  },
  {
   path: 'variedades/fofocalizando',
    redirectTo: 'jornalismo/fofocalizando'
  },
  {
    path: 'triturando',
    redirectTo: 'jornalismo/triturando'
  },
  {
    path: 'operacaomesquita',
    redirectTo: 'variedades/operacao-mesquita'
  },
  {
    path: 'sbtdobem',
    redirectTo: 'institucional/sbt-do-bem'
  },
  {
    path: 'thundermans',
    redirectTo: 'series/thundermans'
  },
  {
    path: 'henrydanger',
    redirectTo: 'series/henry-danger'
  },
  {
    path: 'copanordeste',
    redirectTo: 'especiais/copa-nordeste'
  },
  {
    path: 'copadonordeste',
    redirectTo: 'especiais/copa-nordeste'
  },
  {
    path: 'poliana',
    redirectTo: 'novelas/poliana-moca'
  },
  {
    path: 'polianamoca',
    redirectTo: 'novelas/poliana-moca'
  },
  {
    path: 'asaventurasdepoliana',
    redirectTo: 'novelas/as-aventuras-de-poliana'
  },
  {
    path: 'piaodacasapropria',
    redirectTo: 'auditorio/piao-da-casa-propria'
  },
  {
    path: 'fakenews',
    redirectTo: 'especiais/fake-news'
  },
  {
    path: 'tbtsbt',
    redirectTo: 'especiais/tbt-sbt'
  },
  {
    path: 'ocrimenaocompensa',
    redirectTo: 'series/o-crime-nao-compensa'
  },
  {
    path: 'semprebem',
    redirectTo: 'variedades/sempre-bem'
  },
  {
    path: 'programadamaisa',
    redirectTo: 'talkshow/programa-da-maisa'
  },
  {
    path: 'maisa',
    redirectTo: 'talkshow/programa-da-maisa'
  },
  {
    path: 'aquenaopodiaamar',
    redirectTo: 'novelas/a-que-nao-podia-amar'
  },
  {
    path: 'vempraca',
    redirectTo: 'variedades/vem-pra-ca'
  },
  {
    path: 'mestresdasabotagem',
    redirectTo: 'realities/mestres-da-sabotagem' 
  },
  {
    path: 'tedevoessabrasil',
    redirectTo: 'realities/te-devo-essa-brasil' 
  },
  {
    path: 'sbtsports',
    redirectTo: 'jornalismo/sbt-sports' 
  },
  {
    path: 'sbt-sports',
    redirectTo: 'jornalismo/sbt-sports' 
  },
  {
    path: 'showdomilhaopicpay',
    redirectTo: 'variedades/show-do-milhao-picpay'
  },
  {
    path: 'showdomilhao',
    redirectTo: 'variedades/show-do-milhao-picpay'
  },
  {
    path: 'sdm',
    redirectTo: 'variedades/show-do-milhao-picpay'
  },
  {
    path: 'super-copa',
    redirectTo: 'jornalismo/sbt-sports'
  },
  {
    path: 'supercopa',
    redirectTo: 'jornalismo/sbt-sports'
  },
  {
    path: 'camerasescondidas',
    redirectTo: 'variedades/cameras-escondidas'
  },
  {
    path: 'cameras',
    redirectTo: 'variedades/cameras-escondidas'
  },
  {
    path: 'nft',
    redirectTo: 'institucional/nft'
  },
  {
    path: 'afantasticamaquinadesonhos',
    redirectTo: 'variedades/tvzyn'
  },
  {
    path: 'variedades/a-fantastica-maquina-de-sonhos',
    redirectTo: 'variedades/tvzyn'
  },
  {
    path: 'aovivo',
    redirectTo: 'ao-vivo'
  },
  // Afiliada Allamanda
  {
    path: 'tvallamanda',
    redirectTo: 'afiliada/tvallamanda'
  },
  {
    path: 'broncalivre',
    redirectTo: 'afiliada/tvallamanda/jornalismo/bronca-livre'
  },
  {
    path: 'comandopolicial',
    redirectTo: 'afiliada/tvallamanda/jornalismo/comando-policial'
  },
  {
    path: 'allamandarevista',
    redirectTo: 'afiliada/tvallamanda/variedades/allamanda-revista'
  },
  // Regional Brasilia
  {
    path: 'brasilia',
    redirectTo: 'brasilia/home',
    pathMatch: 'full'
  },
  {
    path: 'sbtbrasiliasegundaedicao',
    redirectTo: 'brasilia/jornalismo/sbt-brasilia-segunda-edicao'
  },
  {
    path: 'inside',
    redirectTo: 'brasilia/variedades/inside'
  },
  {
    path: 'esabado',
    redirectTo: 'brasilia/jornalismo/e-sabado'
  },
  {
    path: 'sbtbrasiliaprimeiraedicao',
    redirectTo: 'brasilia/jornalismo/sbt-brasilia-primeira-edicao'
  },
  {
    path: 'ultimamarcha',
    redirectTo: 'brasilia/variedades/ultima-marcha'
  },
  {
    path: 'sbtsportsbrasilia',
    redirectTo: 'brasilia/jornalismo/sbt-sports-brasilia'
  },
  // Regional Rio de Janeiro
  {
    path: 'rio',
    redirectTo: 'riodejaneiro/home'
  },
  {
    path: 'sbtrio',
    redirectTo: 'riodejaneiro/jornalismo/sbt-rio'
  },
  {
    path: 'sbtcidade',
    redirectTo: 'riodejaneiro/jornalismo/sbt-cidade'
  },
  {
    path: 'sbtesporterio',
    redirectTo: 'riodejaneiro/jornalismo/sbt-esporte-rio'
  },
  {
    path: 'esportemagico',
    redirectTo: 'riodejaneiro/jornalismo/esporte-magico'
  },
  {
    path: 'cozinheirovschefs',
    redirectTo: 'riodejaneiro/variedades/cozinheiro-vs-chefs'
  },
  {
    path: 'cariocou',
    redirectTo: 'riodejaneiro/variedades/cariocou'
  },
  {
    path: 'caminhosdochef',
    redirectTo: 'riodejaneiro/variedades/caminhos-do-chef'
  },
  // Regional Porto Alegre
  {
    path: 'rs',
    redirectTo: 'riograndedosul/home'
  },
  {
    path: 'sbtesporte',
    redirectTo: 'riograndedosul/jornalismo/sbt-esporte'
  },
  {
    path: 'sbtriogrande',
    redirectTo: 'riograndedosul/jornalismo/sbt-rio-grande'
  },
  {
    path: 'masbah',
    redirectTo: 'riograndedosul/variedades/masbah'
  },
  {
    path: 'encantosdeinverno',
    redirectTo: 'riograndedosul/variedades/encantos-de-inverno'
  },
  {
    path: 'encantos-de-inverno',
    redirectTo: 'riograndedosul/variedades/encantos-de-inverno'
  },
  {
    path: 'anonymusgourmet',
    redirectTo: 'riograndedosul/variedades/anonymus-gourmet'
  },
  {
    path: 'sbtriogrande2edicao',
    redirectTo: 'riograndedosul/jornalismo/sbt-rio-grande-2-edicao'
  },

  {
    path: 'estudiosdeinverno',
    redirectTo: 'riograndedosul/variedades/estudiosdeinverno'
  },
  // Afiliada Santa catarina
  // {
  //   path: 'scc',
  //   redirectTo: 'afiliada/scc'
  // },
  // {
  //   path: 'sbtnews',
  //   redirectTo: '/'
  // },
  // {
  //   path: 'sbtmeiodia',
  //   redirectTo: '/'
  // },
  // Afiliada VTV
  {
    path: 'vtv',
    redirectTo: 'afiliada/vtv'
  },
  {
    path: 'avozdapopulacao',
    redirectTo: 'afiliada/vtv/variedades/a-voz-da-populacao'
  },
  {
    path: 'desafieochef',
    redirectTo: 'afiliada/vtv/variedades/desafie-o-chef'
  },
  {
    path: 'futebolesporteshow',
    redirectTo: 'afiliada/vtv/variedades/futebol-esporte-show'
  },
  {
    path: 'jornaldavtv',
    redirectTo: 'afiliada/vtv/jornalismo/jornal-da-vtv'
  },
  {
    path: 'salvemeupet',
    redirectTo: 'afiliada/vtv/variedades/salve-meu-pet'
  },
  {
    path: 'vtvdagente',
    redirectTo: 'afiliada/vtv/jornalismo/vtv-da-gente'
  },
  //Comercial
  {
    path: 'oreo',
    redirectTo: 'realities/fabrica-de-casamentos/fiquepordentro/126393-descubra-a-receita-de-bolo-de-cappuccino-da-oreo-com-a-beca'
  },
  //Inscrições
  {
    path: 'inscricoes/fabricadecasamentos',
    redirectTo: 'inscricoes/fabrica-de-casamentos/198616/sbt-fabrica-de-casamentos'
  },
  {
    path: 'inscricoes/fabrica',
    redirectTo: 'inscricoes/fabrica-de-casamentos/198616/sbt-fabrica-de-casamentos'
  },
  {
    path: 'obrasilqueninguemfala',
    redirectTo: 'colunas/o-brasil-que-ninguem-fala'
  },
  {
    path: 'inscricoes/sbt-na-web/265924/show-do-milhao-universitarios',
    redirectTo: 'inscricoes/show-do-milhao-picpay/266684/show-do-milhao-universitarios'
  },
  {
    path: 'inscricoes/plateia/casos-de-familia',
    redirectTo: 'inscricoes/plateia-dos-programas-de-auditorio/307930/plateia-casos-de-familia'
  },
  {
    path: 'inscricoes/plateia/eliana',
    redirectTo: 'inscricoes/plateia-dos-programas-de-auditorio/307957/plateia-eliana'
  },
  {
    path: 'inscricoes/plateia/domingo-legal',
    redirectTo: 'inscricoes/plateia-dos-programas-de-auditorio/307959/plateia-domingo-legal'
  },
  {
    path: 'inscricoes/plateia/raul-gil',
    redirectTo: 'inscricoes/plateia-dos-programas-de-auditorio/307988/plateia-raul-gil'
  },
  {
    path: 'inscricoes/plateia/programa-silvio-santos',
    redirectTo: 'inscricoes/plateia-dos-programas-de-auditorio/307995/plateia-programa-silvio-santos'
  },
  {
    path: 'inscricoes/plateia/the-noite',
    redirectTo: 'inscricoes/plateia-dos-programas-de-auditorio/308014/plateia-the-noite'
  },
]
export { RedirectRoutes }
