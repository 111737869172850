import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime } from 'rxjs/operators';
import { RECAPTCHA, SBT_SITE_SIGNUP, SBT_SITE_SIGNIN } from 'src/app/app.settings';
import { ScrollService } from 'src/app/services/scroll.service';
import { checkOfAge } from 'src/app/validators/age-of.validator';
import { checkCpf } from 'src/app/validators/cpf.validator';
import { emailConfirmation } from 'src/app/validators/email.validator';
import { checkInvalidDate } from 'src/app/validators/invalid-date.validator';
import { User } from '../../models/user.class';
import { MySbtService } from '../../pages/my-sbt/my-sbt.service';
import { StatesService } from '../../services/states.service';
import { TitleService } from '../../services/title.service';
import { UserService } from '../../services/user.service';
import { passwordConfirmation } from '../../validators/password.validator';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-account-actions',
  templateUrl: './account-actions.component.html',
  styleUrls: ['./account-actions.component.scss'],
  providers: [ScrollService, MySbtService, StatesService, UserService]
})
export class AccountActionsComponent implements OnInit, OnDestroy {
  @ViewChild('accountLabel', {static: true})
  public accountLabel: ElementRef

  public siteKey: string = `${RECAPTCHA}`
  public renderCaptcha: boolean = false

  public registerError: string
  public loginError: string
  public forgotPasswordError: string
  public confirmPasswordError: string
  public confirmEmailError: string

  public sexOptions: any = [
    {
      value: 'Masculino',
      text: 'Masculino'
    },
    {
      value: 'Feminino',
      text: 'Feminino'
    }
  ]

  public maritalOptions: any = [
    {
      value: 'Solteiro',
      text: 'Solteiro'
    },
    {
      value: 'Casado',
      text: 'Casado'
    },
    {
      value: 'Divorciado',
      text: 'Divorciado'
    },
    {
      value: 'Viuvo',
      text: 'Viúvo'
    }
  ]

  public states: any = this.statesService.getStates()

  public cpfValidator: string = '^[0-9]{11}$'
  public cepValidator: string = '^[0-9]{8}$'
  public rgValidator: string = '^[a-zA-Z0-9]{5,15}$'
  public birthValidator: string = '^[0-9]{8}$'
  public nameValidator: string = `^[a-zA-Z]$`
  public pureTextPattern: string = '^[A-Za-zÀ-ú ]+$'
  public emailPattern: string = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.]{1}[a-z]{2,4}$'
  public residentialPhoneValidator: string = '^[0-9]{10}$'
  public cellphoneValidator: string = '^[0-9]{11}$'

  public personalData = this.fb.group({
    name: ['', [Validators.required]],
    sex: [null, [Validators.required]],
    birthday: [
      '',
      [
        Validators.required,
        Validators.pattern(this.birthValidator),
        checkOfAge(18),
        checkInvalidDate()
      ]
    ],
    rg: ['', [Validators.pattern(this.rgValidator)]],
    cpf: ['', [Validators.required, Validators.pattern(this.cpfValidator), checkCpf()]]

    // maritalStatus: [null, [Validators.required]]
  })

  public contactData = this.fb.group({
    cep: ['', [Validators.required, Validators.pattern(this.cepValidator)]],
    state: [null, Validators.required],
    city: ['', Validators.required],
    neighborhood: ['', Validators.required],
    address: ['', Validators.required],
    number: ['', Validators.required],
    additional: [''],
    cellphone: ['', [Validators.pattern(this.cellphoneValidator)]],
    phone: ['', [Validators.pattern(this.residentialPhoneValidator)]]
  })

  public accountData = this.fb.group(
    {
      email: ['', [Validators.required, Validators.pattern(this.emailPattern), Validators.minLength(5), Validators.maxLength(100)]],
      confirmEmail: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      emailoptin: false,
      acceptTerms: [false, Validators.requiredTrue],
      recaptcha: [null, [Validators.required, Validators.minLength(5)]]
    },
    {
      validator: [passwordConfirmation, emailConfirmation]
    }
  )

  public loginData = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', Validators.required]
  })

  public forgotPasswordData = this.fb.group({
    email: ['', [Validators.required]]
  })

  public resetPasswordUrl: string = '' // REMOVER APOS ENVIO DE EMAIL
  public subscriptions = []

  constructor (
    private renderer: Renderer2,
    private fb: FormBuilder,
    private ngxSmartModalService: NgxSmartModalService,
    private _scrollService: ScrollService,
    private router: Router,
    private userService: UserService,
    private MySbtService: MySbtService,
    private statesService: StatesService,
    private location: Location,
    private titleService: TitleService
  ) { }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit () {
    const fragmentSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const fragment = this.router.routerState.snapshot.root.fragment

        if (fragment) {
          if (fragment.includes('criar-conta')) {
            this.openCreateAccountModal()
          }

          if (fragment.includes('login')) {
            this.openLoginModal()
          }

          if (fragment.includes('redefinir-senha')) {
            this.openForgotPasswordModal()
          }
        }
      }
    })

    const cepFieldSubscription = this.contactData
      .get('cep')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(cep => {
        if (cep.length === 8) {
          this.MySbtService.getAdressByCEP(cep)
            .then(response => {
              const { uf, city, district, street } = response

              if (uf) {
                this.contactData.get('state').setValue(uf)
              }

              if (city) {
                this.contactData.get('city').setValue(city)
              }

              if (district) {
                this.contactData.get('neighborhood').setValue(district)
              }

              if (street) {
                this.contactData.get('address').setValue(street)
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })


    const passwordFieldSubscription = this.accountData
      .get('password')
      .valueChanges.pipe()
      .subscribe(password => {
        if (password.length < 6) {
          this.confirmPasswordError = 'SENHA PRECISA TER NO MÍNIMO 6 E NO MÁXIMO 20 CARACTERES'

          return
        }

        this.confirmPasswordError = undefined
      })


    const confirmPasswordFieldSubscription = this.accountData
      .get('confirmPassword')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(newPassword => {
        if (newPassword !== this.accountData.get('password').value) {
          this.confirmPasswordError = 'Essas senhas não coincidem'

          return
        }

        this.confirmPasswordError = undefined
      })

    const confirmEmailFieldSubscription = this.accountData
      .get('confirmEmail')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe(confirmationEmail => {
        if (confirmationEmail !== this.accountData.get('email').value) {
          this.confirmEmailError = 'Esses emails não coincidem'

          return
        }

        this.confirmEmailError = undefined
      })

    this.subscriptions = [fragmentSubscription, cepFieldSubscription, passwordFieldSubscription, confirmPasswordFieldSubscription, confirmEmailFieldSubscription]
  }

  putHashOnUrl (hash: string) {
    const route = this.router.serializeUrl(
      this.router.createUrlTree([], { fragment: hash })
    )

    this.location.replaceState(`${route}`)
  }

  removeFragment (fragment?: string) {
    const pathWithHash = this.location.path(true)
    const pathWithoutHash = this.location.path(false)

    if (pathWithHash === `#${fragment}` || !fragment) {
      this.router.serializeUrl(
        this.router.createUrlTree([], { fragment: null })
      )

      this.location.replaceState(pathWithoutHash)
    }
  }

  removeFromTitle (title: string) {
    const currentTitle = this.titleService.getTitle().replace(`${title} - `, '')

    this.titleService.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.titleService.getTitle()

    this.titleService.setTitle(`${title} - ${currentTitle}`)
  }

  isBirthDayValid () {
    return (
      this.personalData.get('birthday').errors &&
      this.personalData.get('birthday').errors.underAge &&
      (this.personalData.get('birthday').touched ||
        this.personalData.get('birthday').dirty)
    )
  }

  isDateValid () {
    return (
      this.personalData.get('birthday').errors &&
      this.personalData.get('birthday').errors.invalidDate &&
      (this.personalData.get('birthday').touched ||
        this.personalData.get('birthday').dirty)
    )
  }

  isCpfValid () {
    return (
      this.personalData.get('cpf').value.length === 11 &&
      this.personalData.get('cpf').errors &&
      this.personalData.get('cpf').errors.invalidCpf &&
      (this.personalData.get('cpf').touched ||
        this.personalData.get('cpf').dirty)
    )
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll (e) {
    if (window.pageYOffset > 50) {
      this.renderer.addClass(this.accountLabel.nativeElement, 'sticky')
    } else {
      this.renderer.removeClass(this.accountLabel.nativeElement, 'sticky')
    }
  }

  openTermsModal () {
    this.ngxSmartModalService
      .getModal('modalTerms')
      .addCustomClass('high-index')
    this.ngxSmartModalService.getModal('modalTerms').open()
  }

  openPolicyModal () {
    this.ngxSmartModalService
      .getModal('modalPolicy')
      .addCustomClass('high-index')
    this.ngxSmartModalService.getModal('modalPolicy').open()
  }
  
  redirectSSO (direction) {
    window.open(`${environment.firebase.nextPublicHostSSO}/sbt/${direction}?origin=SBTTV&redirect=${window.location.href}`, '_self');
  }

  openCreateAccountModal (): void {
    // if (this.ngxSmartModalService.getModal('modalLogin').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalLogin').close()
    // }

    // this.ngxSmartModalService.getModal('modalCreateAccount').open()
    
    
    // const url = `https://localhost:3000/signup/criar-conta?redirect=${location.href}`
    // const url = `${SBT_SITE_SIGNUP}/criar-conta?redirect=${location.href}`
    // window.open(url, '_self');
    this.redirectSSO('signup');
  }

  openFormsModal (): void {
    if (this.ngxSmartModalService.getModal('modalCreateAccount').isVisible()) {
      this.ngxSmartModalService.getModal('modalCreateAccount').close()
    }

    this.ngxSmartModalService.getModal('modalForms').open()
  }

  openLoginModal (): void {
    // if (this.ngxSmartModalService.getModal('modalForms').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalForms').close()
    // }

    // if (this.ngxSmartModalService.getModal('modalFinish').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalFinish').close()
    // }

    // if (this.ngxSmartModalService.getModal('modalCreateAccount').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalCreateAccount').close()
    // }

    // if (this.ngxSmartModalService.getModal('modalForgotPassword').isVisible()) {
    //   this.ngxSmartModalService.getModal('modalForgotPassword').close()
    // }

    // this.ngxSmartModalService.getModal('modalLogin').open()
    
    // const url = `https://localhost:3000/signin?redirect=${location.href}`
    this.redirectSSO('signin');
  }

  openForgotPasswordModal (): void {
    this.forgotPasswordError = null

    if (this.ngxSmartModalService.getModal('modalLogin').isVisible()) {
      this.ngxSmartModalService.getModal('modalLogin').close()
    }

    this.ngxSmartModalService.getModal('modalForgotPassword').open()
  }

  createAccount (): void {
    const birthday: string = this.personalData.get('birthday').value

    const user = new User({
      name: this.personalData.get('name').value,
      password: this.accountData.get('password').value,
      email: this.accountData.get('email').value.toLowerCase(),
      cpf: this.personalData.get('cpf').value,
      rg: this.personalData.get('rg').value,
      gender: this.personalData.get('sex').value,
      birthdayDay: birthday.substring(0, 2),
      birthdayMonth: birthday.substring(2, 4),
      birthdayYear: birthday.substring(4, 8),
      postalcode: this.contactData.get('cep').value,
      streetLabel: this.contactData.get('address').value,
      streetNumber: this.contactData.get('number').value,
      streetNumber2: this.contactData.get('additional').value,
      district: this.contactData.get('neighborhood').value,
      city: this.contactData.get('city').value,
      state: this.contactData.get('state').value,
      homephone: this.contactData.get('phone').value,
      cellphone: this.contactData.get('cellphone').value,
      favoriteProgram: '',
      favoriteNewsProgram: '',
      acceptTerms: this.accountData.get('acceptTerms').value,
      emailoptin: this.accountData.get('emailoptin').value,
      source: 'Site SBT'
    })

    this.userService
      .create(user)
      .then(response => {
        this.ngxSmartModalService.getModal('modalForms').close()
        this.ngxSmartModalService.getModal('modalFinish').open()
      })
      .catch((error: Error) => {
        this.registerError = error.message
      })
  }

  navigateToTerms (url: string, hash: string = null): void {
    this.ngxSmartModalService.getModal('modalForms').close()
    this.router.navigate([url])

    if (hash) {
      this.router.navigate([url], { fragment: hash })
      setTimeout(() => {
        this._scrollService.scroll()
      }, 500)
    }
  }

  login () {
    const user = {
      email: this.loginData.get('email').value.toLowerCase(),
      password: this.loginData.get('password').value
    }

    this.userService
      .login(user)
      .then(response => {
        this.ngxSmartModalService.getModal('modalLogin').close()

        const currentUrl: string = this.router.url.includes('#') ? this.router.url.split('#')[0] : this.router.url

        if (currentUrl.includes('recuperar-senha')) {
          this.router.navigate(['/'])
          return
        }

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation ='reload';
        this.router.navigate([currentUrl.split('?')[0]], { queryParamsHandling: 'preserve' })

      })
      .catch(() => {
        this.loginError = 'Usuário e/ou senha inválidos'
      })
  }

  forgotPassword () {
    const user = {
      email: this.forgotPasswordData.get('email').value.toLowerCase()
    }

    this.userService
      .forgotPassword(user)
      .then(response => {
        if (
          this.ngxSmartModalService.getModal('modalForgotPassword').isVisible()
        ) {
          this.ngxSmartModalService.getModal('modalForgotPassword').close()
        }

        this.ngxSmartModalService.getModal('modalForgotPasswordSuccess').open()

        const user = response

        this.resetPasswordUrl = `/recuperar-senha/${user.resetPasswordToken}`
      })
      .catch(() => {
        this.forgotPasswordError = 'E-mail não encontrado'
      })
  }

  renderCaptchaStep (): void {
    this.renderCaptcha = true
  }
}
