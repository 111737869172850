import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit, AfterContentChecked, OnDestroy } from '@angular/core'

import { NgxSmartModalService } from 'ngx-smart-modal'
import { ActivatedRoute } from '@angular/router';
import { Character } from '../../models/character.class';
import { TitleService } from '../../services/title.service';

@Component({
  selector: 'app-characters',
  templateUrl: './characters.component.html',
  styleUrls: ['./characters.component.scss']
})
export class CharactersComponent implements AfterViewInit, OnDestroy {
  @Input('characters')
  public characters: any[]

  @Input('title')
  public title: string

  @ViewChild('charactersList', {static: true})
  public charactersList: ElementRef

  public currentCharacter: any

  public watchRouteComponents: string[] = [
    'personagem',
    'personagens'
  ]

  public subscriptions = []

  constructor(public ngxSmartModalService: NgxSmartModalService, private route: ActivatedRoute, private titleService: TitleService) { }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngAfterViewInit() {
    const routeSubscription = this.route.params.subscribe(params => {
      const { component, value } = params

      if (component) {
        if (this.watchRouteComponents.includes(component)) {
          const character: any = this.characters.filter((character: Character) =>
            character.title.toLowerCase() === value
          )
            .map(character => character)

          if (character) {
            setTimeout(() => {
              this.currentCharacter = character[0]

              if (this.ngxSmartModalService) {
                this.ngxSmartModalService.getModal('modal').open()
              }
            }, 10)
          }
        }
      }
    })

    this.subscriptions.push(routeSubscription)
  }

  removeFromTitle (title: string) {
    const currentTitle = this.titleService.getTitle().replace(`${title} - `, '')

    this.titleService.setTitle(`${currentTitle}`)
  }

  changeTitle (title: string) {
    const currentTitle = this.titleService.getTitle()

    this.titleService.setTitle(`${title} - ${currentTitle}`)
  }

  setCurrentCharacter(character: any): void {
    this.currentCharacter = character
    this.ngxSmartModalService.getModal('modal').open()
  }

  scroll(left: number): void {
    this.charactersList.nativeElement.scrollBy({
      left,
      behavior: 'smooth'
    })
  }

  previousCharacters(): void {
    this.scroll(-860)
  }

  nextCharacters(): void {
    this.scroll(860)
  }
}
