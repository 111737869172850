import { ValidatorFn, AbstractControl } from '@angular/forms'
import isValidCpf from '@brazilian-utils/is-valid-cpf';

export const checkCpf = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const cpf: string = control.value

    return isValidCpf(cpf) ? null : { invalidCpf: true }
  }
}
