import { Injectable } from '@angular/core'
import { URL, API_GATEWAY_TOKEN } from '../../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  constructor(private _http: HttpClient) {}


  getHighlightedList(): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/highlighted?limit=5`)
      .toPromise()
      .then((response: any) => {
        const notices = response.results
        return [
          {
            medias: notices
          }
        ]
      })
  }
}
