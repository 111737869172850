import { Component, OnInit, OnDestroy, Renderer2, Inject, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser'
import { ActivatedRoute, Router, Params } from '@angular/router'
import { Location, DOCUMENT } from '@angular/common';
import { NoticeService } from './notice.service'
import Notice, { typeDescriptionEnum } from '../../models/notice.class'
import Pagination from '../../models/pagination.class'
import { MetatagsService } from '../../services/metatags.service'
import Program from 'src/app/models/program.class'
import { ProgramService } from '../program/program.service'
import { StationService } from 'src/app/services/station.service'
import { isMobile } from '../../utils/util.browser'
import { getUrlIframe } from '../../utils/util.iframes'
import { generateSlug, generateTitleSlug } from '../../utils/utils.generators'
import { TitleService } from 'src/app/services/title.service'
import { AmpService } from 'src/app/services/amp.service'
import { CanonicalService } from 'src/app/services/canonical.service'
import { SOCIAL_CONTENT, ID_SITE_SBTNEWS, ID_SITE_SBT_BRASIL, ID_SITE_PRIMEIRO_IMPACTO, URL_SITE_SBTNEWS, SITE_LINK, ID_SITE_SBTNOTICIAS } from 'src/app/app.settings'

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  providers: [NoticeService, AmpService, CanonicalService]
})

export class NoticeComponent implements OnInit, OnDestroy {
  public program: Program
  public notice: Notice
  public notices: Notice[] = []
  public relatedNoticesPagination: Pagination
  public typeDescriptionEnum = typeDescriptionEnum
  public hasGallery: boolean = false
  public currentCommentIndex: number = 0
  public showComments: boolean = false
  public showAudima: boolean = true
  public pageId: string
  public pauseVideo: boolean = false
  public loaded: boolean = false
  public hasContent: boolean
  public noticeType: string
  public isLoading: boolean = false
  public isMobile: boolean = false
  public stationAdUnit: string = ''
  public hasProgramComments: boolean = false
  public showButtonYoutube: boolean = false
  public subscriptions = []
  public getIframeURL = getUrlIframe
  public bannerfixed: boolean = false;
  public bannerFixedFooter: boolean = false;
  public buttonClosedBanner = true
  public calcSpaceFooterBanner: any = 200
  public itemsBreadcrumb: Array<object> = []

  @HostListener('window:scroll', ['$event']) onscroll() {
    if (window.scrollY > 1570) {
      this.bannerfixed = true
    } else {
      this.bannerfixed = false
    }

    if (window.scrollY > 300) {
      this.bannerFixedFooter = true
    } else {
      this.bannerFixedFooter = false
    }

    let calcHeightPage = window.innerHeight + window.pageYOffset
    let calcOffSetHeight = document.body.offsetHeight - this.calcSpaceFooterBanner
    let resultCalcPage = calcHeightPage >= calcOffSetHeight

    if (resultCalcPage) {
      this.bannerFixedFooter = false
    }
  }


  constructor(
    private _service: NoticeService,
    private programService: ProgramService,
    private meta: Meta,
    private metasService: MetatagsService,
    private canonicalService: CanonicalService,
    private route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private title: TitleService,
    private stationService: StationService,
    private ampService: AmpService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
    this.ampService.removeLink()
    this.canonicalService.removeLink()
    this.renderer.destroy()
  }

  ngOnInit(): void {
    this.isMobile = isMobile()

    const audimaScript = this.renderer.createElement('script')
    audimaScript.src = 'https://audio4.audima.co/audima-widget.js'
    this.renderer.appendChild(this._document.head, audimaScript)

    const activeRouteSubscriptions = this.route.params.subscribe(
      (params: Params) => {
        if (params.slug) {
          const { slug } = params

          this.getNotice(slug)
        }
      }
    )
    this.subscriptions.push(activeRouteSubscriptions)
  }

  setStationAdUnit() {
    if (this.stationService.checkStationCookie()) {
      const stationIdentifier = this.stationService.getStationCookie()

      if (stationIdentifier) {
        const station = this.stationService.getStation(stationIdentifier)

        if (station && station[0] && this.program.regional !== 0) {
          this.stationAdUnit = `_${station[0].adUnitName}`
        }
      }
    }
  }

  openComments(index: number): void {
    this.showComments = true
    this.pauseVideo = true

    this.pageId = location.toString()
  }

  hideComments(event: any): void {
    if (event.close) {
      this.showComments = false
      this.pauseVideo = false
    }
  }

  changeUrlState({ visible }, currentNotice: Notice): void {
    if (!currentNotice.programRoute) {
      return
    }
    if (visible) {
      this.title.setTitle(`${currentNotice.title.toString()} - SBT`)

      const route = this.router.serializeUrl(
        this.router.createUrlTree(
          [
            `${currentNotice.programRoute}/${currentNotice.type}/${currentNotice.slug
            }`
          ] // Get uri
        )
      )
      // tslint:disable-next-line:no-extra-boolean-cast
      this.location.replaceState(route)

      this.updateMetaTags(currentNotice)
      this.canonicalService.appendLink()

    }
  }

  isMediastreamIframe(content): boolean {
    return (content.indexOf('mdstrm.com') > -1) ? true : false
  }

  loadNextNotice({ visible }): void {
    if (this.isLoading === true) {
      return
    }
    if (this.notices.length > 0) {
      if (visible) {
        this.isLoading = true
        setTimeout(() => {
          this.nextNotice()
        }, 800)
      }
    }
  }

  onFixVideo(url) {
    this.notices = this.notices.map(notice => {
      notice.descriptions.forEach(description => {
        if (description.content && description.content.includes(url)) {
          description.isFixable = true
        } else {
          description.isFixable = false
        }
      })
      return notice
    })
  }

  removeSpecialStrings(content: string): string {
    if (content.indexOf('GALERIA') !== -1) {
      const startGalleryIndex: number = content.indexOf('[[GALERIA')
      const lastGalleryIndex: number = content.lastIndexOf(')]]') + 3

      return content.replace(
        content.substring(startGalleryIndex, lastGalleryIndex),
        ''
      )
    }

    return content
  }

  getNotice(slug: string): void {
    // const seedTagScript = this.renderer.createElement('script')
    // seedTagScript.defer = true
    // seedTagScript.text = ` window._seedtagq = window._seedtagq || [];
    // window._seedtagq.push(['_setId', '7468-2667-01']);
    // window._seedtagq.push(['iframe_mode']);
    // (function () {
    //   var st = document.createElement('script');
    //   st.type = 'text/javascript';
    //   st.async = true;
    //   st.src = ('https:' == document.location.protocol
    //     ? 'https'
    //     : 'http') + '://config.seedtag.com/loader.js?v=' + Math.random();
    //   var s = document.getElementsByTagName('script')[0];
    //   s.parentNode.insertBefore(st, s);
    // })();`
    // this.renderer.appendChild(this._document.head, seedTagScript)

    this._service.getNotice(slug).then((response: Notice | any) => {

      if (response.idprogram === ID_SITE_SBT_BRASIL || response.idprogram === ID_SITE_PRIMEIRO_IMPACTO || response.idprogram === ID_SITE_SBTNOTICIAS) {
        window.location.href = `${URL_SITE_SBTNEWS}/noticia/${generateTitleSlug(response.program)}/${response.slug}`
      }

      if (response.loaded !== undefined && !response.loaded) {
        this.loaded = true
        this.hasContent = false

        return
      }

      this.loaded = true
      this.hasContent = true
      this.notices = []

      this.programService.getProgramById(response.idprogram).then(program => {
        this.hasProgramComments = program.showComments

        // verifica se aparece botão de inscrever no Youtube
        /*
        "show": true || false,
        "channel": "",
        "showSubscribers": "",
        "layout": ""
        */
        if (program.youtubeSubscribeButton && program.youtubeSubscribeButton[0] && program.youtubeSubscribeButton[0].channel) {
          this.showButtonYoutube = program.youtubeSubscribeButton[0].show
        }

        // verifica se banner da audima aparece ou não
        if (program.showAudima === undefined) {
          this.showAudima = true
        } else {
          this.showAudima = program.showAudima
        }

        const gender = generateSlug(program.gender)

        program.components.forEach(component => {
          if (component.type === 'header') {
            const params = {
              idPlaylist: component.idPlaylist,
              idSiteArea: component.idSiteArea,
              limit: 1
            }

            this.programService.getMedias(params).then(({ results }) => {
              if (results[0]) {
                response.programHeader = results[0].thumbnail
              }
            })
          }
        })

        let stationIdentifier = this.getStationByRoute()
        if (stationIdentifier != undefined) {

          const cookie = this.stationService.getStationCookie()
          const cookieStation = this.stationService.getStation(cookie)[0]
          const station = this.stationService.getStation(stationIdentifier)[0] || cookieStation

          if (station.affiliate && station.identifier != 'default') {
            response.programRoute = `/afiliada/${station.identifier}/${gender}/${program.slug}`
          } else if (!station.affiliate && station.identifier != 'default') {
            response.programRoute = `/${station.identifier}/${gender}/${program.slug}`
          } else {
            response.programRoute = `/${gender}/${program.slug}`
          }
        } else {
          response.programRoute = `/${gender}/${program.slug}`
        }

        gender === 'jornalismo'
          ? (response.type = 'noticia')
          : (response.type = 'fiquepordentro')

        this.changeUrlState({ visible: true }, response)

        if (response.title) {
          this.title.setTitle(`${response.title.toString()} - SBT`)

          if (this.haveGallery(response)) {
            let galleryId = this.getGalleryId(response)

            this.getGalleryMedia(galleryId).then(images => {
              response.images = images
            })

            response.descriptions = response.descriptions.map(({ content, type }) => {
              return {
                content: this.removeSpecialStrings(content.toString()),
                type: type
              }
            })
          }

          this.notices.push(response)
          this.updateMetaTags(response)

          this._service.getNoticesByProgram(response).then(notices => {
            response.relatedNotices = [{ medias: notices }]
            this.relatedNoticesPagination = notices[0].pagination
          })

          let notice: Notice = this.notices[0]
          let script = this.renderer.createElement('script')
          let titleNotice = this.escapeHtml(notice.title)
          let descNotice = this.escapeHtml(notice.content)

          script.type = 'application/ld+json'
          script.id = 'newArticle'
          script.text = `
              {
                "@context": "http://schema.org",
                "@type": "NewsArticle",
                "author": [
                  {
                  "@type": "Person",
                  "name": "${notice.author}",
                  "url": "${SITE_LINK}"
                  }
                ],
                "headline": "${titleNotice}",
                "description": "${descNotice}",
                "isAccessibleForFree": true,
                "mainEntityOfPage": {
                  "@id": "${notice.pageLink}",
                  "@type": "WebPage"
                },
                "publisher":{
                  "@type":"Organization",
                  "name":"SBT",
                  "logo": {
                    "@type":"ImageObject",
                    "url": "https://www.sbt.com.br/assets/images/logo-sbt.png"
                  }
                },

                "image": [
                  "${notice.header}"
                ],
                "datePublished":"${notice.publishdatestring}-03:00",
                "dateModified":"${notice.publishdatestring}-03:00"
              }
          `

          this.renderer.appendChild(this._document.head, script)

          const ampLink = `${SOCIAL_CONTENT}/noticia/${notice.slug}`
          this.ampService.appendLink(ampLink)
          this.canonicalService.appendLink()

        }
        this.program = program

        this.program && this.program.gender && this.program.gender === "Jornalismo" ?
          this.itemsBreadcrumb = [{ route: '/jornalismo', title: 'Jornalismo' },
          { route: response.programRoute, title: response.program }] :
          this.itemsBreadcrumb = [{ route: response.programRoute, title: response.program }]

        this.setStationAdUnit()
      })
    })
  }

  getStationByRoute(): any {
    if (
      this.router.routerState.snapshot &&
      this.router.routerState.snapshot.root.children &&
      this.router.routerState.snapshot.root.children[0] &&
      this.router.routerState.snapshot.root.children[0].params
    ) {
      return this.router.routerState.snapshot.root.children[0].params.station
    }

    return null
  }

  updateMetaTags(response: any): void {
    this.metasService.removeTags(this.meta)
    const metatags = [
      { name: 'description', content: this.escapeHtml(response.content) },
      { name: 'keywords', content: response.keywords },
      { name: 'robots', content: response.idprogram != ID_SITE_SBTNEWS || response.idprogram != ID_SITE_SBT_BRASIL || response.idprogram != ID_SITE_PRIMEIRO_IMPACTO ? 'index, follow, max-image-preview:large' : 'noindex, nofollow' },
      { property: 'fb:app_id', content: '670550876335779' },
      { property: 'og:title', content: this.escapeHtml(response.title) },
      { property: 'og:description', content: this.escapeHtml(response.content) },
      { property: 'og:image', content: response.header },
      { property: 'og:image:secure_url', content: response.header },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image:width', content: '640' },
      { property: 'og:image:height', content: '440' },
      { property: 'og:type', content: 'article' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'theme-color', content: '#2D2F2F' }
    ]

    if (response.facebooknewsindexing && response.facebooknewsindexing === 'S') {
      if (response.facebooknewstags) {
        const faceboooknewstags = response.facebooknewstags.split(';');
        for (var i = 0; i < faceboooknewstags.length; i++) {
          metatags.push({ property: 'article:tag', content: faceboooknewstags[i] })
        }
      }
    }

    const metaTags = Array.from(document.getElementsByTagName('meta'));

    if (metaTags.length > 0) {
      metaTags.forEach(tag => {
        if (tag.dataset.noremove !== 'true') {
          tag.remove()
        }
      })
    }

    document.getElementsByTagName("title")[0].insertAdjacentHTML('afterend', `${metatags.map(meta => {
      if (meta.hasOwnProperty('name')) {
        return `<meta name="${meta.name}" content="${meta.content}" data-metatag></meta>`
      } else {
        return `<meta property="${meta.property}" content="${meta.content}" data-metatag></meta>`
      }
    }).join('')
      }`)

    // this.metasService.updateTags([ ...notice, ...metatags ], this.meta)
  }

  nextNotice() {
    const notice: Notice = this.notices[this.notices.length - 1]
    this.getNextNotice(notice)
  }

  getNextNotice(notice: any) {
    this._service.getNextNotice(notice).then((response: Notice | any) => {
      this.programService
        .getProgramById(response.idprogram.toString())
        .then(program => {
          const gender = generateSlug(program.gender)

          response.programHeader = program.header
          response.programRoute = `/${gender}/${program.slug}`

          gender === 'jornalismo'
            ? (response.type = 'noticia')
            : (response.type = 'fiquepordentro')

          this.program = program
          this.setStationAdUnit()
        })

      if (response.title) {
        if (this.haveGallery(response)) {
          let galleryId = this.getGalleryId(response)

          this.getGalleryMedia(galleryId).then(images => {
            response.images = images
          })

          response.descriptions = response.descriptions.map(({ content, type }) => {
            return {
              content: this.removeSpecialStrings(content.toString()),
              type: type
            }
          })
        }

        for (let i = 0; i < this.notices.length; i++) {
          if (this.notices[i].id === response.id) {
            this.getNextNotice(response)
            return
          }
        }

        this.isLoading = false
        this.notices.push(response)

        this._service
          .getNoticesByProgram(response, this.relatedNoticesPagination)
          .then(notices => {
            response.relatedNotices = [{ medias: notices }]
            this.relatedNoticesPagination = notices[0].pagination
          })
      }
    })
  }

  getGalleryMedia(idPlaylist: number): any {
    return this._service.getGalleryImages(idPlaylist).then(response => {
      return response
    })
  }

  getSlug(): string {
    let slug

    const routeSubscription = this.route.params.subscribe(params => {
      slug = params['slug']
    })

    this.subscriptions.push(routeSubscription)

    return slug
  }

  haveGallery(response: Notice): boolean {
    const galleryIndex = response.description
      ? response.description.indexOf('GALERIASBT')
      : -1
    const hasGallery = galleryIndex !== -1

    return hasGallery
  }

  getGalleryId(response: Notice): number {
    const contentRemains: string[] = response.description.split('GALERIASBT')

    const idInit: number = contentRemains[1].indexOf('(') + 1
    const idEnd: number = contentRemains[1].lastIndexOf(')')

    const id: string = contentRemains[1].substring(idInit, idEnd)

    return +id
  }
  hasPlaybuzz(description: string): boolean {
    return description.includes('<div class="playbuzz"') ? true : false
  }
  hasTwitter(description: string): boolean {
    return description.includes('<blockquote class="twitter-tweet"') ? true : false
  }
  hasInstagram(description: string): boolean {
    return description.includes('<blockquote class="instagram-media') ? true : false
  }

  escapeHtml(text: string): string {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    }
    return text.replace(/[&<>"']/g, function (m) { return map[m] })
  }

  closedBanner() {
    this.buttonClosedBanner = !this.buttonClosedBanner
  }
}
