import { Component, OnInit } from '@angular/core'
import { LiveService } from './live.service'
import { Router } from '@angular/router'
import { Meta, SafeResourceUrl, DomSanitizer} from '@angular/platform-browser'
import { StationService } from '../../services/station.service'
import { MetatagsService } from '../../services/metatags.service'
import { live } from '../../app.metatags'
import { TitleService } from 'src/app/services/title.service'
import { isMobile } from '../../utils/util.browser'
import { CanonicalService } from 'src/app/services/canonical.service'
import { BrandingService } from 'src/app/services/branding.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss'],
  providers: [LiveService, StationService, CanonicalService ]
})
export class LiveComponent implements OnInit {
  public categories: string[] = [
    'Todas',
    'Política',
    'Crime',
    'Cidades',
    'Esportes',
    'Internacionais'
  ]

  public videos: any[]
  public schedule: any[]
  public videosTitle: string
  public videoUrl: string = ''
  public videoIframe: string = ''
  public isMobile: boolean = false
  public stationAdUnit: string = ''

  public programTitle: string = ''
  public currentProgram: any

  public urlIframeNotYoutube: SafeResourceUrl
  public idIframe: string = ''
  public idVideo: string
  public typeVideo: string
  
  public playerHTML: string = ''


  constructor (
    private _service: LiveService,
    private router: Router,
    private title: TitleService,
    private stationService: StationService,
    private metasService: MetatagsService,
    private meta: Meta,
    private canonicalService: CanonicalService,
    private brandingService: BrandingService,
    private sanitizer: DomSanitizer
  ) {
    this.metasService.updateTags(live, this.meta)
  }

  ngOnDestroy() {
    this.brandingService.setBranding(null)
    this.canonicalService.removeLink()
  }

  ngOnInit () {
    this.title.setTitle(`Ao Vivo - SBT`)
    this.canonicalService.appendLink()
    this.isMobile = isMobile()
    this._service.getLiveVideoUrl().then(response => {
      if (response[0]) {

        this.typeVideo = response[0].opcional

        switch (this.typeVideo) {

          case 'youtube': {
            // console.log('youtube')
            this.videoUrl = response[0].description
              .match(/(https?:\/\/[^ ]*)/g)[0]
              .split('"').join('')

            break;
          }

          case 'mediastream': {
            // console.log('mediastream')
            this.idVideo = response[0].opcional5
            this._service.getMediaAoVivo(this.idVideo).then((response: any) => {
              if(response && response.data.access_token ) {
                const playerToken = response.data.access_token   
                this.playerHTML = this.sanitizer.bypassSecurityTrustHtml(`<iframe src="https://mdstrm.com/live-stream/${this.idVideo}?access_token=${playerToken}" frameborder="0" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" width="970" height="546" allowscriptaccess="always" scrolling="no"></iframe>`) as string           
              } 
            })

            break;
          }

          case 'outros': {
            // console.log('outros')
            this.playerHTML =this.sanitizer.bypassSecurityTrustHtml(response[0].description)  as string

            break;
          }
        }
      }
    })

    if (this.stationService.checkStationCookie()) {
      const stationIdentifier = this.stationService.getStationCookie()

      if (stationIdentifier) {
        const station = this.stationService.getStation(stationIdentifier)

        if (station && station[0]) {
          this.stationAdUnit = `_${station[0].adUnitName}`

          this.brandingService.setBranding(station[0].comscore.live)

        }
      }
    }

    this.getProgrammingGrade(100)
  }

  getProgrammingGrade(timeout: number): void {
    setTimeout(() => {
      this._service.getProgrammingGrade().then(schedule => {
        this.schedule = schedule
        this.currentProgram = this.schedule[0]

        if (this.currentProgram){
          this.programTitle = this.currentProgram.title

          this._service.getVideos(this.currentProgram).then(({ videos, title }) => {
            this.videos = videos
            this.videosTitle = title
          })
        }

        if (this.schedule[1] && this.schedule[1]['startdate']) {
          let timeout = this.getTimeoutNextProgram(this.schedule[1]['startdate'])
          this.getProgrammingGrade(timeout)
        }
      })
    }, timeout)

  }

  sendToPage (item: any): void {
    if (item.link && item.link.includes('sbt.com.br')) {
      item.link = item.link.replace('http://www.sbt.com.br/', '')

      if (item.link.includes('/')) {
        item.link = item.link.split('/')[1]
      }
    }

    this.router.navigate([`/${item.gender.toLowerCase()}/${item.link}`])
  }

  getHour (dateString: string): string {
    const date = new Date(dateString)

    return `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    }`
  }

  getTimeoutNextProgram(dateNextProgram: string): number {
    const dateNext = new Date(dateNextProgram)
    const dateNow = new Date()
    var seconds = (dateNext.getTime() - dateNow.getTime()) / 1000
    return (Math.floor(seconds) * 1000) + 30000
  }
}
