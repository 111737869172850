import { getWinVar, callWinFN } from '../utils/util.window'

const UpdateBotService = (user: any, timeout: number = 0): void => {
  if (window) {
    try {
      setTimeout(() => {
        const inBot = getWinVar('inbot')
        if ( inBot ) {
          // call bot update
          // setWinVar('bot_nameuser', user.name)
          // setWinVar('bot_email', user.email)
          callWinFN('inbot_callback_sbt', {user: user.name, email: user.email})
        }
      }, timeout)
    } catch(e) {
      console.log('Error on call UpdateBotService', e)
    }
  }
}

export { UpdateBotService }