import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { terms } from 'src/app/app.metatags';
import { CanonicalService } from 'src/app/services/canonical.service'
import { MetatagsService } from 'src/app/services/metatags.service';
import { TitleService } from 'src/app/services/title.service';
import { AnalyticsService } from '../../services/analytics.service'

@Component({
  selector: 'app-pwa-integra',
  templateUrl: './pwa-integra.component.html',
  styleUrls: ['./pwa-integra.component.scss'],
  providers: [CanonicalService]
})

export class PwaIntegraComponent implements OnInit {

    @Input('update-title')
    public updateTitle: boolean = true
    public textPrivacy: Observable<string>
    public deferredPrompt: any
    public pwaName: any
    public epochTime: any
    public pwaStatusInstalled: any
    public pwaStatusCancelled: any
    public pwaStatusNotInstalled: any
    public cache: any
    public expires: any

    @HostListener('window:beforeinstallprompt',['$event']) beforeInstallPwa(e) {
          e.preventDefault()
          this.deferredPrompt = e
        }

    @HostListener('window:appinstalled',['$event']) installPwa(e) {
          e.preventDefault()
          this.deferredPrompt = null
        }

    constructor (
        private title: TitleService,
        private meta: Meta,
        private metasService: MetatagsService,
        private canonicalService: CanonicalService,
        private analyticsService: AnalyticsService
      ) {
        this.metasService.updateTags(terms, this.meta)
        const time = Math.floor(Date.now() / 1000)
        this.deferredPrompt = null
        this.pwaName = 'installPwa'
        this.epochTime = time
        this.pwaStatusInstalled = 'installed'
        this.pwaStatusCancelled = 'cancelled'
        this.pwaStatusNotInstalled = 'not installed'
        this.expires = parseInt(this.epochTime + 2592000, 10)
      }
    
      ngOnDestroy(): void {
        this.canonicalService.removeLink()
      }
    
      ngOnInit () {
        this.canonicalService.appendLink()
        if (this.updateTitle) {
          this.title.setTitle('PWA - SBT')
        }

      }

      async instalePwa() {
      this.deferredPrompt.prompt()
      const { outcome } = await this.deferredPrompt.userChoice
      if (outcome === 'accepted') {
        this.deferredPrompt = null
      this.analyticsService.sendEvent('installed', 'app')
      } 
    }
}