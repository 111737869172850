import { Injectable } from '@angular/core'
import {
  ScrollToService,
  ScrollToConfigOptions
} from '@nicky-lenaers/ngx-scroll-to'
import { isMobile } from '../utils/util.browser';

@Injectable()
export class ScrollService {
  constructor(private _scrollToService: ScrollToService) { }

  scroll(): void {
    const config: ScrollToConfigOptions = {
      target: location.hash,
      offset: -75
    }

    this._scrollToService.scrollTo(config)
  }

  scrollToId(target: string, offset: number = -100) {
    const config: ScrollToConfigOptions = {
      target,
      offset,
      duration: 300
    }

    return this._scrollToService.scrollTo(config).toPromise()
  }

  scrollToOffset(offset: number): void {
    window.scrollTo({
      top: offset - 85,
      behavior: 'smooth'
    })
  }

  scrollToLive(offset: number) {
    const config: ScrollToConfigOptions = {
      offset,
      duration: 300
    }

    return this._scrollToService.scrollTo(config).toPromise()
  }
}
