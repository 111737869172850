import {
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy
} from '@angular/core'
import { StationService } from 'src/app/services/station.service'
import { Router, NavigationEnd } from '@angular/router'
import { FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss'],
  providers: [StationService]
})
export class StationComponent implements OnInit, OnDestroy {
  @ViewChild('station', {static: true})
  public station: ElementRef

  public stationsList: any[] = []
  public isAffiliate: boolean = false

  public currentStation: any
  public currentPage: string
  public stationLogo: string = ''
  public topLabel: string
  public routerSubscription: Subscription
  public stationField: FormControl

  private pageLabelsList = [
    { case: 'home', label: 'conteúdo' },
    { case: 'inscricoes', label: 'as inscrições' },
    { case: 'institucional', label: 'a página institucional' },
    { case: 'jornalismo', label: 'a página de jornalismo' },
    { case: 'ao-vivo', label: 'a página ao vivo' },
    { case: 'programas', label: 'os programas' },
    { case: 'programacao', label: 'a programação' },
    { case: 'promocao', label: 'as promoções' },
    { case: 'sbt-videos', label: 'os vídeos' },
    { case: 'servicos', label: 'os serviços' }
  ]

  constructor (
    private renderer: Renderer2,
    private stationService: StationService,
    private router: Router
  ) {}

  ngOnInit () {
    this.stationField = new FormControl()

    this.stationsList = [
      {
        identifier: null,
        title: 'Selecione uma opção'
      }
    ]

    this.stationsList.push(...this.stationService.getAllStations())

    this.buildTab()

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.buildTab()
      }
    })
  }

  ngOnDestroy () {
    this.routerSubscription.unsubscribe()
  }

  buildTab () {
    this.currentStation = this.stationService.getStation(this.stationService.getStationCookie());

    if (this.currentStation && this.currentStation.length > 0) {
      this.stationLogo = this.currentStation[0].logo

      this.stationField.setValue(this.currentStation[0].identifier)

      this.currentPage = this.getCurrentPage(this.currentStation[0].identifier)

      this.pageLabelsList.forEach((page: any) => {
        if (this.currentPage === page.case) {
          this.topLabel = `Você está vendo ${page.label} de:`
        }
      })
    }
  }

  getCurrentPage (station: string): string {
    const url: string[] = window.location.href.split('/')

    if (url.includes('afiliada')) {
      return url[5] || 'home'
    }

    if (url[3] !== station) {
      return url[3] || 'home'
    }


    return url[4] || 'home'
  }

  changeStation (event) {
    const stationIdentifier: string = event.target.value

    if (stationIdentifier === 'default') {
      this.stationService.deleteStationCookie()
      this.stationLogo = ''
      // this.router.navigate(['/'])

      // this.currentStation = this.stationService.getStation(value)

      window.location.href = '/'

      return
    }

    this.currentStation = this.stationService.getStation(stationIdentifier)
    this.stationLogo = this.currentStation[0].logo
    this.stationService.setStationCookie(stationIdentifier)

    if (this.currentStation[0]){
      if (this.currentStation[0].affiliate) {
        window.location.href = `/afiliada/${stationIdentifier}`
        return
      }

      window.location.href = `/${stationIdentifier}/home`
      // this.router.navigate([`/${stationIdentifier}/home`])
      return
    }

    // this.currentStation[0].affiliate
    //   ? this.router.navigate([`/afiliada/${value}`])
    //   : this.router.navigate([`/${value}/home`])

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll (e) {
    if (window.pageYOffset > 50) {
      this.renderer.addClass(this.station.nativeElement, 'sticky')
    } else {
      this.renderer.removeClass(this.station.nativeElement, 'sticky')
    }

  }

  openStation (): void {
    if (window.pageYOffset > 50) {
      if (this.station.nativeElement.classList.contains('sticky')) {
        this.renderer.removeClass(this.station.nativeElement, 'sticky')

        return
      }

      this.renderer.addClass(this.station.nativeElement, 'sticky')
    }

  }
  
}
