import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnChanges,
  OnDestroy
} from '@angular/core'
import { ProgramService } from '../../pages/program/program.service'
import { ActivatedRoute, Router} from '@angular/router'
import { generateTitleSlug } from 'src/app/utils/utils.generators'

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ProgramService]
})
export class ListComponent implements OnInit, OnChanges, OnDestroy {
  @Input('has-search')
  public hasSearch: boolean = false

  @Input('is-accordion')
  public isAccordion: boolean = false

  @Input('has-subtitle')
  public hasSubtitle: boolean = false

  @Input('has-action')
  public hasAction: boolean = false

  @Input('accordion-with-card')
  public accordionWithCard: boolean = false

  @Input('title')
  public title: string

  @Input('content')
  public content: any[]

  @Input('endpoint')
  public endpoint: string

  @Input('program-id')
  public idProgram: number

  @Input('program-slug')
  public programSlug: string

  public validComponents: string[] = ['ganhadores', 'quadros', 'inscricoes']
  public contentList: any[]
  public hideSearch: boolean = true
  public currentContent: number
  public lastIndex: number = 0
  public itemsPerPage: number = 5
  public hasMore: boolean

  public storedContent: any[]
  public filterTerm: string = ''

  public subscriptions = []

  constructor(
    private _service: ProgramService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnDestroy () {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(params => {
      if (params.component) {
        if (
          this.validComponents.indexOf(params.component.toLowerCase()) !== -1
        ) {
          let index: number = 0

          index = this.content.findIndex(
            item => item.title.toLowerCase() === params.value
          )

          if (index === -1) {
            this.currentContent = 0
          }

          if (index < this.itemsPerPage) {
            this.currentContent = index
          }

          if (index > this.itemsPerPage) {
            this.moveArrayPosition(index, 0)
            this.currentContent = 0
          }
        }
      }
    })

    this.subscriptions.push(routeSubscription)
  }

  ngOnChanges(changes) {
    if (changes.content) {
      if (changes.content.currentValue) {
        this.content = changes.content.currentValue

        this.prepareList()
      }
    }
  }

  prepareList() {
    if (this.content.length > this.itemsPerPage) {
      this.contentList = this.content.splice(0, this.itemsPerPage)
      this.hasMore = true

      return
    }

    this.contentList = this.content
  }

  moveArrayPosition(from: number, to: number): void {
    this.content.splice(to, 0, this.content.splice(from, 1)[0])
  }

  toggleSearch (): void {
    this.hideSearch = !this.hideSearch

    if (this.hideSearch && this.storedContent) {
      this.contentList = this.storedContent
      this.filterTerm = ''
    }
  }

  setCurrentContent(index: number): void {
    if (this.currentContent === index) {
      this.currentContent = -1

      return
    }

    this.currentContent = index
  }

  sendToInscriptions(content): string {
    const title = generateTitleSlug(content.title);
    return `/inscricoes/${this.programSlug}/${content.id}/${title}`
  }

  search (term: string): void {
    if (!this.filterTerm) {
      this.storedContent = this.contentList
    }

    if (this.filterTerm && !term && this.storedContent) {
      this.contentList = this.storedContent
      this.storedContent = null
      this.filterTerm = ''
    }

    const content = this.hasMore ? [...this.contentList,...this.content ] : this.content

    this.contentList = content
      .filter(frame => {
        frame.title = frame.title.replace('<strong>', '')
        frame.title = frame.title.replace('</strong>', '')
        const index = frame.title.toLowerCase().indexOf(term.toLowerCase())

        if (index !== -1) {
          const firstIndex = index
          const lastIndex = index + term.length
          const termLenght = term.length

          let { title } = frame
          title = `${title.substr(0, firstIndex)}<strong>${title.substr(
            index,
            termLenght
          )}</strong>${title.substr(lastIndex)}`
          frame.title = title

          return frame
        }
      })
      .splice(0, 5)

    this.filterTerm = term
  }

  loadMoreContent(): void {
    if (this.content.length > this.itemsPerPage) {
      this.contentList.push(...this.content.splice(0, this.itemsPerPage))
      this.hasMore = true

      return
    }

    this.contentList.push(...this.content)
    this.content = []

    return
  }
}
