import { Component, OnInit, Input, Pipe, PipeTransform } from '@angular/core'
import { Meta, DomSanitizer } from '@angular/platform-browser'
import { terms } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { TitleService } from 'src/app/services/title.service'
import { TermsService } from './terms-partner.service'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-terms-partner',
  templateUrl: './terms-partner.component.html',
  styleUrls: ['./terms-partner.component.scss']
})
export class TermsPartnerComponent implements OnInit {

  @Input('update-title')
  public updateTitile: boolean = true  
  public textTermos$: Observable<string>

  constructor (
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService,
    private termo : TermsService

  ) {
    this.metasService.updateTags(terms, this.meta)
  }

  ngOnInit() {
    if (this.updateTitile) {
      this.title.setTitle('Termos de Privacidade Para Empresas Parceiras')
      this.textTermos$ = this.termo.getTerms()  
    }else{
      this.textTermos$ = this.termo.getTerms()
    }
  }

}
