import { Injectable } from '@angular/core'
import { User } from '../models/user.class'
import { URL_USERS_API, API_GATEWAY_TOKEN } from '../app.settings'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private _http: HttpClient) {}

  errorHandler({ error }) {
    const errorText: string = error.error

    throw new Error(errorText)
  }

  sendEmail(data: any): Promise<any> {
    return this._http
      .post<any>(`${URL_USERS_API}/api/send_email`, data)
      .toPromise()
      .then(response => {
        return response
      })
      .catch(this.errorHandler)
  }

}
