export const isIE = () => {
  if (typeof navigator === 'undefined') return;
  const ua = navigator.userAgent;
  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
};

export const isMobile = () => {
  if (typeof document === 'undefined') return;
  const viewWidth = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return viewWidth <= 900;
};
