import { Injectable } from '@angular/core'
import { URL } from '../../app.settings'
import { Inscription } from '../../models/inscription.class'
import { Video } from '../../models/video.class'
import Home from '../../models/home.class'
import Program from '../../models/program.class'
import Station from '../../models/station.class'
import { StationService } from '../../services/station.service'
import Pagination from '../../models/pagination.class'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor (private _http: HttpClient, private _stationService: StationService) {}

  getQuickAccessMenu(): any {
    return {
      items: [
        { id: 'programming-grid', title: 'Programação', showElement: true, order: 0 },
        { id: 'inscriptions', title: 'Inscrições', showElement: true, order: 1 },
        { id: 'programFav', title: 'Programa Favorito', showElement: false, order: 2 },
        { id: 'station', title: 'Destaques', showElement: false, order: 3 },
        { id: 'playlists', title: 'Playlists', showElement: false, order: 4 },
        { id: 'high', title: 'Em Alta!', showElement: true, order: 20 },
        { id: 'videos', title: 'Últimos Vídeos', showElement: true, order: 21 },
        //{ id: 'programs', title: 'Programas', showElement: true, order: 22 }
      ],
      initialOrderValue: 5
    }
  }

  getData (station: any, programFav: string): any {
    let videos = []
    
    if (station && station !== null) {
      videos.push(
        this._http
          .get<any>(
            `${URL}/api/videosyoutube?limit=5&highlighted=s&regional=${
              station.id
            }`
          )
          .toPromise()
      )
    }

    videos.push(
      this._http
        .get<any>(
          `${URL}/api/videosyoutube?limit=12&highlighted=s&videoshomenacional=true`
        )
        .toPromise()
    )

    const inscriptions = this._http
      .get<any>(
        `${URL}/api/medias?idplaylist=6170&limit=12&searchopcional2=s&orderby=ordem&sort=desc`
      )
      .toPromise()

    const requests = [...videos, inscriptions]

    return Promise.all(requests).then((response: any) => {
      const home = new Home(null)

      // get the dynamic playlists at home
      this._http.get(`${URL}/api/playlists?idsitearea=2376&orderby=startdate&sort=desc&limit=60`)
        .toPromise()
        .then(async (response: any) => {
          home.playlists = []

          const playlistsList = await response.results.map(playlist => playlist)

          const removeDuplicates = async playlists =>
            await playlists
              .map(playlist => playlist.title)
              .map((playlist, index, final) => final.indexOf(playlist) === index && index)
              .filter(index => playlists[index])
              .map(index => playlists[index])

          const getId = title => {
            let id: string = title.replace(/[^a-zA-Z ]/g, '')
            id = id.split(' ').join('-')
            id = id.toLowerCase()

            return id
          }

          const playlists = await removeDuplicates(playlistsList)
          const quickAccessMenu = this.getQuickAccessMenu()

          home.quickAccessMenu = quickAccessMenu.items

          await playlists.forEach(async (playlist, index) => {
            await this._http.get(`${URL}/api/playlists?idsitearea=2376&playlisttitle=${encodeURIComponent(playlist.title)}&limit=1&orderby=startdate`)
              .toPromise()
              .then(response => {
                const id: string = getId(playlist.title)

                const quickAccessMenuItem = {
                  id,
                  title: playlist.title,
                  showElement: true,
                  order: index + quickAccessMenu.initialOrderValue
                }

                home.quickAccessMenu.push(quickAccessMenuItem)

                return response
              })
              .then((response: any) => new Pagination(response.next))
              .then(async pagination => {
                return await this.getCustomMedia(`idplaylist=${playlist.id}&orderby=ordem&sort=desc`)
                  .then(
                    async medias => {
                      if (pagination.hasMore) {
                        playlist.pagination = pagination
                        playlist.nextQuery = `${URL}/api/playlists?idsitearea=2376&playlisttitle=${encodeURIComponent(playlist.title)}&limit=1&orderby=startdate`
                      }

                      const id: string = getId(playlist.title)

                      return {
                        title: playlist.title,
                        link: playlist.description,
                        color: playlist.opcionalplaylist3,
                        adUnitBlock: `bloco-${index + 1}`,
                        order: index,
                        id,
                        medias: [
                          {
                            ...playlist,
                            medias
                          }
                        ]
                      }
                    }
                  )
              })
              .then(playlist => {
                home.playlists.push(playlist)

                return playlist
              })
              .then(async playlist => {
                const sortPlaylistsByOrder = (firstPlaylist, nextPlaylist) => {
                  if (firstPlaylist.order < nextPlaylist.order) return -1
                  if (firstPlaylist.order > nextPlaylist.order) return 1

                  return 0
                }

                await home.playlists.sort(sortPlaylistsByOrder)

                return playlist
              })
          })
        })

      if (response.length > 2) {
        home.videos = response[0].results.map(
          video => new Video(video)
        )

        home.videos.push(
          ...response[1].results.map(
            video => new Video(video)
          )
        )

        home.inscriptions = response[2].results.map(
          inscription => new Inscription(inscription, null)
        )
      } else {
        home.videos = response[0].results.map(
          video => new Video(video)
        )

        home.inscriptions = response[1].results.map(
          inscription => new Inscription(inscription, null)
        )
      }

      this.getPrograms().then(programs => (home.programs = programs))

      if (station && station !== null) {
        this.getAreaByStation(station.idsite, station.idsitearea).then(
          stationData => {
            home.station = new Station({
              title: station.title,
              notices: stationData
            })
          }
        )
      }

      if (programFav && programFav !== null && programFav !== "null") {
        this._http
          .get<any>(
            `${URL}/api/notices?program=${programFav}&limit=3&orderby=publishdate&sort=desc`
          )
          .toPromise()
          .then((response: any) => {
            const notices = response.results

            const program = notices[0].program
            const idProgram = notices[0].idprogram

            this._http
              .get<any>(`${URL}/api/programs?id=${idProgram}`)
              .toPromise()
              .then(async (response: any) => {
                const responseJSON = await response
                  .results[0]

                home.programFavTitle = await program
                home.programFavRoute = await `${responseJSON.gender.toLowerCase()}/${
                  responseJSON.slug
                }`

                const programFavData = [
                  {
                    opcionalplaylist2: 'modulo-trio',
                    medias: notices.slice(0, 3)
                  }
                ]

                home.programFav = programFavData
              })
          })
      }

      this.getHighlightedList().then(high => (home.inHigh = high))

      this.getMediabox().then(mediabox => (home.mediabox = mediabox))

      home.programmingGrid = { days: [], programs: [] }

      home.programmingGrid.days = this.generateDaysGrid()

      const date = home.programmingGrid.days[0].formattedDate

      if (date) {
        this.getSchedule(date, station).then(
          schedule => (home.programmingGrid.programs = schedule)
        )
      }

      return home
    })
  }

  async getMediabox (): Promise<any> {
    const mediaboxMedias: any[] = []

    const hasStationCookie: boolean = await this._stationService.checkStationCookie()

    if (hasStationCookie) {
      const stationIdentifier: string = await this._stationService.getStationCookie()

      const station: any = await this._stationService.getStation(stationIdentifier)

      if (station && station[0]) {
        await this._http
          .get<any>(`${URL}/api/medias?idsitearea=${station[0].idsiteareamediabox}&limit=10&orderby=ordem&sort=asc`)
          .toPromise()
          .then(async (response: any) => {
            if (response) {
              const results = await response.results

              await mediaboxMedias.push(...results)
            }
          })
      }
    }

    await this._http
      .get<any>(`${URL}/api/medias?limit=10&idsitearea=2091&orderby=ordem&sort=asc`)
      .toPromise()
      .then((response: any) => {
        if (response) {
          const results = response.results

          mediaboxMedias.push(...results)
        }

        return null
      })

    return mediaboxMedias
  }

  getPrograms (): Promise<Program[]> {
    return this._http
      .get<any>(
        `${URL}/api/programs?showathome=S&noar=S&idregional=0`
      )
      .toPromise()
      .then((response: any) => {
        const programs: any = response

        const hasStationCookie: boolean = this._stationService.checkStationCookie()

        if (hasStationCookie) {
          const stationIdentifier: string = this._stationService.getStationCookie()

          const station: any = this._stationService.getStation(
            stationIdentifier
          )

          if (station && station[0]) {
            return this._http
              .get<any>(
                `${URL}/api/programs?showathome=S&noar=S&idregional=${
                  station[0].id
                }`
              )
              .toPromise()
              .then((regionalResponse: any) => {
                const regionalPrograms: any =
                  regionalResponse.results.map(program => new Program(program))
                const nationalPrograms: any = programs.results.map(
                  program => new Program(program)
                )

                return [...regionalPrograms, ...nationalPrograms]
              })
          }
        }

        return programs.results.map(program => new Program(program))
      })
      .catch(e => console.log(e))
  }

  getSchedule (date: string, station: any): Promise<any> {
    if (station) {
      return this._http
        .get<any>(
          `${URL}/api/programscheduleregionais?datagrade=${date}&idregional=${
            station.id
          }&limit=49`
        )
        .toPromise()
        .then((response: any) => {
          const schedules = response.results

          /* if (schedules.length === 0) {
            return this._http
              .get<any>(
                `${URL}/api/programgrade?datagrade=${date}&limit=50`
              )
              .toPromise()
              .then((response: any) => {
                const schedules = response.results

                return schedules
              })
          } */

          return schedules
        })
    }

    return this._http
      .get<any>(
        `${URL}/api/programgrade?datagrade=${date}&limit=49`
      )
      .toPromise()
      .then((response: any) => {
        const schedules = response.results

        return schedules
      })
  }

  getArea (title: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/playlists?idsitearea=2376&playlisttitle=${title}&limit=3&orderby=startdate`
      )
      .toPromise()
      .then((response: any) => {
        const result: any = response
        const playlists: any[] = result.results
        const pagination: Pagination = new Pagination(result.next)
        playlists.forEach(playlist => {
          if (pagination.hasMore) {
            playlist.pagination = pagination
            playlist.nextQuery = `${URL}/api/playlists?idsitearea=2376&playlisttitle=${title}&limit=1&orderby=startdate`
          }

          this.getCustomMedia(`idplaylist=${playlist.id}`).then(
            medias => (playlist.medias = medias)
          )
        })

        return playlists
      })
  }

  getAreaByStation (idsite: number, idsitearea: number): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/playlists?idsitearea=${idsitearea}&idsite=${idsite}&limit=3&orderby=startdate`
      )
      .toPromise()
      .then((response: any) => {
        const result: any = response
        const playlists: any[] = result.results
        const pagination: Pagination = new Pagination(result.next)
        playlists.forEach(playlist => {
          if (pagination.hasMore) {
            playlist.pagination = pagination
            playlist.nextQuery = `${URL}/api/playlists?idsitearea=${idsitearea}&idsite=${idsite}&limit=1&orderby=startdate`
          }
          this.getCustomMedia(`idplaylist=${playlist.id}&orderby=ordem&sort=desc`).then(
            medias => (playlist.medias = medias)
          )
        })

        return playlists
      })
  }

  getCustomMedia (queryString: string): any {
    return this._http
      .get<any>(`${URL}/api/medias?${queryString}&limit=10`)
      .toPromise()
      .then((response: any) => {
        const medias: any = response.results

        return medias
      })
  }

  getInscriptions (): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/medias?idplaylist=6170&searchopcional2=s`
      )
      .toPromise()
      .then((response: any) => {
        const inscriptions = response

        return inscriptions.results.map(
          inscription => new Inscription(inscription, inscriptions.next)
        )
      })
  }

  getVideos (): Promise<any> {
    return this._http
      .get<any>(`${URL}/api/videosyoutube?limit=12`)
      .toPromise()
      .then((response: any) => {
        const videos = response

        return videos.results.map(video => new Video(video))
      })
  }

  getHighlightedList () {
    return this._http
      .get<any>(`${URL}/api/highlighted?type=entretenimento&limit=6`)
      .toPromise()
      .then((response: any) => {
        const videos = response

        return videos.results
      })
  }

  generateDaysGrid (): any[] {
    let dates: any[] = []
    for (let i = 0; i < 7; i++) {
      let date = new Date()

      date.setDate(date.getDate() + i)

      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      const month =
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1

      const year = date.getFullYear()

      let dateString = `${day}/${month}`

      let dayString = this.getStringDay(date.getDay())

      let formattedDate = `${year}-${month}-${day}`

      dates.push({
        title: dayString,
        date: dateString,
        formattedDate
      })
    }

    return dates
  }

  getStringDay (dayIndex: number): string {
    switch (dayIndex) {
      case 0:
        return `DOM`

      case 1:
        return `SEG`

      case 2:
        return `TER`

      case 3:
        return `QUA`

      case 4:
        return `QUI`

      case 5:
        return `SEX`

      case 6:
        return `SÁB`

      default:
        return ``
    }
  }
}
