import { Injectable } from '@angular/core'

import { URL, API_GATEWAY_TOKEN } from '../../app.settings'
import { About } from '../../models/about.class';
import { StayInsideNotice } from '../../models/stayInsideNotice.class';
import { Video } from '../../models/video.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrophyPressService {

  constructor (private _http: HttpClient) {}

  getProgram (slug: string): Promise<any> {
    return this._http
      .get<any>(
        `${URL}/api/programs?slug=trofeu-imprensa&limit=1`,
      )
      .toPromise()
      .then(async (response: any) => {
        const program = response.results[0]

        if (!program) {
          return {
            hasContent: false,
            loaded: true
          }
        }

        if (program.components) {
          const idSite = program.idsite
          const { components } = program

          await components.push({
            type: 'judges-with-editions',
            idSiteArea: 806
          })

          await components.push({
            type: 'winners-with-editions',
            idSiteArea: 807
          })

          await components.push({
            type: 'photos-with-editions',
            idSiteArea: 809
          })

          await components.forEach(async component => {
            const { idSiteArea, idPlaylist } = component

            switch (component.type) {
              case 'header':
                this.getProgramHeader(idSiteArea, idPlaylist).then(
                  programs =>
                    (program.header =
                      programs.results[0].image || program.header)
                )
                break

              case 'about':
                await this.getProgramAbout(idSite, idSiteArea, idPlaylist).then(
                  about => {
                    if (about && about[0]) {
                      about[0].description = `<strong><i>HORÁRIO DE EXIBIÇÃO:</strong> ${program.showtimes.toUpperCase()}</i><br/><br/>${
                        about[0].description
                      }`

                      program.about = new About(about[0])
                    } else {
                      const description = `<strong><i>HORÁRIO DE EXIBIÇÃO:</strong> ${program.showtimes.toUpperCase()}</i><br/><br/>${
                        program.description
                      }`

                      program.about = new About({
                        title: program.title,
                        description,
                        thumbnail: program.header
                      })
                    }
                  }
                )
                break

              case 'notices':
                await this.getProgramNotices(program.id).then(
                  notices =>
                    (program.notices = notices.results.map(
                      notice => new StayInsideNotice(notice, notices.next)
                    ))
                )
                break

              case 'videos':
                await this.getProgramVideos(program.id).then(
                  videos => program.videos = [videos.results.map(video => new Video(video))])
                break

              case 'judges-with-editions':
                let years: number[] = [
                  2017,
                  2016,
                  2015,
                  2015,
                  2014,
                  2013,
                  2012,
                  2011,
                  2010,
                  2009,
                  2008
                ]

                const yearsWithPlaylists = [
                  { year: 2017, idplaylist: 8669 },
                  { year: 2016, idplaylist: 7279 },
                  { year: 2015, idplaylist: 6863 },
                  { year: 2014, idplaylist: 4790 },
                  { year: 2013, idplaylist: 3299 },
                  { year: 2012, idplaylist: 2357 },
                  { year: 2011, idplaylist: 2375 },
                  { year: 2010, idplaylist: 2376 },
                  { year: 2009, idplaylist: 2377 },
                  { year: 2008, idplaylist: 2378 }
                ]

                program.judgesSiteArea = idSiteArea
                program.judgesEditionYears = years
                program.judgesEditionYearsAndPlaylists = yearsWithPlaylists

                await this.getProgramJudges(
                  idSite,
                  idSiteArea,
                  yearsWithPlaylists[0].idplaylist
                ).then(judges => (program.judges = judges))

                break

              case 'winners-with-editions':
                let winnersYears: number[] = [
                  2017,
                  2016,
                  2015,
                  2015,
                  2014,
                  2013,
                  2012,
                  2011,
                  2010,
                  2009,
                  2008
                ]

                const winnersYearsWithPlaylists = [
                  { year: 2017, idplaylist: 8673 },
                  { year: 2016, idplaylist: 7281 },
                  { year: 2015, idplaylist: 6864 },
                  { year: 2014, idplaylist: 4794 },
                  { year: 2013, idplaylist: 3297 },
                  { year: 2012, idplaylist: 2358 },
                  { year: 2011, idplaylist: 2371 },
                  { year: 2010, idplaylist: 2372 },
                  { year: 2009, idplaylist: 2373 },
                  { year: 2008, idplaylist: 2374 }
                ]

                program.winnersSiteArea = idSiteArea
                program.winnersEditionYears = winnersYears
                program.winnersEditionYearsAndPlaylists = winnersYearsWithPlaylists

                await this.getProgramWinners(
                  idSite,
                  idSiteArea,
                  winnersYearsWithPlaylists[0].idplaylist
                ).then(winners => {
                  program.winners = winners

                  winners.forEach(winner => {
                    let html: string

                    if (winner.author && winner.author.includes('[*]')) {
                       html = `
                        <h2>${winner.author.replace('[*]', '')} </h2>
                        <br>
                        <strong>Outros participantes: </strong>
                        <br>
                        <span>${winner.description}</span>
                        <br>
                        <span>${winner.opcional}</span>`
                    }

                    if (winner.description && winner.description.includes('[*]')) {
                      html = `
                       <h2>${winner.description.replace('[*]', '')} </h2>
                       <br>
                       <strong>Outros participantes: </strong>
                       <br>
                       <span>${winner.author}</span>
                       <br>
                       <span>${winner.opcional}</span>`
                   }

                   if (winner.opcional && winner.opcional.includes('[*]')) {
                      html = `
                       <h2>${winner.opcional.replace('[*]', '')} </h2>
                       <br>
                       <strong>Outros participantes: </strong>
                       <br>
                       <span>${winner.author}</span>
                       <br>
                       <span>${winner.description}</span>`
                    }

                    winner.description = html
                  })
                })

                break

              case 'photos-with-editions':
                let editions: string[] = [
                  'Edição 59',
                  'Edição 58',
                  'Edição 57',
                  'Edição 56',
                  'Edição 55',
                  'Edição 54',
                  'Edição 53'
                ]

                const photosWithPlaylists = [
                  { year: 'Edição 59', idplaylist: 7280 },
                  { year: 'Edição 58', idplaylist: 6871 },
                  { year: 'Edição 57', idplaylist: 4796 },
                  { year: 'Edição 56', idplaylist: 3296 },
                  { year: 'Edição 55', idplaylist: 2360 },
                  { year: 'Edição 54', idplaylist: 2366 },
                  { year: 'Edição 53', idplaylist: 2367 }
                ]

                program.photosSiteArea = idSiteArea
                program.photosEditions = editions
                program.photosEditionsAndPlaylists = photosWithPlaylists

                await this.getProgramPhotos(
                  idSite,
                  idSiteArea,
                  photosWithPlaylists[0].idplaylist
                ).then(photos => (program.photos = photos))
                break
            }
          })

          return program
        }
      })
  }

  getProgramHeader (
    idsitearea: number,
    idplaylist: number
    ) {
    return this._http
      .get(
        `${URL}/api/medias?idsitearea=${idsitearea}&idplaylist=${idplaylist}`
      )
      .toPromise()
      .then((response: any) => response)
  }

  getProgramAbout (
    idsite: number,
    idsitearea: number,
    idplaylist: number = null
  ) {
    return this._http
      .get<any>(
        `${URL}/api/medias?idsite=${idsite}&idsitearea=${idsitearea}${
          idplaylist !== null ? `&idplaylist=${idplaylist}` : ''
        }&limit=1`
      )
      .toPromise()
      .then((response: any) => {
        const about = response.results

        return about
      })
  }

  getProgramNotices (programId: number) {
    return this._http
      .get<any>(`${URL}/api/notices?program=${programId}limit=4&orderby=publishdate&sort=desc`)
      .toPromise()
      .then((response: any) => {
        const notices = response

        return notices
      })
  }

  getProgramVideos (programId: number) {
    return this._http
      .get(
        `${URL}/api/videosyoutube?programid=${programId}&limit=20`
      )
      .toPromise()
      .then((response: any) => response)
  }

  getProgramJudges (idSite: number, idSiteArea: number, idPlaylist: number) {
    return this._http
      .get(
        `${URL}/api/medias?idsite=${idSite}&idsitearea=${idSiteArea}&idplaylist=${idPlaylist}&limit=30`
      )
      .toPromise()
      .then((response: any) => response.results)
  }

  getProgramWinners (idSite: number, idSiteArea: number, idPlaylist: number) {
    return this._http
      .get(
        `${URL}/api/medias?idsite=${idSite}&idsitearea=${idSiteArea}&idplaylist=${idPlaylist}&limit=50`,
      )
      .toPromise()
      .then((response: any) => response.results)
  }

  getProgramPhotos (idSite: number, idSiteArea: number, idPlaylist: number) {
    return this._http
      .get(
        `${URL}/api/medias?idsite=${idSite}&idsitearea=${idSiteArea}&idplaylist=${idPlaylist}&limit=30`,
      )
      .toPromise()
      .then((response: any) => response.results)
  }
}
