import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import Program from '../../models/program.class'
import { ProgramService } from 'src/app/pages/program/program.service'
import { Winner } from 'src/app/models/winner.class'
import { Character } from 'src/app/models/character.class'
import { HOTSITE_TROFEUINTERNET } from 'src/app/app.settings'
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { isMobile } from 'src/app/utils/util.browser'
import { Subscription, Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'app-program-wrapper',
  templateUrl: './program-wrapper.component.html',
  styleUrls: ['./program-wrapper.component.scss']
})
export class ProgramWrapperComponent implements OnDestroy {
  @Input('type')
  public type: string

  @Input('title')
  public title: string

  @Input('program')
  public program: Program

  @Input('aboutAdIndex')
  public aboutAdIndex: number

  @Input('station-ad-unit')
  public stationAdUnit: string = ''

  public hotsiteVoteUrl = `${HOTSITE_TROFEUINTERNET}/?origem=SBT`
  public hotsiteVoteUrlSanitized: SafeResourceUrl

  @Output()
  public updateUrlState = new EventEmitter()

  public subscription: Subscription
  public debouncer: Subject<any> = new Subject<any>()

  constructor (private sanitizer: DomSanitizer, private programService: ProgramService) {
    this.hotsiteVoteUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.hotsiteVoteUrl)
    this.subscription = this.debouncer
      .pipe(debounceTime(500))
      .subscribe(title => this.updateUrlState.emit(title))
  }

  ngOnDestroy () {
    this.debouncer.unsubscribe()
  }

  changeWinners ({ title }) {
    const edition = this.program.winnersEditions.filter(
      edition => edition.title === title.title
    )

    if (edition) {
      const { idsitearea, id } = edition[0]

      const queryParams = {
        idsitearea,
        idPlaylist: id,
        limit: 70
      }

      this.programService
        .getMedias(queryParams)
        .then(winners => {
          this.program.winners = winners.results.map(winner => new Winner(winner, winners.next))
        })
    }
  }

  changeCharacters ({ title }) {
    const edition = this.program.charactersEditions.filter(
      edition => edition.title === title.title
    )

    if (edition) {
      const { idsitearea, id } = edition[0]

      const queryParams = {
        idsitearea,
        idPlaylist: id,
        limit: 70
      }

      this.programService
        .getMedias(queryParams)
        .then(characters => {
          this.program.characters = characters.results.map(character => new Character(character))
        })
    }
  }

  changeGallery ({ title }) {
    const edition = this.program.galleryEditions.filter(
      edition => edition.title === title.title
    )

    if (edition) {
      const { idsitearea, id } = edition[0]

      const queryParams = {
        idsitearea,
        idPlaylist: id,
        limit: 70
      }

      this.programService
        .getMedias(queryParams)
        .then(response => {
          this.program.gallery = response.results
        })
    }
  }

  setPageView ({ visible }, title) {
    if (visible) {
      if (['Notícias', 'Fique por Dentro'].includes(title) && !isMobile()) {
        return
      }

      this.debouncer.next(title)
    }
  }
}
