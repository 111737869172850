import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DOMAIN_SBT_TV } from '../app.settings'

@Injectable({
    providedIn: 'root'
})
export class RedirectProgramsService implements CanActivate {
    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        window.location.href = route.params.hasOwnProperty('slug') ?
            `${DOMAIN_SBT_TV}/programas/${route.url.join('/')}` :
            `${DOMAIN_SBT_TV}/programas`
        return true;
    }
}
