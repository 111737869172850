export class Character {
  public title: string
  public description: string
  public thumbnail: string
  public realName: string

  constructor (object) {
    this.title = object.title
    this.description = object.description
    this.thumbnail = object.thumbnail
    this.realName = object.opcional
  }
}
