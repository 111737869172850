import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { URL, DOMAIN_SBT_TV } from '../app.settings'
import { HttpClient } from '@angular/common/http';
 
@Injectable({
    providedIn: 'root'
})
export class RedirectInscriptionsService implements CanActivate {
    constructor(private _http: HttpClient, private router: Router) { }
 
    getGenderProgram(slug: string): Promise<any> {
        return this._http
            .get<any>(`${URL}/api/programs?idregional=0&noar=S`)
            .toPromise()
            .then((response: any) => {
                const genderProgram = response.results.find(program => program.slug === slug)
                    .gender.normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
 
                return genderProgram
            })
            .catch(e => console.log(e))
    }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const slugInscription = route.params.hasOwnProperty('slug')
 
        if (!slugInscription) {
            window.location.href = `${DOMAIN_SBT_TV}/inscricoes`
        } else {
            this.getGenderProgram(route.params.slug)
                .then((genderProgram) => {
                    window.location.href = `${DOMAIN_SBT_TV}/programas/${genderProgram}/${route.params.slug}/inscricoes/${route.params.inscription}/${route.params.inscriptionTitle}`
                })
                .catch((error) => {
                    console.error('Erro ao obter genderProgram:', error);
                });
        }
 
        return true;
    }
}