import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-mediabox',
  templateUrl: './mediabox.component.html',
  providers: [],
  styleUrls: ['./mediabox.component.scss']
})
export class MediaboxComponent implements OnInit {
  @Input('medias')
  public medias: any[]

  @Input('type')
  public type: string

  @Input('extra-classes')
  public extraClasses: string[]

  public currentIndex
  public interval

  private swipeCoord?: [number, number]
  private swipeTime?: number

  constructor () {}

  ngOnInit () {
    this.currentIndex = 0

    this.interval = setInterval(() => {
      this.next()
    }, 10000)
  }

  next (): void {
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.next()
    }, 10000)
    this.currentIndex < this.medias.length - 1 ? this.currentIndex++ : this.currentIndex = 0
  }
  

  prev (): void {
    clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.next()
    }, 10000)
    this.currentIndex > 0 ? this.currentIndex-- : this.currentIndex = this.medias.length - 1
  }

  showImage (newIndex: number): void {
    this.currentIndex = newIndex
  }

  swipe (e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY]
    const time = new Date().getTime()

    if (when === 'start') {
      this.swipeCoord = coord
      this.swipeTime = time
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]]
      const duration = time - this.swipeTime

      if (duration < 1000
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        direction[0] < 0 ? this.next() : this.prev()
      }
    }
  }
}
