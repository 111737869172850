import { Component, OnInit, Input } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { terms } from '../../app.metatags'
import { MetatagsService } from '../../services/metatags.service'
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss']
})
export class ValidatedComponent implements OnInit {
  @Input('update-title')
  public updateTitile: boolean = true
  constructor (
    private title: TitleService,
    private meta: Meta,
    private metasService: MetatagsService
  ) {
    this.metasService.updateTags(terms, this.meta)
  }

  ngOnInit() {
    if (this.updateTitile) {
      this.title.setTitle('Usuário validado - SBT')
    }
    setTimeout(function(){ window.location.href = "/" }, 5000)
  }
}
