/* const generateSlug = (title: string): string => {
  return title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
} */

import unorm from 'unorm'

const generateSlug = (title: string): string => {
  return unorm.nfd(title)
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '')
    .toLowerCase()
}

const generateTitleSlug = (title: string): string => {
  return unorm.nfd(title)
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ - /g, ' ')
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, '-')
    .toLowerCase()
}

const removeAccents = (content: string): string => {
  content = content.toLowerCase()
  content = content.replace('sbt', '').trim()
  content = content.replace(' ', '')
  content = content.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
  content = content.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
  content = content.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
  content = content.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
  content = content.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
  content = content.replace(new RegExp('[Ç]', 'gi'), 'c')

  return content
}

export { generateSlug, generateTitleSlug, removeAccents }
