import { Routes } from '@angular/router'
import { JournalismComponent } from '../pages/journalism/journalism.component'

const JournalismRoutes: Routes = [
  {
    path: 'jornalismo',
    component: JournalismComponent
  },
  {
    path: 'afiliada/:station/jornalismo',
    component: JournalismComponent
  }
]

export { JournalismRoutes }
